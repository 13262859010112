import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "./api/tournaments";
import api from "./api";
import { fetchUserTournamentsSuccess } from "redux/reducers/activeTournamentsReducer";
import {
  createTournamentParticipantSuccess,
  fetchTournamentParticipantError,
  fetchUserTournamentsError,
  fetchUserTournamentsLoading,
  fetchUserTournamentsSuccess as fetchUserTournamentsSuccessFromUserTournaments,
} from "redux/reducers/userTournamentsReducer";

export const fetchUserTournaments = (appSlug: string, country: string) => (dispatch: AppDispatch) => {
  dispatch(fetchUserTournamentsLoading());
  return new Promise((resolve, reject) => {
    api
      .get(`tournaments/users/${appSlug}/${country}/my-games/?timestamp=${Date.now()}`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response: any) => {
        dispatch(fetchUserTournamentsSuccess(response.data));
        dispatch(fetchUserTournamentsSuccessFromUserTournaments(response.data)); // TODO: remove duplicate
        resolve(response.data);
      })
      .catch((e: any) => {
        reject(e);
        dispatch(fetchUserTournamentsError());
      });
  });
};

export const registerParticipant = (tournamentId: number, userId: number) => (dispatch: AppDispatch) => {
  dispatch(fetchUserTournamentsLoading());
  return api
    .post(`tournaments/register/`, {
      tournament: tournamentId,
      user: userId,
    })
    .then(response => {
      dispatch(createTournamentParticipantSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(fetchTournamentParticipantError());
      throw e;
    });
};
