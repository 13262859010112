import { css } from "@emotion/react";

const jazzcashStyles = (theme: ITheme) => css`
  &.jazzcash {
    .payment-btn {
      color: #ffc20c !important;
    }
    .cancel-btn {
      background: #ffffff !important;
      border-color: #000000 !important;
      border: 1px solid !important;
      color: #000000 !important;
    }
    .rank-list-container {
      .unlock-style {
        background: #808080 !important;
        /* box-shadow: 0 2px 15px ${theme.button.primaryGradientStart} !important; */
      }
    }
  }
`;

export default jazzcashStyles;
