import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/Store";

export interface ScoreState {
  max_scores: any;
  loading: boolean;
  error: boolean;
}

const initialState: ScoreState = {
  max_scores: {},
  loading: false,
  error: false,
};

const maxScoreSlice = createSlice({
  name: "maxScore",
  initialState,
  reducers: {
    setMaxScore(state, action) {
      state.max_scores = {
        ...state.max_scores,
        [action.payload.tournamentId]: action.payload.score,
      };
      state.error = true;
    },
  },
});

export const { setMaxScore } = maxScoreSlice.actions;

export default maxScoreSlice.reducer;

export const setUserScore = (tournamentId: any, score: number) => (dispatch: AppDispatch) => {
  dispatch(setMaxScore({ tournamentId, score }));
};
