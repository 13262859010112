import React, { useEffect, useRef, useState } from "react";
import { css, useTheme } from "@emotion/react";
// import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { CommonModal } from "../../Modals/CommonModal";
import GoamaVoucherPaymentService from "services/payment/goama/GoamaVoucherPaymentService";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { fetchUserTournaments } from "actions";
import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import { DynamicTranslation } from "components/common/DynamicTranslation";
import { isStringEqual } from "utils";
import { APP_SLUGS } from "constants/constants";
import { resetCurrentModalName, setCurrentModalName } from "redux/reducers/layoutReducer";

// type: redeem | payment
export default function VoucherPaymentModal({ type, show, onHide, onSuccess = () => {}, tournament }: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { useSuspense: false });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [code, setCode] = useState<string | null>(null);
  const theme: ITheme = useTheme();

  const { application, user, country } = useAppSelector((reduxState: any) => reduxState.common);

  const inputRef = useRef<HTMLInputElement>(null);

  const isLpl = isStringEqual(application?.slug, APP_SLUGS.LPL);

  useEffect(() => {
    if (show) {
      focusInput();
      dispatch(
        setCurrentModalName({
          modal_name: "voucher",
        }),
      );
    } else {
      unFocusInput();
      dispatch(resetCurrentModalName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const modalBody = () => {
    if (redeemSuccess) {
      return (
        <div>
          <b>
            <Trans i18nKey="coupon-applied-">Coupon Applied Successfully</Trans>
          </b>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <b
          css={{
            color: "red",
          }}
        >
          <DynamicTranslation text={errorMessage} />
        </b>
      );
    } else {
      return (
        <b>
          <Trans i18nKey="enter-valid-vou">
            Please enter a valid voucher <br />
            code
          </Trans>
        </b>
      );
    }
  };

  const onApply = () => {
    if (!code) return;
    setLoading(true);

    const paymentObj = new GoamaVoucherPaymentService({
      country,
      application,
      user,
    });

    if (type === "redeem") {
      paymentObj
        .initiateRedeemVoucher(code)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((response: any) => {
          dispatch(fetchTokenBalance(paymentObj.country));
          setRedeemSuccess(true);
        })
        .catch(e => {
          // console.log("error", e.response);
          setErrorMessage(e.response.data.error);
        })
        .finally(() => setLoading(false));
    } else {
      paymentObj
        .initiatePayment({
          tournament,
          extraData: {
            voucher_code: code,
          },
        })
        .then(() => {
          paymentObj.verifyPayment().then(() => {
            setVerifySuccess(true);
            // onSuccess();
          });
          setRedeemSuccess(true);
        })
        .catch(error => {
          setErrorMessage(error.response.data.error);
        })
        .finally(() => {
          setCode(null);
          setLoading(false);
        });
    }
  };

  const closeModal = () => {
    if (verifySuccess) {
      dispatch(fetchUserTournaments(application?.slug, country));
      onSuccess();
    }

    setCode(null);
    setRedeemSuccess(false);
    setErrorMessage("");
    onHide();
  };

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  const unFocusInput = () => {
    inputRef?.current?.blur();
  };

  // if (loading) return <LoadingIndicator size={2} color="red" />;

  return (
    <CommonModal
      show={show}
      loading={loading}
      onHide={closeModal}
      style={voucherModalDesign}
      footer={
        !loading &&
        (redeemSuccess ? (
          <div className="voucher-modal-footer">
            <button className="btn-apply-code" onClick={closeModal}>
              <Trans i18nKey="ok">OK</Trans>
            </button>
          </div>
        ) : (
          <div className="voucher-modal-footer">
            <button className="btn-apply-code" onClick={onApply}>
              <Trans i18nKey="apply-code">Apply Code</Trans>
            </button>
          </div>
        ))
      }
    >
      {loading ? (
        <div className={"d-flex justify-content-center"}>
          <LoadingIndicator size={2} color={theme.button.primaryGradientStart} />
        </div>
      ) : (
        <div className="text-center title-md">
          {modalBody()}
          {!redeemSuccess && (
            <div className="mt-3">
              <input
                ref={inputRef}
                name="voucher"
                type="text"
                autoComplete="off"
                className="ps-2 input-style"
                style={{
                  borderRadius: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "15px",
                  width: "85%",
                  border: "none",
                  backgroundColor: "aliceblue",
                }}
                placeholder={t("enter-voucher-c", {
                  defaultValue: "Enter Voucher Code",
                })}
                onChange={e => {
                  setCode(e.target.value);
                  focusInput();
                }}
                onClick={() => focusInput()}
              />
            </div>
          )}
          {isLpl && (
            <div>
              <h6 className="pt-3">
                <Trans i18nKey="dont-have-a-vou">Don&apos;t have a voucher? Get one now at &nbsp;</Trans>
                <a href="https://letsplay.live/casual-gaming/">https://letsplay.live/casual-gaming/</a>
              </h6>
            </div>
          )}
        </div>
      )}
    </CommonModal>
  );
}

const voucherModalDesign = (theme: ITheme) => css`
  .modal-title {
    font-size: 15px !important;
    font-weight: 600;
  }
  .voucher-modal-footer {
    /* visibility: hidden; */
    .btn-apply-code {
      border: none;
      color: ${theme.button.buttonTextColor};
      text-align: center;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      max-width: 86%;
      margin: 0 auto;
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      ) !important;
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      @media screen and (min-width: 360px) {
        height: 46px !important;
        font-size: 15px !important;
      }

      @media screen and (max-width: 320px) {
        height: 40px !important;
        font-size: 12px !important;
      }
    }
  }
`;
