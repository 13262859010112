import React, { useState, useEffect, useCallback } from "react";
import { css, useTheme } from "@emotion/react";

import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/Store";
import PaymentPoller from "utils/paymentPoller";
import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useTokenTournament from "hooks/useTokenTournament";
import { useHistory } from "hooks/useHistory";
import AuthModal from "components/feature-specific/auth/AuthModal";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { fetchUserTournaments } from "actions";
import { pvpSearchOpponent } from "redux/reducers/uiReducer";
import EntryFee from "components/common/EntryFee";
import { fetchStatusPoints } from "redux/reducers/statusPointReducer";
import { toggleGameListPopup, togglePvPTournament } from "redux/reducers/modalReducer";

const backgroundStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
  background-color: ${theme.background.primary};

  .modal {
    z-index: 10999;
  }

  &.header {
    border: none;

    button.close {
      color: ${theme.text.primary};
      text-shadow: none;
    }
  }

  .close {
    &:focus {
      outline: none;
    }
  }
`;

const errorStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
`;

const buttonStyle = (theme: ITheme) => css`
  border: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
  padding: 8px 20px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  background: linear-gradient(
    270deg,
    ${theme.button.primaryGradientStart} 0%,
    ${theme.button.primaryGradientStop} 122.67%
  );

  &:hover {
    text-decoration: none;
    color: white;
  }
`;

export const WalletTokenPaymentErrorModal = ({ onRetry, ...domProps }: any) => {
  return (
    <Modal {...domProps} centered css={errorStyle}>
      <Modal.Header closeButton className="header" css={backgroundStyle}>
        <b>
          <Trans i18nKey="error">Error</Trans>
        </b>
      </Modal.Header>
      <Modal.Body className="text-center body-style" css={backgroundStyle}>
        <p>
          <Trans i18nKey="something-went-">Something Went wrong!</Trans>
        </p>
      </Modal.Body>
      <Modal.Footer className="footer-style" css={backgroundStyle} style={{ borderTop: "none" }}>
        <Button css={buttonStyle} onClick={onRetry}>
          <Trans i18nKey="try-again">Try Again</Trans>
        </Button>
        <Button css={buttonStyle} onClick={domProps.onHide}>
          <Trans i18nKey="cancel">Cancel</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const WalletTokenPaymentModal = ({
  value,
  tournament_id,
  show,
  onHide,
  paymentType,
  partialUpdateModals,
  stateKey = "payment",
  stateVerificationKey = "verification",
  stateErrorKey = "paymentError",
  tournament,
  ...domProps
}: any) => {
  const closeModal = () => {
    onHide();
  };

  const theme: ITheme = useTheme();

  const { buttonLoading, onPaymentConfirmationClick } = useTokenTournament(value, tournament_id, paymentType, {
    handlePayment: {
      onSuccess: () => {
        partialUpdateModals({
          [stateVerificationKey]: true,
          [stateKey]: false,
        });
      },
      onError: () => partialUpdateModals({ [stateErrorKey]: true, [stateKey]: false }),
    },
  });

  return (
    <Modal
      {...domProps}
      onHide={closeModal}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="header" css={backgroundStyle}>
        <b>
          <Trans i18nKey="payment-confirm">Payment Confirmation PVP</Trans>
        </b>
      </Modal.Header>
      <Modal.Body className="body" css={backgroundStyle}>
        <div className="d-flex col-12 p-0">
          <Trans i18nKey="are-you-sure-yo">
            Are you sure you want to pay&nbsp;
            <b>
              <EntryFee
                payMethod={paymentType}
                entry_fee={value}
                tournamentFeeCurrency={tournament?.entry_fee_currency}
              />
            </b>
            ?
          </Trans>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer" style={{ borderTop: "none" }} css={backgroundStyle}>
        <Button as="a" disabled={buttonLoading} onClick={onPaymentConfirmationClick} css={buttonStyle}>
          {buttonLoading ? (
            <LoadingIndicator size={1.4} color={theme.button.buttonTextColor} />
          ) : (
            <Trans i18nKey="confirm">Confirm</Trans>
          )}
        </Button>
        <Button css={buttonStyle} onClick={closeModal}>
          <Trans i18nKey="cancel">Cancel</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export function WalletTokenVerifyPaymentModal({
  tournament,
  show,
  onHide,
  participant,
  type = "pvp",
  ...domProps
}: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { application, country } = useAppSelector((state: any) => state.common);
  const [showAuthModal, setAuthModal] = useState(false);

  const [modalState, setModalState] = useState<"checking" | "cancelled" | "failed" | "error" | "success">(
    participant ? "success" : "checking",
  );
  const [message, setMessage] = useState("");

  const referenceCode = (localStorage && localStorage.getItem(`pvp-token-ref${tournament?.id}`)) ?? "";

  function closeModal() {
    dispatch(fetchTokenBalance(country)); //coin
    dispatch(fetchStatusPoints()); //ticket
    onHide();
  }

  useEffect(() => {
    dispatch(fetchTokenBalance(country));
  }, [country, dispatch]);

  function verifyPayment(referenceCode: string) {
    const poller = new PaymentPoller(5, 6000);
    poller.pollPaymentStatus(referenceCode, (success, status, failedReason) => {
      if (success) {
        setModalState("success");
      } else if (status === "cancel") {
        setModalState("cancelled");
        if (failedReason) setMessage(failedReason);
      } else if (status === "failed") {
        setModalState("failed");
        if (failedReason) setMessage(failedReason);
      } else {
        setModalState("error");
      }
    });
  }

  useEffect(() => {
    if (modalState === "checking" && show && referenceCode) {
      verifyPayment(referenceCode);
    }
    if (modalState === "success" && show && referenceCode) {
      if (tournament.tournamnet_type === "instant-pvp") {
        closeModal();
        gotoPvpGame();
      }
    }
    // eslint-disable-next-line
  }, [modalState, referenceCode, show]);

  // function isRecentlyConnected() {
  //   if (localStorage) {
  //     let connectedBackends: any = localStorage.getItem("auth-backend");
  //     if (!connectedBackends) {
  //       setAuthModal(true);
  //       return false;
  //     } else {
  //       setAuthModal(false);
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  const hideAuthModal = useCallback(() => {
    setAuthModal(false);
  }, []);

  function getModalTitle() {
    switch (modalState) {
      case "failed":
      case "error":
        return <Trans i18nKey="payment-failed!">Payment Failed!</Trans>;
      case "cancelled":
        return <Trans i18nKey="payment-cancell">Payment Cancelled</Trans>;
      case "success":
        return <Trans i18nKey="payment-success">Payment Successful!</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying Payment</Trans>;
    }
  }

  function getModalBody() {
    const commonErrorMessage = <Trans i18nKey="your-payment-co">Your Payment could not be verified.</Trans>;
    switch (modalState) {
      case "error":
        return commonErrorMessage;
      case "failed":
        return message || commonErrorMessage;
      case "cancelled":
        return message || <Trans i18nKey="your-payment-ca">Your Payment was cancelled.</Trans>;
      case "success":
        if (tournament.tournament_type === "instant-pvp") {
          closeModal();
          gotoPvpGame();
        }

        return <Trans i18nKey="your-payment-su">Your payment was successful.</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying your payment. Please Wait.</Trans>;
    }
  }

  function gotoPvpGame() {
    dispatch<any>(fetchUserTournaments(application?.slug, country)).then(() => {
      const url = window.location.href;
      if (url.includes("pvp-page")) {
        closeModal();
        dispatch(togglePvPTournament());

        dispatch(toggleGameListPopup());
        dispatch(pvpSearchOpponent());
      } else {
        dispatch(togglePvPTournament());
        dispatch(toggleGameListPopup());
        history.push(`/pvp-page/${tournament?.id}`);
      }
    });
  }

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        size="lg"
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        {...domProps}
      >
        <Modal.Header className="header" css={backgroundStyle}>
          <b>{getModalTitle()}</b>
        </Modal.Header>
        <Modal.Body className="body" css={backgroundStyle}>
          <div className="text-center">
            <p>{getModalBody()}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer" style={{ borderTop: "none" }} css={backgroundStyle}>
          {modalState !== "cancelled" && modalState !== "failed" && modalState !== "success" && (
            <Button
              onClick={() => {
                setModalState("checking");
                verifyPayment(referenceCode);
              }}
              css={buttonStyle}
              disabled={modalState === "checking"}
            >
              {modalState === "checking" && <LoadingIndicator size={1.4} color="#fff" />}
              {modalState === "error" && <Trans i18nKey="try-again">Try Again</Trans>}
            </Button>
          )}
          <Button
            css={buttonStyle}
            onClick={() => {
              if (type === "tournament" && modalState === "success") {
                closeModal();
              } else if (modalState === "success") {
                gotoPvpGame();
              } else {
                closeModal();
              }
            }}
            disabled={modalState === "checking"}
          >
            {modalState === "success" ? <Trans i18nKey="okay">Okay</Trans> : <Trans i18nKey="cancel">Cancel</Trans>}
          </Button>
        </Modal.Footer>
      </Modal>
      <AuthModal show={showAuthModal} onHide={hideAuthModal} tournamentId={tournament?.id} />
    </>
  );
}
