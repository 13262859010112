import React, { Fragment, lazy, useEffect } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "redux/Store";

import motion from "utils/polyFilledMotion";
import InactiveTournament from "../tournaments/InactiveTournament";
import MediumLoadingIndicator from "components/common/LoadingIndicator/MediumLoadingIndicator";

const GGameListAccordionItem = lazy(() => import("../../old/GameListAccordion/GameListAccordionItem"));

import { fetchUserTournaments } from "actions";

const backgroundStyle = () => css`
  border-radius: 12px;
  margin-bottom: 30px;
`;

const errorMessageStyle = css`
  width: 100%;
  min-height: 300px;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
`;

export default function MyTournamentsList({ mygamesurlpopup }: any) {
  const dispatch = useAppDispatch();
  const { application, country } = useAppSelector((state: any) => state.common);
  const { data, loading, error } = useAppSelector((state: any) => state.userTournaments);
  const tournaments = data.filter((a: any) => a.tournament.tournament_type !== "instant-pvp");

  useEffect(() => {
    dispatch(fetchUserTournaments(application?.slug, country));
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <MediumLoadingIndicator height={350} />;
  } else if (tournaments.length === 0) {
    return <InactiveTournament />;
  } else if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center" css={errorMessageStyle}>
        <h4 className="subtitle">Something Went Wrong!</h4>
      </div>
    );
  }

  return (
    <div css={backgroundStyle}>
      {tournaments.map((item: any, index: number) => (
        <Fragment key={item?.tournament?.id}>
          <motion.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.1 * (index + 1) }}
          >
            <GGameListAccordionItem
              key={index}
              gameData={item.game_data}
              tournaments={[item.tournament]}
              browseGameItem={true}
              gameBanner={item.tournament.game_banner}
              rank={item.rank}
              mygamesurlpopup={mygamesurlpopup}
            />
          </motion.div>
        </Fragment>
      ))}
    </div>
  );
}
