import React from "react";
import { css } from "@emotion/react";
import { Modal } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";

const CommonSubscriptionModalStyle = () => css`
  .modal-content {
    max-width: 380px;
    min-height: 410px;
    padding: 1rem;
    margin: 0 auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 3px 29px 0px rgba(150, 150, 150, 0.21);
    justify-content: space-between;
  }

  .subscription-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 0;
    text-align: center;
    .subscription-modal-title {
      h6 {
        font-size: 1.125rem;
        margin-top: 1rem;
        color: #000000;
      }
    }
  }

  .subscription-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 220px;
  }

  .subscription-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    button {
      width: 224px;
      height: 42px;
      box-shadow: 0px 9px 10px 0px rgba(231, 102, 102, 0.35);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 17px;
      background: #ed1c24;
      min-height: 34px;
      font-weight: 400;
      color: #e6e6e6;
      outline: none;
      border: none;
      font-size: 14px;
      padding: 4px 12px;
      transition: transform 0.2s ease-in-out;

      &:active {
        outline: none;
        transform: scale(0.91);
      }

      @media (min-width: 420px) {
        font-size: 1rem;
        padding: 8px 25px;
      }
    }
  }
  .back-btn {
    outline: none;
    border: none;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
    background: transparent;
  }
`;

export const CommonSubscriptionModal = ({
  show = false,
  onHide,
  children,
  style,
  logo,
  title,
  footer = null,
  loading = false,
  backBtn = false,
}: any) => {
  const closeModal = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => !loading && closeModal()}
      centered
      css={[CommonSubscriptionModalStyle, style]}
      className="subscribe-confirmation-modal"
    >
      <div className="subscription-modal-header px-3">
        {backBtn && (
          <button className="back-btn" onClick={closeModal}>
            <FaArrowLeft />
          </button>
        )}
        {logo && <img src={logo} alt="logo" width="50%" />}
        <div className="subscription-modal-title">{title}</div>
      </div>
      <div className="subscription-modal-body">{children}</div>
      <div className="subscription-modal-footer">{footer}</div>
    </Modal>
  );
};

export default CommonSubscriptionModal;
