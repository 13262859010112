import api from "actions/api";
// import payments from "actions/api/payments";
import history from "HistoryWrapper";

type PaymentPollStatusCallback = (success: boolean, status: string, failedReason?: string) => void;

/**
 * constructor( number of retries, interval in ms )
 */
export default class PaymentPoller {
  private retries: number;
  private interval: number;
  public checking: boolean;

  constructor(retries: number, retryIntervalMilliseconds: number) {
    this.retries = retries;
    this.interval = retryIntervalMilliseconds;
    this.checking = true;
  }

  private getPaymentStatus = async (referenceCode: string) => {
    try {
      const response = await api.get(`payments/verify/${referenceCode}/?verifyPaymentTimestamp=${Date.now()}`);
      const { data } = response;
      if (sessionStorage) {
        sessionStorage.setItem("congrats", JSON.stringify(data));
      }

      history.replace({ search: "" });
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  public pollPaymentStatus(
    referenceCode: string,
    onComplete: PaymentPollStatusCallback,
    // application?: IApplication,
  ): void {
    setTimeout(() => {
      (async () => {
        const data = await this.getPaymentStatus(referenceCode);
        if (data?.status === "done") {
          onComplete(true, data.status);
        } else if (data?.status === "cancel" || data?.status === "failed") {
          onComplete(false, data?.status, data?.failed_reason);
          history.replace({ search: "" });
        } else {
          this.retries--;
          if (this.retries === 0) {
            onComplete(false, data?.status);
            history.replace({ search: "" });
          } else {
            this.pollPaymentStatus(referenceCode, onComplete);
          }
        }
      })();
    }, this.interval);
  }
}
