import React, { useState , useEffect } from "react";
import { css } from "@emotion/react";
import { useAppSelector } from "redux/Store";
import PrizesSliderItem from "./PrizesSliderItem";


const PrizesSlider = ({
  game,
  currentTournament,
  onTournamentChange = () => {}
}: {
  game: IGame;
  currentTournament: ITournament;
  onTournamentChange?: Function;
}) => {
  const [tournaments, setTournaments] = useState<ITournament[]>([]);
  const { data } = useAppSelector((state: any) => state.tournaments);
  useEffect(
    () => {
      const gameWiseTournamentData = data?.find(
        (d: any) => d.game_data?.slug === game?.slug
      );
      let allTournaments: ITournament[] =
        gameWiseTournamentData?.tournaments || [];
      allTournaments = allTournaments.filter(
        (tourni: ITournament) => tourni?.id !== currentTournament?.id
      );
      allTournaments.unshift(currentTournament);
      setTournaments(allTournaments);
    },
    // eslint-disable-next-line
    []
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(
    () => {
      if (tournaments?.[selectedIndex]) {
        onTournamentChange(tournaments[selectedIndex]);
      }
    },
    // eslint-disable-next-line
    [selectedIndex]
  );

  return (
    <div css={baseCss} className="prizes-slider-container">
      {currentTournament && (
        <PrizesSliderItem
          showNext={selectedIndex < tournaments.length - 1}
          showPrevious={selectedIndex > 0}
          onNext={() => {
            if (selectedIndex + 1 <= tournaments.length - 1) {
              setSelectedIndex(selectedIndex + 1);
            }
          }}
          onPrevious={() => {
            if (selectedIndex - 1 >= 0) {
              setSelectedIndex(selectedIndex - 1);
            }
          }}
          tournament={currentTournament}
          game={game}
        />
      )}
    </div>
  );
};

export default PrizesSlider;

const baseCss = (theme: ITheme) => css`
  &.prizes-slider-container {
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: ${theme.foreground.primary};
    border-radius: 15px;
  }
`;
