import { css } from "@emotion/react";

const dibbleStyles = (theme: ITheme) => css`
  &.dibble {
    .mission-page-container {
      padding: 0px 0px !important;
      padding-top: 25px;
      padding-bottom: 0;
      .mission-nav-bar-container {
        width: 98%;
      }
      .section-header {
        padding-left: 20px;
        margin-top: 30px;
      }
      .navbar-top-logo-2 {
        &.TOP_LEFT {
          margin-left: 8px;
        }
      }
    }

    .gamename-style {
      color: #ffffff;
    }

    .recommended-games {
      .header-style {
        color: #ffffff !important;
      }

      .list-heading {
        .text-start {
          color: #ffffff !important;
        }
      }
    }

    .rank-list-card {
      .reward-sty {
        color: #ffffff !important;
      }

      .list-heading {
        .text-start {
          color: #ffffff !important;
        }

        .name-style {
          color: #ffffff !important;
        }

        .text-end {
          color: #ffffff !important;
        }
      }
    }

    .rank-list-container {
      .show-more {
        color: #ffffff !important;
      }
    }

    .active_progress_bottom_curve,
    .modal_header,
    .desktop-loader,
    .game-loading-overlay,
    .modal_body,
    button > .loading-style,
    .recommended-games,
    .leaderboard-body {
      background: ${theme.background.gradientStart};
    }

    .no-back-btn {
      width: 100%;
    }
    .ticket-status {
      box-shadow: none;
      background-color: ${theme.text.tertiaryTextColor};
    }
    .icon-play {
      fill: ${theme.button.secondaryButtonBackground};
    }
    .rank-btn {
      .rank-icon {
        fill: ${theme.button.secondaryButtonBackground} !important;
      }
    }
    .play-btn-browse {
      background: ${theme.foreground.secondary} !important;
      .play-icon {
        fill: ${theme.button.secondaryButtonBackground} !important;
      }
    }
    .triangle-button {
      background: ${theme.foreground.secondary} !important;
    }
    .play-button {
      background: ${theme.foreground.secondary} !important;
    }
    .progress-share-overlay {
      .half-style {
        background: ${theme.background.gradientStart};
      }
      .board-container {
        .tile {
          fill: ${theme.foreground.secondary};
        }
        .label-style {
          background: ${theme.text.tertiaryTextColor};
        }
        .score {
          color: ${theme.background.gradientStart};
        }
      }
      .navbar-top-logo-2 {
        &.TOP_LEFT {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    .first-section {
      .header-text {
        color: #ffffff;
      }
    }
  }
`;

export default dibbleStyles;
