// import paymentsAPI from "actions/api/payments";
import { useAppSelector } from "redux/Store";
import { useState, useContext } from "react";

import { SdkContext } from "providers/client-sdk-providers";
import api from "actions/api";

function generateRedirectURL(queryParams: any) {
  const location = window.location;
  const baseURL = `${location.protocol}//${location.host}/token-profile`;

  let params = "";

  Object.keys(queryParams).map(key => {
    const value = encodeURIComponent(`${queryParams[key]}`);
    params += `${key}=${value}&`;
    return null;
  });
  return `${baseURL}?${params}`;
}

interface IUseAddTokenNowButtonConfig {
  handlePayment?: {
    onSuccess?: Function;
    onError?: Function;
    onFinally?: Function;
  };

  footer?: {
    onClick: Function;
  };
}

export default function useTokenAddButton(value: any, config?: IUseAddTokenNowButtonConfig) {
  const { application, country, user } = useAppSelector((state: any) => state.common);
  const sdk = useContext(SdkContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onPaymentConfirmationClick = (e: any): any => {
    const queryParams: any = {
      country,
      callback: true,
      app: application?.slug,
      userid: user.username.split("__")[0],
    };
    if (application.is_custom_avatar_enabled && user.extra_data.profile_pic) {
      queryParams.profile_pic = user.extra_data.profile_pic;
    }
    if (application.is_custom_avatar_enabled && user.first_name) {
      queryParams.name = user.first_name;
    }

    setButtonLoading(true);

    // Make call to paymentsAPI
    return api
      .post(`payments/init/${application?.slug}/`, {
        token: true,
        amount: value.amount, // NOTE: backend doesn't match, it price means amount there
        token_package_id: value.id,
        redirect_link: generateRedirectURL(queryParams),
      })
      .then(response => {
        // Set last reference id into localStorage
        // For verifying upon callback redirect
        localStorage?.setItem(`token-buy-ref`, response.data.reference);
        return response;
      })
      .then(response => {
        if (config?.handlePayment?.onSuccess) config.handlePayment.onSuccess(response);
        return response;
      })
      .then(response => {
        if (sdk?.postPaymentConfirmationCallback) {
          sdk.postPaymentConfirmationCallback(response.data);
        } else {
          window.location.replace(response.data?.deeplinkURL || response.data?.checkoutURL || "#");
        }
      })
      .catch(e => config?.handlePayment?.onError && config.handlePayment.onError(e))
      .finally(() => {
        setButtonLoading(false);
        config?.handlePayment?.onFinally && config.handlePayment.onFinally();
      });
  };

  return {
    onPaymentConfirmationClick,
    buttonLoading,
    setButtonLoading,
  };
}
