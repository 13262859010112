import { css } from "@emotion/react";

const chopeStormsStyles = css`
  &.chope-storms {
    .registration-text {
      color: #000000;
    }
  }
`;

export default chopeStormsStyles;
