import { css } from "@emotion/react";

const lplStyles = css`
  &.lpl {
    .top-bar-container {
      .avatarCss {
        box-shadow: none;
      }
    }
    .sidebar-container {
      .icon-container {
        background: #191919;
      }
      a:not(.active-link) {
        .link-label,
        .link-img {
          color: #565656 !important;
          fill: #565656 !important;
        }
      }
      .link-label,
      .link-img {
        color: #565656 !important;
      }

      .active-link {
        .link-img {
          fill: #ffffff;
          color: #ffffff;
        }
        .link-label {
          color: #ffffff !important;
        }
      }
    }
    .profile-history-wrapper {
      .back-ground {
        color: #a8a8a8;
      }
      .list-group-item {
        background-color: #2e2e2e;
        border: 1px solid #2e2e2e;
      }
    }
    .profile-page {
      .top-bar-wrapper {
        .support-style {
          background-color: #2e2e2e;
        }
      }
      #icon-edit-profile {
        fill: #ffffff;
      }
    }
    .lps-modal-content {
      background-color: #191919;
      border-radius: 0 !important;
      .close {
        color: #ffffff;
        box-shadow: none;
      }
    }
    .swiper-wrapper {
      .top-bar-container {
        background-color: #2e2e2e;
        color: #ffffff;
      }
      .game-name,
      .tournament-subtitle,
      .ranking-button {
        color: #ffffff;
      }
    }
    .clock-participant-bg-svg {
      border: 1px solid #a8a8a8;
      border-radius: 15px;
      padding: 2px;
      svg {
        fill: #2e2e2e;
      }
    }
    .clock-participant-text {
      color: #ffffff;
    }
    .clock-participant-text {
      svg {
        fill: #fff;
      }
    }
    .draggable-modal {
      .modal_body,
      .modal_header {
        background-color: #191919;
      }
      .img-style {
        box-shadow: none;
      }
      color: #ffffff;
      .btn-style {
        color: #ffffff !important;
        border: 1px solid #565656;
      }
      .rank-btn,
      .play-btn-browse {
        background-color: #767676;
        background: #767676;
        box-shadow: none;
        svg {
          fill: #ffffff;
          color: #ffffff;
        }
      }
      .label-no-ranks {
        color: #ffffff;
      }
      .heading-border {
        border-color: #565656;
      }
      .drag-handle:after {
        background-color: #565656;
      }
      .leaderboard-popup-item {
        background-color: #191919;
      }
      .small-card {
        background-color: #2e2e2e;
      }
      .lower-div-item {
        border-color: #565656;
      }
      .list-heading {
        color: #767676 !important;
      }
      .rank-list-container {
        .li {
          background-color: #2e2e2e;
        }
      }
    }
    .browse-games-container {
      .arcade-icon,
      .icon-play {
        fill: #ffffff;
      }
    }
    .triangle-button {
      box-shadow: none !important;
      border: 1px solid #a8a8a8 !important;
    }
    .game-loading-overlay {
      background: #2e2e2e;
    }
    .progress-bar {
      box-shadow: none !important;
    }
    .leaderboard-container {
      .prizes-slider-item {
        background-color: #2e2e2e;
      }
    }
    .recommended-tournament-container {
      .header-style {
        color: #ffffff;
      }
    }
    .triangle-button {
      svg {
        fill: #ffffff;
      }
    }
    .player-numb {
      .arcade-icon {
        fill: #ffffff;
      }
    }
    .progress-share-overlay {
      .latest-score-label,
      .latest-score,
      .name-style,
      .score {
        color: #ffffff;
      }
      .label-style {
        background: #565656;
      }
      .btn-container {
        border-color: #565656;
      }
    }
    .my-games-popup {
      .rank-icon,
      .play-btn svg,
      .star {
        fill: #ffffff;
      }
      .page-title {
        h1 {
          color: #ffffff;
        }
      }
      .card {
        background-color: #565656;
      }
      .subtitle-text,
      .iconLabel-style {
        color: #a8a8a8;
      }
      .rank-btn {
        background-color: #767676;
        border: 0;
      }
    }
    .icon,
    .played-count {
      fill: #a8a8a8;
      color: #a8a8a8;
    }
    .see-style {
      color: #2ed88b;
    }
    .browse-game-item {
      .rank-btn {
        background: #767676;
        box-shadow: none;
        border: 0;
        .rank-icon {
          fill: #ffffff;
        }
      }
      .play-icon,
      .star {
        fill: #ffffff;
      }
      .iconLabel-style,
      .list-price {
        color: #a8a8a8;
      }
      .icon-style {
        fill: #ffffff;
      }
      .text-marquee {
        color: #a8a8a8;
      }
    }
    .play-button {
      box-shadow: none !important;
      .font-play {
        color: #ffffff;
      }
    }
    .play-game-close-btn {
      background: #000000;
      svg {
        fill: #ffffff;
      }
    }
  }
`;

export default lplStyles;
