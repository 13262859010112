import React from "react";
import { css , useTheme } from "@emotion/react";
import { darken } from "polished";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import gift from "assets/images/gift-box.png";
import { ReactComponent as Close } from "assets/images/closedown.svg";
import { clearNotificationPopUpItem } from "redux/reducers/notificationReducer";
import { useAppDispatch } from "redux/Store";
import { slugifyStringForTranslation } from "utils";

const notificationPopUP = (theme: ITheme) => css`
  .modal-content {
    border-radius: 15px;
    width: 100%;
    border: 3px solid #ffffff;
    box-shadow: 0 10px 20px ${theme.button.primaryShadow};
  }

  .modal-dialog {
    margin: 0.2rem;
  }

  .modal-backdrop.fade {
    opacity: 0.2 !important;
  }

  .img-icon {
    width: 40px;
    height: 40px;
  }

  .title-msg {
    font-size: 12px;
    font-weight: 600;
    color: ${theme.button.primaryGradientStart};

    @media (min-width: 1022px) and (max-width: 2000px) {
      color: white;
    }
  }

  .end-timer {
    font-size: 12px;
    @media (min-width: 1022px) and (max-width: 2000px) {
      color: white;
    }
  }

  .list-group-item {
    /* border-bottom: 0.5px solid #ffffff; */
    background-color: ${darken(0.1, theme.background.primary)};
    border-radius: 15px;
  }

  .list-group-item:last-child {
    border-bottom: none;
  }

  .parent {
    border-bottom: 0.5px solid #ffffff;
  }

  /* .parent:last-child {
    border-bottom: none;
  } */

  .btn {
    border: none;
    font-size: 13px;
  }
`;

export default function NotificationPopUp({ onHide, data, show }: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { useSuspense: false });
  data.length === 0 && onHide();

  const generatedTitles = data?.map((d: any) => {
    const title = d?.event;
    let n;
    const numberMatch = d?.event.match(/(\d+)/);
    if (numberMatch) {
      n = numberMatch[0];
    }
    return {
      defaultValue: title,
      n,
      key: slugifyStringForTranslation(d?.event.replace(/\d+/g, "{{n}}"))
    };
  });

  const generatedMessage = data?.map((d: any) => {
    const title = d?.msg;
    let n;
    const numberMatch = d?.msg.match(/(\d+)/);
    if (numberMatch) {
      n = numberMatch[0];
    }
    return {
      defaultValue: title,
      n,
      key: slugifyStringForTranslation(d?.msg.replace(/\d+/g, "{{n}}"))
    };
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      closeButton
      size="lg"
      top
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 100000 }}
      css={notificationPopUP}
      className="desktop-modal p-0 notification-pop-up"
      backdrop={false}
      // autoFocus={false}
    >
      <div className="">
        <li
          className="list-group-item p-0 "
          css={css`
            border: none;
          `}
        >
          {data.map((d: any, index: any) => (
            <div
              key={d.notification_id}
              className=" pt-2 pb-2 ps-3 pe-3 parent"
            >
              <div className="d-flex flex-row justify-content-between share-container ">
                <div className="d-flex justify-content-center align-self-center">
                  <img src={gift} alt="gift-box" className="img-icon" />
                  <div className="ps-3">
                    <span className="title-msg">
                      {/* <Trans i18nKey="congratulations"> */}

                      {t(generatedTitles[index].key, {
                        defaultValue:
                          generatedTitles[index].defaultValue.toUpperCase(),
                        n: generatedTitles[index].n
                      })}
                      {/* </Trans> */}
                    </span>{" "}
                    <br />
                    <span className="end-timer">
                      {/* <Trans i18nKey="you-have-succes"> */}

                      {t(generatedMessage[index].key, {
                        defaultValue:
                          generatedMessage[index].defaultValue.toUpperCase(),
                        n: generatedMessage[index].n
                      })}
                      {/* </Trans> */}
                    </span>
                  </div>
                </div>

                <div className="button-container d-flex justify-content-center ">
                  <Close
                    onClick={() => {
                      dispatch(clearNotificationPopUpItem(d.notification_id));
                    }}
                    cursor="pointer"
                    width={10}
                    fill={theme.text.secondary}
                  />
                </div>
              </div>
            </div>
          ))}

          {data.length > 1 && (
            <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  onHide();
                  dispatch(clearNotificationPopUpItem(null));
                }}
                className="btn"
              >
                Clear All
              </button>
            </div>
          )}
        </li>
      </div>
    </Modal>
  );
}
