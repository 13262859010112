import { css } from "@emotion/react";

const bonoxsStyles = css`
  &.bonoxs {
    * {
      font-family: "Montserrat", sans-serif;
    }

    .ticket-entry-fee-container {
      img {
        transform: none !important;
      }
    }

    .btn-container > svg,
    .rank-icon {
      fill: #000000 !important;
    }

    .rank-list-container,
    .modal_header,
    .leaderboard-wrapper,
    .prize-list-container,
    .list-group-item,
    .text-header,
    .balance,
    .subtitle-4,
    .mobile-footer-page .text-center {
      color: #ae5af3 !important;
    }

    .btn-style,
    .text-center,
    .redeem,
    .img-sty,
    .background-contain > div > div,
    .name-style,
    .score,
    .prizes-slider-item,
    .prize-calculator-item,
    .btn-orange > .button-style,
    .btn-orange {
      color: #000000 !important;
    }

    .desktop-loader,
    .game-loading-overlay,
    button > .loading-style,
    .recommended-games {
      background-color: #000000 !important;
    }

    .desktop-footer {
      .footer-content {
        background-color: #1e1f22 !important;
      }
    }

    .leaderboard-popup-item {
      .gamename-style {
        color: #ae5af3 !important;
      }
    }

    .ticket-status,
    .partner-status,
    .bonoxs-ticket {
      box-shadow: #8b00f0 -1pt 6pt 0.3125rem 0rem !important;
    }

    .back-arrow {
      fill: #ae5af3 !important;
      stroke: #ae5af3 !important;
    }

    .background-contain > div > div,
    .loading-style,
    .unlock-style {
      background-color: #ae5af3 !important;
    }

    .prizes-slider-item,
    .prize-list-container,
    .leaderboard-wrapper,
    .white-card-design {
      background-color: #878787 !important;
    }

    .list-heading > div {
      color: #878787 !important;
    }

    .recommended-tournament-container {
      display: none !important;
    }

    .userprofile-background {
      background-color: #ae5af3 !important;
    }

    .ticket-status > div > img,
    .img-sty,
    .reward-img,
    .heroes img {
      transform: none !important;
    }

    .partner-status > div > img,
    .img-sty,
    .reward-img,
    .heroes img {
      transform: none !important;
    }

    .list-group-item > div > .prize-amount,
    .list-group-item > div > .prize-amount > span {
      color: #ae5af3 !important;
    }

    .token-circle > .img-sty {
      background-color: transparent !important;
    }

    .img-sty {
      padding-bottom: 0 !important;
    }

    .list-group-item > div > .prize-amount > span {
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }

    .ticket-status > div > img,
    .partner-status > div > img {
      width: 2.5rem;
    }

    .modal-content > .modal-footer > div > button {
      color: #000000 !important;
    }

    .top-tournament-card > div > div > .img-style {
      max-width: 100%;
    }

    .small-card {
      background-color: #2d2d2d;

      .name-sty,
      .text-sty-label {
        color: #ffffff;
      }

      .play-btn-browse {
        svg {
          fill: #ffffff !important;
        }
      }
    }

    .modal_container_2 {
      .title-md {
        color: #ffffff !important;
      }
    }

    .half-style {
      box-shadow: 0rem 1.4587rem 2.675rem 0rem #ae5af3f9;
    }

    .second-half {
      .btn-circle-with-icon {
        background: #ae5af3 !important;
        box-shadow: 0 12px 17px -7px #ae5af3 !important;
      }
    }
    .board-container {
      .label-style {
        background: #1e1e1e4d !important;
      }
      .tile {
        fill: #ae5af3 !important;
      }
    }

    .game-over-mission-wrapper {
      .missions__item {
        border: 3px solid #ae5af3;
        &.claimed {
          filter: grayscale(0%);
        }
        .play-button {
          color: #000000;
          background: #ae5af3;
          box-shadow: none;
          font-weight: bold;
        }
        .text-claimed {
          color: #ae5af3;
        }
        /* box-shadow: 0px 0px 5px 2px #AE5AF3; */
        background: #000000;
        .text-progress {
          color: #ffffff;
        }
        .text-title {
          color: #ffffff;
        }
        .clock-icon {
          fill: #ffffff;
        }
        .text-timer {
          color: #ffffff;
        }
      }
    }

    .recommended-games-container {
      position: relative;
      top: 0;

      .recommended-games {
        padding-top: 0 !important;
      }
    }

    .custom-page,
    .home-container,
    .tournament-games-page {
      background: #2b2d31 !important;
    }

    .browse-games-container {
      background: #313339 !important;
    }

    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background: #131010 !important;
      }
    }

    .navbar-home {
      background: #1e1f22 !important;

      .background-sty {
        background: #1e1f22 !important;
      }
    }

    .header-style {
      color: #ae5af3 !important;
    }

    .loginpopup-desktop-background {
      fill: #2b2d31 !important;
    }

    .desktop-modal-content-body {
      @media (width < 63.875rem) {
        background: #2b2d31;
      }

      .title {
        color: #ae5af3 !important;
      }

      .sign-up,
      .login {
        color: #ffffff !important;
      }
    }

    .user-profile-customization {
      .support-style {
        background: #2b2d31 !important;

        svg {
          fill: #ffffff !important;
        }
      }
    }

    .profile-history-wrapper {
      background: #1e1f22 !important;

      ul {
        background-color: #1e1f22 !important;

        .col-items {
          color: #ffffff !important;
        }
      }
    }

    .bottom-bar-inside {
      .play-button {
        background: #8b00f0 !important;
      }
    }

    .partner-status {
      background: #8b00f0 !important;

      .label-sty {
        color: #f9f9f8 !important;
      }

      svg {
        color: #ffffff !important;
      }
    }

    .single-game-card {
      .square-card {
        background: #1e1e1e;

        .rounded-div {
          background-color: #ae5af3;

          .progressContainer {
            background: #2d2d2d;

            .progressbar_wrapper {
              .RCP {
                .RCP__track {
                  stroke: #414141;
                }

                .small_circle {
                  border-color: #2d2d2d;
                }
              }
            }

            .progress_bottom_curve {
              background-color: transparent;

              .game_info {
                .time_counter_title {
                  font-size: 1rem;
                }

                .timer {
                  .clock_icon {
                    margin-right: 0.2rem;

                    svg {
                      path {
                        fill: #ffffff;
                      }
                    }
                  }

                  .timer-data {
                    font-size: 0.5rem;
                  }
                }
              }
            }
          }
        }

        .action_button {
          bottom: 0.5rem !important;

          .small_button {
            background: #ae5af3;
            box-shadow: 0rem 0.1319rem 0.1319rem 0rem #8b00f040;
            padding: 0;
          }
        }

        &.active-card {
          background: #57148e !important;
          border: none;
          box-shadow: 0rem 0.1319rem 0.9906rem 0rem #7100ce80;

          .rounded-div {
            background: radial-gradient(50% 50% at 50% 50%, #8548a6 0%, #3f0260 53%);

            .progressContainer {
              background: radial-gradient(50% 50% at 50% 50%, #8548a6 0%, #3f0260 53%);

              .progressbar_wrapper {
                .RCP {
                  .RCP__track {
                    stroke: #414141;
                  }

                  .small_circle {
                    border-color: #2d2d2d;
                  }
                }
              }

              .progress_bottom_curve {
                background-color: #4a008f !important;

                .game_info {
                  span:first-of-type {
                    color: #ae5af3;
                    font-size: 1rem;
                  }
                }
              }
            }
          }

          .action_button {
            button {
              background: #ffffff;
              box-shadow: 0rem 0.1319rem 0.1319rem 0rem #00000040;
              color: #4a008f;
              font-size: 0.7rem;
              height: 1.7rem;
            }
          }
        }
      }
    }

    [data-rsbs-overlay],
    .leaderboard-body {
      background-color: #1e1f22;
    }

    .browse-popup {
      background-color: #1e1f22;

      .gamename-style {
        color: #ffffff !important;
      }
    }

    .white-card {
      background-color: #2d2d2d;
    }

    .rank-list-container-first {
      background-color: #2d2d2d;

      .list-heading {
        .text-style {
          color: #ffffff !important;
        }
      }
    }

    .list-group-item {
      background-color: #2d2d2d;
    }
  }
`;

export default bonoxsStyles;
