import "dsbridge";
import store from "redux/Store";

const klookSdk: ISdkResource = {
  postInitCallback: () => {
    window?.dsBridge?.call(
      "nativeShareButtonShow",
      {
        isShow: 0,
      },
      function () {
        // nothing ...
        // RollbarClient?.error("Klook", "nativeShareButtonShow callback", v);
      },
    );
  },
  goBackHome: () => {},
  shareHandler: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    text: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    score?: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    url?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tournamentId?: number,
  ) => {
    console.log("in share handler", window?.dsBridge);
    /* const shareMessage = `${text} ${url}`;
    if (window.login?.shareonsocialmedia) {
      window.login.shareonsocialmedia(shareMessage);
    } else if (window.webkit?.messageHandlers?.shareonsocialmedia) {
      window.webkit.messageHandlers.shareonsocialmedia.postMessage(
        shareMessage
      );
    } */
    const { common } = store.getState();
    let title = "Disney tickets up for grabs";
    let content = "Take the ride for daily prizes on the Klookcoaster";
    let langCode = "others";
    const { country = "US" } = common;
    if (country === "KR") {
      langCode = "ko";
    } else if (country === "SG") {
      langCode = "en-SG";
    } else if (country === "AU") {
      langCode = "en-AU";
    } else if (country === "NZ") {
      langCode = "en-NZ";
    } else if (country === "JP") {
      langCode = "ja";
    } else if (country === "HK") {
      langCode = "zh-HK";
    } else if (country === "TW") {
      langCode = "zh-TW";
    } else {
      langCode = "others";
    }
    switch (country) {
      case "HK":
        title = "送迪士尼樂園門票";
        content = "挑戰每日小遊戲，有機會獲得驚喜獎賞";
        break;
      case "TW":
        title = "送你迪士尼樂園票券";
        content = "參加每日遊戲小挑戰，就有機會贏得驚喜好禮";
        break;
      case "KR":
        title = "디즈니 덕후 인증하고 선물 받기";
        content = "매일 달라지는 선물이 준비되어 있어요.";
        break;
      case "JP":
        title = "ディズニーチケットも当たる!?";
        content = "毎日違う賞品が手に入るチャンス、今すぐ始めよう。";
        break;
      default:
        break;
    }
    // on click share btn
    window?.dsBridge?.call(
      "nativeShare",
      {
        // fixed value
        type: "Disney100",
        sourcePageIHTSpm: "Disney100_Goama",
        // stage env
        // language path needs to be mapped to the country code
        // eg:
        // goama game page: https://staging.go-games.gg/tournament?app=klook&country=SG&userid=test
        // share link: https://t54.fat.klook.io/en-SG/tetris/general/disney-100-landing/?from=goama
        url: `https://www.klook.com/${langCode}/tetris/general/disney-100-landing/`,
        // should be provided later
        title,
        content,
        imgUrl: "https://res.klook.com/image/upload/q_auto,c_fill,w_1120,h_584/v1645439471/fyhpxzhcyqrzhpxnthoo.jpg",
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (res: any) => {
        // callback, do something ..
        // console.log(res);
      },
    );
  },
  type: "external-script",
};

export default klookSdk;
