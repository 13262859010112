import { css } from "@emotion/react";

const unicoUnileverStyles = (theme: ITheme) => css`
  &.unico-unilever {
    .half-style {
      /* background: transparent !important; */
      .name-style,
      .score,
      .label-style {
        color: ${theme.text.secondary} !important;
      }
    }

    .top-bar-container {
      p {
        color: #000000 !important;
      }
    }

    .title-subtitle-container {
      .game-name {
        color: #000000 !important;
      }

      .tournament-subtitle {
        color: #000000 !important;
      }
    }

    .ranking-button {
      color: #000000 !important;
    }

    .input-field {
      color: #ffffff !important;
    }

    .update-button {
      color: #000000 !important;
    }

    .title-md,
    .show-all-text,
    .show-more,
    .close_wrapper > svg {
      color: ${theme.foreground.primary};
      fill: ${theme.foreground.primary};
    }
    .sidebar-container a:not(.active-link) .link-img,
    .sidebar-container a:not(.active-link) .link-label {
      color: ${theme.foreground.primary} !important;
      fill: ${theme.foreground.primary} !important;
    }
    .home-container {
      .header {
        color: ${theme.foreground.primary} !important;
      }
    }
    .leaderboard-popup-item {
      .gamename-style {
        color: ${theme.text.secondary} !important;
      }
      .drag-handle:after {
        background: ${theme.background.primary};
      }
      .close_wrapper > svg {
        fill: ${theme.background.primary};
      }
    }

    .leaderboard-popup-item {
      &.desktop {
        .gamename-style,
        .reward-sty,
        .name-style {
          color: ${theme.foreground.primary} !important;
        }
        .rank-list-card {
          .rank-list-container {
            .text-start {
              color: #000000 !important;
            }
          }
          .list-heading {
            .text-end,
            .text-start {
              color: #ffffff !important;
            }
          }
        }
        .close_wrapper > svg {
          fill: ${theme.foreground.primary};
        }
        .drag-handle:after {
          background: ${theme.foreground.primary};
        }
      }
    }
    .drag-handle:after {
      background: ${theme.foreground.primary};
    }
    .game-name {
      color: ${theme.button.primaryGradientStart};
    }
    .active-link {
      .link-label {
        color: ${theme.button.primaryGradientStart};
      }
    }
    .leaderboard-container {
      .prizes-slider-container {
        .title-header {
          color: ${theme.text.secondary} !important;
        }
      }
      .rank-list-card {
        .text-start {
          color: ${theme.text.secondary} !important;
        }
      }
      .play-btn-browse-container {
        .play-btn-browse {
          color: ${theme.text.secondary} !important;
        }
      }
    }
  }
`;

export default unicoUnileverStyles;
