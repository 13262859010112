import { css } from "@emotion/react";

const jedaStyles = css`
  &.jeda {
    .partner-logo {
      width: unset;
      height: 50px;
    }
    @media (max-width: 991px) {
      .top-tournaments-card_rectangular {
        button {
          width: 78px;
          font-size: 0.7rem;
        }
      }
    }
  }
`;

export default jedaStyles;
