import { css } from "@emotion/react";

const bkashStyles = css`
  &.bkash {
    #bKashFrameWrapper {
      /* height: 100vh; */
      /* position: absolute; */
      z-index: 9999999 !important;
      /* width: 100vw; */
      /* margin-top: 0; */
      /* top: 0; */
      /* position: fixed; */
    }
  }
`;

export default bkashStyles;
