import { css } from "@emotion/react";

const apostarStyles = css`
  &.apostar {
    .desk-width {
      .icon-container {
        .sub-button {
          background: #ce1230 !important;
          color: #ffffff !important;
        }
      }
    }
  }
`;

export default apostarStyles;
