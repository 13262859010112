import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as Notification } from "assets/images/icon-notification.svg";
import Avatar from "components/feature-specific/avatar/desktop/Avatar";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { useNavigate } from "react-router";
import { hideNotifications, showNotifications } from "redux/reducers/layoutReducer";

const DesktopNavBarRight = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, application } = useAppSelector((state: any) => state.common);
  const { currentRightSidebarState } = useAppSelector((state: any) => state.layout);
  const isNotificationMenuVisible = !application?.disable_notification;

  const handleAvatarClick = () => navigate("/profile");

  return (
    <div css={baseCss}>
      {user && isNotificationMenuVisible && (
        <div>
          <Notification
            onClick={() => {
              if (currentRightSidebarState !== "notification") {
                dispatch(showNotifications());
              } else {
                dispatch(hideNotifications());
              }
            }}
          />
        </div>
      )}
      {!application?.disable_profile && user?.extra_data && (
        <div>
          <Avatar userExtraData={user?.extra_data} onClick={handleAvatarClick} />
        </div>
      )}
    </div>
  );
};

const baseCss = () => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .right {
    margin-left: auto;
  }

  > :not(:last-of-type) {
    margin-right: 25px;
  }

  .notification-icon {
    width: 20px;
    height: auto;
    cursor: pointer;
  }
`;
export default DesktopNavBarRight;
