import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { useAppSelector } from "redux/Store";
import cn from "classnames";
import motion from "utils/polyFilledMotion";
import PageTitle from "components/common/PageTitle";
import useScrollToTop from "hooks/useScrollToTop";
import MyTournamentsList from "components/feature-specific/my-games/MyTournamentsList";
import { APP_SLUGS } from "constants/constants";
import { isStringEqual } from "utils";

const backGround = (theme: ITheme) => css`
  background-color: ${theme.background.primary};
  min-height: calc(100vh - 260px);
  flex-grow: 1;
  &.globe-game-page {
    background-color: transparent;
  }
  &.container {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const danaNavbar = (theme: ITheme) => css`
  position: fixed !important;
  background-color: ${theme.background.primary};
  z-index: 999;
  top: 70px;
`;

export default function MyGamesPage() {
  const { application } = useAppSelector((state: any) => state.common);
  useScrollToTop();
  return (
    <motion.div
      className="my-games-page"
      initial={{ opacity: 0, scale: 0.98 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div style={{ paddingTop: 85 }}>
        <div className="d-flex flex-column col-12" css={isStringEqual(application?.slug, APP_SLUGS.DANA) && danaNavbar}>
          <PageTitle>
            <h1
              css={css`
                font-size: 19px;
                font-weight: 600 !important;
              `}
              className="title-md page-title"
            >
              <Trans i18nKey="my-games">My Games</Trans>
            </h1>
          </PageTitle>
        </div>

        <div
          className={cn(`py-1 desktop-pg-background ${application?.slug}-game-page`, {
            "pt-4": isStringEqual(application?.slug, APP_SLUGS.DANA),
          })}
          css={backGround}
        >
          <MyTournamentsList mygamesurlpopup={true} />
        </div>
      </div>
    </motion.div>
  );
}
