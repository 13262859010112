// import { ActionTypes } from "actions";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { toggleGameListPopup } from "redux/reducers/modalReducer";

interface IBrowseGameCardProps {
  game: IGame;
  isArcade?: boolean;
  isRecommended?: boolean;
}

export default function useBrowseGameItem({ game }: IBrowseGameCardProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: any) => state.tournaments);

  const handleButtonClick = () => {
    // isRecommended && history.push("/tournament");
    dispatch(toggleGameListPopup({ gameSlug: game?.slug }));

    // setTimeout(
    //   () => {
    //     dispatch({
    //       type: ActionTypes.SHOW_GAMELIST_POPUP,
    //       gameSlug: game?.slug,
    //     });
    //   },
    //   isRecommended ? 10 : 0,
    // );
  };

  const tournaments: ITournament[] = data?.find((d: any) => d.game_data?.slug === game?.slug)?.tournaments || [];
  const tournamentWithListTitle = tournaments.find(t => !!t.list_title);
  const gameTitle = tournamentWithListTitle ? tournamentWithListTitle.list_title : game.name;

  return {
    handleButtonClick,
    gameTitle,
  };
}
