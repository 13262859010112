import React from "react";

interface AvatarFrameProps {
  className?: string;
  url: string;
}

const AvatarFrame = ({ className = "profile_avatar_frame", url }: AvatarFrameProps) => {
  if (url === "undefined" || typeof url === "undefined") return null;

  return <img src={url} className={className} alt="avatar-frame" />;
};

export default AvatarFrame;
