import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/Store";

export interface IGamePlay {
  gameStarted: boolean;
  progressShareModalVisibility: boolean;
}

const initialState: IGamePlay = {
  gameStarted: false,
  progressShareModalVisibility: false,
};

const gameplaySlice = createSlice({
  name: "gameplay",
  initialState,
  reducers: {
    startGame(state) {
      state.gameStarted = true;
    },
    endGame(state) {
      state.gameStarted = false;
    },
    showProgressShareOverlay(state) {
      state.progressShareModalVisibility = true;
    },
    hideProgressShareOverlay(state) {
      state.progressShareModalVisibility = false;
    },
  },
});

export const { startGame, endGame, showProgressShareOverlay, hideProgressShareOverlay } = gameplaySlice.actions;

export default gameplaySlice.reducer;

export const onGameStart = () => (dispatch: AppDispatch) => {
  dispatch(startGame());
};

export const onGameEnd = () => (dispatch: AppDispatch) => {
  dispatch(endGame());
};
