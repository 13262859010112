import { css } from "@emotion/react";

const darkuiStyles = (theme: ITheme) => css`
  &.darkui {
    .recommended-games {
      .header-style {
        color: #000000 !important;
      }
    }
    .tournament-list-item {
      .tries-text {
        color: #000;
      }
    }

    body {
      background-color: #171717 !important;
    }

    .modal_header {
      background-color: #000000 !important;
      border-top: 1px solid rgb(255, 255, 255);
    }

    .modal_body {
      background-color: #000000 !important;
    }

    .back-design {
      .label-sty {
        color: #000000 !important;
      }
    }

    .history-border {
      .subtitle {
        color: #000000 !important;
      }
    }

    .redeem {
      color: #000000 !important;
    }

    .card-border {
      background-color: #000000 !important;
      border-top: 1px solid rgb(255, 255, 255) !important;
    }

    .right-style {
      .token-status {
        background-color: ${theme.button.primaryGradientStart};
      }
    }

    .token-sty {
      .right-style {
        .token-status {
          border: 1px solid #ffff !important;
        }
      }
    }

    .message-card {
      color: #ffff !important;
    }

    .avatar-update-modal {
      .modal-title {
        color: #000000 !important;
      }
    }

    .scroll-bar {
      .subtitle {
        color: #000000 !important;
      }
    }

    .index-search-form {
      .input {
        background-color: #f3f3f3 !important;
      }
    }

    .Toaster__manager-top-right {
      top: 80px !important;
      .toast-title,
      .toast-close {
        color: #fff !important;
      }
    }
    .play-btn-browse-container {
      height: 80% !important;
    }
    .game-loading-overlay {
      > div {
        height: 100% !important;
        justify-content: space-between !important;
      }
      .logo {
        width: 70% !important;
        &.small {
          width: 100px !important;
        }
        &.partner {
          /* margin-top: 30% !important;
          margin-bottom: -25% !important; */
          width: 70% !important;
          margin-top: 105px !important;
          margin-bottom: -25% !important;
          margin-right: 33px !important;
          margin-left: 35px !important;
        }
      }
      .progress-bar-container {
        margin-top: 0 !important;
        .progress-bar {
          box-shadow: none !important;
          &.partner {
            background-color: #000000b0 !important;
          }
        }
      }
      .powered-by {
        width: 100% !important;
        position: relative !important;
        margin-bottom: 10% !important;
        align-self: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
    .goama-copyright-text {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .desktop-sidebar {
      .active-link .link-img {
        fill: #43d62c;
      }
    }
    .half-style {
      background: transparent !important;
      .name-style,
      .score,
      .label-style {
        color: #000000 !important;
      }
    }
    .latest-score-container > .latest-score {
      /* text-shadow: 0 2px 15px !important; */
    }
    .latest-score-container > .latest-score-label {
      /* color: #b5b5b5 !important; */
    }
    .tile {
      /* filter: drop-shadow(
        0px 2px 15px ${theme.button.primaryGradientStart}
      ) !important; */
    }
    .btn-circle-with-icon {
      /* box-shadow: 0 2px 30px ${theme.button.primaryGradientStart} !important; */
    }

    .top-tournament-card .game-name,
    .top-tournament-card .btn,
    .top-tournament-card .participant,
    .top-tournament-card .prize,
    .top-tournament-card .rank,
    .top-battles-card .game-name,
    .top-battles-card .count,
    .trending-game-carousel .played-count,
    .browse-game-item .rank-btn,
    .browse-game-item .play-btn,
    .game-card .played-count,
    .leaderboard-popup-item .gamename-style,
    .user-achievements .title,
    .goama-copyright-text,
    .tournament-list-popup .contain-style > span {
      color: #ffffff !important;
      svg {
        fill: #fff !important;
      }
    }
    .tournament-info .entry-fee h5,
    .tournament-info .title-sub,
    .leaderboard-popup-item .entry-fee,
    .leaderboard-popup-item .fontStyle,
    .browse-game-item .game-name,
    .browse-game-item .label-price,
    .prize-calculator-container .fontStyle,
    .profile-history-list .list-group-item-text,
    .pvp-stats .label-font,
    .pvp-stats .value-font {
      color: #222222 !important;
    }
    .desktop-footer {
      .footer-content {
        border-top: 2px solid #43d62c;
        background-color: #000000ed;
      }
      .link-img {
        fill: #ffffff !important;
      }
      .link-label {
        color: #ffffff !important;
      }
      .active-link {
        .link-img {
          fill: #43d62c !important;
        }
        .link-label {
          color: #43d62c !important;
        }
      }
    }

    .prize-list-container {
      .fontColor,
      .mark-style,
      .label-style {
        color: #222 !important;
      }
      .btn-tap-to-see {
        box-shadow: 0px 0px 0px 3px #44d62c !important;
      }
    }

    .browse-game-item {
      .rank-btn {
        background: #000000 !important;
      }
      .play-btn {
        background: #000000 !important;
        box-shadow: 0px 0px 0px 3px #44d62c !important;
      }
    }

    .top-battles-card .arIcon,
    .top-tournament-card #icon-user,
    .game-card .icon,
    .trending-game-carousel .icon {
      fill: #ffffff !important;
    }

    .leaderboard-popup-item,
    .tournament-info-popup,
    .tournament-list-popup,
    .custom-popup,
    .achievement-popup {
      border-top: 1px solid #ffffff;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .leaderboard-popup-item .drag-handle:after,
    .achievement-popup .drag-handle:after,
    .tournament-info-popup .drag-handle:after,
    .custom-popup .drag-handle:after,
    .tournament-list-popup .drag-handle:after {
      background-color: #919191 !important;
    }

    .show-more,
    .show-all-text {
      // color: rgb(0, 0, 0);
      cursor: pointer;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      color: #000;
      font-weight: 500;
    }

    .tournament-list-popup .close,
    .achievement-popup .close,
    .tournament-info-popup .close,
    .leaderboard-popup-item .close {
      fill: #ffffff !important;
    }

    .profile-page {
      background-color: ${theme.background.primary};
      .section-title {
        background-color: transparent !important;
        color: ${theme.text.primary};
      }
    }

    .user-profile-customization .support-style {
      background: #ffffff;
      svg {
        fill: #828282 !important;
      }
    }

    .modal-btn-block {
      color: #ffffff !important;
    }

    .browse-game-item {
      .profile-icon {
        fill: #222 !important;
      }
      .iconLabel-style {
        color: #222 !important;
      }
      .img-div {
        /* box-shadow: 0 2px 10px #ffffff; */
        border-radius: 15px;
      }
    }

    .total-stats,
    .pvp-stats,
    .missions-card,
    .profile-history-list ul,
    .rank-list-container-first,
    .rank-list-container,
    .achievements-card {
      /* box-shadow: 0 2px 10px #ffffff !important; */
    }

    .missions-card {
      .in-active {
        color: #ffffff !important;
      }
      .header {
        color: #222222 !important;
      }
    }

    .prize-calculator-item {
      .label-rank {
        color: #222222 !important;
      }

      .label-prize {
        color: black !important;
      }
    }

    .achievement-history-list-item {
      .text-style-transaction,
      .avatar-name {
        color: #0c0c0c !important;
      }
    }

    .tournament-info-list {
      .white-card-design {
        /* box-shadow: 0 2px 20px #ffffff !important; */
        div:last-of-type {
          border-bottom: 0;
        }
      }
    }

    .rank-list-container-first {
      .list-heading {
        background-color: #f2f5fe !important;
      }
      .text-style {
        color: #8a8a8a !important;
      }
    }

    .rank-list-container {
      border-radius: 10px !important;
    }

    .tournament-info-popup {
      background: #171717 !important;
      .header-line,
      .competitor-name,
      .competitor-score,
      .own-score {
        color: #ffffff !important;
      }
    }

    .tournament-history-page {
      background: #171717 !important;
      .header-style {
        color: #ffffff !important;
      }
      .white-card {
        /* box-shadow: 0 2px 20px #ffffff !important; */
      }
    }

    .cta-button {
      box-shadow: 0px 0px 0px 3px #44d62c !important;
    }

    .tournament-list-popup {
      .small-card {
        box-shadow: 0 2px 20px #ffffff !important;
        .border-style {
          /* border-bottom: 1px solid #818181 !important; */
        }
      }
      .play-btn,
      .play-btn-browse {
        box-shadow: 0px 0px 0px 3px #44d62c !important;
        background: #000000 !important;
        svg {
          fill: #fff !important;
        }
      }
      .rank-btn {
        background: #000000 !important;
        box-shadow: none !important;
        svg {
          fill: #fff !important;
        }
        /* box-shadow: 0px 15px 10px #b1b1b1cc !important; */
      }
      .text-title {
        color: #2d2942 !important;
      }
      .text-sty-label {
        color: #818181 !important;
      }
      .user,
      svg {
        fill: #818181 !important;
      }
    }

    .profile-history-list {
      .header-row,
      .back-ground {
        background-color: #f2f5fe !important;
      }
      .header-text {
        color: #8a8a8a !important;
      }
    }

    .game-of-the-month {
      .header-title,
      .sub-header,
      .play-count {
        color: #ffffff !important;
      }
      svg {
        fill: #ffffff !important;
      }
      .button-style {
        /* box-shadow: 0 2px 10px ${theme.button.primaryGradientStart} !important; */
      }
    }

    .top-tournament-card {
      .icon-play {
        fill: #fff !important;
      }
      .play-button {
        box-shadow: 0px 0px 0px 3px #44d62c !important;
        background: #000000 !important;
        .font-play {
          color: #fff !important;
          font-size: 14px !important;
          font-weight: 500;
          padding-right: 2px;
        }
        > svg {
          fill: #222 !important;
        }
      }
    }

    .progress-share-overlay {
      background: #262626;
      .half-style {
        .name-style,
        .label-style {
          color: #000000 !important;
        }
        .latest-score-label {
          color: #7e7a76;
        }
      }
    }

    .custom-btn-primary {
      color: ${theme.button.primaryGradientStart} !important;
      &.has-glow {
        /* box-shadow: 0 2px 15px ${theme.button.primaryGradientStart} !important; */
      }
    }

    .razer-silver-color {
      color: #3ccff5 !important;
      fill: #3ccff5 !important;
    }

    .razer-gold-color {
      color: #fdb32b !important;
      fill: #fdb32b !important;
    }

    .custom-btn-tertiary {
      color: ${theme.button.tertiaryButtonBackground} !important;
      box-shadow: 0px 0px 0px 3px #44d62c !important;
      &.has-glow {
        /* box-shadow: 0 2px 15px ${theme.button.tertiaryButtonBackground} !important; */
      }
    }

    .rank-list-container {
      .unlock-style {
        background: #000000 !important;
        /* box-shadow: 0 2px 15px ${theme.button.primaryGradientStart} !important; */
      }
    }

    .custom-popup {
      .title-text {
        color: #ffffff !important;
      }
    }

    .prizes-slider-container {
      /* box-shadow: 0 0 8px ${theme.foreground.primary}; */
    }

    .browse-game-card {
      .triangle-button {
        background: #000000 !important;
        /* border: 3pt solid #44D62C !important; */
        box-shadow: 0px 0px 0px 3px #44d62c !important;
        /* box-shadow: none !important; */
      }
      .icon-play {
        fill: #fff !important;
      }
    }

    .rank-list-card {
      .no-rank-list {
        background: #fff !important;
        border-radius: 10px !important;
      }
      .label-no-ranks {
        color: #222 !important;
      }
      .rank-list-container-first {
        .text-style-transaction {
          color: #222 !important;
        }
      }
      .rank-list-container {
        .text-style-transaction {
          color: #222 !important;
        }
      }
      .recommended-games-card {
        .game-info {
          margin-bottom: 0 !important;
        }
      }
      /*  .show-more {
        color: #222 !important;
      } */
    }
    .board-container {
      .tile {
        fill: #44d62c !important;
      }
      .label-style {
        background: #59b726 !important;
      }
      .first-board {
        .score-body {
          border-left: 0.5px solid #000000 !important;
        }
      }
      .last-board {
        .score-body {
          border-right: 0.5px solid #000000 !important;
        }
      }
    }
    .second-half {
      .btn-circle-with-icon {
        background: #44d62c !important;
        box-shadow: none !important;
      }
    }

    /* DESKTOP */
    .congrats-modal {
      .modal-content {
        background: #000000 !important;
      }
      .title,
      .message {
        color: #fff !important;
      }
    }
    .leaderboard-popup-item,
    .tournament-info-popup,
    .tournament-list-popup,
    .custom-popup,
    .achievement-popup {
      &.desktop {
        border: 1px solid #ffffff !important;
        border-bottom: 0 !important;
      }
    }

    .desktop-container {
      /* padding: 80px 5px 0 10px !important; */
      .recommended-tournament-container {
        background: #000000 !important;
        .header-style {
          color: #ffffff !important;
        }
      }
    }

    .browse-games-container {
      &.desktop-view-container {
        background: #000000 !important;
      }
    }

    .sidebar-right-container .user-profile {
      padding: 0 !important;
    }

    .text-inactive-tournament {
      .subtitle {
        color: #919191 !important;
      }
    }

    .my-games-popup {
      h1 {
        color: #ffffff !important;
      }
    }

    .game-loading-overlay {
      &.desktop {
        .progress-bar-container {
          width: 480px !important;
        }
        .logo {
          width: 75% !important;
          &.partner {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
          &.small {
            width: 100px !important;
          }
        }
        .powered-by {
          margin-bottom: 1% !important;
        }
      }
    }
    /* END: DESKTOP */
    .css-adfwve-pvpPageStyle {
      color: #000;
    }

    .pvp-card-container,
    .start-batt {
      color: #000 !important;
    }

    .subtitle-note {
      li {
        &::before {
          border: 1px solid #fff;
        }
      }
    }

    .notification-page,
    .notification-title {
      color: #fff !important;
    }

    .single-game-card {
      .card-title {
        color: #000;
      }
    }

    .section-header {
      h4 {
        color: #fff;
      }
    }

    .progress_bottom_curve {
      /* background: #ffffff !important; */

      .time_counter_title {
        color: #fff;
      }
    }

    .title-msg,
    .end-timer {
      color: #fff !important;
    }
    .notification-style,
    .notification-body,
    .show-all-text {
      color: ${theme.text.primary};
    }
    .font-style {
      color: ${theme.text.primary};
    }
  }
  &.DARK {
    .page-container {
      background-color: #000;
    }
  }
`;

export default darkuiStyles;
