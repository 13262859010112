// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging and check if messaging is supported
isSupported()
  .then(supported => {
    if (supported) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const messaging = getMessaging(app);
      // Additional messaging setup here, if necessary
    } else {
      console.warn("Firebase Messaging is not supported in this environment.");
      // Handle the lack of messaging support in your environment appropriately
    }
  })
  .catch(error => {
    console.error("An error occurred while initializing Firebase Messaging:", error);
    // Handle initialization errors appropriately
  });

export default app;
