import React, { useEffect } from "react";
import { css, useTheme } from "@emotion/react";
import { useAppSelector } from "redux/Store";
import { useTranslation } from "react-i18next";
import BrowseGameRectangularCard from "components/feature-specific/BrowseGames/BrowseGameRectangularCard";
import Advertisement from "components/feature-specific/advertisement/Advertisement";
import { useNavigate } from "react-router-dom";
import { CommonModal } from "../CommonModal";
import { isDana } from "utils/applicationSlug";

const buttonsContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: 3rem; /* Instead of gap */
  }
`;

interface IRecommendedGamesModalProps {
  onHide: () => void;
  show: boolean;
}

const RecommendedGamesModal = ({ onHide, show }: IRecommendedGamesModalProps) => {
  const { allGames } = useAppSelector(({ arcadeGames }: any) => arcadeGames);
  const [currentGames, setCurrentGames] = React.useState<IGame[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { useSuspense: false });

  const hideAndBack = () => {
    onHide();
    navigate("/arcade");
  };

  const theme: ITheme = useTheme();

  const buttons = !isDana
    ? [
        {
          label: t("keep-playing", "Keep Playing"),
          onClick: onHide,
          className: "btn btn-primary",
          css: css`
            background-color: ${theme.button.primaryGradientStart};
            border: ${theme.button.primaryGradientStart};
            &:focus {
              background-color: ${theme.button.secondaryButtonBackground};
              border: ${theme.button.secondaryButtonBackground};
            }
          `,
        },
        {
          label: t("exit-game", "Exit Game"),
          onClick: hideAndBack,
          className: "btn btn-outline",
          css: css`
            /* color: ${theme.text.primary}; */
          `,
        },
      ]
    : [
        {
          label: t("exit-game", "Exit Game"),
          onClick: hideAndBack,
          className: "btn btn-outline",
          css: css`
            /* color: ${theme.text.primary}; */
          `,
        },

        {
          label: t("keep-playing", "Keep Playing"),
          onClick: onHide,
          className: "btn btn-primary",
          css: css`
            background-color: ${theme.button.primaryGradientStart};
            border: ${theme.button.primaryGradientStart};
            &:focus {
              background-color: ${theme.button.secondaryButtonBackground};
              border: ${theme.button.secondaryButtonBackground};
            }
          `,
        },
      ];

  const onClickGame = (game: IGame) => {
    navigate("/C2PplayGamePage", { state: { ...game } });
    onHide();
  };

  useEffect(() => {
    if (!show) return;
    const shuffleArray = (array: IGame[]) => {
      // Fisher-Yates shuffle algorithm
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    try {
      const topPlayingSortedGames = allGames?.toSorted((a: IGame, b: IGame) => b.played_count - a.played_count);

      if (topPlayingSortedGames) {
        // Shuffle the array to get a random order
        shuffleArray(topPlayingSortedGames);

        const firstSixGames = topPlayingSortedGames.slice(0, 6);
        setCurrentGames(firstSixGames);
      }
    } catch (error) {
      // Handle the error, you can log it or take appropriate action
      console.error("An error occurred in useEffect:", error);
    }
  }, [allGames, show]);

  const commonModalHeader = t("why-not-try-oth", { defaultValue: "Why not try other games?" });
  return (
    <CommonModal
      footer={
        <div css={buttonsContainerStyle}>
          {buttons.map(button => (
            <button key={button.label} css={button.css} onClick={button.onClick} className={button.className}>
              {button.label}
            </button>
          ))}
        </div>
      }
      show={show}
      onHide={onHide}
      header={commonModalHeader}
    >
      <Advertisement name="Arcade Game Exit Top" />
      <div>
        <div className="row">
          {currentGames?.map((game: any) => (
            <div className="col-4" key={game.slug}>
              <BrowseGameRectangularCard onClick={() => onClickGame(game)} game={game} />
            </div>
          ))}
        </div>
      </div>
      <Advertisement name="Arcade Game Exit Top Bottom" />
    </CommonModal>
  );
};

export default RecommendedGamesModal;
