import React from "react";
import { css } from "@emotion/react";
import Modal from "react-bootstrap/Modal";
import { useAppSelector } from "redux/Store";
import { useHistory } from "hooks/useHistory";
import { Trans } from "react-i18next";
import { slugifyStringForTranslation } from "utils";
// import { APP_SLUGS } from "Constants";
import WinnerPopUp from "assets/images/WinnerPopUp.jpg";
import { isRazer } from "utils/applicationSlug";

const congratsModal = () => css`
  .modal-content {
    width: 90%;
    border-radius: 1.3rem;
  }

  .title {
    font-size: 35px;
    font-weight: 600;

    @media (min-width: 300px) and (max-width: 420px) {
      font-size: 25px;
    }
  }

  .message {
    margin-top: 35px;
    @media (min-width: 300px) and (max-width: 359px) {
      font-size: 13px;
    }
    @media (min-width: 360px) and (max-width: 420px) {
      font-size: 15px;
    }
  }

  .trophy {
    width: 200px;
  }
`;

const button = (theme: ITheme) => css`
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 50px;
  padding-left: 50px;
  background-color: ${theme.button.primaryGradientStart};
  color: ${theme.button.buttonTextColor};
  box-shadow: 0px 4px 7px 1px ${theme.button.primaryShadow};
  font-weight: 500;
  border: none;
  font-size: 20px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (min-width: 300px) and (max-width: 359px) {
    font-size: 16px;
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (min-width: 360px) and (max-width: 420px) {
    font-size: 18px;
    padding-right: 50px;
    padding-left: 50px;
  }
`;

export const CongratsModal = ({ show, onHide }: any) => {
  const history = useHistory();
  const { user } = useAppSelector((state: any) => state.common);

  const closeModal = () => {
    onHide();
  };

  const message = user?.inbox.length > 0 ? user?.inbox[0].message : null;

  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const title = message?.title || "";
  const messageBody = message?.message || "";
  const actionUrl = message?.action_url || "";
  const buttonText = message?.action || "";

  return (
    <Modal className="congrats-modal" show={show} centered closeButton onHide={closeModal} css={congratsModal}>
      {isRazer ? (
        <div
          onClick={() => {
            closeModal();
          }}
        >
          <img src={WinnerPopUp} alt="Congrats" className="w-100" />
        </div>
      ) : (
        <div>
          <img src={message?.image} alt="Congrats" className="d-block w-75 mx-auto mt-4" />

          <div className="text-center title mt-3">
            <Trans i18nKey={slugifyStringForTranslation(title)}>{title}</Trans>
          </div>

          <div className="text-center message px-3">
            {
              // isRazer ? (
              //   <div>
              //     <Trans i18nKey={slugifyStringForTranslation(messageBody)}>
              //       {messageBody}
              //     </Trans>
              //   </div>
              // ) :
              <div
                dangerouslySetInnerHTML={{
                  __html: messageBody,
                }}
              />
            }
          </div>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <button
              css={button}
              onClick={() => {
                if (!isRazer && !isDesktop) {
                  actionUrl ? window.location.replace(actionUrl) : history.push(`/profile`);
                } else {
                  closeModal();
                }
              }}
            >
              {(!isRazer && !isDesktop && (
                <Trans i18nKey={slugifyStringForTranslation(buttonText)}>{buttonText}</Trans>
              )) || <Trans i18nKey="close">CLOSE</Trans>}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CongratsModal;
