import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/Store";

export interface UUIDState {
  apple_uuid: any;
  error: boolean;
}

const initialState: UUIDState = {
  apple_uuid: [],
  error: false
};

const appleUUIDSlice = createSlice({
  name: "appleAuthentication",
  initialState,
  reducers: {
    setAppleUUID(state, action) {
      state.apple_uuid = action?.payload;
      state.error = true;
    }
  }
});

export const { setAppleUUID } = appleUUIDSlice.actions;

export default appleUUIDSlice.reducer;

export const setAppleUserID = (uuid: string) => (dispatch: AppDispatch) => {
  dispatch(setAppleUUID([uuid]));
};
