import { css } from "@emotion/react";

const republikaStyles = css`
  &.republika {
    @media screen and (max-width: 1021px) {
      .light-mode {
        overflow-x: unset;
        margin: auto 0;
        padding: unset;
      }
    }
    @media screen and (max-width: 320px) {
      .game-name {
        font-size: 13px;
      }
      .browse-game-card {
        &.triangle {
          .triangle-div {
            top: 20px;
          }
          .triangle-bg {
            top: 30px;
          }
        }
      }
    }
  }
`;

export default republikaStyles;
