
import { type Configuration } from "rollbar";

export const rollbarConfig: Configuration = {
    accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM ?? "",
    captureUncaught: false,
    captureUnhandledRejections: false,
    environment: import.meta.env.MODE ?? "unknown",
    reportLevel: "error",
    autoInstrument: {
        log: false,
    },
    /* checkIgnore: (isUncaught, args, item) => {
      console.log({ isUncaught, args, item });
      return false;
    }, */
    ignoredMessages: [
        "(unknown): Script error.", // NOTE - bkash
        "Script error.", // NOTE - bkash
        "TypeError: window.webViewJSBridge.onResume is not a function. (In 'window.webViewJSBridge.onResume()', 'window.webViewJSBridge.onResume' is undefined)", // NOTE - bkash
        "window.webViewJSBridge.onResume is not a function. (In 'window.webViewJSBridge.onResume()', 'window.webViewJSBridge.onResume' is undefined)", // NOTE - bkash
    ],
};

export default rollbarConfig;
