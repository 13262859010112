import React from "react";
import { css } from "@emotion/react";
import BAT from "assets/images/halloween/bat.png";
const cloudStyle = css`
  z-index: 999;
  height: 100%;
  html,
  body {
    height: 100%;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    background: #1a1a1a; /* Dark background for Halloween theme */
    min-height: 100%;
    overflow: hidden;
    text-align: center;
  }

  .bat {
    position: absolute;
    background: url(${BAT}) no-repeat center center;
    background-size: contain;
    width: 50px;
    height: 50px;
    z-index: 9999;
    -webkit-animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
    animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
  }

  .x1 {
    left: 5%;
    transform: scale(0.9);
    opacity: 0.6;
  }

  .x2 {
    left: 25%;
    transform: scale(0.6);
    opacity: 0.9;
    -webkit-animation: moveclouds 25s linear infinite, sideWays 5s ease-in-out infinite alternate;
    animation: moveclouds 25s linear infinite, sideWays 5s ease-in-out infinite alternate;
  }

  .x3 {
    left: 55%;
    transform: scale(0.8);
    opacity: 0.8;
    -webkit-animation: moveclouds 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
    animation: moveclouds 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
  }

  .x4 {
    left: 72%;
    transform: scale(0.75);
    opacity: 0.9;
    -webkit-animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
  }

  .x5 {
    left: 88%;
    transform: scale(0.8);
    opacity: 0.3;
    -webkit-animation: moveclouds 7s linear infinite, sideWays 1s ease-in-out infinite alternate;
    animation: moveclouds 7s linear infinite, sideWays 1s ease-in-out infinite alternate;
  }

  .x6 {
    left: 33%;
    opacity: 1;
    -webkit-animation: moveclouds 2s linear infinite, swayWays 1s ease-in-out infinite alternate;
    animation: moveclouds 10s linear infinite, swayWays 9s ease-in-out infinite alternate;
  }

  @keyframes moveclouds {
    0% {
      top: 800px;
    }
    100% {
      top: -500px;
    }
  }

  @keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }

  @keyframes swayWays {
    0% {
      transform: rotate(12deg);
      left: -0.3%;
    }
    33% {
      transform: rotate(-2deg);
      left: 0.7%;
    }
    100% {
      transform: rotate(0deg);
      left: -12%;
    }
  }
`;

const AnimationBats = () => {
  return (
    <div className="wrapper" css={cloudStyle}>
      <div className="bat x1" />
      <div className="bat x2" />
      <div className="bat x3" />
      <div className="bat x4" />
      <div className="bat x5" />
      <div className="bat x6" />
    </div>
  );
};

export default AnimationBats;
