import { css } from "@emotion/react";

const goamaLatamStyles = (theme: ITheme) => css`
  &.goama-latam {
    .icon-container {
      .input-wrapper {
        input {
          color: ${theme.foreground.primary};
          @media (max-width: 1021px) {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export default goamaLatamStyles;
