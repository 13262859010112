import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "actions/api";
import { type RootState } from "redux/Store";
import { decodeDataFromURL } from "utils";

interface Image {
  name: string;
  url: string;
}

interface SelectedNFT {
  image: Image;
}

interface NftazosConfiguration {
  banner?: {
    banner_image: string | null;
    banner_link_type: string;
    banner_link_url: string | null;
  } | null;
  faq_text: string;
  game_instruction_text: string;
}

interface Tournament {
  // Define the tournament properties here
  game_data: object;
  tournament: object;
}

interface NFTazosState {
  vatom_id: string | null | undefined;
  selected_nft: SelectedNFT | null;
  tournaments: Tournament[];
  configuration: NftazosConfiguration;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  ui_state: {
    match_stopped: boolean;
    player_left: boolean;
  };
}

// Initial state
const initialState: NFTazosState = {
  vatom_id: null,
  selected_nft: {
    image: {
      name: "",
      url: "",
    },
  },
  tournaments: [],
  configuration: {
    banner: null,
    faq_text: "",
    game_instruction_text: "",
  },
  ui_state: {
    match_stopped: false,
    player_left: false,
  },
  status: "idle",
  error: null,
};

// Async thunk to fetch selected NFT data
export const fetchSelectedNFT = createAsyncThunk("nftazos/fetchSelectedNFT", async (_, { dispatch }) => {
  const { tokenid } = decodeDataFromURL();
  // if (!tokenid) return;
  if (tokenid) {
    const r = await api.get(`nftazos/nft-verify/${tokenid}/`);
    dispatch(setVatomId(tokenid));
    return r.data.data.selected_nft;
  }
  const response = await api.get(`nftazos/users-nft/`);
  dispatch(setVatomId(response.data.data.vatom_id));
  return response.data.data.selected_nft;
});

// Async thunk to fetch configuration data
export const fetchNftazosConfiguration = createAsyncThunk(
  "nftazos/fetchNftazosConfiguration",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { application } = state.common;
    const response = await api.get(`tournaments/nftazos/${application?.slug}/`);
    return response.data;
  },
);

// Async thunk to fetch tournaments data
export const fetchNftazosTournaments = createAsyncThunk("nftazos/fetchNftazosTournaments", async (_, { getState }) => {
  const state = getState() as RootState;
  const { application, country } = state.common;
  if (!application) return [];
  const response = await api.get(
    `tournaments/all/?type=instant-nftazos-pvp&country=${country}&app_slug=${application.slug}`,
  );
  return response.data;
});

const nftazosSlice = createSlice({
  name: "nftazos",
  initialState,
  reducers: {
    setVatomId: (state, action: PayloadAction<string | null | undefined>) => {
      state.vatom_id = action.payload;
    },
    removeSelectedNFT: state => {
      state.selected_nft = null;
    },
    nftazosMatchStopped: state => {
      state.ui_state.match_stopped = true;
    },
    resetNftazosMatchStoppedState: state => {
      state.ui_state.match_stopped = false;
    },
    nftazosPlayerLeft: state => {
      state.ui_state.player_left = true;
    },
    resetNftazosPlayerLeft: state => {
      state.ui_state.player_left = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNftazosConfiguration.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchNftazosConfiguration.fulfilled, (state, action: PayloadAction<NftazosConfiguration>) => {
        state.status = "succeeded";
        state.configuration = action.payload;
      })
      .addCase(fetchNftazosConfiguration.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch selected NFT";
      })
      .addCase(fetchSelectedNFT.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchSelectedNFT.fulfilled, (state, action: PayloadAction<SelectedNFT>) => {
        state.status = "succeeded";
        state.selected_nft = action.payload;
      })
      .addCase(fetchSelectedNFT.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch selected NFT";
      })
      .addCase(fetchNftazosTournaments.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchNftazosTournaments.fulfilled, (state, action: PayloadAction<Tournament[]>) => {
        state.status = "succeeded";
        state.tournaments = action.payload;
      })
      .addCase(fetchNftazosTournaments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch tournaments";
      });
  },
});

export const {
  setVatomId,
  removeSelectedNFT,
  nftazosMatchStopped,
  resetNftazosMatchStoppedState,
  nftazosPlayerLeft,
  resetNftazosPlayerLeft,
} = nftazosSlice.actions;

export default nftazosSlice.reducer;
