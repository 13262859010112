import React, { useState, useContext, useEffect, useCallback } from "react";
import { css, useTheme } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useHistory } from "hooks/useHistory";
import { Trans } from "react-i18next";

import GTimer from "components/common/GTimer";
import motion from "utils/polyFilledMotion";
import AuthModal from "components/feature-specific/auth/AuthModal";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { BuyTokenModal } from "components/feature-specific/Modals/BuyTokenModal";
import { WindowSizeContext } from "providers/WindowSizeProvider";

// import Coin from "assets/images/Coin.png";
// import Ticket from "assets/images/G-Ticket.png";
// import RazerGold from "assets/images/razer/razer-gold.png";
// import RazerSilver from "assets/images/razer/razer-silver.png";
import { ReactComponent as Prize } from "assets/images/Icon-Prize.svg";
import { ReactComponent as Clock } from "assets/images/icon-clock2.svg";
import { ReactComponent as IconPlay } from "assets/images/Icon-Play.svg";
import { ReactComponent as JoystickIcon } from "assets/images/gaming.svg";

import { WalletTokenVerifyPaymentModal } from "components/feature-specific/payment/PvpTokenPaymentModal";
import EntryFee from "components/common/EntryFee";
import { SdkContext } from "providers/client-sdk-providers";
import { showLoginPopup } from "actions";
import { isHTML } from "utils";
import PaymentModal from "components/feature-specific/payment/PaymentModal/PaymentModal";
import useModalHandler from "hooks/useModalHandler";
import { toggleGameListPopup } from "redux/reducers/modalReducer";
// import { APP_SLUGS } from "Constants";
import { UserIcon } from "utils/Icons";

interface ITournamentListItemProps {
  tournament: ITournament;
  game: IGame;
}

/* const token = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
`; */

const itemStyle = css`
  .text-title {
    font-size: 10pt;
    font-weight: 600;
    display: flex;
    text-align: center;
  }

  .clock-icon {
    width: 9.72pt;
    height: 11.65pt;
  }
  .lower-div-title {
    color: #62768b;
  }
`;

export default function PvpTournamentListItem({ tournament, game }: ITournamentListItemProps) {
  const breakpoint = 400;
  // const desktop = 1022;
  const theme: ITheme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { search: queryParams } = useLocation();
  const { width } = useContext(WindowSizeContext);
  const { application, user, country } = useAppSelector((state: any) => state.common);
  const sdk = useContext(SdkContext);
  const { isRazerGuestUser = null } = sdk;
  const { balance } = useAppSelector((state: any) => state.walletToken);
  const [showAuthModal, setAuthModal] = useState(false);
  const [showInsufficienBalance, setInsufficientBalance] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
  const [modalState, setModalState] = useState({
    payment: false,
    tokenError: false,
    tokenDeduct: false,
    paymentError: false,
    verification: queryParams.includes("callback=true"),
  });
  const { balance: ticketBalance } = useAppSelector((state: any) => state.statusPoint);
  const { user_info } = useAppSelector((state: any) => state.subscription);

  const enableSubscriptionPackage = application?.enable_subscription && user_info.length === 0;

  const { openSubscriptionModal } = useModalHandler();

  // const partialUpdateModals = useCallback(
  //   (state: any) => {
  //     const currentState: any = { ...modalState };
  //     Object.keys(state).map((k: any) => (currentState[k] = state[k]));
  //     setModalState(currentState);
  //   },
  //   [modalState]
  // );

  // const showModal = useCallback(
  //   (type: keyof typeof modalState) => {
  //     switch (type) {
  //       case "payment":
  //         return setModalState({ ...modalState, payment: true });
  //       case "paymentError":
  //         return setModalState({ ...modalState, paymentError: true });
  //       case "tokenError":
  //         return setModalState({ ...modalState, tokenError: true });
  //       case "verification":
  //         return setModalState({ ...modalState, verification: true });
  //     }
  //   },
  //   [modalState]
  // );

  const hideModal = useCallback(
    (type: keyof typeof modalState) => {
      switch (type) {
        case "payment":
          return setModalState({ ...modalState, payment: false });
        case "paymentError":
          return setModalState({ ...modalState, paymentError: false });
        case "tokenError":
          return setModalState({ ...modalState, tokenError: false });
        case "verification":
          return setModalState({ ...modalState, verification: false });
      }
    },
    [modalState],
  );

  // const tournamentEntryFee = tournament.entry_fee;

  const hideInsufficientBalanceModal = useCallback(() => {
    setInsufficientBalance(false);
  }, []);

  const hideAuthModal = useCallback(() => {
    setAuthModal(false);
  }, []);

  const { participant_display_type, participant_count, max_participant } = tournament;

  const maxParticipantText = max_participant > 0 ? max_participant : <Trans i18nKey="unlimited">Unlimited</Trans>;

  useEffect(() => {
    dispatch(fetchTokenBalance(country));
  }, [country, dispatch]);

  function checkBalance(tournament: any) {
    if (tournament?.entry_fee === 0 && isGuestUser && enableSubscriptionPackage) {
      dispatch(showLoginPopup());
    }

    if (tournament?.entry_fee === 0 && !isGuestUser) {
      //NOTE - SUBSCRIPTION_PURPOSE
      if (enableSubscriptionPackage) {
        openSubscriptionModal();
      } else {
        history.push(`/pvp-page/${tournament?.id}`);
        dispatch(toggleGameListPopup());
        return;
      }
    }

    if (isRazerGuestUser) {
      dispatch(showLoginPopup());
    } else if (
      (application?.applicationsetting?.enable_coin ||
        (application?.applicationsetting?.enable_user_journey &&
          application?.applicationsetting?.enable_journey_country)) &&
      tournament?.entry_fee > 0
    ) {
      if (
        tournament?.entry_fee_type === "ticket"
          ? tournament?.entry_fee <= ticketBalance.ticket_balance
          : tournament?.entry_fee <= balance.balance
      ) {
        setShowPaymentModal(true);
      } else {
        setInsufficientBalance(true);
      }
    }
  }

  /* const getCurrencyImage = () => {
    if (application?.payment_channel?.custom_currency_icon) {
      return application?.payment_channel?.custom_currency_icon;
    } else if (tournament?.entry_fee_type === "ticket") {
      return Ticket;
    } else if (tournament?.entry_fee_type === "coin") {
      return Coin;
    } else if (tournament?.entry_fee_type === "razer-gold") {
      return RazerGold;
    } else if (tournament?.entry_fee_type === "razer-silver") {
      return RazerSilver;
    }
  }; */

  const renderPlayButtonContent = () => {
    if (!user) return <Trans i18nKey="sign-in">Sign In</Trans>;

    if (tournament?.entry_fee === 0) return <IconPlay />;

    if (tournament?.entry_fee > 0) {
      return <EntryFee entry_fee={tournament?.entry_fee} payMethod={tournament?.entry_fee_type} />;
    }
  };

  const { isDesktop } = useAppSelector((state: any) => state.layout);

  const tournamentListSubtitle = () => {
    if (isHTML(tournament.list_subtitle)) {
      return (
        <span
          className="coin text-title"
          dangerouslySetInnerHTML={{
            __html: tournament.list_subtitle,
          }}
        />
      );
    }

    return (
      <span>
        <Trans i18nKey="tournament-subt">{tournament.list_subtitle}</Trans>
      </span>
    );
  };

  return (
    <>
      <div className="border-style me-1 ms-1  d-flex flex-row" css={itemStyle}>
        <div
          className={cn("ms-1 me-2 d-flex flex-column justify-content-between position-relative", {
            "pt-4 pb-4 col pe-0 ps-2": width > breakpoint,
            "pt-3 pb-3 col pe-0 ps-2": width < breakpoint,
            // "pt-3 pb-3 ": width >= desktop
          })}
        >
          <div
            className={
              width > breakpoint
                ? `d-flex flex-row align-items-center mb-2 sub-title`
                : `d-flex align-items-center p-0 mb-2 sub-title`
            }
          >
            {tournament.list_subtitle ? (
              tournamentListSubtitle()
            ) : (
              <div>
                <Prize />
                <span className="ps-1 coin">{tournament.prize_pool}</span>
              </div>
            )}
          </div>

          <div className="justify-content-left d-flex flex-row">
            <div className="d-flex flex-row align-items-center pe-3">
              {(participant_display_type === "participant_only" || participant_display_type === "participant") && (
                <UserIcon fill={theme.text.secondary} />
              )}
              {participant_display_type === "game_play" && (
                <JoystickIcon width={20} height={20} fill={theme.text.secondary} />
              )}
              <span className="ps-1 text-sty-label">
                {participant_display_type === "participant_only" && participant_count}
                {participant_display_type === "participant" && (
                  <>
                    {participant_count}/{maxParticipantText}
                  </>
                )}
                {participant_display_type === "game_play" && game.played_count}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <Clock fill={theme.text.secondary} className="clock-icon" />
              <span className="ps-1 text-sty-label ">
                <GTimer endTime={tournament.end_time} fields={["days", "hours", "minutes", "seconds"]} />
              </span>
            </div>
          </div>

          {/* Desktop */}

          {/* {width > breakpoint ? (
          <div className="d-flex flex-row align-items-center pe-1">
            {(participant_display_type === "participant_only" ||
              participant_display_type === "participant") && (
              <UserIcon fill={theme.text.secondary} />
            )}
            {participant_display_type === "game_play" && (
              <JoystickIcon
                width={20}
                height={20}
                fill={theme.text.secondary}
              />
            )}
            <span className="ps-1 text-sty-label">
              {participant_display_type === "participant_only" &&
                participant_count}
              {participant_display_type === "participant" && (
                <>
                  {participant_count}/{maxParticipantText}
                </>
              )}
              {participant_display_type === "game_play" && game.played_count}
            </span>
          </div>
        ) : null} */}

          {/* {width > breakpoint ? (
          <div
            className={cn("d-flex flex-row align-items-center clock-style", {
              "pe-5": width < desktop
            })}
          >
            <Clock fill={theme.text.secondary} />
            <span className="ps-1 text-sty-label">
              <GTimer
                endTime={tournament.end_time}
                fields={["days", "hours", "minutes", "seconds"]}
              />
            </span>
          </div>
        ) : null} */}

          {/* Desktop */}
        </div>

        <div className="d-flex align-items-center justify-content-end col-4">
          <motion.button
            className={cn({
              "btn-mob": !isDesktop,
              "btn-desk": isDesktop,
              "p-0": width < breakpoint,
              "play-btn": theme.layout === "TRIANGLE",
              "btn-sty play": theme.layout === "BOXED",
              // "has-glow": isRazer,
              /* "custom-btn-primary":
              isRazer && tournament?.entry_fee_type !== "razer-silver",
            "custom-btn-tertiary":
              isRazer && tournament?.entry_fee_type === "razer-silver" */
            })}
            onClick={() => {
              checkBalance(tournament);
            }}
            whileTap={theme.layout === "TRIANGLE" ? { scale: 0.9 } : { scale: 1 }}
          >
            {renderPlayButtonContent()}
          </motion.button>
        </div>
      </div>

      <BuyTokenModal
        key="buy-token"
        pvpTicket={tournament?.entry_fee_type}
        show={showInsufficienBalance}
        onHide={hideInsufficientBalanceModal}
      />
      <AuthModal show={showAuthModal} onHide={hideAuthModal} />
      {modalState.verification && (
        <WalletTokenVerifyPaymentModal
          key="wallet-token-verify"
          tournament={tournament}
          show={modalState.verification}
          onHide={() => hideModal("verification")}
        />
      )}

      <PaymentModal
        show={showPaymentModal}
        onHide={() => {
          setShowPaymentModal(false);
        }}
        tournament={tournament}
        value={tournament?.entry_fee}
        // participant={participant}
        amount={<EntryFee entry_fee={tournament?.entry_fee} payMethod={tournament?.entry_fee_type} />}
      />

      {/* <WalletTokenPaymentModal
        key="wallet-token-payment"
        show={modalState.payment}
        tournament={tournament}
        tournament_id={tournament?.id}
        onHide={() => hideModal("payment")}
        partialUpdateModals={partialUpdateModals}
        value={tournament.entry_fee}
        paymentType={tournament?.entry_fee_type}
      /> */}
    </>
  );
}
