import { css } from "@emotion/react";

const waveStyles = (theme: ITheme) => css`
  &.wave-mm {
    .header-style {
      color: ${theme.background.secondary}!important;
    }
    .close {
      color: ${theme.background.secondary}!important;
    }
    .validation {
      color: ${theme.background.secondary}!important;
    }
    .header-line {
      color: ${theme.background.secondary}!important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: ${theme.background.secondary};
    }
    .suggestions-container {
      background-color: ${theme.button.primaryGradientStart};
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.background.tertiary};
      }
    }
    .recommended-games {
      .header-style {
        color: ${theme.background.primary}!important;
      }
    }
    .modal-header .btn-close {
      filter: invert(100%);
      font-size: 1.5rem;
    }
  }
`;

export default waveStyles;
