import { css } from "@emotion/react";

const KFCStyles = (theme: ITheme) => css`
  &.kfc {
    .sidebar-container {
      .active-link {
        .icon-container {
          .link-img {
            fill: ${theme.button.tertiaryButtonBackground};
            color: ${theme.button.tertiaryButtonBackground};
          }
        }
      }
    }
    .card-prize {
      .div-group {
        color: ${theme.text.secondary};
      }
    }
    .rank-list-card {
      .list-heading,
      .title-md {
        color: ${theme.text.secondary} !important;
      }
    }
    .desktop {
      .rank-list-card {
        .list-heading,
        .title-md {
          color: ${theme.text.primary} !important;
        }
      }

      .gamename-style {
        color: ${theme.text.primary} !important;
      }
      .drag-handle:after {
        background-color: ${theme.text.primary} !important;
      }
      .close {
        fill: ${theme.text.primary} !important;
        color: ${theme.text.primary} !important;
      }
    }
    .profile-history-wrapper {
      .back-ground {
        background-color: ${theme.foreground.secondary};
      }
      .history-border {
        color: ${theme.text.secondary} !important;
      }
    }
    .font-style {
      color: #fff;
    }
    .registration-text {
      color: #000;
    }
    .dont-account {
      color: #ffffff;
    }

    .label-sty {
      color: #000;
    }
    .input-wrapper {
      input {
        color: #fff;
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #fff;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff;
        }
      }
      .icon-right {
        svg {
          color: #fff;
        }
      }
    }
    .browse-game-item {
      .labelbold-style {
        .game-name {
          color: #000;
        }
      }
    }
    .rank-list-card {
      .show-more {
        color: #000;
      }
    }
    .browse-game-item {
      .img-style {
        background-color: #ffffff !important;
      }
    }
    .sign-up {
      color: #ffc300;
    }
  }
`;

export default KFCStyles;
