import { createSlice } from "@reduxjs/toolkit";
// import loginAPI from "actions/api/login";
import { fetchCommonData } from "actions";
// import referApi from "actions/api/referralApi";
import { clearUserStatusPoints } from "redux/reducers/statusPointReducer";
import history from "HistoryWrapper";

import Store, { AppDispatch } from "redux/Store";
import { decodeDataFromURL, updateLocalQueryParams } from "utils";
import guestStorage from "utils/guestStorage";
import { isFegg, isGoamaPH, isMetaVerseGo } from "utils/applicationSlug";
import { apiUnauthorized } from "actions/api";
import { clearCommonUser } from "./commonReducer";

export interface ILogin {
  userdetail: null | string;
  token: null | string;
  loading: boolean;
  error: boolean;
}

const initialState: ILogin = {
  userdetail: null,
  token: null,
  loading: false,
  error: false,
};

export const userLoginSlice = createSlice({
  name: "userLoginDetail",
  initialState,
  reducers: {
    fetchUserLoginInfoLoading(state) {
      state.loading = true;
    },
    fetchUserLoginInfoSuccess(state, action) {
      state.userdetail = action?.payload?.userdetail;
      state.token = action?.payload?.token;
      state.loading = false;
      state.error = false;
    },
    fetchUserLoginInfoError(state) {
      state.userdetail = null;
      state.token = null;
      state.loading = false;
      state.error = true;
    },
    verifyUserLoginInfoLoading(state) {
      state.loading = true;
    },
    verifyUserLoginInfoSuccess(state, action) {
      const token = action?.payload?.token;
      state.token = token;
      state.loading = false;
      state.error = false;
      // updateAxiosInstanceAPIToken([referApi], token);
    },
    verifyUserLoginInfoError(state) {
      state.token = null;
      state.loading = false;
      state.error = false;
    },
    clearUserDetails(state) {
      state.userdetail = null;
      state.token = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const {
  fetchUserLoginInfoLoading,
  fetchUserLoginInfoSuccess,
  fetchUserLoginInfoError,
  verifyUserLoginInfoLoading,
  verifyUserLoginInfoSuccess,
  verifyUserLoginInfoError,
  clearUserDetails,
} = userLoginSlice.actions;

export default userLoginSlice.reducer;

export const UserLoginDetails =
  (
    first_name: string,
    last_name: string,
    email: string,
    password: any,
    country: string,
    team: string,
    extra_data: any = {},
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchUserLoginInfoLoading());
    const state = Store.getState();
    const { application, user } = state.common;
    const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
    const params: any = {
      first_name,
      last_name,
      email,
      password,
      country,
      extra_data,
    };

    if (application?.applicationsetting?.team_base_application) params["team"] = team;

    let ref: string | null = null;
    try {
      ref = sessionStorage && sessionStorage.getItem("ref_code");
      if (ref) params.ref = ref;
    } catch (e) {
      //
    }
    if (isGuestUser) {
      return apiUnauthorized
        .post(`user/account/registration/`, params, {
          headers: {
            Authorization: `Bearer ${user?.token?.token}`,
          },
        })
        .then(response => {
          dispatch(fetchUserLoginInfoSuccess(response.data));
          return response.data;
        })
        .catch(e => {
          dispatch(fetchUserLoginInfoError());
          throw e;
        });
    } else {
      return apiUnauthorized
        .post(`user/account/registration/`, params)
        .then(response => {
          dispatch(fetchUserLoginInfoSuccess(response.data));
          return response.data;
        })
        .catch(e => {
          dispatch(fetchUserLoginInfoError());
          throw e;
        });
    }
  };

export const VerifyUserLoginDetails = (email: string, password: any, country: string) => async (dispatch: AppDispatch) => {
  const state = Store.getState();
  const { application } = state.common;
  dispatch(verifyUserLoginInfoLoading());
  return apiUnauthorized
    .post(`user/account/login/`, { email, password, country, application: application?.slug })
    .then(response => {
      dispatch(verifyUserLoginInfoSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(verifyUserLoginInfoError());
      throw e;
    });
};

export const VerifyUserLoginDetailsWithUserID = (userid: string, name: string) => async (dispatch: AppDispatch) => {
  dispatch(verifyUserLoginInfoLoading());
  const state = Store.getState();
  const { application, country } = state.common;
  return apiUnauthorized
    .post(`user/account/login/`, { country, application: application?.slug, userid, first_name: name })
    .then(response => {
      dispatch(verifyUserLoginInfoSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(verifyUserLoginInfoError());
      throw e;
    });
};

export const authenticateUsingPhoneNumberUID = (uid: string, country: string) => async (dispatch: AppDispatch) => {
  dispatch(verifyUserLoginInfoLoading());
  const state = Store.getState();
  const { application } = state.common;
  return apiUnauthorized
    .post(`user/account/login/phone/`, { phone_id: uid, country, app_slug: application?.slug })
    .then(response => {
      dispatch(verifyUserLoginInfoSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(verifyUserLoginInfoError());
      throw e;
    });
};

export const loginUserWithPhoneId = (uid: string) => async (dispatch: AppDispatch) => {
  return new Promise((resolve, reject) => {
    const state = Store.getState();
    const { application, country: stateCountry } = state.common;
    const { app_slug, country, userid } = decodeDataFromURL();
    dispatch<any>(authenticateUsingPhoneNumberUID(uid, stateCountry || ""))
      .then(async (response: any) => {
        if (response?.token?.token) {
          if (userid) {
            updateLocalQueryParams({
              app_slug: app_slug || application?.slug,
              country: country || stateCountry,
              userid: null,
              name: null,
              profile_pic: null,
            });
          }

          if (localStorage) {
            localStorage.setItem("user", JSON.stringify(response));
            localStorage.setItem("rem", "true");
            localStorage.setItem("init-token", response?.token?.token);
          } else if (sessionStorage) {
            sessionStorage.setItem("user", JSON.stringify(response));
            sessionStorage.setItem("rem", "true");
            sessionStorage.setItem("init-token", response?.token?.token);
          }

          sessionStorage && sessionStorage.setItem("init-token", response?.token?.token);
          guestStorage.syncData();
          await dispatch(fetchCommonData() as any);
        }
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const registerUsingPhoneNumberOtp =
  (
    first_name: string,
    last_name: string,
    email: string,
    uid: any,
    country: string,
    team: string,
    extra_data: any = {},
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchUserLoginInfoLoading());
    const state = Store.getState();
    const { application, user } = state.common;
    const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
    const params: any = {
      first_name,
      email,
      phone_id: uid,
      country,
      extra_data,
      app_slug: application?.slug,
    };
    if (isMetaVerseGo || isGoamaPH || isFegg) {
      delete params.email;
    }

    if (application?.applicationsetting?.team_base_application) params["team"] = team;

    let ref: string | null = null;
    try {
      ref = sessionStorage && sessionStorage.getItem("ref_code");
      if (ref) params.ref = ref;
    } catch (e) {
      //
    }
    const headerConfig = isGuestUser
      ? {
          headers: {
            Authorization: `Bearer ${user?.token?.token}`,
          },
        }
      : {};
    return apiUnauthorized
      .post(`user/account/registration/phone/`, params, headerConfig)
      .then(response => {
        dispatch(fetchUserLoginInfoSuccess(response.data));
        return response.data;
      })
      .catch(e => {
        dispatch(fetchUserLoginInfoError());
        throw e;
      });
  };

export const LogOutUser = () => (dispatch: AppDispatch) => {
  const state = Store.getState();
  const { application } = state.common;
  /* const isGuestUser =
    application?.applicationsetting?.enable_guest_user &&
    user?.extra_data?.guest; */
  dispatch(clearUserDetails());
  dispatch(clearUserStatusPoints());
  dispatch(clearCommonUser());
  if (localStorage) {
    localStorage.removeItem("user");
    localStorage.removeItem("rem");
    localStorage.removeItem("init-token");
    localStorage.removeItem("auth-backend");
    localStorage.removeItem("apple-auth");
  }
  if (sessionStorage) {
    sessionStorage.clear();
  }
  dispatch(fetchCommonData());
  history.push("/tournament");
  setTimeout(() => {
    if (application?.applicationsetting?.enable_guest_user) {
      window.location.reload();
    }
  }, 10);
  //await window.location.reload();
};
