import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export const PRE_ROLL_TOURNAMENT = "PRE ROLL TOURNAMENT";
export const POST_ROLL_TOURNAMENT = "POST ROLL TOURNAMENT";

interface IAdvertisementReducerState {
  data: any[];
  tournamentAdverts: any;
  c2pGameAdvert: any;
  show: boolean;
  adName: string;
  pre_roll_count: number;
  post_roll_count: number;
  try_again_count: number;
  arcade_play_back_button: boolean;
  adsError?: boolean;
  loading?: boolean;
}

const defaultData: IAdvertisementReducerState = {
  data: [],
  tournamentAdverts: {},
  c2pGameAdvert: {},
  show: false,
  adName: "",
  pre_roll_count: 0,
  post_roll_count: 0,
  try_again_count: 0,
  arcade_play_back_button: false,
  adsError: false,
};

const advertisementsSlice = createSlice({
  name: "advertisements",
  initialState: defaultData,
  reducers: {
    fetchAdvertisementsLoading(state) {
      state.loading = true;
      state.adsError = false;
    },
    fetchAdvertisementsSuccess(state, action: PayloadAction<any[]>) {
      state.data = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchAdvertisementsError(state) {
      // Handle error state if necessary
    },
    fetchTournamentAdvertisementsSuccess(
      state,
      action: PayloadAction<{ tournamentId: string | number; advertisements: any[] }>,
    ) {
      state.tournamentAdverts[action.payload.tournamentId] = {
        advertisements: action.payload.advertisements,
      };
    },
    fetchArcadeGamesDetailSuccess(state, action: PayloadAction<{ slug: string; advertisements: any[] }>) {
      state.c2pGameAdvert[action.payload.slug] = action.payload.advertisements;
    },
    showAdModal(state, action: PayloadAction<string>) {
      state.show = true;
      state.adName = action.payload;
    },
    hideAdModal(state) {
      state.show = false;
    },
    setAdError(state, action: PayloadAction<boolean>) {
      state.adsError = !!action.payload;
    },
    resetAdError(state) {
      state.adsError = false;
    },
    increaseGameOverCount(state, action: PayloadAction<string>) {
      if (action.payload === PRE_ROLL_TOURNAMENT) {
        state.pre_roll_count += 1;
      }
      if (action.payload === POST_ROLL_TOURNAMENT) {
        state.post_roll_count += 1;
      }
    },
    resetGameOverCount(state, action: PayloadAction<string>) {
      if (action.payload === PRE_ROLL_TOURNAMENT) {
        state.pre_roll_count = 0;
      }
      if (action.payload === POST_ROLL_TOURNAMENT) {
        state.post_roll_count = 0;
      }
    },
    increaseTryAgainCount(state) {
      state.try_again_count += 1;
    },
    resetTryAgainCount(state) {
      state.try_again_count = 0;
    },
    setBackButtonPress(state, action: PayloadAction<boolean>) {
      state.arcade_play_back_button = action.payload;
    },
  },
});

export const {
  fetchAdvertisementsLoading,
  fetchAdvertisementsSuccess,
  fetchAdvertisementsError,
  fetchTournamentAdvertisementsSuccess,
  fetchArcadeGamesDetailSuccess,
  showAdModal,
  hideAdModal,
  setAdError,
  resetAdError,
  increaseGameOverCount,
  resetGameOverCount,
  increaseTryAgainCount,
  resetTryAgainCount,
  setBackButtonPress,
} = advertisementsSlice.actions;

export default advertisementsSlice.reducer;
/* export const advertisementsReducer = (state = defaultData, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_ADVERTISEMENTS_LOADING:
      return { ...state, loading: true, error: false };
    case ActionTypes.FETCH_ADVERTISEMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case ActionTypes.FETCH_ADVERTISEMENTS_ERROR:
      return {
        ...state
      };
    case ActionTypes.FETCH_TOURNAMENT_ADVERTISEMENTS_SUCCESS:
      return {
        ...state,
        tournamentAdverts: {
          ...state.tournamentAdverts,
          [action.payload.tournamentId]: {
            advertisements: action.payload.advertisements
          }
        }
      };
    case ActionTypes.FETCH_C2P_GAMES_DETAIL_SUCCESS:
      return {
        ...state,
        c2pGameAdvert: {
          ...state.c2pGameAdvert,
          [action.payload.slug]: action.payload.advertisements
        }
      };
    case ActionTypes.SHOW_AD_MODAL:
      return {
        ...state,
        show: true,
        adName: action.adName
      };

    case ActionTypes.HIDE_AD_MODAL:
      return {
        ...state,
        show: false
      };

    case ActionTypes.SET_AD_ERROR:
      return {
        ...state,
        adsError: action.errorName
      };

    case ActionTypes.RESET_AD_ERROR:
      return {
        ...state,
        adsError: false
      };

    case ActionTypes.INCREASE_GAME_OVER_COUNT:
      if (action.info === PRE_ROLL_TOURNAMENT) {
        return { ...state, pre_roll_count: state.pre_roll_count + 1 };
      }
      if (action.info === POST_ROLL_TOURNAMENT) {
        return { ...state, post_roll_count: state.post_roll_count + 1 };
      }
      break;

    case ActionTypes.RESET_GAME_OVER_COUNT:
      if (action.info === PRE_ROLL_TOURNAMENT) {
        return { ...state, pre_roll_count: 0 };
      }
      if (action.info === POST_ROLL_TOURNAMENT) {
        return { ...state, post_roll_count: 0 };
      }
      break;

    case ActionTypes.INCREASE_TRY_AGAIN_COUNT:
      return { ...state, try_again_count: state.try_again_count + 1 };

    case ActionTypes.RESET_TRY_AGAIN_COUNT:
      return { ...state, try_again_count: 0 };

    case ActionTypes.BACK_BUTTON_PRESS_TRUE:
      return { ...state, arcade_play_back_button: true };

    case ActionTypes.BACK_BUTTON_PRESS_FALSE:
      return { ...state, arcade_play_back_button: false };

    default:
      return state;
  }
};
 */
