import { css } from "@emotion/react";

const bountieStyles = (theme: ITheme) => css`
  &.bountie {
    overflow-x: hidden !important;
    .desktop-footer {
      .footer-content {
        background-color: #1e2227;
        border-top: 1px solid #edc59c;
      }

      .link-img {
        fill: #fff8f4 !important;
      }

      .link-label {
        color: #fff8f4 !important;
      }

      .active-link {
        .link-img {
          fill: #edc59c !important;
        }
        .link-label {
          color: #edc59c !important;
        }
      }
    }

    .list-group-item {
      .btn {
        color: #ffffff !important;
      }
    }

    .end-timer {
      color: #ffffff !important;
    }

    .login-class {
      fill: #ffffff !important;
    }

    .notification-page {
      color: #ffffff !important;

      .notification-style {
        color: #ffffff !important;
      }

      .notification-body {
        color: #ffffff !important;
      }
    }

    .modal_header {
      background-color: #1e2227 !important;
      border-top: 1px solid #edc59c;

      .gamename-style {
        color: #ffffff;
      }
    }

    .modal_body {
      background-color: #1e2227 !important;
    }

    .leaderboard-popup-item,
    .tournament-info-popup,
    .tournament-list-popup,
    .custom-popup,
    .achievement-popup {
      border-top: 1px solid #edc59c;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .leaderboard-popup-item .drag-handle:after,
    .achievement-popup .drag-handle:after,
    .tournament-info-popup .drag-handle:after,
    .custom-popup .drag-handle:after,
    .tournament-list-popup .drag-handle:after {
      background-color: #919191 !important;
    }

    .tournament-list-popup .close,
    .achievement-popup .close,
    .tournament-info-popup .close,
    .leaderboard-popup-item .close {
      fill: #ffffff !important;
    }

    .tournament-info-popup {
      background-color: #1e2227 !important;
    }

    .modal_container_2 {
      .title-md {
        color: #ffffff !important;
      }
    }

    .card-prize {
      .div-group {
        color: #000000 !important;
      }
    }

    .profile-history-list {
      .history-border {
        color: #000000 !important;
      }
    }

    .item-container {
      .browse-game-item {
        .img-div {
          .labelbold-style {
            color: #000000 !important;
          }

          .subtitle-text {
            color: #000000 !important;
          }
        }
      }
    }

    .leaderboard-container {
      .leaderboard-body {
        .header-line {
          color: #ffffff !important;
        }
      }
    }

    .history-container {
      .header-style {
        color: #ffffff !important;
      }
    }

    .rank-list-container-first {
      .achievement-history-list-item {
        .text-style-transaction {
          color: #000000 !important;
        }
      }
    }

    .rank-list-container {
      .achievement-history-list-item {
        .text-style-transaction {
          color: #000000 !important;
        }
      }
    }

    .container-class {
      .show-all-text {
        color: #ffffff !important;
      }
    }

    .half-style {
      background: #1e2227 !important;

      .btn-container {
        box-shadow: none !important;
      }
    }

    .triangle-div {
      .triangle-button {
        box-shadow: none !important;
      }
    }

    .recommended-games-container {
      .recommended-games {
        background: #1e2227 !important;
      }
    }

    .progress-bar-container {
      .progress-bar {
        box-shadow: 0 0 8px 2px #edc59c !important;
      }
    }

    .rank-list-container {
      .no-rank-list {
        .label-no-ranks {
          color: #ffffff !important;
        }
      }
    }

    .goama-font {
      .game-name {
        color: #ffffff !important;
      }

      .player-numb {
        .arIcon {
          fill: #ffffff !important;
        }
        .count {
          color: #ffffff !important;
        }
      }
    }

    .custom-popup-container {
      .coming-soon-text {
        color: #ffffff !important;
      }
    }

    .info-container {
      .player-numb {
        .arcade-icon {
          fill: #ffffff !important;
        }
      }
    }

    .avatar-update-modal {
      .modal-title {
        color: #000000 !important;
      }
    }
    .arcade-game-page {
      .no-back-btn {
        .emptyDivCss {
          width: unset;
        }
      }
      .button-group {
        img {
          max-height: 40px;
        }
      }
    }
    .pvp-page-style {
      color: ${theme.text.secondary};
      .timer-text {
        color: #ffffff;
      }
    }
    .profile-pvp-stats {
      .value-font {
        color: #1e2227;
      }
    }
    .rank-list-card {
      .show-more {
        color: #1e2227;
      }
    }
    .head {
      position: sticky;
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
    }
    .logo-design {
      padding-top: 10px;
    }
    .not-login-logo {
      padding-top: 10px;
    }
    .leaderboard-popup-item {
      background-color: ${theme.background.primary};
    }
    [data-rsbs-overlay],
    [data-rsbs-backdrop],
    [data-rsbs-root]:after {
      background-color: rgba(0, 0, 0, 0.45);
    }
    @media (max-width: 1021px) {
      .player-rank-board {
        .name-style {
          font-size: 1rem;
        }
        .score {
          font-size: 1rem;
        }
      }
      .latest-score-label {
        font-size: 1.2rem;
      }
    }
  }
`;

export default bountieStyles;
