import { AppDispatch } from "redux/Store";
import api from "./api";
import { fetchAuthAssignError, fetchAuthAssignLoading, fetchAuthAssignSuccess } from "redux/reducers/authLinkKey";
import {
  fetchAuthLoginUrlError,
  fetchAuthLoginUrlLoading,
  fetchAuthLoginUrlSuccess,
} from "redux/reducers/authUserLoginReducers";

export const fetchUserAuthLogin = (app: string, network: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchAuthLoginUrlLoading());
  return api
    .post(`auth/${app}/${network}/`)
    .then(response => {
      dispatch(fetchAuthLoginUrlSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchAuthLoginUrlError()));
};

export const fetchAuthLinkKey = (app: string, network: string, postData: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchAuthAssignLoading());

  return api
    .post(`auth/${app}/${network}/authorize/`, postData)
    .then(response => {
      dispatch(fetchAuthAssignSuccess(response.data));
      sessionStorage?.setItem("session-token", response.data.token);
      localStorage?.setItem("session-token", response.data.token);
      localStorage?.setItem("session-exp", new Date().valueOf().toString());
      return response.data;
    })
    .catch(() => dispatch(fetchAuthAssignError()));
};
