import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import statusAPI from "actions/api/status";

export interface IAchievement {
  data: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IAchievement = {
  data: [],
  loading: false,
  error: false
};

const achievementSlice = createSlice({
  name: "achievement",
  initialState,
  reducers: {
    fetchAchievementLoading(state) {
      state.loading = true;
    },
    fetchAchievementSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchAchievementError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchAchievementLoading,
  fetchAchievementSuccess,
  fetchAchievementError
} = achievementSlice.actions;

export default achievementSlice.reducer;

export const fetchAchievementInfo =
  (name: string, country: string) => async (dispatch: AppDispatch) => {
    dispatch(fetchAchievementLoading());
    return api
      .get(`statuspoints/achivement/${name}/${country}/`)
      .then(response => {
        dispatch(fetchAchievementSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchAchievementError()));
  };
