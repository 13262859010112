import { createSlice } from "@reduxjs/toolkit";

import authStatusAPI from "actions/api/old_tournaments";
import { AppDispatch } from "redux/Store";

export interface IStatus {
  socialAuths: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IStatus = {
  socialAuths: [],
  loading: false,
  error: false
};

const statusSlice = createSlice({
  name: "socialAuthStatus",
  initialState,
  reducers: {
    fetchsocialAuthStatusLoading(state) {
      state.loading = true;
    },
    fetchsocialAuthStatusSuccess(state, action) {
      state.socialAuths = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchsocialAuthStatusError(state) {
      state.socialAuths = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchsocialAuthStatusError,
  fetchsocialAuthStatusLoading,
  fetchsocialAuthStatusSuccess
} = statusSlice.actions;

export default statusSlice.reducer;

export const fetchSocialAuthStatus = () => async (dispatch: AppDispatch) => {
  dispatch(fetchsocialAuthStatusLoading());
  return authStatusAPI
    .get(`/users/auth/connected-accounts/`)
    .then(response => {
      dispatch(fetchsocialAuthStatusSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchsocialAuthStatusError()));
};
