import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { resetPaymentState, selectedTournament, showPayment } from "redux/reducers/paymentReducer";
import PaymentModal from "./PaymentModal";
import EntryFee from "components/common/EntryFee";
import useQueryParams from "hooks/useQueryParams";
import history from "HistoryWrapper";
import { useNavigate } from "react-router-dom";
import { isBkash, isFoodpanda, isOoredoo, isTng } from "utils/applicationSlug";
import { decodeSearchDataFromURL } from "utils";

export default function PaymentModalProvider() {
  const { application, country, user } = useAppSelector((reduxState: any) => reduxState.common);
  const { paymentModalVisibility, tournament, partnerPaymentStatus } = useAppSelector((state: any) => state.paymentState);

  const { allTournaments } = useAppSelector((state: any) => state.tournaments);
  const [participant, setParticipant] = useState<IParticipant | null>(null);
  const { participants } = useAppSelector((state: any) => state.userTournaments);

  const directPaymentEnabled = isBkash || isOoredoo || isTng;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryParams = useQueryParams();

  useEffect(() => {
    let urlTournament = null;
    if (!tournament) {
      const tournamentIdParam: any = queryParams.get("tournament");
      const tournamentId: number = parseInt(tournamentIdParam);
      urlTournament = allTournaments?.filter((tournamentDetail: ITournament) => {
        return tournamentDetail?.id === tournamentId;
      })[0];
      if (urlTournament) {
        dispatch(selectedTournament(urlTournament));
      }
    }
  }, [allTournaments, dispatch, queryParams, tournament]);

  useEffect(() => {
    // setLoading(true);
    if (application && country && user && loading) {
      if (allTournaments && allTournaments.length > 0) {
        if (tournament) {
          const p = participants?.find((p: IParticipant) => p.tournament === tournament?.id);
          if (p) {
            setParticipant(p);
          }
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      }
    } else {
      setParticipant(null);
    }
  }, [
    allTournaments,
    application,
    country,
    dispatch,
    loading,
    navigate,
    participant,
    participants,
    paymentModalVisibility,
    queryParams,
    tournament,
    user,
  ]);

  useEffect(() => {
    const tournamentIdParam: any = queryParams.get("tournament");
    const tournamentId: number = parseInt(tournamentIdParam);
    const { callback = false } = decodeSearchDataFromURL();
    if (!loading) {
      const p = participants?.find((p: IParticipant) => p.tournament === tournament?.id);
      if (p) {
        if (tournamentId && callback) {
          dispatch(resetPaymentState());
          history.replace({ search: "" });
          navigate("/tournament");
          setTimeout(() => {
            navigate(`/tournaments/${tournamentId}/play`);
            setParticipant(null);
          }, 500);
        }
      } else if (partnerPaymentStatus?.status && partnerPaymentStatus?.status !== "CANCELED") {
        dispatch(showPayment());
      } else if (tournamentId && callback) {
        dispatch(showPayment());
      }
    }
  }, [
    tournament,
    loading,
    navigate,
    participant,
    queryParams,
    dispatch,
    partnerPaymentStatus?.status,
    partnerPaymentStatus,
    allTournaments,
    participants,
  ]);

  if (!directPaymentEnabled && loading && !paymentModalVisibility) return null;

  return (
    <PaymentModal
      show={paymentModalVisibility}
      onHide={() => {
        setParticipant(null);
        dispatch(resetPaymentState());
      }}
      onShow={() => dispatch(showPayment())}
      tournament={tournament}
      value={tournament?.entry_fee}
      amount={
        <EntryFee
          entry_fee={tournament?.entry_fee}
          payMethod={tournament?.entry_fee_type}
          foodpandaAppIconColor={isFoodpanda}
        />
      }
    />
  );
}
