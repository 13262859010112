import React from "react";
import { css } from "@emotion/react";
import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import { CommonModal } from "../../Modals/CommonModal";

interface PaymentErrorModalProps {
  show: boolean;
  onHide: () => void;
  message?: string;
}

const PaymentErrorModal = ({ show, onHide, message }: PaymentErrorModalProps) => {
  return (
    <CommonModal
      show={show}
      // loading={loading}
      onHide={onHide}
      // css={voucherModalDesign}
      header={<Trans i18nKey="payment-failed!">Payment Failed!</Trans>}
      footer={
        <div>
          <Button css={buttonStyle} onClick={onHide} className="cancel-btn">
            <div className="okay-btn">
              <Trans i18nKey="okay">Okay</Trans>
            </div>
          </Button>
        </div>
      }
    >
      <div className="text-center title-md">
        <div className="d-flex col-12 p-0">
          {message ? <p>{message}</p> : <Trans i18nKey="your-payment-ca">Your Payment was cancelled.</Trans>}{" "}
        </div>
      </div>
    </CommonModal>
  );
};

const buttonStyle = (theme: ITheme) => css`
  border: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
  padding: 8px 20px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  background: linear-gradient(
    270deg,
    ${theme.button.primaryGradientStart} 0%,
    ${theme.button.primaryGradientStop} 122.67%
  );

  &:hover {
    text-decoration: none;
    color: white;
  }
`;

export default PaymentErrorModal;
