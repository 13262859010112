import React from "react";
import { css } from "@emotion/react";
import { Modal } from "react-bootstrap";
import FoodPandaDriverIdLoginForm from "./FoodPandaDriverIdLoginForm";

const baseCss = () => css`
  .modal-header {
    border: 0;
    .close {
      outline: none;
    }
  }
  .modal-body {
    padding: 0;
  }
`;

const FoodPandaDriverIdLoginModal = ({ show, onHide }: any) => {
  return (
    <Modal css={baseCss} show={show} onHide={onHide} centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <FoodPandaDriverIdLoginForm />
      </Modal.Body>
    </Modal>
  );
};

export default FoodPandaDriverIdLoginModal;
