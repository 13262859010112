import { createBrowserHistory } from "history";
import analytics from "utils/analytics";

const history = createBrowserHistory();

history.listen((location: Location) => {
  analytics.pageView(location?.pathname);
});

export default history;
