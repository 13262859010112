import React, { useEffect } from "react";
import { css } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import { FaPlus } from "react-icons/fa";


import { fetchTokenBalance } from "redux/reducers/tokenReducer";

import Coin from "assets/images/Coin.png";
import foodpandaCoin from "assets/images/foodpandacoin.png";

import { Link } from "react-router-dom";
import { abbreviateNumber } from "utils/numberParser";
import { fetchFoodpandaCoinInfo } from "redux/reducers/sdkReducer";

import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";

interface IPlusButtonProps {
  plusButton?: boolean;
  bgColor?: string;
  textColor?: string;
  iconColor?: string;
  buttonIconUrl?: string;
  url?: string;
}

export default function ButtonWithIcon({
  plusButton = false,
  bgColor,
  textColor = "#332927",
  url = "/token-profile",
  iconColor = "#332927",
  buttonIconUrl,
}: IPlusButtonProps) {
  const backgroundStyle = (theme: ITheme) => css`
    background-color: ${!bgColor ? theme.button.primaryGradientStart : bgColor};
    box-shadow: 0 4px 8px 0 ${!bgColor ? theme.button.primaryShadow : bgColor};
    border-radius: 130px;
    height: 35px;
    padding-right: 5px;
    font-weight: 600;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 350px) {
      height: 35px;
      width: 85px;
    }
    .token-radius {
      background: linear-gradient(
        270deg,
        ${!bgColor ? theme.button.primaryGradientStart : bgColor} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );
      border-radius: 50%;
      width: 35px;
      height: 35px;
      right: 50%;
      margin-right: 5px;
      top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 350px) {
        height: 30px;
        width: 30px;
      }
    }

    .label-sty {
      color: ${textColor};
      margin-top: 0;

      @media screen and (max-width: 350px) {
        padding-left: 37px;
      }
    }
  `;
  const tokenStyle = (theme: ITheme) => css`
    box-shadow: 0 4px 8px 0 ${theme.button.primaryShadow};
    position: relative;
    display: flex;
    border-radius: 130px;
    height: 35px;
    padding-right: 10px;
    font-weight: 600;
    width: 100%;
    min-width: 90px;

    svg,
    svg g,
    svg circle,
    svg path,
    svg rect {
      fill: ${theme.foreground.primary};
    }

    .token-border {
      background-color: white;
      border-radius: 5px;
      color: white;
      font-size: 15px;
    }

    .token-radius {
      background: linear-gradient(
        270deg,
        ${!bgColor ? theme.button.primaryGradientStart : bgColor} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );

      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .token {
      width: 15px;
      height: 10px;
      border-radius: 50%;
      position: relative;
      display: block;
      margin: 0 auto;
      top: 9px;
    }
  `;

  const link = (theme: ITheme) => css`
    border: none;
    text-decoration: none;
    display: inline-block;

    &.right-style {
      right: 0;
      min-width: 96px;
    }

    &.style {
      right: 50px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
    }

    .icon {
      fill: white !important;
    }

    .icon-style {
      width: 15px;
      height: 15px;
    }

    .token-svg {
      z-index: 999;
      fill: ${theme.foreground.primary};
    }

    .label-sty {
      color: ${theme.text.primary};
      font-weight: 600;
      font-size: 15px;
    }
  `;
  const { application, country } = useAppSelector((state: any) => state.common);
  const { balance } = useAppSelector((state: any) => state.walletToken);
  const dispatch = useAppDispatch();
  const { foodpanda, loading } = useAppSelector((state: any) => state.sdkReducer);

  const renderTokenIcon = () => {
    let imageUrl = Coin; // Default to Coin

    if (buttonIconUrl) {
      imageUrl = buttonIconUrl;
    } else if (application?.slug === "foodpandaapp") {
      imageUrl = foodpandaCoin;
    } else if (application?.payment_channel?.custom_currency_icon) {
      imageUrl = application?.payment_channel?.custom_currency_icon;
    }
    return (
      <img
        src={imageUrl}
        alt="coin"
        css={css`
          width: 25px;
        `}
        id="img-token-status"
      />
    );
  };

  const renderTokenBalance = () => {
    if (application?.slug === "foodpandaapp") {
      if (loading) {
        return (
          <div className="ps-1">
            <LoadingIndicator size={1.4} />
          </div>
        );
      } else {
        return abbreviateNumber(foodpanda?.coin_balance);
      }
    } else {
      return abbreviateNumber(balance?.balance);
    }
  };

  useEffect(() => {
    if (application?.slug === "foodpandaapp") {
      dispatch(fetchFoodpandaCoinInfo());
    } else {
      if (application?.applicationsetting.enable_coin) dispatch(fetchTokenBalance(country));
    }
  }, [country, application, dispatch]);

  return application?.applicationsetting?.enable_coin ? (
    <Link to={url} css={link} className={plusButton ? "right-style" : "style pe-2"}>
      <div
        className={plusButton ? "d-flex flex-row token-status" : "upper token-status"}
        css={plusButton ? backgroundStyle : tokenStyle}
        id="token-status"
      >
        <div className="d-flex flex-row justify-content-center align-items-center pe-1 me-1 ms-1">
          {renderTokenIcon()}
          <span className="label-sty ps-1"> {renderTokenBalance()}</span>
        </div>
        {plusButton ? (
          <div className="d-flex align-items-center justify-content-center icon pe-1 desktop-plus">
            <FaPlus style={{ color: iconColor }} className="icon-style" />
          </div>
        ) : null}
      </div>
    </Link>
  ) : null;
}
