import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { useAppSelector } from "redux/Store";
import classNames from "classnames";

const labelStyle = css`
  width: 100%;
  /* min-height: 58vh; */
  padding: 1.5rem;
  text-align: center;
  &.isDesktop {
    min-height: 60vh;
  }
`;

export default function InactiveTourment() {
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  return (
    <div
      className={classNames("d-flex justify-content-center align-items-center text-inactive-tournament", {
        isDesktop,
      })}
      css={labelStyle}
    >
      <h4 className="subtitle">
        <Trans i18nKey="you-have-no-act">You have no active tournaments.</Trans>
      </h4>
    </div>
  );
}
