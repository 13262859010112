import { css } from "@emotion/react";
import AstroCoin from "assets/images/AstroCoins.png";
import Ticket from "assets/images/G-Ticket.png";
import GizerBoost from "assets/images/gizer/gizerboostcoin.png";
import { useHistory } from "hooks/useHistory";
import cn from "classnames";
import React from "react";
import { Trans } from "react-i18next";
import Coin from "assets/images/Coin.png";
import GcashCoin from "assets/clients/gcash/gcash_coin.png";
import foodpandaCoin from "assets/images/foodpandacoin.png";
import RazerGold from "assets/images/razer/razer-gold.png";
import RazerSilver from "assets/images/razer/razer-silver.png";
import ORNG_ICON from "assets/clients/go3/orng.webp";
import GO3_ICON from "assets/clients/go3/Go_Coins.png";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { convertNumberToInt, formatCurrencySeparator, getPartnerCurrencySymbol } from "utils";
import DOMPurify from "isomorphic-dompurify";
import { useAppSelector } from "redux/Store";
import {
  isDana,
  isGoogly,
  isBountie,
  isTng,
  isGoamaLabs,
  isBonoxs,
  isGcash,
  isGcashAliplus,
  isGo3,
} from "utils/applicationSlug";

const coinStyle = css`
  &.tournamentpage {
    max-width: 24px;
    padding: 1px;
  }

  &.otherpage {
    width: 25px;
    margin-right: 5px;
  }
`;

type TEntryFeeProps = {
  customClass?: string;
  entry_fee?: number | string;
  foodpandaAppIconColor?: boolean;
  iconRight?: boolean;
  onlyCurrencyIcon?: boolean;
  payMethod: string;
  tournamentFeeCurrency?: string;
  trialMethod?: boolean;
  prizeType?: boolean;
};

const EntryFee = ({
  prizeType = false,
  customClass = "",
  entry_fee,
  foodpandaAppIconColor,
  iconRight = false,
  onlyCurrencyIcon,
  payMethod,
  tournamentFeeCurrency,
  trialMethod,
}: TEntryFeeProps) => {
  const history = useHistory();
  const { application } = useAppSelector((state: any) => state.common);
  const { data } = useAppSelector((state: any) => state.partnerBalance);
  const { name } = useAppSelector(state => state.cryptoToken);
  // const formattedEntryFee: any = convertNumberToInt(`${entry_fee}`);
  let formattedEntryFee: any = entry_fee ? parseFloat(`${entry_fee}`) : ""; // TODO: check this, this was needed for crypto tokens
  if (isGo3) {
    formattedEntryFee = isNaN(formattedEntryFee) ? "" : formattedEntryFee;
  }

  if (entry_fee === 0 && !trialMethod && payMethod !== "voucher") {
    return <Trans i18nKey="play">Play</Trans>;
  }

  const entryFeeAmountPlacementRight = isGoogly ?? isBonoxs;
  const entryFeeAmountPlacementLeft = !entryFeeAmountPlacementRight;

  const tournamentCurrency = () => {
    if (isGoamaLabs) {
      return "ETH ";
    }

    if (tournamentFeeCurrency) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(tournamentFeeCurrency),
          }}
        />
      );
    } else {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(application?.payment_channel?.currency_symbol || ""),
          }}
        />
      );
    }
  };

  const getTicketIcon = () => {
    return application?.applicationsetting?.user_journey_image || Ticket;
  };

  const ethereumValue: any = localStorage.getItem("ethereum");

  const partnerBaseEntryFee = () => {
    if (isDana) {
      return `${formatCurrencySeparator(formattedEntryFee)}`;
      // return `${formattedEntryFee}`;
    } else if (isTng) {
      return entry_fee;
    } else if (isGoamaLabs) {
      const amount = (ethereumValue * formattedEntryFee).toString();
      return `${amount.substring(0, 7)}`;
    } else if (isBountie && payMethod === "cash") {
      return `${formattedEntryFee * 100}`;
    } else {
      return `${formattedEntryFee}`;
    }
  };

  switch (payMethod) {
    case "voucher":
      return (
        <span
          css={css`
            font-size: 10px;
            font-weight: bold;
          `}
        >
          <Trans i18nKey="use-code">Use Code</Trans>
        </span>
      );
    case "ticket":
    case "TICKET":
      return (
        <div
          className={cn("ticket-entry-fee-container", {
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
            "flex-row-reverse": iconRight,
          })}
        >
          <img
            src={getTicketIcon()}
            alt=""
            css={css`
              width: auto;
              height: 23px;
              transform: rotate(10deg);
              padding-left: 5px;
              margin-right: 5px;
            `}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          <span>{formattedEntryFee}</span>
        </div>
      );
    case "astro-coin":
      return (
        <div
          className={cn({
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={AstroCoin}
            alt="currency-logo"
            css={css`
              width: auto;
              height: 20px;
              padding-left: 5px;
              margin-right: 5px;
            `}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          {formattedEntryFee}
        </div>
      );

    case "gizer-boost":
      return (
        <div
          className={cn({
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={GizerBoost}
            alt="currency-logo"
            css={css`
              width: auto;
              height: 20px;
            `}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          <span> {formattedEntryFee} </span>
        </div>
      );

    case "razer-silver":
      return (
        <div
          className={cn("razer-silver-color", {
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={RazerSilver}
            alt="currency-logo"
            css={coinStyle}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          <span>{formattedEntryFee}</span>
        </div>
      );

    case "coin":
    case "TOKEN":
      return (
        <div
          className={cn({
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={Coin}
            alt="currency-logo"
            css={coinStyle}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          <span> {" " + formattedEntryFee} </span>
        </div>
      );

    case "ORNG":
      return (
        <div
          css={css`
            img {
              width: 20px;
            }
          `}
          className={cn({
            "d-flex flex-row justify-content-center align-items-center gap-1": true,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={ORNG_ICON}
            alt="currency-logo"
            css={coinStyle}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          {name !== "ORNG" && entry_fee != "" && !prizeType ? (
            <span>Connect</span>
          ) : (
            <span> {"" + formattedEntryFee} </span>
          )}
        </div>
      );

    case "GO3":
      return (
        <div
          className={cn({
            "d-flex flex-row justify-content-center align-items-center gap-1": true,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={GO3_ICON}
            alt="currency-logo"
            css={coinStyle}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          {name !== "GO3" && entry_fee != "" && !prizeType ? (
            <span>Connect</span>
          ) : (
            <span> {"" + formattedEntryFee} </span>
          )}
        </div>
      );

    case "razer-gold":
      return (
        <div
          className={cn("razer-gold-color", {
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          <img
            src={RazerGold}
            alt="currency-logo"
            css={coinStyle}
            className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
          />
          <span> {formattedEntryFee} </span>
        </div>
      );

    case "GoTokins":
    case "GoPoints":
      return (
        <>
          {isGoogly && <span className="body-md">{partnerBaseEntryFee()}&nbsp;</span>}
          {<span className="body-md">{tournamentCurrency()}&nbsp;</span>}
        </>
      );
    case "bountie-token":
      return (
        <span
          className="body-md currency-symbol"
          css={css`
            font-weight: 600;
            max-width: 85px;
            width: 80px;
          `}
        >
          {partnerBaseEntryFee()}&nbsp;
          {"Tokens"}
        </span>
      );
    case "mok-points":
      return (
        <div
          className={cn({
            "d-flex flex-row justify-content-center align-items-center": !customClass,
            [`${customClass}`]: !!customClass,
            "align-items-center": true,
          })}
        >
          {data?.custom_currency_icon ? (
            <img
              src={getPartnerCurrencySymbol(data)}
              alt="currency-logo"
              css={css`
                width: auto;
                height: 20px;
              `}
              className={history.location.pathname === `/tournament` ? "tournamentpage" : "otherpage"}
            />
          ) : (
            <span className="currencyIcon"> {getPartnerCurrencySymbol(data)}</span>
          )}

          <span>{formattedEntryFee}</span>
        </div>
      );

    case "f-secure-sub":
      return <Trans i18nKey="tng-play">TNG Play</Trans>;

    case "cash":
    default:
      return (
        <>
          {entryFeeAmountPlacementRight && !onlyCurrencyIcon && (
            <span className="body-md">{partnerBaseEntryFee()}&nbsp;</span>
          )}

          {application?.payment_channel?.custom_currency_icon !== null || onlyCurrencyIcon ? (
            <img
              src={foodpandaAppIconColor ? foodpandaCoin : application?.payment_channel?.custom_currency_icon}
              alt=""
              css={css`
                height: 20px;
                width: auto;
                margin-bottom: 1px;
              `}
              className={cn("me-1", {
                "ms-1": onlyCurrencyIcon,
              })}
            />
          ) : (
            <span className="body-md">
              {!(isGcash ?? isGcashAliplus) && tournamentCurrency()}
              {!isDana && <>&nbsp;</>}
            </span>
          )}
          {entryFeeAmountPlacementLeft && !onlyCurrencyIcon && <span className="body-md">{partnerBaseEntryFee()}</span>}

          {(isGcash || isGcashAliplus) && (
            <img
              src={GcashCoin}
              alt=""
              css={css`
                height: 18px;
                width: auto;
                margin-left: 3px;
              `}
              className={cn("me-1", {
                "ms-1": onlyCurrencyIcon,
              })}
            />
          )}
        </>
      );
  }
};

export default EntryFee;
