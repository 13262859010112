import { createSlice } from "@reduxjs/toolkit";

import tournamentAPI from "actions/api/old_tournaments";
import { AppDispatch } from "redux/Store";

export interface IGamesOfTheWeek {
  data: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IGamesOfTheWeek = {
  data: [],
  loading: false,
  error: false
};

const gamesOfTheWeekSlice = createSlice({
  name: "gamesOfTheWeek",
  initialState,
  reducers: {
    fetchGamesOfTheWeekLoading(state) {
      state.loading = true;
    },
    fetchGamesOfTheWeekSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchGamesOfTheWeekError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchGamesOfTheWeekLoading,
  fetchGamesOfTheWeekSuccess,
  fetchGamesOfTheWeekError
} = gamesOfTheWeekSlice.actions;

export default gamesOfTheWeekSlice.reducer;

export const fetchGamesOfTheWeek =
  (country: any, application: any) => async (dispatch: AppDispatch) => {
    dispatch(fetchGamesOfTheWeekLoading());
    // const { country } = decodeDataFromURL();
    return tournamentAPI
      .get(`/games-of-the-week/${application}/${country}/`)
      .then(response => {
        dispatch(fetchGamesOfTheWeekSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchGamesOfTheWeekError()));
  };
