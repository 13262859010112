import React, { useEffect } from "react";
import { css } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import { FaPlus } from "react-icons/fa";


import cn from "classnames";
import Coin from "assets/images/gizer/gizerboostcoin.png";

import { abbreviateNumber } from "utils/numberParser";
import { fetchGizerCoinInfo } from "redux/reducers/sdkReducer";
import { useHistory } from "hooks/useHistory";

interface IPlusButtonProps {
  plusButton?: boolean;
}

export default function GizerBoostStatus({ plusButton = false }: IPlusButtonProps) {
  const { application, country } = useAppSelector((state: any) => state.common);
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  //   const { balance } = useAppSelector((state: any) => state.walletToken);
  const dispatch = useAppDispatch();
  const { gizer } = useAppSelector((state: any) => state.sdkReducer);

  useEffect(() => {
    dispatch(fetchGizerCoinInfo());
  }, [country, application, dispatch]);
  const history = useHistory();
  const isInProfile = history.location.pathname === `/profile`;

  return (
    <div
      css={link}
      className={cn("right-style justify-content-space-between pe-1", {
        "pb-2": history.location.pathname === `/profile`,
        "page-profile": isInProfile,
        "app-off": !application?.is_back_button_enabled || !application?.enable_top_bar,
        "ticket-disable": !application?.applicationsetting?.enable_user_journey,
        "ticket-disable-coin-enabled":
          !application?.applicationsetting?.enable_user_journey && application?.applicationsetting?.enable_coin,
        "ticket-enabled-coin-enabled":
          application?.applicationsetting?.enable_user_journey &&
          application?.applicationsetting?.enable_coin &&
          !application?.is_back_button_enabled &&
          !isDesktop,
        "ticket-enabled-coin-enabled-backbutton-enabled":
          application?.applicationsetting?.enable_user_journey &&
          application?.applicationsetting?.enable_coin &&
          application?.is_back_button_enabled,
      })}
    >
      <div
        className={plusButton ? "d-flex flex-row token-status" : "upper token-status"}
        css={plusButton ? backgroundStyle : tokenStyle}
      >
        <div className="d-flex flex-row justify-content-center align-items-center pe-1 me-1 ms-1">
          {
            <img
              src={Coin}
              alt="coin"
              css={css`
                width: 25px;
              `}
            />
          }
          <span className="label-sty ps-1"> {abbreviateNumber(gizer?.coin_balance)}</span>
        </div>
        {plusButton ? (
          <div className="d-flex align-items-center justify-content-center icon pe-1 desktop-plus">
            <FaPlus style={{ color: "green" }} className="icon-style" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

const backgroundStyle = (theme: ITheme) => css`
  background-color: ${theme.button.primaryGradientStart};
  box-shadow: 0px 0px 4px 0px ${theme.button.primaryShadow};
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 350px) {
    height: 35px;
    /* width: 90px; */
  }

  .token-radius {
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );

    border-radius: 50%;
    width: 35px;
    height: 35px;
    right: 50%;
    margin-right: 5px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 350px) {
      height: 30px;
      width: 30px;
    }
  }

  .label-sty {
    color: ${theme.foreground.primary} !important;
    margin-top: 0;
    font-size: 15px;

    @media screen and (max-width: 350px) {
      padding-left: 37px;
    }
  }
`;

const tokenStyle = (theme: ITheme) => css`
  background-color: ${theme.button.primaryGradientStart};
  box-shadow: 0px 0px 4px 0px ${theme.button.primaryShadow};
  position: relative;
  display: flex;
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  svg,
  svg g,
  svg circle,
  svg path,
  svg rect {
    fill: ${theme.foreground.primary};
  }

  .token-border {
    background-color: white;
    border-radius: 5px;
    color: white;
    font-size: 15px;
  }

  .token-radius {
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );

    border-radius: 50%;
    width: 29px;
    height: 29px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token {
    width: 15px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 0 auto;
    top: 9px;
  }
`;

const link = (theme: ITheme) => css`
  border: none;
  text-decoration: none;
  display: inline-block;
  /* position: absolute; */

  &.right-style {
    right: 0;
    min-width: 96px;
    margin-right: 0.15rem;
  }

  &.ticket-enabled-coin-enabled {
    right: 0;
    min-width: 96px;
    margin-right: -5rem;
  }

  &.app-off {
    margin-left: auto;
    @media (min-width: 320px) and (max-width: 380px) {
      margin-left: 5px;
    }
  }

  &.page-profile {
    margin-right: 0;
    margin-left: 0;
  }

  &.ticket-enabled-coin-enabled-backbutton-enabled {
    right: 0;
    min-width: 96px;
  }

  &.ticket-disable-coin-enabled {
    right: 0;
    min-width: 96px;
  }

  &.ticket-disable {
    margin-left: auto;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  .icon {
    fill: white;
  }

  .icon-style {
    width: 15px;
    height: 15px;
    color: ${theme.foreground.primary};
    @media (max-width: 360px) {
      width: 12px;
      height: 12px;
    }
  }

  .token-svg {
    z-index: 999;
    fill: ${theme.foreground.primary};
  }

  .label-sty {
    color: ${theme.foreground.primary} !important;
    font-weight: 600;
    font-size: 15px;
    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
`;
