import { css } from "@emotion/react";

const benedettisStyles = (theme: ITheme) => css`
  &.benedettis {
    .leaderboard-container {
      .rank-list-card {
        .text-start {
          color: ${theme.text.secondary} !important;
        }
      }
    }
    .active-link {
      .link-label {
        color: ${theme.button.primaryGradientStart};
      }
    }
    .game-name {
      color: ${theme.button.primaryGradientStart};
    }

    .history-border {
      .subtitle {
        color: #000000 !important;
      }
    }

    .card-prize {
      .div-group {
        color: #000000 !important;
      }
    }

    .rank-list-card {
      .show-more {
        color: #000000 !important;
      }
    }
  }
`;

export default benedettisStyles;
