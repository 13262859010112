import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { go3ABI, go3TokenAddress, orngABI, orngTokenAddress } from "components/feature-specific/web3/web3constants";

const initialState: CryptoTokenState = {
  modalInstance: null,
  recipientAddress: "0x2d45C2C8aC40Ff5C6C9B530AA17d83f141BE8Fc3",
  chainId: null,
  address: "",
  abi: null,
  balance: "0",
  selectedToken: null, // default to GO3
  name: null, // default to GO3
  isConnected: false,
  tokenAddress: "",
  contract: null,
  walletProvider: null,
  balances: {
    GO3: null,
    ORNG: null,
  },
};

const cryptoTokenSlice = createSlice({
  name: "cryptoToken",
  initialState,
  reducers: {
    setModalInstance(state, action: PayloadAction<any>) {
      state.modalInstance = action.payload;
    },
    setRecipientAddress(state, action: PayloadAction<string>) {
      state.recipientAddress = action.payload;
    },
    setWalletProvider(state, action: PayloadAction<any>) {
      state.walletProvider = action.payload;
    },
    setABI(state, action: PayloadAction<any>) {
      state.abi = action.payload;
    },
    setBalance(state, action: PayloadAction<string>) {
      state.balance = action.payload;
    },
    setBalances(state, action: PayloadAction<{ name: "GO3" | "ORNG"; balance: null | string }>) {
      const { name, balance } = action.payload;
      state.balances[name] = balance;
    },
    setSelectedToken(state, action: PayloadAction<"GO3" | "ORNG">) {
      state.selectedToken = action.payload;
    },
    setContract(state, action: PayloadAction<any>) {
      state.contract = action.payload;
      //   state.isConnected = true;
    },
    setChainId(state, action: PayloadAction<number | null>) {
      state.chainId = action.payload;
      //   state.isConnected = true;
    },
    setAddress(state, action: PayloadAction<any>) {
      state.address = action.payload;
      state.isConnected = true;
    },
    connectWallet(state, action: PayloadAction<any>) {
      const { contract, address, chainId, abi, name } = action.payload;
      state.chainId = chainId;
      state.abi = abi;
      state.contract = contract;
      state.address = address;
      state.selectedToken = name;
      state.name = name;
    },
    disconnectWallet(state) {
      state.chainId = null;
      state.abi = null;
      state.contract = null;
      state.address = "";
      state.selectedToken = null;
      state.name = null;
    },
  },
});

export const {
  setModalInstance,
  setRecipientAddress,
  setABI,
  setBalance,
  setSelectedToken,
  setWalletProvider,
  setContract,
  setAddress,
  setChainId,
  connectWallet,
  disconnectWallet,
  setBalances,
} = cryptoTokenSlice.actions;
export default cryptoTokenSlice.reducer;
