import { css } from "@emotion/react";

const beesStyles = (theme: ITheme) => css`
  &.bees {
    .draggable-modal {
      [data-rsbs-overlay] {
        background: none !important;
      }
      .leaderboard-container {
      }
      .leaderboard-popup-item {
        background: ${theme.foreground.primary} !important;
        .modal_header {
          background: ${theme.foreground.primary} !important;
          .gamename-style {
            color: ${theme.text.tertiaryTextColor};
          }
        }
        .leaderboard-container {
          background: none !important;
          .modal_body {
            background: none !important;
            .prizes-slider-item {
              background: ${theme.background.primary} !important;
              span {
                color: ${theme.text.primary} !important;
              }
            }
            .list-group-item {
              border: 1px solid ${theme.text.tertiaryTextColor};
            }
            .avatar-container-style {
              background-color: ${theme.text.tertiaryTextColor};
            }
          }
          .prize-list-container {
            background-color: ${theme.text.tertiaryTextColor};
            z-index: 1 !important;
          }
          .motion-button {
            position: relative;
            z-index: 2 !important;
          }
          .label-no-ranks {
            color: ${theme.text.tertiaryTextColor};
          }
        }
      }
      .rank-list-card {
        .col-items,
        .text-style-transaction,
        .reward-sty {
          color: ${theme.text.tertiaryTextColor};
        }
        .heading-border {
          border-color: ${theme.text.tertiaryTextColor};
        }
      }
    }
    .top-tournament-card {
      .icon-play {
        fill: ${theme.text.primary};
      }
      .clock-participant-container {
        .clock-participant-text {
          fill: ${theme.text.tertiaryTextColor};
          color: ${theme.text.tertiaryTextColor};
        }
        .clock-participant-bg-svg {
          fill: ${theme.foreground.primary};
        }
      }
      .bottom-bar-inside {
        > *,
        span,
        div {
          color: ${theme.text.tertiaryTextColor} !important;
        }
      }
      /* &[data-rsbs-overlay] {
          background: var(--rsbs-bg, ${theme.background.primary});
        }
        &[data-rsbs-overlay],
        &[data-rsbs-backdrop],
        &[data-rsbs-root]:after {
          --rsbs-bg: ${theme.background.primary} !important;
          background: ${theme.foreground.primary} !important;
        } */
    }
    .desktop-modal-content {
      .title {
        @media (max-width: 1021px) {
          color: ${theme.text.tertiaryTextColor};
        }
        color: ${theme.text.primary};
      }
      .close-button {
        svg {
          @media (max-width: 1021px) {
            fill: ${theme.text.tertiaryTextColor};
          }
          fill: ${theme.text.primary};
        }
      }
    }
    .sign-up {
      color: #f19600;
    }
    .btn-login,
    .btn-sign-up {
      background: ${theme.button.primaryGradientStart} !important;
      color: ${theme.button.buttonTextColor} !important;
      svg {
        fill: ${theme.button.buttonTextColor} !important;
      }
    }
    .back-button-wrapper {
      svg {
        fill: ${theme.button.buttonTextColor} !important;
        stroke: ${theme.button.buttonTextColor} !important;
      }
    }
    .prizes-slider-container {
      /* color: ${theme.text.tertiaryTextColor}; */
    }
    .bees {
      .subtitle {
        color: ${theme.text.tertiaryTextColor};
      }
      .browse-popup {
        background-color: ${theme.text.primary};
      }
    }
    .ticket-status-bar {
      .upper {
        background-color: ${theme.button.tertiaryButtonBackground};
      }
    }
    .active-link {
      .icon-container {
        &.active-dark-bg {
          svg {
            fill: ${theme.foreground.primary};
            color: ${theme.foreground.primary};
          }
        }
      }
    }
    .my-games-popup {
      .title-sm,
      .row-style .rank-btn {
        fill: ${theme.text.tertiaryTextColor};
      }
      .labelbold-style {
        color: ${theme.text.tertiaryTextColor};
      }
      .page-title {
        h1 {
          color: ${theme.foreground.primary};
        }
      }
    }
    .rank-list-container-first {
      /*  background-color: ${theme.button.tertiaryButtonBackground}; */
    }
    .user-achievements {
      .achievements-card {
        background-color: ${theme.text.tertiaryTextColor};
      }
    }

    .profile-history-list {
      .profile-history-wrapper {
        background-color: ${theme.text.tertiaryTextColor};
        font-weight: 600;
        .subtitle {
          color: ${theme.foreground.primary};
        }
      }
    }

    .modal_container_2 {
      .small-card {
        background-color: ${theme.text.tertiaryTextColor};
        .rank-btn {
          svg {
            fill: ${theme.text.tertiaryTextColor};
          }
        }

        .border-style {
          .user {
            fill: #9ea4aa !important;
          }

          .text-sty-label {
            color: #9ea4aa !important;
          }
        }
      }
      .title-md {
        color: ${theme.text.tertiaryTextColor};
      }
    }

    .mission-page-container {
      .square-card.active-card {
        box-shadow: none;
      }
      .square-card {
        .card-title {
          color: ${theme.text.tertiaryTextColor};
        }
      }
    }

    .white-card {
      .title-md,
      .body-sm,
      .body-md,
      .bold-text,
      .label {
        color: ${theme.foreground.secondary};
      }
    }
    .progress-share-overlay {
      .latest-score,
      .latest-score-label {
        color: ${theme.text.tertiaryTextColor};
      }
      .btn-container {
        svg {
          fill: ${theme.text.secondary};
          color: ${theme.text.secondary};
        }
      }
    }
  }
`;

export default beesStyles;
