import { css } from "@emotion/react";

const xctualityStyles = css`
  &.xctuality {
    .coupon-card-container {
      color: #000000 !important;
      .coupon-card-header {
        color: #000000 !important;
      }
    }
    .xctuality-ticket {
      box-shadow: none;
    }
    .notification-page {
      .notification-style {
        color: #fff;
      }
      .scroll-y > h6 {
        color: #fff;
      }
    }
    .notification-body {
      color: #fff;
    }
    .notification-pop-up {
      .end-timer {
        color: #fff;
      }
    }
    .coupon-card-container {
      .expired-notice {
        background: #000000;
      }
    }
    .desktop-footer {
      .footer-content {
        background-color: #000000;
      }
    }
    .leaderboard-popup-item {
      .modal_body,
      .modal_header,
      .list-group-item,
      .prizes-slider-item {
        background-color: #000000;
      }
      .list-group-item {
        border-color: #ffffff !important;
      }
      .gamename-style,
      .prizes-slider-item {
        color: #ffffff;
      }
    }
    .modal_container_2 {
      .title-md {
        color: #ffffff;
      }
      .rank-btn {
        svg {
          fill: #000000;
        }
      }
    }
    .mission-page-container {
      .active-card {
        .card-title {
          color: #ffffff !important;
        }
      }
      .card-title {
        color: #79056d !important;
      }
      .small_button {
        box-shadow: none;
      }
    }
    .top-bar-top {
      .redeem {
        color: #79056d;
      }
    }
    .scroll-bar {
      .subtitle {
        color: #000000;
      }
    }
    .header-text,
    .message-card {
      color: #ffffff;
    }
    .history-border {
      .subtitle {
        color: #000000;
      }
    }
    .ticket-status-bar {
      .label-sty {
        color: #000000;
      }
    }
    .reward-payment-modal {
      .date {
        color: #000000;
      }
    }
    .payment-modal-2 {
      background-color: #ffffff;
      .modal-header,
      .modal-body,
      .modal-footer {
        background-color: #ffffff;
        color: #000000;
      }
      .modal-header {
        button {
          span {
            color: #000000 !important;
          }
        }
      }
    }
    .profile-page {
      .show-all-text {
        color: #ffffff;
      }
      .history-border {
        color: #000000;
      }
    }
    .history-container {
      .header-style {
        color: #ffffff;
      }
    }
    .leaderboard-body {
      .title-md {
        color: #ffffff;
      }
      .text-style-transaction {
        color: #000000;
      }
    }

    .text-first {
      color: #ffffff !important;
    }

    .coupon-back {
      background-color: #ffffff !important;
    }

    .coupon {
      color: #000000 !important;
      text-align: center !important;
    }

    .button-reward {
      color: #ffffff !important;
    }

    .validation {
      .text-valid {
        color: #ffffff !important;
      }
    }
  }
`;

export default xctualityStyles;
