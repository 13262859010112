import { css } from "@emotion/react";

const snickersStyles = css`
  &.snickers {
    .registration-text {
      color: #ffffff !important;
    }
    .reward-label {
      color: #fdac2e !important;
    }

    .desk-sublabel {
      color: #fdac2e !important;
    }

    .title-md {
      color: #fdac2e !important;
    }
    .card-win-prizes {
      .title-md {
        color: #ffffff !important;
      }
    }

    .notification-body {
      color: #fdac2e !important;
    }

    .notification-style {
      color: #fdac2e !important;
    }
    input {
      background-color: rgb(232, 240, 254);
    }
    .forget-password-page {
      .input-style {
        color: #000 !important;
      }
    }
    .reset-password-page {
      .inputstyle {
        color: #000 !important;
      }
    }
    .textstyleotp {
      color: #000;
    }
    .registration-text {
      color: #000;
    }
    input {
      background-color: rgb(232, 240, 254);
    }
    .textstyleotp {
      color: #000;
    }
    .form-check-label {
      color: rgb(253, 172, 46);
    }
    .social-text {
      color: rgb(253, 172, 46);
    }
  }
`;

export default snickersStyles;
