// import paymentsAPI from "actions/api/payments";
import { updateLocalQueryParams } from "utils";
import { isTournamentTryBased } from "./tournamentUtils";
import api from "actions/api";

interface IInitializePaymentArgs {
  country: string;
  application: IApplication;
  tournament: ITournament;
  user: IUser;
  extraData?: any;
}

class PaymentHandler {
  private generateRedirectURL(tournament: ITournament, queryParams: any) {
    const location = window.location;
    const baseURL = `${location.protocol}//${location.host}/tournament?tournament=${tournament?.id}`;

    let params = "";
    Object.keys(queryParams).map(key => {
      const value = encodeURIComponent(`${queryParams[key]}`);
      params += `${key}=${value}&`;
      return null;
    });
    return `${baseURL}?${params}`;
  }

  initializePayment({ application, country, user, tournament, extraData = {} }: IInitializePaymentArgs) {
    const queryParams = {
      country,
      callback: true,
      app: application?.slug,
      userid: user?.username.split("__")[0],
      tournament: tournament?.id,
    };

    const params = {
      amount: tournament?.entry_fee,
      tournament: tournament?.id,
      redirect_link: this.generateRedirectURL(tournament, queryParams),
      ...extraData,
    };

    let url = `init/${application?.slug}/`;
    let localStorageKey = `active-ref-${tournament?.id}`;

    if (isTournamentTryBased(tournament)) {
      url = `init/trial/${application?.slug}/`;
      localStorageKey = `trialbased-ref-${tournament?.id}`;
      params["amount"] = tournament?.trial_packages[0]?.currency_value;
      const paymentType = tournament?.trial_packages[0]?.currency;
      if (paymentType === "coin") {
        params["token"] = true;
      } else {
        params[`${paymentType}`] = true;
      }
      params["package"] = tournament?.trial_packages[0]?.id;
      params["trial_tournament"] = tournament?.id;
      delete params["tournament"];
    }

    return api.post("payments/" + url, { ...params }).then(response => {
      // Set last reference id into localStorage
      // For verifying upon callback redirect
      localStorage?.setItem(localStorageKey, response.data.reference);
      // Set new_userid into query_params if available
      if (response.data?.new_userid) {
        updateLocalQueryParams({ userid: response.data.new_userid });
      }
      return response;
    });
  }

  verifyPayment(tournament: ITournament) {
    let paymentRefKey = `active-ref-${tournament?.id}`;
    if (isTournamentTryBased(tournament)) {
      paymentRefKey = `trialbased-ref-${tournament?.id}`;
    }
    const referenceCode = localStorage?.getItem(paymentRefKey) || "";
    return api.get(`payments/verify/${referenceCode}/`);
  }
}

const paymentHandler = new PaymentHandler();

export default paymentHandler;
