import { isBubbaloo } from "utils/applicationSlug";
import { getUserServiceBaseUrl, isInProductionMode } from "actions/api/utils";
import axios from "axios";

export enum MetaTrackerTypes {
  INITIATE_CHECKOUT = "InitiateCheckout",
  SUBMIT_APPLICATION = "SubmitApplication",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  VIEW_CONTENT = "ViewContent",
}

const waitForFbq = (callback: () => void) => {
  if (window.fbq) callback();
  else setTimeout(() => waitForFbq(callback), 100);
};

const facebookEventsUrl = (event: MetaTrackerTypes) => {
  const baseUrl = getUserServiceBaseUrl();
  const facebookEventsUrl = `${baseUrl}/api/v2/events/facebook-conversion-event/${event}/`;

  return facebookEventsUrl;
};

const sendFacebookEvent = (event: MetaTrackerTypes) => {
  const authToken = localStorage.getItem("init-token");

  if (!authToken) return;

  axios.get(facebookEventsUrl(event), {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Cookies-Enabled": "true",
      Cookies: document.cookie,
    },
    withCredentials: true,
  });
};

const useMetaTracker = () => {
  const metaTracker = (metaTrackerType: MetaTrackerTypes) => {
    if (!isInProductionMode() || !isBubbaloo) return;

    waitForFbq(() => {
      window.fbq("track", metaTrackerType);
      sendFacebookEvent(metaTrackerType);
    });
  };

  return metaTracker;
};

export default useMetaTracker;
