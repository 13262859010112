import React, { useEffect, useState } from "react";

import { calculateTimeLeft, digitizeNumber } from "utils";

const GTimer = ({
  expiredText = "",
  endTime,
  fields = ["days", "hours", "minutes", "seconds"],
  onEnd = () => {},
  extendedDuration = false,
}: any) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultTimeLeft: any = {};
  fields.map((key: string) => {
    defaultTimeLeft[key] = "00";
    return null;
  });
  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft(new Date(endTime)));
  const [hasEnded, setHasEnded] = useState<boolean>(false);

  useEffect(() => {
    // Reset hasEnded state whenever the endTime changes
    setHasEnded(false);
    setTimeLeft(calculateTimeLeft(new Date(endTime)));
  }, [endTime]);

  useEffect(() => {
    if (hasEnded) return; // If the countdown has ended, do not set up a new timer.

    const timer = setTimeout(() => {
      const timeLeft = calculateTimeLeft(new Date(endTime));
      if (Object.keys(timeLeft).length > 0) {
        setTimeLeft(calculateTimeLeft(new Date(endTime)));
      } else {
        setTimeLeft(defaultTimeLeft);
      }

      const { days = 0, hours = 0, minutes = 0, seconds = 0 } = (timeLeft as any) || 0;
      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        if (!hasEnded) {
          onEnd();
          setHasEnded(true); // Mark as ended to prevent future calls.
        }
        clearTimeout(timer);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [endTime, hasEnded, defaultTimeLeft, onEnd]);

  if (
    timeLeft?.hours === "00" &&
    timeLeft?.days === "00" &&
    timeLeft?.minutes === "00" &&
    timeLeft?.seconds === "00" &&
    expiredText
  ) {
    return <b>{expiredText}</b>;
  }

  if (extendedDuration) {
    return (
      <React.Fragment>
        {timeLeft.days > 0 && `${timeLeft.days}day `}
        {timeLeft.hours > 0 && `${digitizeNumber(timeLeft.hours)}hr `}
        {timeLeft.minutes > 0 && `${digitizeNumber(timeLeft.minutes).replace(/^0+/, "")}min `}
        {timeLeft.seconds > 0 && `${digitizeNumber(timeLeft.seconds).replace(/^0+/, "")}s `}
      </React.Fragment>
    );
  }

  return (
    <>
      {timeLeft.days > 0 && `${timeLeft?.days}d `}
      {`${digitizeNumber(timeLeft?.hours || 0)}:`}
      {`${digitizeNumber(timeLeft?.minutes || 0)}:`}
      {`${digitizeNumber(timeLeft?.seconds || 0)}`}
    </>
  );
};

export default GTimer;
