import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "./api/tournaments";
// import gamesAPI from "./api/games";
import api from "./api";

import {
  fetchActiveTournamentsError,
  fetchActiveTournamentsLoading,
  fetchActiveTournamentsSuccess,
  fetchGameDetailsSuccess,
  fetchTournamentDetailsSuccess,
} from "redux/reducers/activeTournamentsReducer";

export const fetchActiveTournaments = (appSlug: string, country: string | null) => (dispatch: AppDispatch) => {
  if (!country) return;
  dispatch(fetchActiveTournamentsLoading());
  return api
    .get(`tournaments/users/${appSlug}/${country}/active-tournaments/`)
    .then(response => {
      dispatch(fetchActiveTournamentsSuccess({ data: response.data }));
      return response.data;
    })
    .catch(() => dispatch(fetchActiveTournamentsError()));
};

export const fetchTournamentDetails = (tournamentId: number) => (dispatch: AppDispatch) => {
  return api
    .get(`tournaments/${tournamentId}/?tournamentDetails=${Date.now()}`, { timeout: 10000 })
    .then(response => {
      dispatch(fetchTournamentDetailsSuccess(response.data));
      return response.data;
    })
    .catch(() => {});
};

export const fetchGameDetails = (gameSlug: string) => (dispatch: AppDispatch) => {
  return api
    .get(`games/mjs-games/${gameSlug}/`)
    .then(response => {
      dispatch(fetchGameDetailsSuccess(response.data));
      return response;
    })
    .catch(() => {});
};
