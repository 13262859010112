import React, { createContext, useState, useCallback } from "react";
import io from "socket.io-client";

import { getRealTimeServiceBaseUrl } from "actions/api/utils";

interface ISocketContextValue {
  socket: null | SocketIOClient.Socket;
  initiateSocket: Function;
  sessionSocket: null | SocketIOClient.Socket;
  initiateSessionSocket: Function;
}

export const SocketContext = createContext<ISocketContextValue>({
  socket: null,
  initiateSocket: () => {},
  sessionSocket: null,
  initiateSessionSocket: () => {}
});

const socketURL = getRealTimeServiceBaseUrl() + "/pvp-tournaments";
const socketSessionURL = getRealTimeServiceBaseUrl() + "/amp-user-session";

export default function SocketProvider({ children }: any) {
  const [socket, setSocket] = useState<null | SocketIOClient.Socket>(null);
  const [sessionSocket, setSessionSocket] =
    useState<null | SocketIOClient.Socket>(null);

  const initiateSocket = useCallback(() => {
    if (socket === null) {
      setSocket(io(socketURL, { transports: ["websocket"] }));
    }
  }, [socket]);

  const initiateSessionSocket = useCallback(() => {
    if (sessionSocket === null) {
      setSessionSocket(
        io(socketSessionURL, {
          transports: ["websocket"]
        })
      );
    }
  }, [sessionSocket]);

  return (
    <SocketContext.Provider
      value={{ socket, initiateSocket, sessionSocket, initiateSessionSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
}
