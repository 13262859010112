import { css } from "@emotion/react";

const pulzoStyles = css`
  &.pulzo {
    .desk-width {
      .icon-container {
        .sub-button {
          background: #348b6c !important;
          color: #ffffff !important;
        }
      }
    }
  }
`;

export default pulzoStyles;
