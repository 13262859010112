import React, { lazy } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import Modal from "react-bootstrap/Modal";
import { getInterstitialAd } from "utils/interstitialAd";
import { hideAdModal } from "redux/reducers/advertisementsReducer";

const AdvertisementVideo = lazy(() => import("components/feature-specific/advertisement/AdvertisementVideo"));

const admodalCss = css`
  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
`;

export default function FullScreenAdModal() {
  const dispatch = useAppDispatch();
  const { data, show, adName } = useAppSelector((state: any) => state.advertisements);
  let adBlocker = show;
  const adUnit = getInterstitialAd(data, adName);

  if (typeof window.google === "undefined") {
    // Ad blocker is enabled
    if (adBlocker) {
      adBlocker = false;
    }
  }

  return (
    <Modal
      size="lg"
      className="img-fluid"
      backdrop="static"
      show={adBlocker}
      centered
      css={admodalCss}
      onHide={() => dispatch(hideAdModal())}
    >
      <Modal.Body>
        <AdvertisementVideo adUnit={adUnit} />
      </Modal.Body>
    </Modal>
  );
}
