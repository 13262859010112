import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
// import tournamentsAPI from "actions/api/tournaments";

export interface ICompetitor {
  id: number;
  name: string;
  profile_pic?: string;
  profile_pic_id?: number;
}

export interface IUIState {
  messageShown: boolean;
  tournamentNavigationStatus: boolean;
  miniAppPaymentVerifyModalStatus: "checking" | "cancelling" | "cancelled" | "failed" | "error" | "success" | "waiting";
  pvp: {
    status: "idle" | "searching" | "matchFound" | "matchNotFound" | "playerLeft" | "gameOver" | "gameOverWaiting";
    userScore: number | null;
    competitor: ICompetitor | null;
    sessionScores: any;
  };
  translations: any;
  translationsLoading: boolean;
}

const initialState: IUIState = {
  messageShown: false,
  tournamentNavigationStatus: false,
  miniAppPaymentVerifyModalStatus: "waiting",
  pvp: {
    status: "idle",
    userScore: null,
    competitor: null,
    sessionScores: null,
  },
  translations: null,
  translationsLoading: true,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showMessage(state) {
      state.messageShown = true;
    },

    setNavigationToTournamentDone(state) {
      state.tournamentNavigationStatus = true;
    },

    fetchTranslationsBegin(state) {
      state.translationsLoading = true;
    },

    fetchTranslationsSuccess(state, action) {
      state.translations = action.payload;
      state.translationsLoading = false;
    },

    fetchTranslationsFailed(state) {
      state.translationsLoading = false;
    },

    // Mini Program Verify Payment Modal state
    changeMiniProgramPaymentVerifyModalStatus(state, action) {
      state.miniAppPaymentVerifyModalStatus = action.payload;
    },

    // PVP Actions
    pvpSearchOpponent(state) {
      state.pvp = { ...initialState.pvp, status: "searching" };
    },
    pvpGameOver(state, action) {
      state.pvp.status = "gameOver";
      state.pvp.sessionScores = action.payload;
    },
    pvpGameOverWaiting(state, action) {
      if (state.pvp.status === "matchFound") {
        state.pvp.status = "gameOverWaiting";
      }
      state.pvp.userScore = action.payload;
    },
    pvpGameStarted(state, action) {
      if (state.pvp.status === "searching") {
        state.pvp.status = "matchFound";
        state.pvp.competitor = action.payload;
      }
    },
    pvpMatchNotFound(state) {
      state.pvp = { ...initialState.pvp, status: "matchNotFound" };
    },
    setPVPStatus(state, action) {
      state.pvp.status = action.payload;
    },
    resetPVP(state) {
      state.pvp = initialState.pvp;
    },
  },
});

export const {
  resetPVP,
  showMessage,
  pvpGameOver,
  setPVPStatus,
  pvpGameStarted,
  pvpMatchNotFound,
  pvpSearchOpponent,
  pvpGameOverWaiting,
  fetchTranslationsSuccess,
  fetchTranslationsBegin,
  fetchTranslationsFailed,
  setNavigationToTournamentDone,
  changeMiniProgramPaymentVerifyModalStatus,
} = uiSlice.actions;

export default uiSlice.reducer;

export const fetchTranslations = (appSlug: string, country: string) => (dispatch: any) => {
  const translations: any = {
    en: {
      translation: {},
    },
    [country]: {
      translation: {},
    },
  };
  dispatch(fetchTranslationsBegin());
  api
    .get(`tournaments/translations/${appSlug}/${country}/`)
    .then(res => {
      res.data.map((trans: any) => {
        const slug = trans?.key_text?.substring(0, 15).replace(/\s+/g, "-").toLowerCase();
        translations["en"]["translation"][slug] = trans.key_text;
        translations[country]["translation"][slug] = trans.translation;
        return null;
      });
      dispatch(fetchTranslationsSuccess(translations));
    })
    .catch(() => {
      dispatch(fetchTranslationsFailed());
    });
};
