import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as User } from "assets/images/icon-player.svg";
import { formatPlayCount } from "utils";
import TextMarquee from "../../common/TextMarquee";
import { useAppDispatch } from "redux/Store";
import useBrowseGameItem from "hooks/useBrowseGameItem";
import { toggleGameListPopup } from "redux/reducers/modalReducer";

interface IBrowseGameCardProps {
  game: IGame;
  onClick?: Function;
}

const BrowseGameRectangularCard = ({ game, onClick }: IBrowseGameCardProps) => {
  const dispatch = useAppDispatch();
  const { gameTitle } = useBrowseGameItem({
    game,
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      dispatch(toggleGameListPopup({ gameSlug: game?.slug }));
    }
  };

  return (
    <div css={baseCss} className="browse-games-rectangular-card" onClick={handleClick}>
      <div
        className="browse-games-rectangular-card-top"
        css={css`
          background: ${game.background_gradient_stop} 0% 0% no-repeat padding-box;
        `}
      >
        <img src={game.thumbnail_image} alt="" />
      </div>
      <div className="browse-games-rectangular-card-bottom">
        <span className="game-name">
          {" "}
          <TextMarquee text={gameTitle} activationLength={15} width={90} />
        </span>
        <div className="participant-counter-row">
          <User width={8} height={8} style={{ marginBottom: 2 }} />
          <span className="count">{formatPlayCount(game.played_count)}</span>
        </div>
      </div>
    </div>
  );
};

export default BrowseGameRectangularCard;

const baseCss = css`
  &.browse-games-rectangular-card {
    .text-marquee {
      overflow: visible !important;
    }
    height: 100%;
    min-width: 100%;
    /* max-width: 150px; */
    /* overflow: hidden; */
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    /* border: 1px solid #000; */
    border: 0;
    outline: none;
    /* border-radius: 15px; */
    /* margin-left: 12px; */
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    .browse-games-rectangular-card-top {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      /* height: 70%; */
      img {
        height: auto;
        width: 100%;
        padding: 10px;
      }
      /* background: #ff7a00 0% 0% no-repeat padding-box; */
    }
    .browse-games-rectangular-card-bottom {
      overflow: hidden;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 2px 10px;
      flex-shrink: 1;
      .game-name {
        color: #000;
        font: normal normal 600 10px/20px Poppins;
        line-height: 0;
        white-space: nowrap;
      }
      .participant-counter-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: -5px;
        svg {
          fill: #e60f6a;
          margin-right: 2px;
          margin-top: 1px;
        }
        .count {
          display: inline-block;
          line-height: 0;
          text-align: left;
          font: 600 8px/21px Poppins;
          letter-spacing: 0;
          color: #e60f6a;
        }
      }
    }
  }
`;
