import React from "react";
import { css } from "@emotion/react";
import { useHistory } from "hooks/useHistory";
import cn from "classnames";
import { useAppSelector } from "redux/Store";
import { RAZER_CURRENCY_CODES } from "constants/constants";
import { commaSeparatedNumber } from "utils/numberParser";
import { showEarnMoreSilverDialog } from "providers/client-sdk-providers/razer";
import Ticket from "assets/images/razer/razer-silver.png";

interface IPlusButtonProps {
  plusButton?: boolean;
}

export default function RazerSilverStatus({ plusButton = false }: IPlusButtonProps) {
  const { application, user } = useAppSelector(state => state.common);
  const { razer } = useAppSelector(state => state.sdkReducer);
  const { silver_balance } = razer;
  const history = useHistory();
  const walletCurrency = user?.extra_data?.wallet_currency || "";
  const currencySupported = RAZER_CURRENCY_CODES.includes(walletCurrency);

  return (
    <div
      tabIndex={0}
      role="button"
      css={link}
      className={cn("right-style razer-silver-status", {
        // "ps-3": history.location.pathname === `/profile`,
        "gold-enabled": currencySupported,
        "pe-2": history.location.pathname === `/tournament`,
        "app-off":
          // application?.is_back_button_enabled ||
          // !application?.enable_top_bar ||
          application?.payment_channel?.payment_type !== "GOAMA_TOKEN",
        "game-list-topbar":
          (!application?.is_back_button_enabled || !application?.enable_top_bar) &&
          application?.applicationsetting?.layout_type === "REGULAR",
      })}
      onClick={showEarnMoreSilverDialog}
      onKeyDown={showEarnMoreSilverDialog}
    >
      <div className={plusButton ? "d-flex flex-row" : "upper"} css={plusButton ? backgroundStyle : tokenStyle}>
        <div className="d-flex flex-row justify-content-center align-items-center back-design pe-1 me-1 ms-1">
          <img
            src={Ticket}
            alt="ticket"
            css={css`
              width: 30px;
              /* transform: rotate(10deg); */
              /* padding-left: 5px; */
            `}
          />

          <span className="label-sty ps-1">{silver_balance === null ? 0 : commaSeparatedNumber(silver_balance)}</span>
        </div>
        {/* <div className="d-flex align-items-center justify-content-center icon pe-2 desktop-plus ms-auto">
          <FaPlus
            style={{ color: "green" }}
            className="icon-style"
          />
        </div> */}
      </div>
    </div>
  );
}

const backgroundStyle = () => css`
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  width: 100px;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (max-width: 350px) {
    height: 35px;
    width: 90px;
  }

  @media screen and (min-width: 1022px) {
    height: 40px;
  }

  .token-radius {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    right: 50%;
    margin-right: 5px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 350px) {
      height: 30px;
      width: 30px;
    }
  }

  .label-sty {
    color: #332927;
    margin-top: 0;
    font-size: 12px;

    @media screen and (max-width: 350px) {
      padding-left: 37px;
    }
    @media screen and (min-width: 1022px) {
      font-size: 15px;
    }
  }
`;

const tokenStyle = () => css`
  position: relative;
  display: flex;
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  cursor: pointer;
  svg,
  svg g,
  svg circle,
  svg path,
  svg rect {
    fill: #3ccff5;
  }

  .token-border {
    /* background-color: white; */
    border-radius: 5px;
    /* color: white; */
    font-size: 15px;
  }

  .token-radius {
    border-radius: 50%;
    width: 29px;
    height: 29px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token {
    width: 15px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 0 auto;
    top: 9px;
  }
`;

const link = () => css`
  border: none;
  text-decoration: none;
  display: inline-block;

  &.right-style {
    right: 0;
    min-width: 100px;
    /* margin-right: 5px; */
  }

  &.app-off {
    /* margin-left: auto; */
    /* margin-right: 12px; */
  }

  &.game-list-topbar {
    margin-left: auto;
    &.gold-enabled {
      margin-left: 0;
    }
    /* margin-right: 10px; */
  }

  &.style {
    right: 50px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  .icon {
    fill: white !important;
  }

  .icon-style {
    width: 15px;
    height: 15px;
    color: #3ccff5 !important;
  }

  .token-svg {
    z-index: 999;
    fill: #3ccff5;
  }

  .label-sty {
    color: #3ccff5;
    font-size: 15px;
    font-weight: 600;
    @media screen and (min-width: 1022px) {
      font-size: 15px;
    }
  }
`;
