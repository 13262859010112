import { APP_SLUGS, MINI_APP_SLUGS } from "constants/constants";
import { useState, useCallback, useContext } from "react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { isStringEqual } from "utils";
import { showLoginPopup, hideLoginPopup } from "actions";
import { isTournamentTryAvailable, isTournamentTryBased } from "utils/tournamentUtils";
import { SdkContext } from "providers/client-sdk-providers";
import { showPayment } from "redux/reducers/paymentReducer";
import useModalHandler from "hooks/useModalHandler";
import { isKlook } from "utils/applicationSlug";
import { deductLoginPopUpCounter, showPlayGameButton } from "redux/reducers/layoutReducer";

const useDirectPayment = (props: any) => {
  const { isButtonDisabled, tournament, registered, onPlayNowButtonClick, onPaymentConfirmationClick } = props;
  const sdk = useContext(SdkContext);
  const { application, user } = useAppSelector((state: any) => state.common);
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
  const { isRazerGuestUser = null } = sdk;
  const dispatch = useAppDispatch();
  const { user_info } = useAppSelector((state: any) => state.subscription);
  const enableSubscriptionPackage = application?.enable_subscription && user_info.length === 0;
  const { openSubscriptionModal } = useModalHandler();

  const [showVoucherModal, setShowVoucherModal] = useState(false);

  const handleButtonLogic = useCallback(
    (e: any) => {
      if (isButtonDisabled) return;
      if (!isGuestUser && !user) {
        dispatch(showLoginPopup());
        return;
      }

      if (isRazerGuestUser && isRazerGuestUser()) {
        if (!(!isTournamentTryBased(tournament) && tournament?.entry_fee === 0)) {
          if (
            (!isTournamentTryBased(tournament) && tournament.entry_fee > 0) ||
            !isTournamentTryAvailable(tournament)
          ) {
            dispatch(showLoginPopup());
            setTimeout(() => {
              dispatch(hideLoginPopup());
            }, 50);
            return;
          }
        }
      }

      // if (isStringEqual(application?.slug, APP_SLUGS.GO3) && isGuestUser && tournament.entry_fee > 0) {
      //   if (sdk?.handlePayment) {
      //     sdk.handlePayment(tournament);
      //     return;
      //   }
      // }
      if (
        (isGuestUser && tournament?.entry_fee > 0) ||
        (isGuestUser && tournament?.entry_fee_type === "voucher") ||
        (isGuestUser && enableSubscriptionPackage)
      ) {
        dispatch(showLoginPopup());
        return;
      }

      if (isGuestUser && application?.applicationsetting?.login_popup_counter > 0 && tournament?.entry_fee === 0) {
        dispatch(deductLoginPopUpCounter());
      }

      if (isTournamentTryBased(tournament)) {
        if (!isTournamentTryAvailable(tournament)) {
          if (sdk?.handlePayment) {
            sdk.handlePayment(tournament);
          } else {
            if (tournament?.entry_fee_type === "voucher") {
              setShowVoucherModal(true);
            } else {
              if (!isKlook) {
                dispatch(showPayment());
              }
            }
          }
        } else {
          onPlayNowButtonClick(e);
        }
        return;
      }

      if (tournament?.entry_fee_type === "voucher" && !registered) {
        setShowVoucherModal(true);
      } else if (!registered && tournament?.entry_fee > 0) {
        if (sdk?.handlePayment) {
          sdk.handlePayment(tournament);
        } else {
          localStorage && localStorage.setItem("tournament-callback", JSON.stringify(tournament));

          if (isStringEqual(application?.slug, APP_SLUGS.B2C_BD)) {
            /* NOTE: DIRECT TO PAYMENT WITHOUT CONFIRMING - REQUESTED BY TARO */
            onPaymentConfirmationClick(e);
          } else if (MINI_APP_SLUGS.includes(application?.slug) && tournament?.entry_fee_type === "cash") {
            onPlayNowButtonClick(e);
          } else {
            dispatch(showPayment());
            if (tournament?.entry_fee_type === "f-secure-sub") {
              dispatch(showPlayGameButton());
            }
          }
        }
      } else {
        //NOTE - SUBSCRIPTION_PURPOSE

        if (enableSubscriptionPackage && tournament?.trial_gameplay_counter === 0) {
          return openSubscriptionModal();
        } else {
          return onPlayNowButtonClick(e);
        }
      }
    },

    [
      isButtonDisabled,
      isGuestUser,
      user,
      isRazerGuestUser,
      tournament,
      application?.applicationsetting?.login_popup_counter,
      application?.slug,
      registered,
      dispatch,
      sdk,
      onPlayNowButtonClick,
      onPaymentConfirmationClick,
      enableSubscriptionPackage,
      openSubscriptionModal,
    ],
  );

  return {
    handleButtonLogic,
    setShowVoucherModal,
    showVoucherModal,
  };
};

export default useDirectPayment;
