import { css } from "@emotion/react";

const goamaphStyles = css`
  &.goama-ph {
    .confirm-modal-wrapper {
      .big-btn {
        background: linear-gradient(270deg, rgb(139, 0, 240) 0%, rgb(74, 0, 128) 122.67%) !important;
      }
    } 
    .sub-button {
        background: linear-gradient(270deg, rgb(139, 0, 240) 0%, rgb(74, 0, 128) 122.67%) !important;
    }
    .tournament-games-page {
       &.not-login {
        padding-top: 0;
       }
    } 
    .homepage-background {
        .label-sty {
          color: #ffffff;
        }
      .desktop-plus {
        svg {
          color: #ffffff !important;
        }
      }
    }
    .leaderboard-container {
      .rank-btn svg {
        fill: #666666;
      }
      .gamename-style {
        color: #332927;
      }
    }
  } 
`
export default goamaphStyles;