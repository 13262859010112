import React, { createContext, useEffect } from "react";
import { decodeDataFromURL } from "utils";

import { sdkResources } from "./resources";
import { useAppSelector } from "redux/Store";
import { LAYOUT_TYPES } from "constants/constants";

export const SdkContext = createContext(sdkResources.default);

export default function SdkProvider({ children }: any) {
  const { layoutType } = useAppSelector((state: any) => state.layout);

  const { app_slug } = decodeDataFromURL();
  let selectedSdk: ISdkResource;

  if (app_slug && sdkResources[app_slug.toLowerCase()]) {
    selectedSdk = sdkResources[app_slug.toLowerCase()];
  } else {
    selectedSdk = sdkResources["default"];
  }

  if (selectedSdk.type === "external-script") {
    const script = document.createElement("script");
    script.src = selectedSdk?.resourceLink || "";
    script.id = `sdk-external-script-${app_slug?.toLowerCase()}`;
    if (!document.querySelector(`#${script.id}`)) {
      document.head.appendChild(script);
    }
  }

  useEffect(() => {
    const htmlTag = document.getElementsByTagName("html")[0];
    htmlTag?.classList.add(`${app_slug?.toLowerCase()}`);
  }, [app_slug]);

  useEffect(() => {
    const htmlTag = document.getElementsByTagName("html")[0];
    htmlTag?.classList.add(`${layoutType === LAYOUT_TYPES.DARK_V2 && LAYOUT_TYPES.DARK_V2}`);
  }, [layoutType]);

  return <SdkContext.Provider value={selectedSdk}>{children}</SdkContext.Provider>;
}
