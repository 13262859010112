import { css } from "@emotion/react";

const rappicoStyles = css`
  &.rappico {
    .first-board {
      .tile {
        fill: #5ac36f !important;
      }

      .label-style {
        background: #5ac36f !important;
      }
    }

    .middle-board {
      .tile {
        fill: #5ac36f !important;
      }

      .label-style {
        background: #5ac36f !important;
      }
    }

    .last-board {
      .tile {
        fill: #5ac36f !important;
      }

      .label-style {
        background: #5ac36f !important;
      }
    }

    .btn-container {
      background: #5ac36f !important;
    }

    .back-arrow {
      fill: #ffffff !important;
    }

    .link-label {
      color: #000000 !important;
    }

    .active-link {
      .link-img {
        fill: #ff2425 !important;
      }
    }
    .container {
      .link-label {
        color: #000000 !important;
      }
    }

    .link-img {
      fill: #000000 !important;
    }

    .labelbold-style {
      .game-name {
        color: #000000 !important;
      }
    }

    .rank-player {
      .rank-btn {
        .rank-icon {
          fill: #000000 !important;
        }
      }
    }

    .arcade-icon {
      fill: #ffffff !important;
    }

    .browse-game-item {
      .profile-icon {
        fill: #000000 !important;
      }
      .iconLabel-style,
      .list-price {
        color: #000000 !important;
      }
      .img-div {
        border-radius: 15px;
      }

      .timer-side {
        .icon-style {
          .timer-icon {
            fill: #000000 !important;
          }
        }
      }
    }

    .default-color {
      color: #ffff !important;
    }

    .play-icon {
      fill: white !important;
    }

    .title-subtitle-container {
      .game-name {
        color: #ffffff !important;
      }
    }

    .text-marquee {
      .game-name {
        color: #ffffff !important;
      }
    }

    .subtitle {
      color: #000000 !important;
    }

    .gamename-style {
      color: #ffffff !important;
    }

    .text-style-transaction {
      color: #000000 !important;
    }

    .game-loading-overlay {
      background: #000000 !important;
    }

    .leaderboard-popup-item,
    .tournament-info-popup,
    .tournament-list-popup,
    .custom-popup,
    .achievement-popup {
      border-top: 1px solid #ffffff;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .leaderboard-popup-item .drag-handle:after,
    .achievement-popup .drag-handle:after,
    .tournament-info-popup .drag-handle:after,
    .custom-popup .drag-handle:after,
    .tournament-list-popup .drag-handle:after {
      background-color: #919191 !important;
    }
    .show-more,
    .show-all-text {
      cursor: pointer;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      color: #ffff !important;
      font-weight: 500;
    }
    .tournament-list-popup .close,
    .achievement-popup .close,
    .tournament-info-popup .close,
    .leaderboard-popup-item .close {
      fill: #ffffff !important;
    }

    .top-tournament-card .game-name,
    .top-tournament-card .btn,
    .top-tournament-card .participant,
    .top-tournament-card .prize,
    .top-tournament-card .rank,
    .top-battles-card .game-name,
    .top-battles-card .count,
    .trending-game-carousel .played-count,
    .browse-game-item .rank-btn,
    .browse-game-item .play-btn,
    .game-card .played-count,
    .leaderboard-popup-item .gamename-style,
    .user-achievements .title,
    .goama-copyright-text,
    .tournament-list-popup .contain-style > span {
      color: #ffffff !important;
      svg {
        fill: #fff !important;
      }
    }

    .clock-icon,
    .user {
      fill: #000000 !important;
    }

    .modal_header {
      background-color: #000000 !important;
      border-top: 1px solid rgb(255, 255, 255);
    }

    .modal_body {
      background-color: #000000 !important;
    }

    .label-no-ranks {
      color: #ffffff !important;
    }

    .header-style,
    .header-line {
      color: #ffffff !important;
    }

    .list-group-item {
      .table-text {
        color: #000000 !important;
      }
    }

    .progress-bar-container {
      .progress-bar.partner {
        background-color: #ff25ff !important;
      }
    }

    .goama-copyright-text {
      background-color: #000000 !important;
    }

    .name-style {
      color: #000000 !important;
    }

    .list-heading {
      .title-xs {
        color: #ffffff !important;
      }
    }

    .rank-list-container-first {
      .list-heading {
        .subtitle-xs {
          color: #ffffff !important;
        }
      }
    }

    .card-style {
      .label-style {
        color: #000000 !important;
      }
    }

    .desktop-play-back {
      background: #000000 !important;
    }

    .pvp-stats {
      .label-font,
      .value-font {
        color: #000000 !important;
      }
    }

    .arIcon {
      fill: #ffffff;
    }
    .pvp-card-container {
      .score-style {
        color: #000000 !important;
      }
    }
  }
`;

export default rappicoStyles;
