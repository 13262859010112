import { Trans } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { css } from "@emotion/react";
import { hidePayment, resetPaymentState, updatePaymentStatus } from "redux/reducers/paymentReducer";
import { useAppDispatch } from "redux/Store";
import { hidePlayGameButton } from "redux/reducers/layoutReducer";

const congratsModalStyle = (theme: ITheme) => css`
  z-index: 100004 !important;

  .modal-content {
    border-radius: 15px;
    width: 95%;

    @media (min-width: 1022px) {
      width: 70%;
    }
  }

  .close {
    font-size: 35px;
    padding-top: 5px;
    font-weight: 300;

    &:focus {
      outline: none;
    }
  }

  .modal-header {
    border: none;
  }

  .congrats {
    font-size: 25px;
    font-weight: 600;
    color: black;
  }

  .first-para {
    padding: 15px 15px;
    font-weight: 500;
    font-size: 18px;
    @media (min-width: 300px) and (max-width: 390px) {
      font-size: 12px;
    }

    @media (min-width: 330px) and (max-width: 390px) {
      font-size: 15px;
    }
  }

  .second-para {
    padding: 15px 0;
    font-weight: 500;
    font-size: 17px;
  }

  .btn {
    background: ${theme.button.primaryGradientStart};
    border-radius: 10px;
    font-weight: 600;
    color: ${theme.foreground.primary};

    @media (min-width: 300px) and (max-width: 390px) {
      font-size: 12px;
    }

    @media (min-width: 330px) and (max-width: 390px) {
      font-size: 15px;
    }
  }
`;

export default function FSecureSubscriptionModal({ show, onHide }: { show: boolean; onHide: () => void }) {
  const dispatch = useAppDispatch();

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(resetPaymentState());
        dispatch(hidePayment());
        dispatch(updatePaymentStatus(""));
        dispatch(hidePlayGameButton());
        onHide();
      }}
      closeButton
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 100000 }}
      css={congratsModalStyle}
      className="desktop-modal p-0"
      backdrop="static"
    >
      <Modal.Header closeButton />

      <div className="share-container d-flex justify-content-center align-items-center">
        <span className="first-para">
          <Trans i18nKey="you-are-not-sub">You are not subscribed to F-Secure</Trans>
        </span>
      </div>

      <div className="button-container d-flex justify-content-center pt-4 pb-5">
        <button
          className="btn pt-2 pb-2 pe-5 ps-5"
          onClick={() => {
            dispatch(resetPaymentState());
            dispatch(hidePayment());
            dispatch(updatePaymentStatus(""));
            dispatch(hidePlayGameButton());
            window.open("https://touchngoewallet.onelink.me/8mmV/d44gwf5i");
            onHide();
          }}
        >
          <Trans i18nKey="subscribe-now">Subscribe Now</Trans>
        </button>
      </div>
    </Modal>
  );
}
