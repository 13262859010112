import { createWeb3Modal, defaultConfig, useSwitchNetwork, useWeb3Modal } from "@web3modal/ethers/react";
import { go3TestNet, orngMainNet } from "components/feature-specific/web3/web3constants";
import { useEffect } from "react";
import eventEmitter from "utils/eventEmitter";

// 1. Your WalletConnect Cloud project ID
const projectId = "479d164eb3edacc709e257fbcd05eca1";
// 3. Create a metadata object
const metadata = {
  name: "GO3",
  description: "GO3",
  // url: "http://192.168.0.135:3000", // origin must match your domain & subdomain
  // url: "https://go-games.gg", // origin must match your domain & subdomain
  url: "https://play.orangeweb3.com/", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
// 4. Create Ethers config
const ethersConfig = defaultConfig({
  enableCoinbase: false,
  /*Required*/
  metadata,
  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  // rpcUrl: "...", // used for the Coinbase SDK
  // defaultChainId: 1, // used for the Coinbase SDK
});

createWeb3Modal({
  ethersConfig,
  chains: [go3TestNet, orngMainNet],
  // defaultChain: testnet,
  // tokens: ["GO3", "ORNG"],

  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // allWallets: "HIDE",
  // featuredWalletIds: ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"],
  // includeWalletIds: ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"],
  // excludeWalletIds: ["fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa"],
});

export const CryptoInit = () => {
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();
  // const { modalInstance } = useAppSelector(state => state.cryptoToken);
  useEffect(() => {
    eventEmitter.on("CRYPTO", (data: any) => {
      // console.log("event", data);
      if (data.event === "switch") {
        switchNetwork(data.chainId);
      } else if (data.event === "open") {
        open();
      }
    });
    // 5. Create a Web3Modal instance
    return () => {
      eventEmitter.off("CRYPTO");
    };
    // modal.subscribeState(newState => console.log(newState));
    // modal.subscribeEvents(event => console.log(event)); // subscribe to events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default CryptoInit;
