import { useLocation, useNavigate } from "react-router-dom";
export function useHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const listen: any = null;

  return {
    push: navigate,
    go: navigate,
    goBack: () => navigate(-1),
    goForward: () => navigate(1),
    listen,
    location,
  };
}
