import { css } from "@emotion/react";
import { darken } from "polished";
import aliPlusStyles from "styles/client-app-styles/AliPlus";
import apostarStyles from "styles/client-app-styles/Apostar";
import arcorStyles from "styles/client-app-styles/Arcor";
import beesStyles from "styles/client-app-styles/Bees";
import benedettisStyles from "styles/client-app-styles/Benedettis";
import bkashStyles from "styles/client-app-styles/Bkash";
import bonoxsStyles from "styles/client-app-styles/Bonoxs";
import bountieStyles from "styles/client-app-styles/Bountie";
import bubbalooStyles from "styles/client-app-styles/Bubbaloo";
import burguerKingRuStyles from "styles/client-app-styles/BurguerKingRu";
import cervezaSolStyles from "styles/client-app-styles/CervezaSol";
import chopeStormsStyles from "styles/client-app-styles/ChopeStorms";
import claroStyles from "styles/client-app-styles/Claro";
import croydonStyles from "styles/client-app-styles/Croydon";
import danaStyles from "styles/client-app-styles/Dana";
import darkuiStyles from "styles/client-app-styles/Darkui";
import dibbleStyles from "styles/client-app-styles/Dibble";
import elTiempoStyles from "styles/client-app-styles/ElTiempo";
import foodPandaStyles from "styles/client-app-styles/FoodPanda";
import gcashAliPlusStyles from "styles/client-app-styles/GcashAliPlus";
import gcashStyles from "styles/client-app-styles/Gcash";
import globeStyles from "styles/client-app-styles/Globe";
import goamaLatamStyles from "styles/client-app-styles/GoamaLatam";
import googlyStyles from "styles/client-app-styles/Googly";
import jazzcashStyles from "styles/client-app-styles/Jazzcash";
import KFCStyles from "styles/client-app-styles/KFC";
import klookStyles from "styles/client-app-styles/Klook";
import lplStyles from "styles/client-app-styles/Lpl";
import mcDonaldsStyles from "styles/client-app-styles/McDonalds";
import mokStyles from "styles/client-app-styles/Mok";
import nequiStyles from "styles/client-app-styles/Nequi";
import nurtureStyles from "styles/client-app-styles/Nurture";
import ooredooStyles from "styles/client-app-styles/Ooredoo";
import paidiaStyles from "styles/client-app-styles/Paidia";
import pepsicoStyles from "styles/client-app-styles/Pepsico";
import poxipolStyles from "styles/client-app-styles/Poxipol";
import presenteStyles from "styles/client-app-styles/Presente";
import pulzoStyles from "styles/client-app-styles/Pulzo";
import rappicoStyles from "styles/client-app-styles/Rappico";
import razerStyles from "styles/client-app-styles/Razer";
import reddoorzStyles from "styles/client-app-styles/Reddoorz";
import skibidiToiletStyles from "styles/client-app-styles/SkibidiToilet";
import snickersStyles from "styles/client-app-styles/Snickers";
import tadaStyles from "styles/client-app-styles/Tada";
import trendiStyles from "styles/client-app-styles/Trendi";
import unicoUnileverStyles from "styles/client-app-styles/UnicoUnilever";
import valepassStyles from "styles/client-app-styles/Valepass";
import winSportsStyles from "styles/client-app-styles/WinSports";
import xctualityStyles from "styles/client-app-styles/Xctuality";
import yippiStyles from "styles/client-app-styles/Yippi";
import jedatyles from "styles/client-app-styles/Jeda";
import waveStyles from "styles/client-app-styles/Wave";
import metaversegoStyles from "styles/client-app-styles/metaversego";
import goamaphStyles from "styles/client-app-styles/Goamaph";
import niuStyles from "styles/client-app-styles/Niu";
import republikaStyles from "styles/client-app-styles/Republika";
import egEsportsStyles from "styles/client-app-styles/EGEsports";
import barcaStyles from "styles/client-app-styles/Barca";
import wowGamesStyles from "./client-app-styles/wowGamesStyles";
import orangeStyles from "./client-app-styles/Orange";
import go3Styles from "./client-app-styles/Go3";
import avoltaStyles from "./client-app-styles/Avolta";
import tourStyles from "./client-app-styles/TourEast";
import converseStyles from "./client-app-styles/Converse";
import paobcStyles from "./client-app-styles/Paobc";
import polemicGamesStyles from "./client-app-styles/PolemicGames";
import memepopStyles from "./client-app-styles/Memepop";

const globalStyles = (theme: ITheme) => css`
  body,
  * {
    font-family: "${theme.font}", sans-serif;
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
      /* background: #ff0000; */
    }
    /* iframe[id^="google_ads_iframe_"] {
      height: 100%;
    } */
    a {
      text-decoration: none;
    }
  }

  body {
    background-color: ${theme.background.primary} !important;
    .modal-backdrop {
      z-index: 100004;
    }
    .modal {
      z-index: 100004;
    }
    padding-top: 0px !important; /* NOTE: top bar sticky ads adds padding top to body which causes broken layout*/
  }
  .confirm-modal-dialog {
    /* @media (max-width: 1021px) {
      background-color: rgba(255, 255, 255, 0.5);
      height: 100%;
    }
    background-color: rgba(255, 255, 255, 1);
   */
  }
  .monopoly-tooltip {
    z-index: 999999 !important;
  }
  :root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: white;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: 85%;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
    --rsbs-overlay-rounded: 16px;
    --rsbs-overlay-translate-y: 15%;

    --rsbs-content-opacity: 1;
    @media (min-width: 1022px) {
      --rsbs-max-w: 50%;
      --rsbs-bg: ${darken(0.1, theme.background.primary)};
    }
    @media (max-width: 1021px) {
      --rsbs-max-w: 90%;
    }
    @media (max-width: 700px) {
      --rsbs-max-w: 98%;
    }
  }
  [data-content-opacity] {
    opacity: 1;
    background: red;
  }
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: 100004 !important;
    bottom: -12% !important;
    @media (max-width: 1021px) {
      /* bottom: -20% !important; */
    }
  }

  /* .body-top-tournament [data-rsbs-overlay],
  .body-top-tournament [data-rsbs-backdrop],
  .body-top-tournament [data-rsbs-root]:after {
    top: 160px;
    max-width: 65vw;
    margin-left: 105px;
    background-color: transparent;
    overscroll-behavior: contain;
  } */
  .body-top-tournament [data-rsbs-backdrop] {
    opacity: 0 !important;
  }

  [data-rsbs-overlay] {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    transition: all 0.35s linear 0s !important;
  }
  [data-rsbs-header] {
    position: absolute;
    width: 100%;
    height: 135px;
    z-index: 999999 !important;
    cursor: grab !important;
    @media (max-width: 1021px) {
      height: 100px;
    }
  }
  [data-rsbs-content] {
    overflow: unset !important;
  }
  [data-rsbs-scroll] {
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: auto !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .razer [data-rsbs-scroll]:after {
    content: "";
    position: absolute;
    left: 0;
    top: 90%;
    width: 1px;
    height: 100%;
    background-color: #fff;
  }
  .razer [data-rsbs-scroll]:before {
    content: "";
    position: absolute;
    right: 0;
    top: 90%;
    width: 1px;
    height: 100%;
    background-color: #fff;
  }
  [data-rsbs-header]:before {
    width: 102px !important;
    height: 52px !important;
    top: -50px !important;
    background-color: transparent !important;
    @media (max-width: 1021px) {
      width: 75px !important;
      height: 52px !important;
      top: -52px !important;
    }
  }
  [data-rsbs-root]:after {
    content: none !important;
  }
  /**********
    end modal
  ********/
  .btn-link-custom {
    &:hover {
      color: ${theme.button.primaryGradientStart};
    }
  }
  .form-label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.text.primary};
  }
  .form-input {
    background: ${theme.background.primary};
    border-radius: 15px;
    border: none;
    height: 40px;

    &:focus {
      outline: none;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .pt-30px,
  .py-30px,
  .p-30px {
    padding-top: 30px;
  }
  .pb-30px,
  .py-30px,
  .p-30px {
    padding-bottom: 30px;
  }
  .ps-30px,
  .px-30px,
  .p-30px {
    padding-left: 30px;
  }
  .pe-30px,
  .px-30px,
  .p-30px {
    padding-right: 30px;
  }

  /* .custom-page-modal-open {
    filter: opacity(0.5) grayscale(60%) blur(1px) drop-shadow(2px 4px 6px #222);
    pointer-events: none;
  } */

  .close,
  .show-all-text {
    cursor: pointer !important;
  }

  .tournament-info-list {
    .white-card-design {
      div:last-of-type {
        border-bottom: 0;
      }
    }
  }
  @media (max-width: 1021px) {
    .top-tournament-full-width {
      /* padding-top: 90px; */
    }

    .no-top-tournament {
      .game-page-inner {
        padding-top: 70px;
      }
    }

    .DARK {
      .top-tournament-full-width {
        padding-top: 0;
      }
    }
  }

  .page-wrapper {
    min-height: calc(100vh - 70px);
    @media (min-width: 280px) {
      min-height: calc(100vh - 50px);
    }
    @media (min-width: 500px) {
      min-height: calc(100vh - 75px);
    }
    @media (min-width: 768px) {
      min-height: calc(100vh - 120px);
    }
    @media (min-width: 900px) {
      min-height: calc(100vh - 140px);
    }
    @media (min-width: 1021px) {
      min-height: 100vh;
    }
    &.has-footer-terms {
      min-height: calc(100vh - 105px);
      @media (min-width: 280px) {
        min-height: calc(100vh - 100px);
      }
      @media (min-width: 400px) {
        min-height: calc(100vh - 110px);
      }
      @media (min-width: 500px) {
        min-height: calc(100vh - 140px);
      }
      @media (min-width: 540px) {
        min-height: calc(100vh - 125px);
      }
      @media (min-width: 541px) {
        min-height: calc(100vh - 160px);
      }
      @media (min-width: 900px) {
        min-height: calc(100vh - 180px);
      }
      @media (min-width: 1021px) {
        min-height: 100vh;
      }
    }
  }
  /* .single-game-upper-div {
    min-height: calc(100vh - 170px);
  } */
  .empty-page-wrapper {
    min-height: calc(100vh - 180px);
  }
  #icon-edit-profile {
    display: inherit;
  }
  .terms-condition-page,
  .contact-us-page,
  .faq-page {
    overflow-x: hidden;
    .no-back-btn {
      .emptyDivCss {
        width: unset !important;
      }
    }
  }
  .privacy-policy-page {
    max-width: 100vw;
    overflow-x: hidden;
    .no-back-btn {
      .emptyDivCss {
        width: unset !important;
      }
    }
  }
  .heroes {
    .no-back-btn {
      .emptyDivCss {
        width: unset !important;
      }
    }
  }
  .token-page {
    .no-back-btn {
      .emptyDivCss {
        width: unset !important;
      }
    }
  }
  .has-back-btn {
    .small-right-header-2 {
      padding-right: 2rem;
    }
  }
  .mission-page-container {
    padding-top: 25px;
    .back-button-wrapper {
      width: 100%;
    }
  }
  .custom-page {
    &.heroes {
      overflow-x: hidden;
    }
  }
  .my-games-page {
    overflow-x: hidden;
  }
  .half-style {
    .second-half {
      .btn-container {
        margin-bottom: 20px;
      }
    }
  }
  .mobile-home-page {
    /* padding-top: 90px; */
  }
  .has-partner-logo {
    .mobile-home-page {
      padding-top: 5rem;
    }
    .my-games-page {
      padding-top: 3rem !important;
    }
  }
  .DARK {
    .has-top-tournament {
      padding-top: 0;
    }
  }
  /* ----------------------------- Start Single Game Page style ---------------------------- */
  .SINGLE_game_page {
    .background-sty,
    .header-color {
      background-color: transparent !important;
    }
    .ticket-status {
      box-shadow: none !important;
    }
  }
  /* ----------------------------- End Single Game Page style---------------------------- */
  .faq-page {
    padding-bottom: 35px;
  }
  .profile-pvp-stats {
    .label-font {
      font-weight: 600;
    }
  }
  html {
    ${aliPlusStyles}
    ${apostarStyles}
    ${arcorStyles}
    ${beesStyles(theme)}
    ${benedettisStyles(theme)}
    ${bkashStyles}
    ${bonoxsStyles}
    ${bountieStyles(theme)}
    ${bubbalooStyles(theme)}
    ${burguerKingRuStyles}
    ${cervezaSolStyles}
    ${chopeStormsStyles}
    ${claroStyles}
    ${croydonStyles(theme)}
    ${danaStyles(theme)}
    ${darkuiStyles(theme)}
    ${dibbleStyles(theme)}
    ${elTiempoStyles}
    ${foodPandaStyles(theme)}
    ${gcashAliPlusStyles(theme)}
    ${gcashStyles(theme)}
    ${globeStyles(theme)}
    ${goamaLatamStyles(theme)}
    ${googlyStyles}
    ${jazzcashStyles(theme)}
    ${KFCStyles(theme)}
    ${klookStyles}
    ${lplStyles}
    ${mcDonaldsStyles}
    ${mokStyles}
    ${nequiStyles}
    ${nurtureStyles}
    ${ooredooStyles(theme)}
    ${paidiaStyles(theme)}
    ${paobcStyles(theme)}
    ${pepsicoStyles}
    ${poxipolStyles}
    ${presenteStyles}
    ${pulzoStyles}
    ${rappicoStyles}
    ${razerStyles(theme)}
    ${reddoorzStyles}
    ${skibidiToiletStyles(theme)}
    ${snickersStyles}
    ${tadaStyles(theme)}
    ${trendiStyles(theme)}
    ${unicoUnileverStyles(theme)}
    ${valepassStyles}
    ${winSportsStyles}
    ${xctualityStyles}
    ${yippiStyles}
    ${jedatyles}
    ${metaversegoStyles}
    ${waveStyles(theme)}
    ${goamaphStyles}
    ${niuStyles}
    ${republikaStyles}
    ${egEsportsStyles}
    ${barcaStyles}
    ${wowGamesStyles}
    ${orangeStyles}
    ${go3Styles}
    ${avoltaStyles}
    ${tourStyles}
    ${converseStyles(theme)}
    ${polemicGamesStyles}
    ${memepopStyles}

    .profile-page,
    .mission-page-container,
    .arcade-game-page {
      .has-back-btn {
        width: 100% !important;
      }
    }
    /* Start for redeem and reward tooltips */
    .custom-tooltips {
      .tooltip-inner {
        background-color: ${theme.button.primaryGradientStart};
      }
      .arrow::before,
      .bs-tooltip-top .arrow::before {
        border-top-color: ${theme.button.primaryGradientStart};
        top: -0.9px;
      }
      &.active {
        .tooltip-inner {
          background-color: ${theme.text.tertiaryTextColor};
        }
        .arrow::before,
        .bs-tooltip-top .arrow::before {
          border-top-color: ${theme.text.tertiaryTextColor};
        }
      }
    }
    /* End for redeem and reward tooltips */
    /* for copy coupon code  */
    .Toastify__toast-container {
      z-index: 10000000;
    }
    #draggable-modal-content {
      max-height: 100vh;
      overflow-x: hidden;
    }
  }
`;

export default globalStyles;
