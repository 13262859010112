import { css } from "@emotion/react";
import { APP_SLUGS } from "constants/constants";

const tourStyles = css`
  &.${APP_SLUGS.TOUREAST} {
    .back-arrow {
      stroke: #000000 !important;
      fill: #000000 !important;
    }
  }
`;

export default tourStyles;
