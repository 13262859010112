import { createSlice } from "@reduxjs/toolkit";
import tournamentAPI from "actions/api/old_tournaments";
import { AppDispatch } from "redux/Store";

export interface IPlayGame {
  data: any;
  allGames: any;
  loading: boolean;
  error: boolean;
}

const initialState: IPlayGame = {
  data: {},
  allGames: [],
  loading: true,
  error: false
};

export const arcadeGameSlice = createSlice({
  name: "arcadeGames",
  initialState,
  reducers: {
    fetchArcadeGameLoading(state) {
      state.loading = true;
    },
    fetchArcadeGameSuccess(state, action) {
      const categorizedGames: any = {};
      const allGame: any = [];

      action.payload.map((game: IGame) => {
        if (categorizedGames[game.category])
          categorizedGames[game.category].push(game);
        else categorizedGames[game.category] = [game];

        return null;
      });

      action.payload.map((game: any) => {
        if (allGame[game.category]) allGame[game.category].push(game);
        return null;
      });

      state.data = categorizedGames;
      state.allGames = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchArcadeGameError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchArcadeGameLoading,
  fetchArcadeGameSuccess,
  fetchArcadeGameError
} = arcadeGameSlice.actions;

export default arcadeGameSlice.reducer;

export const fetchArcadeAllGames =
  (application: IApplication, country: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchArcadeGameLoading());
    return tournamentAPI
      .get(`/arcade/games/${application?.slug}/${country}/`, {
        params: { country }
      })
      .then(response => {
        dispatch(fetchArcadeGameSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchArcadeGameError()));
  };
