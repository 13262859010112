type EventHandler = (data: any) => void;

class EventEmitter {
  private events: Record<string, EventHandler[]> = {};

  public on(event: string, listener: EventHandler): void {
    if (!this.events[event]) {
      this.events[event] = [];
      this.events[event].push(listener);
    }
  }

  public emit(event: string, data: any): void {
    const listeners = this.events[event];
    if (listeners) {
      listeners.forEach(listener => {
        listener(data);
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public off(event: string, listener?: EventHandler): void {
    delete this.events[event];
  }
}

const eventEmitter = new EventEmitter();

export default eventEmitter;
