import React, { lazy, useCallback, useContext, useEffect, useState } from "react";
import { css } from "@emotion/react";
import Modal from "react-bootstrap/Modal";
import { SdkContext } from "providers/client-sdk-providers";
import { fetchSabayUserProfileId, setSabayAccessAndRefreshToken } from "./utils";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { fetchCommonData } from "actions";
import { Link } from "react-router-dom";
import history from "HistoryWrapper";
import SabayLogo from "assets/images/sabay/SabayLogo.png";
import SabayLogoOutline from "assets/images/sabay/SabayLogoOutline.png";
import classNames from "classnames";
import { Trans } from "react-i18next";
import { SABAY_USERID_KEY } from "constants/constants";
const FullScreenLoadingIndicator = lazy(() => import("components/common/LoadingIndicator/FullScreenLoadingIndicator"));
const MySabayLoginForm = lazy(() => import("./MySabayLoginForm"));
const PhoneLoginForm = lazy(() => import("./PhoneLoginForm"));
const OTPForm = lazy(() => import("./OTPForm"));

const backgroundStyle = (theme: ITheme) => css`
  &.mobile-view {
    padding-top: 0 !important;
    margin-top: 0 !important;
    img {
      //margin-top: 1rem;
      width: 80%;
    }
    .div-footer {
      width: 85%;
      font-size: 0.55em;
    }
  }
  color: ${theme.text.primary};

  &.header {
    border: none;

    button.close {
      color: ${theme.text.primary};
      text-shadow: none;
    }
  }
  img {
    //margin-top: 1rem;
    width: 55%;
  }
  .div-footer {
    width: 39%;
    font-size: 0.6em;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
  }
`;

const errorStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
  .modal-content {
    border: 0;
    background: linear-gradient(60deg, rgba(246, 0, 67, 1) 7%, rgba(254, 165, 85, 1) 100%);
  }
  .modal-header {
    border: 0;
  }
  .terms {
    color: #fff !important;
    a {
      color: #fff !important;
    }
  }
  .cta-text {
    font-size: 1.2em;
    color: #fff !important;
    a {
      color: #fff !important;
    }
  }
`;

export const SabayLoginModal = ({ show, onHide, ...domProps }: any) => {
  const dispatch = useAppDispatch();
  const { instance } = useContext(SdkContext);
  const { application, country, user } = useAppSelector((state: any) => state.common);
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState<"phone" | "otp" | "username">("phone");

  useEffect(() => {
    if (show) {
      setCurrentForm("phone");
    }
  }, [show]);

  const loginToPlatform = useCallback(
    (response: any) => {
      setSabayAccessAndRefreshToken(instance, response?.accessToken?.tokenString, response?.refreshToken?.tokenString);
      setLoading(true);
      fetchSabayUserProfileId(instance)
        .then((userid: string) => {
          localStorage.setItem(SABAY_USERID_KEY, userid);
          return dispatch(
            fetchCommonData({
              app_slug: application?.slug,
              country,
              userid,
            }),
          );
        })
        .then(() => history.push("/tournament"))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((e: Error) => {
          // console.log(e);
          setLoading(false);
        });
    },
    [instance, application, country, dispatch],
  );

  if (user) return null;

  return (
    <Modal show={show} onHide={onHide} {...domProps} centered css={errorStyle} size="lg">
      <Modal.Header closeButton />
      <Modal.Body
        style={{
          zIndex: 99999,
          overflow: "hidden",
        }}
        className={`text-center body-style ${!isDesktop && "mobile-view"}`}
        css={backgroundStyle}
      >
        {loading && <FullScreenLoadingIndicator />}
        <img src={SabayLogo} alt="" />
        <br />
        <br />
        <br />
        <span className="cta-text">
          <b>
            <Trans i18nKey="login-to-play-a">LOGIN TO PLAY AND WIN CASH PRIZES</Trans>
          </b>
        </span>
        <br />
        {currentForm === "phone" && (
          <PhoneLoginForm
            setCurrentForm={setCurrentForm}
            onSuccess={phone => {
              setPhoneNumber(phone);
              setCurrentForm("otp");
            }}
          />
        )}
        {currentForm === "otp" && (
          <OTPForm setCurrentForm={setCurrentForm} phoneNumber={phoneNumber} onSuccess={loginToPlatform} />
        )}

        {currentForm === "username" && <MySabayLoginForm setCurrentForm={setCurrentForm} onSuccess={loginToPlatform} />}
        <div
          style={{
            zIndex: 9999,
          }}
          className={classNames("div-footer mb-2", {
            "mt-5 pt-5 ": isDesktop,
            "mt-0 pt-4": !isDesktop,
          })}
        >
          <span>
            <Trans i18nKey="login-with-phon">LOGIN WITH PHONE NUMBER</Trans>
            &nbsp;
            <Trans i18nKey="automatically-c">
              AUTOMATICALLY CREATES A <b>MYSABAY ACCOUNT</b> IF DO NOT ALREADY OWN ONE.
            </Trans>
          </span>
          <br />
          <br />
          <span className="terms">
            <Link to="/tournament/faq" target="_blank">
              <Trans i18nKey="terms-and-condi">
                <b>TERMS AND CONDITIONS</b>
              </Trans>
            </Link>
          </span>
        </div>
        <img
          style={{
            position: "absolute",
            bottom: 24,
            left: "-3%",
            zIndex: -1,
          }}
          src={SabayLogoOutline}
          alt=""
        />
      </Modal.Body>
    </Modal>
  );
};

export default SabayLoginModal;
