import { useEffect } from "react";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app from "configs/firebase-config";
import { useAppSelector } from "redux/Store";
import tournamentAPI from "actions/api/old_tournaments";

const useFirebase = () => {
  const { application, user } = useAppSelector((state: any) => state.common);
  const messaging = getMessaging(app);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");

          // Get the token
          const token = await getToken(messaging, {
            vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
          });
          console.log("Token:", token);

          if (user?.extra_data?.notification_tokens) {
            const tokenMatching = user?.extra_data?.notification_tokens?.filter((data: any) => token === data);

            if (tokenMatching && tokenMatching.length === 0) {
              tournamentAPI.post(`/${application?.slug}/users/push/notification/subscribe/`, {
                token: token,
                provider: "firebase",
              });
            }
          } else {
            tournamentAPI.post(`/${application?.slug}/users/push/notification/subscribe/`, {
              token: token,
              provider: "firebase",
            });
          }
        } else {
          console.log("Unable to get permission to notify.");
        }
      } catch (error) {
        console.error(error);
      }
    };
    requestPermission();
  }, [application?.slug, messaging, user?.extra_data?.notification_tokens]);

  onMessage(messaging, payload => {
    console.log("Message received. ", payload);
  });
};

export default useFirebase;
