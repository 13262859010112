import React, { lazy, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { Trans } from "react-i18next";
// import { lighten } from "polished";
import { fetchSocialAuthStatus } from "redux/reducers/socialAuthStatusReducer";
import { fetchUserAuthLogin } from "actions/fetchUserAuthLogin";
import Link from "assets/images/link.png";
import UnLink from "assets/images/unlink.png";
import Google from "assets/images/google.png";
import Facebook from "assets/images/facebook.png";

const MediumLoadingIndicator = lazy(() => import("components/common/LoadingIndicator/MediumLoadingIndicator"));
const MyAppleSigninButton = lazy(() => import("components/common/AppleSignIn"));

const backSty = (theme: ITheme) => css`
  box-shadow: 0px 20px 40px ${theme.button.primaryShadow};
  background-color: ${theme.foreground.primary};
  border-radius: 15px;

  .logo {
    width: 40px;
    margin-right: 20px;
  }

  .react-apple-signin-auth-btn-dark {
    padding: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #000000 !important;

    svg {
      width: 56px !important;
      height: 56px !important;

      #Rectangle {
        fill: #000000 !important;
      }
    }
  }

  .apple-grey {
    filter: opacity(0.66);
  }
`;

const connectedLogo = (theme: ITheme) => css`
  position: absolute;
  bottom: 7px;
  right: 10px;
  background: white;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 20px 4px ${theme.button.primaryShadow};

  img {
    width: 100%;
    height: 100%;
  }

  &.apple {
    right: -10px !important;
    bottom: 8px !important;
  }
`;

const greyStyle = () => css`
  filter: grayscale(100%);
`;

const headerName = (theme: ITheme) => css`
  color: ${theme.text.primary};
  font-size: 20px;
  font-weight: 600;
  &.heading-globe {
    color: #1a458b;
  }
`;

const TotalStats = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state: any) => state.stats);
  const { loading } = useAppSelector((state: any) => state.authlinkkey);
  const { application, user } = useAppSelector((state: any) => state.common);
  const { socialAuths } = useAppSelector((state: any) => state.socialAuthStatus);
  const [appleAuthVerification, setAppleAuthVerification] = useState(false);

  const socialAuthArray: string[] = socialAuths.map((element: any) => element.provider);

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const userId = params.get("state");
  const socialAuth = params.get("social_login");

  useEffect(() => {
    if (socialAuth === "apple") {
      if (userId === localStorage.getItem("apple-auth")) {
        setAppleAuthVerification(true);
      }
    }
  }, [socialAuth, userId]);

  useEffect(() => {
    user && dispatch(fetchSocialAuthStatus());
  }, [dispatch, user]);

  const isRecentlyConnected = (backend: string) => {
    if (sessionStorage) {
      let connectedBackends: any = sessionStorage.getItem("connected-backends");
      if (!connectedBackends) connectedBackends = [];
      else connectedBackends = connectedBackends.split(",");
      for (let i = 0; i < connectedBackends.length; i++) {
        if (connectedBackends[i] === backend) return true;
      }
    }
    return false;
  };

  const isConnectedApple = sessionStorage.getItem("connected-apple");

  if (loading) return <MediumLoadingIndicator height={50} />;
  else if (!loading && error && user) return null;
  else if (!application || !application?.enable_social_auth) return null;

  return (
    <>
      <span css={headerName} className={`heading-${application?.slug}`}>
        <Trans i18nKey="linked-with">Linked With</Trans>
      </span>
      <div className="d-flex flex-row mt-3 py-4 px-3 total-stats" css={backSty}>
        {application && application?.enable_social_auth && (
          <div className="d-flex flex-row">
            {application?.google_auth && (
              <div
                tabIndex={0}
                role="button"
                css={isRecentlyConnected("google-oauth2") ? "" : greyStyle}
                className="position-relative pointer"
                onClick={() =>
                  dispatch<any>(fetchUserAuthLogin(application?.slug, "google-oauth2")).then((data: any) => {
                    if (localStorage) localStorage.setItem("auth-backend", "google-oauth2");
                    if (application?.aplicationsetting?.iframe_loading) window.parent.location.replace(data.login);
                    window.location.replace(data.login);
                  })
                }
                onKeyDown={() =>
                  dispatch<any>(fetchUserAuthLogin(application?.slug, "google-oauth2")).then((data: any) => {
                    if (localStorage) localStorage.setItem("auth-backend", "google-oauth2");
                    if (application?.aplicationsetting?.iframe_loading) window.parent.location.replace(data.login);
                    window.location.replace(data.login);
                  })
                }
              >
                <img className="position-relative logo" alt="logo" src={Google} />
                {!isRecentlyConnected("google-oauth2") && (
                  <div css={connectedLogo}>
                    <img
                      className="position-relative"
                      alt="logo"
                      src={socialAuthArray?.includes("google-oauth2") ? Link : UnLink}
                    />
                  </div>
                )}
              </div>
            )}

            {application?.facebook_auth && (
              <div
                tabIndex={0}
                role="button"
                css={isRecentlyConnected("facebook") ? "" : greyStyle}
                className="position-relative pointer"
                onClick={() =>
                  dispatch<any>(fetchUserAuthLogin(application?.slug, "facebook")).then((data: any) => {
                    if (localStorage) localStorage.setItem("auth-backend", "facebook");
                    if (application?.aplicationsetting?.iframe_loading) window.parent.location.replace(data.login);
                    window.location.replace(data.login);
                  })
                }
                onKeyDown={() =>
                  dispatch<any>(fetchUserAuthLogin(application?.slug, "facebook")).then((data: any) => {
                    if (localStorage) localStorage.setItem("auth-backend", "facebook");
                    if (application?.aplicationsetting?.iframe_loading) window.parent.location.replace(data.login);
                    window.location.replace(data.login);
                  })
                }
              >
                <img className="position-relative logo" alt="logo" src={Facebook} />
                {!isRecentlyConnected("facebook") && (
                  <div css={connectedLogo}>
                    <img
                      className="position-relative"
                      alt="logo"
                      src={socialAuthArray?.includes("facebook") ? Link : UnLink}
                    />
                  </div>
                )}
              </div>
            )}

            {application?.apple_auth && (
              <div className={isConnectedApple === "apple" ? "react-apple-signin-auth-btn-dark" : "apple-grey"}>
                <MyAppleSigninButton />

                {isConnectedApple !== "apple" && (
                  <div css={connectedLogo} className="apple">
                    <img className="position-relative" alt="logo" src={appleAuthVerification ? Link : UnLink} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TotalStats;
