import { css } from "@emotion/react";
import PARALUCENT from "assets/fonts/Paralucent/Paralucent-DemiBold.ttf";

const googlyStyles = css`
  @font-face {
    font-family: "Paralucent";
    src: local("Paralucent"), url(${PARALUCENT}) format("truetype");
  }

  &.googly {
    * {
      font-family: "Paralucent";
    }

    background: #121319 !important;

    .back-arrow {
      fill: #ffff !important;
      stroke: #ffff !important;
    }

    .bottom-bar-inside {
      .title-subtitle-container {
        .game-name {
          color: #ffffff !important;
        }

        .tournament-subtitle {
          color: #ffffff !important;
        }
      }

      .ranking-button {
        color: #ffffff !important;
      }
    }

    .clock-participant-container {
      .clock-participant-bg-svg {
        fill: #2d303e !important;
      }
    }

    .clock-participant-text {
      color: #c3cace !important;

      svg {
        fill: #c3cace !important;
      }
    }

    .top-battles-card {
      .frame {
        stroke: #ffffff !important;
        stroke-width: 2px !important;
      }
    }

    .goama-font {
      .game-name {
        color: #ffffff !important;
      }

      .player-numb {
        .count {
          color: #ffffff !important;
        }

        svg {
          fill: #ffffff !important;
        }
      }
    }

    .game-info {
      .triangle-bg {
        stroke: #ffffff !important;
        stroke-width: 2px !important;
      }

      .image-style {
        border: 1px solid #ffffff;
      }
    }

    .info-container {
      .triangle-div {
        .triangle-button {
          box-shadow: none !important;
          svg {
            fill: #ffffff !important;
          }
        }
      }
      .player-numb {
        svg {
          fill: #ffffff !important;
        }
      }
    }

    .desktop-footer {
      .link-img {
        fill: #747b83 !important;
      }

      .link-label {
        color: #747b83 !important;
      }

      .active-link {
        .link-img {
          fill: #00ffff !important;
        }
        .link-label {
          color: #00ffff !important;
        }
      }
    }

    .browse-popup {
      .contain-style {
        color: #ffffff !important;
      }
    }

    .play-btn-browse-container {
      .rank-btn {
        background: #444955 !important;

        svg {
          fill: #ffffff !important;
        }
      }

      .play-btn-browse {
        background: #9b32ff !important;
        svg {
          fill: #ffffff !important;
        }
      }
    }

    .leaderboard-container {
      .browse-popup {
        background: #2d303e !important;

        .name-sty {
          .user {
            fill: #9ea4aa !important;
          }

          .text-sty-label {
            color: #9ea4aa !important;
          }

          .clock-icon {
            fill: #9ea4aa !important;
          }
        }
      }
    }

    .small-card {
      border: 2px solid #444955 !important;
      background-color: #2d303e !important;

      .border-style {
        .btn-mob {
          background: #9b32ff !important;
          svg {
            fill: #ffffff !important;
          }
        }

        .sub-title {
          color: #ffffff !important;
        }

        .user {
          fill: #9ea4aa !important;
        }

        .clock-icon {
          fill: #9ea4aa !important;
        }

        .text-sty-label {
          color: #9ea4aa !important;
        }

        .play-btn-browse {
          max-width: 130px !important;
          width: 95px !important;
        }
      }
    }

    .play-button {
      .font-play {
        color: #ffffff !important;
      }
    }

    .leaderboard-wrapper {
      .leaderboard-container {
        .modal_body {
          background: #2d303e !important;

          .btn-tap-to-see {
            .motion-button {
              .btn-style {
                background: #444955 !important;
                color: #ffffff !important;
              }
            }
          }

          .prize-list-container {
            background: #2d303e !important;
            border: 2px solid #444955 !important;

            .card-prize {
              .div-group {
                background: #2d303e !important;
              }
            }
          }
        }

        .prizes-slider-container {
          border: 2px solid #444955 !important;

          .prizes-slider-item {
            background: #2d303e !important;
            .upper-div {
              color: #ffffff !important;
            }

            .lower-div {
              .lower-div-items {
                .lower-div-title {
                  color: #9ea4aa !important;
                }

                .icon {
                  background: #5d626d !important;
                  box-shadow: none !important;

                  svg {
                    fill: #c3cace !important;
                  }
                }
              }

              .lower-div-footer {
                color: #ffffff !important;
              }
            }
          }
        }
      }

      .close_wrapper {
        svg {
          fill: #9ea4aa !important;
        }
      }
    }

    .leaderboard-popup-item {
      .modal_header {
        background: #2d303e !important;

        .drag-handle:after {
          background: #9ea4aa !important;
        }
      }

      .gamename-style {
        color: #ffffff !important;
      }
    }

    .rank-list-card {
      .rank-list-container-first {
        background: #2d303e !important;
        border: 2px solid #444955 !important;

        .list-group-item {
          background: #2d303e !important;
        }
      }
    }

    .rank-list-container {
      .list-group-item {
        background: #2d303e !important;
        border: 2px solid #444955 !important;
      }

      .no-rank-list {
        border: 2px solid #444955 !important;
        h5 {
          color: #ffffff !important;
        }
      }
    }

    .desktop-play-button-backward {
      .motion-button {
        .desktop-play-footer {
          background: #00ffff !important;
          box-shadow: none !important;
        }
      }
    }

    .modal-dialog {
      .modal-content {
        background: #2d303e !important;

        .modal-header {
          color: #ffffff !important;

          .close {
            color: #9ea4aa !important;
          }
        }

        .modal-body {
          color: #ffffff !important;
        }

        .modal-footer {
          .cancel-btn {
            background: #444955 !important;
          }

          .confirm-btn {
            background: #00ffff !important;
            color: #000000 !important;
          }

          .okay-btn,
          .verify-btn {
            background: #00ffff !important;
            color: #000000 !important;
            padding: 17px;
            border-radius: 10px;
          }
        }
      }
    }

    .browse-game-item {
      .img-div {
        .col-browse {
          background: #2d303e !important;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;

          .subtitle-text {
            color: #ffffff !important;
          }

          .row-style {
            .rank-player {
              .label-rank {
                color: #9ea4aa !important;

                .icon-style {
                  svg {
                    fill: #9ea4aa !important;
                  }
                }

                .iconLabel-style {
                  color: #9ea4aa !important;
                }
              }

              .rank-btn {
                border: none !important;
                background: #444955 !important;
                color: #ffffff !important;

                svg {
                  fill: #ffffff !important;
                }
              }
            }

            .rank-timer {
              .browse-game-item {
                svg {
                  fill: #ffffff !important;
                }
              }

              .label-rank {
                .icon-style {
                  svg {
                    fill: #9ea4aa !important;
                  }
                }

                .iconLabel-style {
                  color: #9ea4aa !important;
                }
              }

              .play-btn {
                background: #9b32ff !important;
              }
            }
          }
        }
      }
    }

    .pvp-page-style {
      background: #121319 !important;
    }

    .pvp-card-container {
      .card-style {
        background: #2d303e !important;

        .avatarCss2 {
          svg {
            .st0 {
              fill: #ffffff !important;
            }
          }
        }

        .loader-wrapper {
          .loader {
            color: #747b83 !important;
          }
        }

        .vsIcon {
          fill: #ffffff !important;
        }

        .name-style {
          color: #ffffff !important;
        }

        .timer-bg {
          .gradient-start,
          .gradient-stop {
            stop-color: #444955 !important;
          }
          filter: none !important;
        }

        .thumbnail {
          border: 2px solid #ffffff !important;
        }
      }

      .button-sty {
        background: #00ffff !important;
        color: #000000 !important;
      }
    }

    .game-loading-overlay {
      .progress-bar-container {
        background: #a679ff !important;
      }
    }

    .profile-pvp-stats {
      .pvp-stats {
        background: #2d303e !important;

        .divCircle {
          svg {
            fill: #9b32ff !important;
          }
        }
      }
    }

    .show-all-text {
      color: #9b32ff !important;
    }

    .profile-history-wrapper {
      .back-ground {
        background: #2d303e !important;
        color: #9ea4aa !important;
        border: 1px solid #444955 !important;
      }

      .history-border {
        border: 1px solid rgb(68, 73, 85) !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;

        .list-group-item {
          background: #121319 !important;
          border: 1px solid #444955 !important;
        }
      }
    }

    .tournament-history-page {
      .history-container {
        .header-style {
          color: #ffffff !important;
        }

        .white-card {
          background: #2d303e !important;

          .first-row {
            border-bottom: 1px solid #9ea4aa !important;
            .tournament-name {
              .street-text {
                color: #ffffff !important;
              }

              .txt-style {
                color: #9ea4aa !important;
              }
            }

            .won-label {
              .won-style {
                color: #9ea4aa !important;
              }

              .dollar {
                color: #ffffff !important;
              }
            }
          }

          .second-row {
            .label {
              color: #9ea4aa !important;
            }

            .bold-text {
              color: #ffffff !important;
            }
          }
        }
      }
    }

    .tournament-info-popup {
      background: #121318 !important;

      .header-line {
        color: #ffffff !important;
      }

      .avatar-row {
        .competitor-name {
          color: #ffffff !important;
        }

        .competitor-score {
          color: #ffffff !important;
        }

        .own-score {
          color: #ffffff !important;
        }
      }

      .rank-list-container-first {
        background: none !important;

        .list-heading {
          background: #2d303e !important;
          border: 1px solid #444955 !important;

          .text-style {
            color: #ffffff !important;
          }
        }

        .achievement-history-list-item {
          background: #121318 !important;
          border: 1px solid #444955 !important;
        }
      }

      .rank-list-container {
        .list-group-item {
          background: #121318 !important;
          border: 1px solid #444955 !important;
        }
      }
    }

    .progress-share-overlay {
      .modal-dialog {
        .modal-content {
          background: #121319 !important;
        }
      }

      .half-style {
        background: #2d303e !important;
        .latest-score-label {
          color: #9ea4aa !important;
        }

        .latest-score {
          color: #ffffff !important;
        }
      }

      .first-board {
        .tile {
          fill: #121319 !important;
        }
      }
      .middle-board {
        .tile {
          fill: #121319 !important;
        }
      }
      .last-board {
        .tile {
          fill: #121319 !important;
        }
      }

      .score-body {
        .name-style {
          color: #222222 !important;
        }
      }
      .btn-container {
        svg {
          fill: #222222 !important;
        }
      }

      .score-body {
        .name-style {
          color: #ffffff !important;
        }

        .label-style {
          background: #202229 !important;
          .score {
            color: #ffffff !important;
          }
        }
      }

      .second-half {
        .btn-circle-with-icon {
          background: #2d303e !important;
          box-shadow: none !important;
          border: 1px solid #9ea4aa80 !important;

          svg {
            fill: #9ea4aa !important;
          }
        }

        .btn-replay {
          background: #9b32ff !important;

          svg {
            fill: #ffffff !important;
          }
        }
      }
    }

    .game-loading-overlay {
      background: #121319 !important;
    }

    .game-loading-overlay {
      .progress-bar-container {
        background: #2d303e !important;
      }
    }

    .bottom-bar-inside {
      .play-button {
        max-width: 140px !important;
        width: 100px !important;
      }
    }

    .payment-class {
      font-size: 14px !important;
    }

    .desktop-layout {
      background: #121319 !important;
    }

    .desktop-sidebar {
      .sidebar-container a:not(.active-link) .link-label {
        fill: #d4f200 !important;
        color: #747b83 !important;
      }

      .sidebar-container {
        .active-dark-bg {
          background: #121319 !important;
        }

        .link-img {
          fill: #747b83 !important;
        }

        .icon-container {
          svg {
            fill: #747b83 !important;
          }
        }

        .active-link {
          .link-img {
            fill: #00ffff !important;
          }
          .link-label {
            color: #00ffff !important;
          }
        }
      }
    }

    .desktop-container {
      .my-games-popup {
        background-color: #121319 !important;
        border: 1px solid #444955 !important;
      }
    }

    .border-style {
      .play-btn {
        background: rgb(155, 50, 255) !important;

        svg {
          fill: #ffffff !important;
        }
      }
    }

    .close_wrapper {
      .close {
        right: 34px !important;
      }
    }

    .recommended-tournament-container {
      .header-style {
        color: #ffffff !important;
      }
    }

    .pvp-card-container {
      .button-sty {
        box-shadow: none !important;
      }
    }
  }
`;

export default googlyStyles;
