import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface ITournamentRewardsState {
  error: boolean;
  loading: boolean;
  data: any[];
}

const defaultState: ITournamentRewardsState = {
  error: false,
  loading: false,
  data: [],
};

const tournamentRewardsSlice = createSlice({
  name: 'tournamentRewards',
  initialState: defaultState,
  reducers: {
    fetchTournamentRewardsLoading(state) {
      state.loading = true;
      state.error = false;
    },
    fetchTournamentRewardsSuccess(state, action: PayloadAction<{ data: any[] }>) {
      state.data = action.payload.data;
      state.error = false;
      state.loading = false;
    },
    fetchTournamentRewardsError(state) {
      state.error = true;
      state.loading = false;
    },
    removeTournamentRewardsSuccess(state, action: PayloadAction<{ rewardId: any }>) {
      state.data = state.data.filter((d: any) => d?.id !== action.payload.rewardId);
    },
  },
});

export const {
  fetchTournamentRewardsLoading,
  fetchTournamentRewardsSuccess,
  fetchTournamentRewardsError,
  removeTournamentRewardsSuccess,
} = tournamentRewardsSlice.actions;

export default tournamentRewardsSlice.reducer;


/* export const tournamentRewardsReducer = (
  state: ITournamentRewardsState = defaultState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.FETCH_TOURNAMENT_REWARDS_LOADING:
      return { ...state, loading: true, error: false };

    case ActionTypes.FETCH_TOURNAMENT_REWARDS_SUCCESS:
      return {
        data: action.payload.data,
        error: false,
        loading: false
      };

    case ActionTypes.FETCH_TOURNAMENT_REWARDS_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };

    case ActionTypes.REMOVE_TOURNAMENT_REWARDS_SUCCESS:
      return {
        ...state,
        data: state.data.filter((d: any) => d?.id !== action?.payload?.rewardId)
      };

    default:
      return state;
  }
}; */
