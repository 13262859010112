import React, { useCallback, useContext, useEffect, useState } from "react";
import { SocketContext } from "providers/SocketProvider";

import NotificationPopUp from "routes/notifications/components/NotificationPopUp";
import { useAppDispatch, useAppSelector } from "redux/Store";
import {
  clearNotificationPopUpItem,
  pushNotificationPopUpItem
} from "redux/reducers/notificationReducer";
import { SdkContext } from "./client-sdk-providers";

export default function NotificationProvider({ children }: any) {
  const sdk = useContext(SdkContext);
  const { application, user } = useAppSelector((state: any) => state.common);
  const { socket, initiateSocket } = useContext(SocketContext);
  const [showNotificationPopUp, setShowNotificationPopUp] =
    useState<boolean>(false);

  const { notifyPopUp } = useAppSelector((state: any) => state.notification);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      application &&
      user &&
      !application.disable_notification &&
      !user.disable_notification &&
      socket === null
    ) {
      // Disabling socket for testing
      initiateSocket();
    }
  }, [socket, application, user, initiateSocket]);

  const joinForNotify = useCallback(() => {
    if (socket) {
      socket?.emit("JOIN_FOR_NOTIFY", {
        user_id: user?.id,
        app: application?.slug
      });
    }
  }, [socket, application, user]);

  useEffect(() => {
    joinForNotify();
  }, [joinForNotify]);

  useEffect(() => {
    socket?.on("USER_NOTIFY", (data: any) => {
      if (!data?.miniprogram_auto_share) {
        dispatch(pushNotificationPopUpItem(data));
      } else {
        if (sdk?.shareTournamentWin) {
          const msg = data?.msg;
          try {
            const jsonData = JSON.parse(msg);
            sdk?.shareTournamentWin(jsonData);
          } catch (e) {
            // 
          }
        }
      }
      // setTimeout(() => {
      //   !showNotificationPopUp && setShowNotificationPopUp(true);
      // }, 2000);
    });
    // eslint-disable-next-line
  }, [socket]);

  //For modal data empty
  useEffect(() => {
    setTimeout(() => {
      setShowNotificationPopUp(false);
      dispatch(clearNotificationPopUpItem(null));
    }, 50000);

    // eslint-disable-next-line
  }, [showNotificationPopUp]);

  useEffect(() => {
    if (notifyPopUp.length > 0 && !showNotificationPopUp) {
      setShowNotificationPopUp(true);
    }
  }, [notifyPopUp, showNotificationPopUp]);

  return (
    <>
      {children}
      {notifyPopUp.length > 0 && (
        <NotificationPopUp
          onHide={() => setShowNotificationPopUp(false)}
          show={showNotificationPopUp}
          data={notifyPopUp}
        />
      )}
    </>
  );
}
