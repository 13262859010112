import React, { lazy, useEffect, Suspense, useState, useContext } from "react";
import { Routes, Route, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { Trans } from "react-i18next";
import { Global, type Interpolation, type Theme } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import globalStyles from "styles/globalStyles";
import { decodeDataFromURL, isStringEqual } from "utils";
import analytics from "utils/analytics";
import { fetchActiveTournaments, fetchCommonData, fetchUserTournaments } from "actions";
// import authAPI from "actions/api/old_auth";
// import eventsAPI from "actions/api/old_events";
// import gamedataAPI from "actions/api/old_gamedata";
// import paymentsAPI from "actions/api/old_payments";
// import tournamentsAPI from "actions/api/old_tournaments";
// import statusAPI from "actions/api/old_status";
// import balanceAPI from "actions/api/old_balance";
// import passwordAPI from "actions/api/old_passwords";
// import loginAPI from "actions/api/old_login";
// import redeemAPI from "actions/api/old_redeem";
// import campaignAPI from "actions/api/old_campaign";
// import biReportsAPI from "actions/api/biReportsApi";
// import { updateAxiosInstanceAPIToken } from "actions/api/utils";
import { fetchAuthLinkKey } from "actions/fetchUserAuthLogin";
import { SdkContext } from "providers/client-sdk-providers";
import { mobileRoutes, desktopRoutes } from "routes";
import { APP_SLUGS } from "constants/constants";
import "react-toastify/dist/ReactToastify.css";
// import monopolyAPI from "actions/api/old_monopoly";
// import subscriptionAPI from "actions/api/old_subscription";
import { ErrorBoundary } from "components/common/ErrorBoundary";
import HelmetScriptLoader from "components/common/HelmetScriptLoader";
import useDiscoveryPlus from "hooks/useDiscoveryPlus";
import OOREDOO_LOADING from "assets/clients/Ooredoo/ooredoo_loading.gif";
import history from "HistoryWrapper";

/* const FullScreenAdModal from "Shared/Components/Modals/FullScreenAdModal") */
// import MobileRouting from "Shared/Mobile/MobileRouting";
import FullScreenLoadingIndicator from "components/common/LoadingIndicator/FullScreenLoadingIndicator";
const DesktopLayout = lazy(() => import("components/layouts/desktop/Layout"));
const FullScreenMessage = lazy(() => import("components/feature-specific/pvp/FullScreenMessage"));
const TrendiLeaderboard = lazy(() => import("components/clients/Trendy/Leaderboard"));
import MobileLayout from "components/layouts/mobile/MobileLayout";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import SnowFlakes from "Shared/Components/SnowFlakes";

import { resetPaymentState } from "redux/reducers/paymentReducer";
import { isBubbaloo, isKlook, isOoredoo, isTrendi, isWowGames } from "utils/applicationSlug";
import Advertisement from "components/feature-specific/advertisement/Advertisement";

// import { useRollbar } from "@rollbar/react";
// import rewardsAPI from "actions/api/old_rewards";
import { BaseModalLoader } from "./components/BaseModalLoader";
import { useMain } from "./hooks/useMain";
// import HeaderBiddingAdvertisement from "components/feature-specific/advertisement/HeaderBiddingAdvertisement";

// import { RegisterUserModal } from "Shared/Components/Modals/ResgisterUserModal";

export default function Main() {
  // const { search: ooredooParams } = useLocation();
  const dispatch = useAppDispatch();
  const sdk = useContext(SdkContext);
  // const location = useLocation(); {Dont Remove this code}
  const isDesktop = useMediaQuery({ query: "(min-width: 1022px)" });
  const location = useLocation();

  const { application, loading, error, country } = useAppSelector((reduxState: any) => reduxState.common);
  const { partnerPaymentStatus } = useAppSelector((state: any) => state.paymentState);

  const singleFeaturedLayout = isStringEqual(application?.applicationsetting?.layout_type, "SINGLE_FEATURED");

  const [imageSrc, _setImageSrc] = useState(false);

  useMain();

  useDiscoveryPlus();

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      _setImageSrc(true);
    };
    img.src = OOREDOO_LOADING;
  }, []);

  //--------------Snowflakes Code {Dont Remove this code}------------------------------------

  /* useEffect(() => {
    const unlisten = history.listen((location: any) => {
      setShowSnowFlakes(location?.pathname);
      // do your magic things here
      // reset the search: clear the results and the search input field
    });
    return function cleanup() {
      unlisten();
    };
  }, []); */

  //--------------Snowflakes Code {Dont Remove this code}------------------------------------
  useEffect(() => {
    if (application?.tournament_enabled && country) {
      dispatch(fetchActiveTournaments(application?.slug, country));
      dispatch(fetchUserTournaments(application?.slug, country));
    } else if (!application?.tournament_enabled && application?.is_c2p_enabled) {
      analytics?.arcadeLandingPage();
      history.push(`/arcade`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  useEffect(() => {
    if (partnerPaymentStatus?.status === "CANCELED") dispatch(resetPaymentState());
  }, [dispatch, partnerPaymentStatus?.status]);

  const { app_slug, country: urlCountry } = decodeDataFromURL();
  const navigate = useNavigate();

  // NOTE: LOADING DEFAULT PLATFORM (GOAMA) LOGIC
  /* IF there's no parameters (app, country) in the url and there's no application/country value saved in localstorage, 
  it will loadd app=goama&country =SG as default.
  Bubbaloo told us to load their platform inside "www.bubbaloocity.com.mx". 
  The issue is they won't add parameters in their url like www.bubbaloocity.com.mx/tournament?app=bubbaloo&country=MX. 
  If the user loads www.bubbaloocity.com.mx, it loads goama as there's no params, so we had to handle it. 
  So, I added the logic to check for "www.bubbaloocity.com.mx" in the url and changed the url to www.bubbaloocity.com.mx/tournament?app=bubbaloo&country=MX from code.
  If any partners say that goama gets loaded instead of theirs then the issue is related to this code.
  *** created by: Ashik, 19/08/2023 */
  useEffect(() => {
    // Get the current browser URL
    const url = window.location.href;
    // Create a URL object
    const urlObject = new URL(url);
    // Get different components from the URL
    // const protocol = urlObject.protocol; // "https:"
    // const host = urlObject.host; // "www.bubbaloocity.com.mx"
    const hostname = urlObject.hostname; // "www.bubbaloocity.com.mx"
    // if (isStringEqual(hostname, "www.bubbaloocity.com.mx") || isStringEqual(hostname, "bubbaloocity.com.mx")) {
    //   if (!application && !country) {
    //     navigate(`/tournament/play-featured-game?app=${APP_SLUGS.BUBBALOO}&country=MX&directNavigation=true`);
    //   }
    // }
    if (isStringEqual(hostname, "play.orangeweb3.com")) {
      if (!application && !country) {
        navigate(`/tournament?app=go3&country=SG`);
      }
    } else if (!application && !country && !app_slug && !urlCountry) {
      history.replace("/tournament?app=goama&country=SG");
    }
  }, [app_slug, application, country, navigate, urlCountry]);

  // in this state, query params has {state|code|state_code|scope|access_token|}
  const authUrl = new URL(window.location.href);
  const queryParams = new URLSearchParams(authUrl.search);
  const queryParamsData: any = {};
  let hasData = false;
  for (const key of Array.from(queryParams.keys())) {
    queryParamsData[key] = queryParams.get(key);
    hasData = true;
  }

  useEffect(() => {
    if (hasData && queryParams.get("state")) {
      const { app_slug, country } = decodeDataFromURL();
      queryParamsData["app"] = app_slug;
      queryParamsData["country"] = country;
      dispatch<any>(
        fetchAuthLinkKey(app_slug || "", localStorage.getItem("auth-backend") || "backend", queryParamsData),
      ).then((scopeToken: any) => {
        // has to save the token for further use
        // in case of reload the browser of tab
        if (scopeToken?.token && scopeToken.exp) {
          let connectedBackend: any = sessionStorage.getItem("connected-backends");
          if (!connectedBackend) connectedBackend = [];
          else connectedBackend = connectedBackend.split(",");
          connectedBackend.push(localStorage.getItem("auth-backend"));
          if (sessionStorage) {
            sessionStorage.setItem("session-token", scopeToken.token);
            localStorage?.setItem("session-token", scopeToken.token);
            localStorage?.setItem("session-exp", new Date().valueOf().toString());
            sessionStorage.setItem("connected-backends", connectedBackend);
          }
          /* updateAxiosInstanceAPIToken(
            [
              authAPI,
              eventsAPI,
              gamedataAPI,
              tournamentsAPI,
              campaignAPI,
              paymentsAPI,
              statusAPI,
              balanceAPI,
              passwordAPI,
              loginAPI,
              redeemAPI,
              rewardsAPI,
              monopolyAPI,
              // biReportsAPI,
              subscriptionAPI,
            ],
            scopeToken.token,
          ); */
        }
        dispatch(fetchCommonData(undefined, sdk?.preInitCallback));
        analytics.pageView(location.pathname);
      });
    } else {
      dispatch(fetchCommonData(undefined, sdk?.preInitCallback));
      analytics.pageView(location.pathname);
    }
    // eslint-disable-next-line
  }, []);

  if (loading && isOoredoo && imageSrc) return <FullScreenLoadingIndicator />;

  const isAliPlusApp =
    application?.slug === APP_SLUGS.GCASH_ALIPLUS ||
    application?.slug === APP_SLUGS.DANA_ALIPLUS ||
    application?.slug === APP_SLUGS.TNG_ALIPLUS;

  const renderTitle = () => {
    if (isAliPlusApp) {
      return "Games";
    } else if (isKlook) {
      return "Klookcoaster";
    } else if (isBubbaloo) {
      return "Bubbaloo City";
    } else if (isWowGames) {
      return "WOW Games";
    } else {
      return "Goama Games";
    }
  };

  const title = renderTitle();

  if (loading) {
    if (isOoredoo) {
      imageSrc && <FullScreenLoadingIndicator />;
    } else {
      return <FullScreenLoadingIndicator />;
    }
  }

  if (error) {
    return (
      <Suspense>
        {error === "bad-request" && (
          <FullScreenMessage>
            <Trans i18nKey="something-went-">Something went wrong!</Trans>
          </FullScreenMessage>
        )}
        {error === "unauthorized" && (
          <FullScreenMessage>
            <Trans i18nKey="your-session-ha">Your session has expired.</Trans>
          </FullScreenMessage>
        )}
        {error === "outdated" && (
          <FullScreenMessage dark={false} tryAgain={false} status={false}>
            <a href="https://www.mpt.com.mm/en">
              <img
                alt="MPT Logo"
                className="mb-2"
                src="https://www.mpt.com.mm/wp-content/themes/mpt/images/mpt-logo.svg"
              />
            </a>
            <p className="fw-semibold">မင်္ဂလာပါ။</p>
            <p>ကျေးဇူးပြုပြီး ဂိမ်းကစားရန် MPT4U app ကို update လုပ်ရန်အောက်ပါ link ကိုနှိပ်ပါ။</p>
            <p>
              <a href="http://bit.ly/FReedownLoad">bit.ly/FReedownLoad</a>
            </p>
          </FullScreenMessage>
        )}
      </Suspense>
    );
  }

  return (
    <>
      <HelmetScriptLoader>
        <title>{title}</title>
      </HelmetScriptLoader>
      <Global styles={globalStyles as Interpolation<Theme>} />
      {/* Load css from sdk */}
      {sdk?.customStyles && <Global styles={sdk.customStyles} />}
      <ErrorBoundary>
        <Suspense fallback={isOoredoo && imageSrc && <FullScreenLoadingIndicator />}>
          <Routes>
            {isTrendi && <Route key="trendi_app" path="/games/:gameSlug/:init?" element={<TrendiLeaderboard />} />}
            <Route
              element={
                isDesktop ? (
                  <DesktopLayout>
                    <Outlet />
                  </DesktopLayout>
                ) : (
                  <MobileLayout>
                    <Outlet />
                  </MobileLayout>
                )
              }
            >
              {isDesktop &&
                desktopRoutes.map((route: any, index: number) => (
                  <Route key={index} path={route.path} element={<route.component />} />
                ))}
              {!isDesktop &&
                mobileRoutes.map((route: any, index: number) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={singleFeaturedLayout && route.componentTwo ? <route.componentTwo /> : <route.component />}
                  />
                ))}
              {/* {allRoutes.map((routesInfo: any) =>
                isDesktop
                  ? routesInfo.routes.map(
                      (routeData: any) =>
                        routesInfo.routeType === "desktop" && (
                          <Route key={routeData.key} path={routeData.path} element={<routeData.component />} />
                        ),
                    )
                  : routesInfo.routeType === "mobile" &&
                    routesInfo.routes.map((routeData: any) => (
                      <Route
                        key={routeData.key}
                        path={routeData.path}
                        element={
                          singleFeaturedLayout && routeData.componentTwo ? (
                            <routeData.componentTwo />
                          ) : (
                            <routeData.component />
                          )
                        }
                      />
                    )),
              )} */}
            </Route>
          </Routes>
        </Suspense>
        <BaseModalLoader />
      </ErrorBoundary>
      <Advertisement name="Interstitial Ad Before Game Play" />
    </>
  );
}
