export function shortenText(text: string | null | undefined, text_length: number = 9): string {
  if (!text) return "";
  const maxLength = text_length;
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
}

export function cleanString(input: string | undefined): string {
  if (!input) return "";
  // Remove emojis by matching surrogate pairs and unicode ranges for emojis
  const emojiRegex =
    /([\u{1F600}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}]|[\uD83C-\uDBFF\uDC00-\uDFFF])/gu;
  // Remove special characters except &
  const specialCharRegex = /[!"#$%()*+,./:;<=>?@[\\\]^_`{|}~]/g;

  // Replace matched characters with an empty string
  const result = input.replace(emojiRegex, "").replace(specialCharRegex, "");
  return result;
}
