import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
// import { ActionTypes } from "actions/actionTypes";
import { processGameNameResponse } from "utils";

type IActiveTournamentState = {
  data: any[];
  topTournaments: ITournament[];
  topFeatured: ITournament[];
  topBattles: any[];
  allGames: IGame[];
  allTournaments: ITournament[];
  activeTournamentCount: number;
  loading: boolean;
  error: boolean;
};

const defaultState: IActiveTournamentState = {
  data: [],
  topTournaments: [],
  topFeatured: [],
  topBattles: [],
  activeTournamentCount: 0,
  allGames: [],
  allTournaments: [],
  loading: true,
  error: false,
};

const activeTournamentsSlice = createSlice({
  name: "activeTournaments",
  initialState: defaultState,
  reducers: {
    fetchActiveTournamentsLoading(state) {
      state.loading = true;
      state.error = false;
    },
    fetchActiveTournamentsSuccess(state, action: PayloadAction<{ data: any[] }>) {
      const tournaments = [...state.allTournaments];
      const games = [...state.allGames];
      let activeTournamentCount = 0;

     /*  function processTournament(tournament: ITournament): ITournament {
        return {
          ...tournament,
          start_time: new Date(tournament.start_time),
          end_time: new Date(tournament.end_time),
        };
      } */

      function updateGame(game_data: IGame): IGame {
        const processedData = { ...game_data };
        processedData.name = processGameNameResponse(game_data.name);

        const gIndex = games.findIndex(game => game?.slug === processedData?.slug);
        if (gIndex < 0) games.push(processedData);
        else {
          games[gIndex] = processedData;
        }
        return processedData;
      }

      function updateTournamentData(tournament: ITournament, game_data: IGame) {
        tournament.game = game_data?.slug;
        tournament.start_time = new Date(tournament.start_time);
        tournament.end_time = new Date(tournament.end_time);
      }

      function updateTournament(tournament: ITournament) {
        const tIndex = tournaments.findIndex((t: ITournament) => t?.id === tournament?.id);

        if (tIndex < 0) {
          tournaments.push(tournament);
        } else {
          tournaments[tIndex] = tournament;
        }
      }

      function updateResponseData(data: any, sortByEntryFee = false) {
        activeTournamentCount += data.tournaments.length;
        data.tournaments.map((tournament: ITournament) => {
          updateTournamentData(tournament, data.game_data);
          updateTournament(tournament);
          return null;
        });

        if (sortByEntryFee) {
          data.tournaments.sort((a: ITournament, b: ITournament) => (a?.entry_fee > b?.entry_fee ? -1 : 1));
        }

        const game_data = updateGame(data.game_data);
        return { ...data, game_data };
      }

      const topTournaments: ITournament[] = action.payload.data
        .map((d: any) => d.tournaments)
        .reduce((a: ITournament[], b: any) => [...a, ...b], [])
        .filter((t: ITournament) => t.tournament_type !== "instant-pvp" && t.is_feature_game === true);
      topTournaments.sort((a, b) => a.order - b.order);

      const topFeatured: ITournament[] = action.payload.data
        .map((d: any) => d.tournaments)
        .reduce((a: ITournament[], b: any) => [...a, ...b], [])
        .filter((t: ITournament) => t.tournament_type !== "instant-pvp" && t.is_top_featured_game === true);
      topFeatured.sort((a, b) => a.order - b.order);

      const topBattles = action.payload.data
        .map((game: any) => {
          return {
            ...game,
            tournaments: game.tournaments.filter((t: ITournament) => t.tournament_type === "instant-pvp"),
          };
        })
        .filter((d: any) => d.tournaments.length !== 0)
        .map((d: any) => updateResponseData(d));

      const data = action.payload.data
        .map((data: any) => {
          return {
            ...data,
            tournaments: data.tournaments.filter((t: ITournament) => t.tournament_type !== "instant-pvp"),
          };
        })
        .filter((data: any) => data.tournaments.length !== 0)
        .map((data: any) => updateResponseData(data));

      state.activeTournamentCount = activeTournamentCount;
      state.data = data;
      state.topBattles = topBattles;
      state.topTournaments = topTournaments;
      state.topFeatured = topFeatured;
      state.allGames = games;
      state.allTournaments = tournaments;
      state.loading = false;
      state.error = false;
    },
    fetchUserTournamentsSuccess(state, action: PayloadAction<any[]>) {
      const tournaments = [...state.allTournaments];
      const games = [...state.allGames];

      action.payload.forEach((data: any) => {
        updateGame(data.game_data);
        updateTournamentData(data.tournament, data.game_data);
        updateTournament(data.tournament);
      });

      state.allGames = games;
      state.allTournaments = tournaments;

      function updateGame(game_data: IGame): IGame {
        const processedData = { ...game_data };
        processedData.name = processGameNameResponse(game_data.name);

        const gIndex = games.findIndex(game => game?.slug === processedData?.slug);
        if (gIndex < 0) games.push(processedData);
        else {
          games[gIndex] = processedData;
        }
        return processedData;
      }

      function updateTournamentData(tournament: ITournament, game_data: IGame) {
        tournament.game = game_data?.slug;
        tournament.start_time = new Date(tournament.start_time);
        tournament.end_time = new Date(tournament.end_time);
      }

      function updateTournament(tournament: ITournament) {
        const tIndex = tournaments.findIndex((t: ITournament) => t?.id === tournament?.id);

        if (tIndex < 0) {
          tournaments.push(tournament);
        } else {
          tournaments[tIndex] = tournament;
        }
      }
    },
    fetchTournamentDetailsSuccess(state, action: PayloadAction<ITournament>) {
      const tournaments = [...state.allTournaments];
      const fetchedTournament = action.payload;
      if (fetchedTournament?.payment_reference)
        localStorage?.setItem(`active-ref-${fetchedTournament?.id}`, fetchedTournament.payment_reference);

      function processTournament(tournament: ITournament): ITournament {
        return {
          ...tournament,
          start_time: new Date(tournament.start_time),
          end_time: new Date(tournament.end_time),
        };
      }

      const processedTournament = processTournament(fetchedTournament);
      updateTournament(processedTournament);
      state.allTournaments = tournaments;

      function updateTournament(tournament: ITournament) {
        const tIndex = tournaments.findIndex((t: ITournament) => t?.id === tournament?.id);

        if (tIndex < 0) {
          tournaments.push(tournament);
        } else {
          tournaments[tIndex] = tournament;
        }
      }
    },
    fetchGameDetailsSuccess(state, action: PayloadAction<IGame>) {
      const games = [...state.allGames];
      updateGame(action.payload);
      state.allGames = games;

      function updateGame(game_data: IGame): IGame {
        const processedData = { ...game_data };
        processedData.name = processGameNameResponse(game_data.name);

        const gIndex = games.findIndex(game => game?.slug === processedData?.slug);
        if (gIndex < 0) games.push(processedData);
        else {
          games[gIndex] = processedData;
        }
        return processedData;
      }
    },
    fetchActiveTournamentsError(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchActiveTournamentsLoading,
  fetchActiveTournamentsSuccess,
  fetchUserTournamentsSuccess,
  fetchTournamentDetailsSuccess,
  fetchGameDetailsSuccess,
  fetchActiveTournamentsError,
} = activeTournamentsSlice.actions;

export default activeTournamentsSlice.reducer;

/* 
export const activeTournamentsReducer = (
  state: IActiveTournamentState = defaultState,
  action: any,
): IActiveTournamentState => {
  const tournaments = [...state.allTournaments];
  const games = [...state.allGames];
  let activeTournamentCount = 0;

  function processTournament(tournament: ITournament): ITournament {
    return {
      ...tournament,
      start_time: new Date(tournament.start_time),
      end_time: new Date(tournament.end_time),
    };
  }

  function updateGame(game_data: IGame): IGame {
    const processedData = { ...game_data };
    processedData.name = processGameNameResponse(game_data.name);

    const gIndex = games.findIndex(game => game?.slug === processedData?.slug);
    if (gIndex < 0) games.push(processedData);
    else {
      games[gIndex] = processedData;
    }
    return processedData;
  }

  function updateTournamentData(tournament: ITournament, game_data: IGame) {
    tournament.game = game_data?.slug;
    tournament.start_time = new Date(tournament.start_time);
    tournament.end_time = new Date(tournament.end_time);
  }

  function updateTournament(tournament: ITournament) {
    const tIndex = tournaments.findIndex((t: ITournament) => t?.id === tournament?.id);

    if (tIndex < 0) {
      tournaments.push(tournament);
    } else {
      tournaments[tIndex] = tournament;
    }
  }

  function updateResponseData(data: any, sortByEntryFee = false) {
    activeTournamentCount += data.tournaments.length;
    data.tournaments.map((tournament: ITournament) => {
      updateTournamentData(tournament, data.game_data);
      updateTournament(tournament);
      return null;
    });

    if (sortByEntryFee) {
      data.tournaments.sort((a: ITournament, b: ITournament) => (a?.entry_fee > b?.entry_fee ? -1 : 1));
    }

    // Add games to allGames state
    const game_data = updateGame(data.game_data);
    return { ...data, game_data };
  }

  switch (action.type) {
    case ActionTypes.FETCH_ACTIVE_TOURNAMENTS_LOADING:
      return { ...state, loading: true, error: false };

    case ActionTypes.FETCH_ACTIVE_TOURNAMENTS_SUCCESS: {
      const topTournaments: ITournament[] = action.payload.data
        .map((d: any) => d.tournaments)
        .reduce((a: ITournament[], b: any) => [...a, ...b], [])
        .filter(
          (t: ITournament) =>
            // t.order >= 990 &&
            t.tournament_type !== "instant-pvp" && t.is_feature_game === true,
        );
      topTournaments.sort((a, b) => a.order - b.order);

      const topFeatured: ITournament[] = action.payload.data
        .map((d: any) => d.tournaments)
        .reduce((a: ITournament[], b: any) => [...a, ...b], [])
        .filter(
          (t: ITournament) =>
            // t.order >= 990 &&
            t.tournament_type !== "instant-pvp" && t.is_top_featured_game === true,
        );
      topFeatured.sort((a, b) => a.order - b.order);

      const topBattles = action.payload.data
        .map((game: any) => {
          return {
            ...game,
            tournaments: game.tournaments.filter((t: ITournament) => t.tournament_type === "instant-pvp"),
          };
        })
        .filter((d: any) => d.tournaments.length !== 0)
        .map(updateResponseData);

      const data = action.payload.data
        .map((data: any) => {
          return {
            ...data,
            tournaments: data.tournaments.filter((t: ITournament) => t.tournament_type !== "instant-pvp"),
          };
        })
        .filter((data: any) => data.tournaments.length !== 0)
        .map((data: any) => updateResponseData(data));

      return {
        ...state,
        activeTournamentCount,
        data,
        topBattles,
        topTournaments,
        topFeatured,
        allGames: games,
        allTournaments: tournaments,
        loading: false,
        error: false,
      };
    }

    case ActionTypes.FETCH_USER_TOURNAMENTS_SUCCESS:
      action.payload.map((data: any) => {
        updateGame(data.game_data);
        updateTournamentData(data.tournament, data.game_data);
        updateTournament(data.tournament);
        return null;
      });

      return {
        ...state,
        allGames: games,
        allTournaments: tournaments,
      };

    case ActionTypes.FETCH_TOURNAMENT_DETAILS_SUCCESS: {
      const fetchedTournament = action.payload;
      if (fetchedTournament?.payment_reference)
        localStorage?.setItem(`active-ref-${fetchedTournament?.id}`, fetchedTournament.payment_reference);
      const processedTournament = processTournament(fetchedTournament);
      updateTournament(processedTournament);
      return { ...state, allTournaments: tournaments };
    }

    case ActionTypes.FETCH_GAME_DETAILS_SUCCESS:
      updateGame(action.payload);
      return { ...state, allGames: games };

    case ActionTypes.FETCH_ACTIVE_TOURNAMENTS_ERROR:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
}; */
