import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "./api/tournaments";
import api from "./api";
import { updateUserAvatarSuccess } from "redux/reducers/commonReducer";

export const updateUserAvatar = (picture_id: number) => async (dispatch: AppDispatch) => {
  const postData: any = {
    picture_id,
  };
  return api
    .put(`tournaments/users/update-profile-picture/`, postData)
    .then(response => {
      dispatch(updateUserAvatarSuccess(response.data));
      return response.data;
    });
    // .catch(() => dispatch({ type: ActionTypes.UPDATE_USER_AVATAR_ERROR }));
};
