import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { FaLock } from "react-icons/fa";

const fontStyle = (theme: ITheme) => css`
  box-shadow: 0 10px 20px 0 ${theme.button.primaryShadow};
  font-weight: 600px;
  border-radius: 10px !important;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.text.primary};
  /* box-shadow: 0px 0px 12px rgba(80, 80, 80, 0.15); */
  @media screen and (max-width: 360px) {
    font-size: 11px;
  }

  .colorStyle {
    background-color: ${theme.foreground.primary};
  }
`;

const backGround = (theme: ITheme) => css`
  background-color: ${theme.foreground.primary};

  .dummyDataStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const unlockStyle = css`
  top: 16%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  background-color: #61149b;
  border-radius: 18px;
`;

const textStyle = css`
  color: white;
  font-size: 12px;
`;

const dummyTextCSS = css`
  width: 15px;
  height: 3px;
  background-color: #e6e7e8;
  border-radius: 5px;
  margin: auto;
`;

const dummyTextCSS2 = css`
  width: 30px;
  height: 3px;
  background-color: #e6e7e8;
  border-radius: 5px;
  margin: auto;
`;

const dummyAvatarCSS = css`
  width: 33px;
  height: 33px;
  background-color: #e6e7e8;
  border-radius: 50%;
`;

const dummyItems: any = [];

for (let i = 0; i < 8; i++) {
  dummyItems.push(
    <li className="li list-group-item" css={backGround} key={i}>
      <div className="row align-items-center">
        <div className="col-3 col-items text-start dummyDataStyle">
          <div className="" css={dummyTextCSS} />
        </div>
        <div className="col-1 col-items text-start me-3">
          <div css={dummyAvatarCSS} />
        </div>

        <div className="col-2 col-items text-start">
          <div
            css={[
              dummyTextCSS,
              css`
                width: 48px;
              `
            ]}
           />
        </div>

        <div className="col-2 col-items">
          <div css={dummyTextCSS2} />
        </div>

        <div className="col-2 col-items text-end px-4 ms-2">
          <div css={dummyTextCSS2} />
        </div>
      </div>
    </li>
  );
}

interface IDummyRankListProps {
  overlayText: string;
  overlayBackgroundColor: string;
}

export default function DummyRankList({
  overlayBackgroundColor,
  overlayText
}: IDummyRankListProps) {
  return (
    <div className="position-relative rank-list-container dummy-rank-list">
      <div css={fontStyle}>
        <li className="li list-group-item colorStyle">
          <div className="row align-items-center">
            <div className="col-3 col-items text-start px-2">
              <Trans i18nKey="rank">Rank</Trans>
            </div>
            <div className="col-3 col-items text-start px-3">
              <Trans i18nKey="name">Name</Trans>
            </div>
            <div className="col-2 col-items text-end ">
              <Trans i18nKey="prize">Prize</Trans>
            </div>
            <div className="col-2 col-items text-end ms-4">
              <Trans i18nKey="score">Score</Trans>
            </div>
          </div>
        </li>
        {dummyItems}
      </div>

      <div
        className="position-absolute py-2 unlock-style"
        style={{ backgroundColor: overlayBackgroundColor }}
        css={unlockStyle}
      >
        <div className="text-center px-2" css={textStyle}>
          <FaLock className="me-1" />
          {overlayText}
        </div>
      </div>
    </div>
  );
}
