import { css } from "@emotion/react";
import RUBIK from "assets/fonts/Rubik/Rubik-SemiBold.ttf";

const burguerKingRuStyles = css`
  @font-face {
    font-family: "Rubik";
    src: local("Rubik"), url(${RUBIK}) format("truetype");
  }

  &.burger-king-ru {
    * {
      font-family: "Rubik";
    }
    .navbar-logos {
      .king-burger-navbar-logo {
        position: absolute;
        left: 15px;
        top: 15px;
        max-height: 50px;
        z-index: 999;
      }
    }
    .tournament-games-page {
      &.logo-design {
        padding-top: 90px;
      }
    }
    .back-button-enabled {
      .king-burger-navbar-logo {
        left: 65px;
        top: 10px;
      }
      .back-arrow {
        fill: #502314;
        stroke: #502314;
      }
    }
    .arcade-header-logo {
      position: absolute;
      left: -12px;
      top: 15px;
    }
    .has-partner-logo {
      .mobile-home-page {
        padding-top: 0;
      }
    }
    .label-sty {
      color: #ffffff;
    }
  }
`;

export default burguerKingRuStyles;
