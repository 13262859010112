import React, { useState } from "react";
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";
import logo from "assets/clients/foodpanda/foodpanda.png";
import banner_winner from "assets/clients/foodpanda/360X50_winners.png";
import { Form, FormGroup, Label, Input, Button, Tooltip } from "reactstrap";

import { useAppSelector } from "redux/Store";

const login_form = css`
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
  height: 100%;
  margin-top: 0%;
`;

const button = css`
  background-color: #d70f64 !important;
  border: none;
  margin: 0 auto;
  width: 100%;
  border-radius: 0;

  &:hover {
    background-color: #d70f64 !important;
  }
`;

const label = css`
  font-size: 15px;
  margin-top: 0.5rem;
  text-align: center;
  font-weight: 300;
`;

const image = css`
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const formStyle = css`
  margin-bottom: 25.5px;
`;
const formStyleConsent = css`
  margin-bottom: 25.5px;
  margin-left: 20px;
`;

const emailStyle = css`
  color: #d64161;
`;

const buttonStyle = css`
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  cursor: pointer;
`;

const inputStyle = css`
  border-radius: 0;
  &:focus {
    border-color: black;
    box-shadow: none;
  }
`;

interface IUserForm {
  name: string;
  driverId?: string;
  email?: string;
  consent: boolean;
}

export default function FoodPandaDriverIdLoginForm() {
  const [show, setShow] = useState(true);
  const { country } = useAppSelector((state: any) => state.common);
  const { clearErrors, handleSubmit, register, errors, unregister } = useForm<IUserForm>();

  const onSubmit = (values: any) => {
    const paramName = encodeURI(values.name);
    const paramUserId = encodeURI(show ? values.driverId : values.email);
    const BASE_URL = `${window.location.origin}/tournament?app=foodpanda&country=${country}&name=${paramName}&userid=${paramUserId}`;
    window.location.replace(BASE_URL);
  };

  return (
    <div>
      <Form method="post" onSubmit={handleSubmit(onSubmit)} css={login_form}>
        <a
          href="https://goama.com/blog/foodpanda-mobile-tournament-powered-by-goama-list-of-winners"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={banner_winner} alt="Logo" />
        </a>
        <p />
        <img css={image} src={logo} alt="Logo" />

        <p css={label}>
          Welcome to foodpanda mobile game tournament powered by Goama Games!
          <br />
          Play to WIN exciting prizes! Log-in now!
        </p>
        {show ? (
          <FormGroup>
            <Label>Rider ID</Label>
            <Input
              id="TooltipDriverID"
              css={inputStyle}
              type="text"
              name="driverId"
              placeholder="Enter your Rider ID."
              required
              onChange={() => clearErrors("driverId")}
              innerRef={register({
                required: true,
                pattern: {
                  value: /^\d+$/i,
                  message: "Only numbers are allowed",
                },
              })}
            />
            <Tooltip
              isOpen={!!errors.driverId?.message}
              target="TooltipDriverID"
              placement="bottom"
              required
              style={{ backgroundColor: "#fa7751", color: "white" }}
              arrowClassName="fp-arrow"
            >
              {errors.driverId?.message}
            </Tooltip>
          </FormGroup>
        ) : (
          <FormGroup>
            <Label>Email</Label>
            <Input
              id="TooltipEmail"
              css={inputStyle}
              type="email"
              required
              name="email"
              placeholder="Enter your email address."
              innerRef={register({
                required: true,
              })}
            />
            <Tooltip
              isOpen={!!errors.email?.message}
              target="TooltipEmail"
              placement="bottom"
              style={{ backgroundColor: "#fa7751", color: "white" }}
              arrowClassName="fp-arrow"
            >
              {errors.email?.message}
            </Tooltip>
          </FormGroup>
        )}
        <FormGroup css={formStyle}>
          <Label>Name</Label>
          <Input
            css={inputStyle}
            id="TooltipName"
            type="text"
            name="name"
            placeholder="Enter your name."
            required
            innerRef={register({
              required: true,
              pattern: {
                value: /[a-zA-Z]+(\s[a-zA-Z]+){0,}/i,
                message: "Name must be an Alphabet",
              },
            })}
          />
          <Tooltip
            isOpen={!!errors.name?.message}
            target="TooltipName"
            placement="bottom"
            style={{ backgroundColor: "#fa7751", color: "white" }}
            arrowClassName="fp-arrow"
          >
            {errors.name?.message}
          </Tooltip>
        </FormGroup>
        <FormGroup css={formStyleConsent}>
          <Input
            id="TooltipConsent"
            css={inputStyle}
            type="checkbox"
            name="consent"
            required
            innerRef={register({
              required: true,
            })}
          />
          <Tooltip
            isOpen={!!errors.consent?.message}
            target="TooltipConsent"
            placement="bottom"
            style={{ backgroundColor: "#fa7751", color: "white" }}
            arrowClassName="fp-arrow"
          >
            {errors.consent?.message}
          </Tooltip>

          {show ? (
            <Label>
              I consent to the disclosure of my data to Delivery Hero (Singapore) Pte. Ltd (&quot;foodpanda&quot;) to
              collect and use it for food panda&apos;s rider programme.
            </Label>
          ) : (
            <Label>
              I consent to the disclosure of my data to Goama Pte Ltd and agreed to the{" "}
              <a href="https://goama.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://goama.com/terms/" target="_blank" rel="noopener noreferrer">
                T&C{" "}
              </a>
              to collect and use it for food panda&apos;s mobile game tournament marketing programme.
            </Label>
          )}
        </FormGroup>
        <Button type="submit" className="btn-block" css={button}>
          <b>PLAY GAME</b>
        </Button>

        {show ? (
          <div css={label}>
            Don&apos;t have a <b>Rider ID?</b> Use your{" "}
            <button
              css={buttonStyle}
              onClick={() => {
                setShow(false);
                unregister("driverId");
              }}
            >
              <b css={emailStyle}>Email</b>
            </button>{" "}
            to play.
          </div>
        ) : (
          <div css={label}>
            Use your{" "}
            <button
              css={buttonStyle}
              onClick={() => {
                setShow(true);
                unregister("email");
              }}
            >
              <b css={emailStyle}>Rider ID</b>
            </button>{" "}
            to play.
          </div>
        )}
      </Form>
    </div>
  );
}
