import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { useAppSelector } from "redux/Store";
import LoadingIndicator from "../../../common/LoadingIndicator/LoadingIndicator";

const style = () => css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999999;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;

  h4 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
`;

const PaymentLoading: React.FC = () => {
  const { paymentLoading } = useAppSelector((state: any) => state.sdkReducer);

  if (!paymentLoading) return null;

  return (
    <div css={style}>
      <h4>
        <Trans i18nKey="payment-process">Payment Processing...</Trans>
      </h4>
      <LoadingIndicator size={3} color="rgba(255,255,255,0.8)" />
    </div>
  );
};

export default PaymentLoading;
