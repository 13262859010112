import React from "react";
import { css } from "@emotion/react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "hooks/useHistory";
import { rgba } from "polished";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/Store";

import Coin from "assets/images/Coin.png";
import Ticket from "assets/images/G-Ticket.png";
import { isTournamentTryBased } from "utils/tournamentUtils";
import { hidePayment } from "redux/reducers/paymentReducer";
import { isBkash, isDana, isOoredoo } from "utils/applicationSlug";

const modalStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};

  .modal-content {
    width: 90%;
    border-radius: 15px;
  }

  .modal-header {
    font-size: 10px;
    background-color: white;
    padding: 8px 13px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: none;
  }

  .modal-body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
  }
`;

const backgroundStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
  background-color: ${theme.background.primary};

  &.header {
    border: none;

    button.close {
      color: ${theme.text.primary};
      text-shadow: none;
    }
  }

  .label-style {
    justify-content: space-between;
    font-size: 10px;
  }

  .token {
    width: 10px;
  }

  .token-style {
    font-weight: 600;
    color: #7e78c8;
  }

  .token-svg {
    fill: ${theme.button.primaryGradientStart};
    padding-left: 2px;
    width: 15px;
  }

  .square-design {
    justify-content: space-around;
  }

  .token-radius {
    background: linear-gradient(270deg, ${theme.background.primary} 0%, ${theme.background.primary} 122.67%);

    border-radius: 50%;
    width: 29px;
    height: 29px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .square-style {
    background-color: white;
    width: 100%;
    border-radius: 5px;
    font-size: 10px;
    color: ${theme.text.primary};
    font-weight: 500;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px ${theme.button.primaryShadow};

    svg,
    svg g,
    svg circle,
    svg path,
    svg rect {
      fill: ${theme.button.primaryGradientStart};
    }
  }

  .close {
    &:focus {
      outline: none;
    }
  }

  .border-style {
    font-size: 10px;
    padding: 10px 13px !important;
  }

  .sorry-text {
    font-size: 35px;
    font-weight: 700;
    justify-content: center;
    color: ${theme.button.primaryGradientStart};
  }

  .text-style {
    font-weight: 700;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 15px;
    text-align: center;
    color: ${theme.text.secondary};
  }

  .buy-token-style {
    border-bottom: 1px solid #f2f2f2;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 13px !important;
    margin-top: 10px;
    background-color: ${theme.button.primaryGradientStart};
    color: white;
    justify-content: center;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 15px 14px 0px ${rgba(theme.button.primaryGradientStart, 0.3)};
    &:focus {
      outline: none;
    }
  }
`;

export const BuyTokenModal = ({ show, onHide, value, entryFeeType, tournament }: any) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { application } = useAppSelector((state: any) => state.common);
  const directPaymentEnabled = isBkash || isOoredoo; // NOTE: In future add more checks like isBkash || isOredoo

  function closeModal() {
    dispatch(hidePayment());
    onHide();
  }

  function goToWallet() {
    if (entryFeeType === "ticket") {
      closeModal();
      history.push(`/tickets`);
      if (directPaymentEnabled) {
        dispatch(hidePayment());
      }
    } else if (value?.reward_value_type === "TICKET") {
      history.push(`/tournament`);
      closeModal();
    } else {
      localStorage.setItem("buy-token", "true");
      if (isDana) {
        history.push(`/coin-purchase`);
      } else {
        history.push(`/token-profile`);
      }

      if (directPaymentEnabled) {
        dispatch(hidePayment());
      }
      closeModal();
    }
  }

  const getCurrencyIcon = () => {
    let icon;

    if (application?.payment_channel?.custom_currency_icon) {
      icon = application.payment_channel.custom_currency_icon;
    } else if (value?.reward_value_type === "TICKET") {
      icon = Ticket;
    } else {
      icon = Coin;
    }
    return icon;
  };

  return (
    <Modal show={show} centered css={modalStyle} onHide={closeModal}>
      <Modal.Header closeButton css={backgroundStyle} className="d-flex align-items-center" />
      <Modal.Body className="body-style d-flex flex-column p-0" css={backgroundStyle}>
        <span className="sorry-text d-flex justify-content-center pt-2 pb-2">
          <Trans i18nKey="sorry">Sorry!</Trans>
        </span>

        {(entryFeeType === "ticket" ||
          (isTournamentTryBased(tournament) && tournament?.trial_packages[0]?.currency === "ticket")) && (
          <span className="border-style text-style d-flex justify-content-center">
            <Trans i18nKey="you-do-not-have">
              You do not have sufficient tickets to participate in this battle. Know how to earn more tickets.
            </Trans>
          </span>
        )}

        {(entryFeeType === "coin" ||
          (isTournamentTryBased(tournament) && tournament?.trial_packages[0]?.currency === "coin")) && (
          <span className="border-style text-style d-flex justify-content-center">
            <Trans i18nKey="you-do-not-coin"> You do not have sufficient coins to participate in this battle.</Trans>
          </span>
        )}

        {/* // NOTE - Only for Pepsico Cash */}

        {(entryFeeType === "cash" ||
          (isTournamentTryBased(tournament) && tournament?.trial_packages[0]?.currency === "cash")) && (
          <span className="border-style text-style d-flex justify-content-center">
            <Trans i18nKey="you-do-not-have">You do not have sufficient joy point to participate in this battle.</Trans>
          </span>
        )}

        {/* // NOTE - Only for Pepsico Cash */}

        {value &&
          (value?.reward_value_type !== "TICKET" ? (
            <span className="border-style text-style d-flex justify-content-center">
              <Trans i18nKey="you-do-not-tick">You do not have sufficient coins to participate in this battle.</Trans>
            </span>
          ) : (
            <span className="border-style text-style d-flex justify-content-center">
              <Trans i18nKey="you-have-insuff">You have insufficient tickets, please earn tickets and come back.</Trans>
            </span>
          ))}

        <div className="d-flex justify-content-center mt-2 mb-4">
          {/* // NOTE - For cash currency button won't be visible */}
          {tournament?.trial_packages[0]?.currency !== "cash" && entryFeeType !== "cash" && (
            <button
              className="d-flex flex-row  position-relative align-items-center  buy-token-style"
              onClick={() => goToWallet()}
            >
              {(entryFeeType === "coin" || value?.reward_value_type === "TOKEN") && (
                <img
                  src={getCurrencyIcon()}
                  alt="coin"
                  css={
                    value?.reward_value_type !== "TICKET"
                      ? css`
                          width: 20px;
                        `
                      : css`
                          width: 18px;
                          transform: rotate(10deg);
                          padding-left: 5px;
                        `
                  }
                />
              )}

              {value?.reward_value_type !== "TICKET" &&
                entryFeeType !== "ticket" &&
                !isTournamentTryBased(tournament) && (
                  <span className="ps-2">
                    <Trans i18nKey="buy-coins">Buy Coins</Trans>
                  </span>
                )}

              {value?.reward_value_type === "TICKET" && (
                <span className="ps-2">
                  <Trans i18nKey="play-game">Play Game</Trans>
                </span>
              )}

              {isTournamentTryBased(tournament) && tournament?.trial_packages[0]?.currency === "coin" && (
                <span className="ps-2">
                  <Trans i18nKey="buy-coins">Buy Coins</Trans>
                </span>
              )}

              {(entryFeeType === "ticket" ||
                (isTournamentTryBased(tournament) && tournament?.trial_packages[0]?.currency === "ticket")) && (
                <span className="ps-2">
                  <Trans i18nKey="learn-more">Learn More</Trans>
                </span>
              )}
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
