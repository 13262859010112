import { css } from "@emotion/react";
import React, { type Context } from "react";
import { Context as RollbarContext, type ContextInterface, getRollbarFromContext } from "@rollbar/react";
import { Trans } from "react-i18next";

const getRetryCountFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return parseInt(urlParams.get("retryCount") || "0", 10);
};

const MaxRetryCount = 1;

const retryPageLoading = () => {
  const currentRetryCount = getRetryCountFromURL();

  if (currentRetryCount < MaxRetryCount) {
    const newRetryCount = currentRetryCount + 1;
    const newURL = `${window.location.pathname}?retryCount=${newRetryCount}`;
    try {
      caches?.keys()?.then(list =>
        list?.map(key => {
          try {
            caches?.delete(key);
          } catch (e) {
            //
          }
          return key;
        }),
      );
    } catch (e) {
      //
    }
    window.location.assign(newURL);
  }
};

export class ErrorBoundary extends React.Component<any, any> {
  // This must always be named `contextType`. It's a special React property.
  static contextType = RollbarContext;
  rollbar: any;
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidMount(): void {
    this.rollbar = getRollbarFromContext(this.context as Context<ContextInterface>);
  }

  componentDidCatch(error: any, errorInfo: any) {
    retryPageLoading();
    this.rollbar?.error(`Something went wrong: ${error}`, error, errorInfo);
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div
          css={css`
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100vh;
            padding: 5%;
            overflow-x: scroll;
            background: #222;
            color: white;
            button {
              font-weight: 600;
              background-color: #fff;
              border-radius: 14px;
              border: 1px solid #fff;
              color: #222;
              outline: none;
              /* width: 100px; */
              /* font-size: 4vw; */
              padding: 1%;
            }
          `}
        >
          <h2>Something went wrong.</h2>
          <button
            className="my-5"
            onClick={() => {
              try {
                caches?.keys()?.then(list =>
                  list?.map(key => {
                    try {
                      caches?.delete(key);
                    } catch (e) {
                      //
                    }
                    return key;
                  }),
                );
              } catch (e) {
                //
              }
              window.location.reload();
            }}
          >
            <Trans i18nKey="try-again">Try Again</Trans>?
          </button>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
