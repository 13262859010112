import { useAppDispatch } from "redux/Store";
import { showLeaderboardPopup, hideLeaderboardPopup as baseHideLeaderboardPopup } from "redux/reducers/layoutReducer";

export default function useRankingButton() {
  const dispatch = useAppDispatch();

  const openLeaderBoardPopUp = (tournament: ITournament, openPrize = false) => {
    dispatch(
      showLeaderboardPopup({
        openPrize,
        tournament: tournament.id,
      }),
    );
  };

  const hideLeaderboardPopUp = () => {
    dispatch(baseHideLeaderboardPopup());
  };

  return { openLeaderBoardPopUp, hideLeaderboardPopUp };
}
