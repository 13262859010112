import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";

// import balanceAPI from "actions/api/balance";

export interface IPartnerBalance {
  data: any[];
  balance: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IPartnerBalance = {
  data: [],
  balance: [],
  loading: false,
  error: false,
};

const partnerBalanceSlice = createSlice({
  name: "partnerBalance",
  initialState,
  reducers: {
    fetchPartnerBalanceInfoLoading(state) {
      state.loading = true;
    },
    fetchPartnerBalanceInfoSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchPartnerBalanceInfoError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    },
    fetchPartnerBalanceLoading(state) {
      state.loading = true;
    },

    fetchPartnerBalanceSuccess(state, action) {
      state.balance = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchPartnerBalanceError(state) {
      state.balance = [];
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchPartnerBalanceInfoLoading,
  fetchPartnerBalanceInfoSuccess,
  fetchPartnerBalanceInfoError,
  fetchPartnerBalanceLoading,
  fetchPartnerBalanceSuccess,
  fetchPartnerBalanceError,
} = partnerBalanceSlice.actions;

export default partnerBalanceSlice.reducer;

export const fetchPartnerBalanceInfo = (slug: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchPartnerBalanceInfoLoading());
  return api
    .get(`partnerbalance/${slug}/config/`)
    .then(response => {
      dispatch(fetchPartnerBalanceInfoSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchPartnerBalanceInfoError()));
};

export const fetchPartnerBalance = (slug: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchPartnerBalanceLoading());
  return api
    .get(`partnerbalance/${slug}/`)
    .then(response => {
      dispatch(fetchPartnerBalanceSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchPartnerBalanceError()));
};

export const fetchPaymentPartnerBalance = (slug: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchPartnerBalanceLoading());
  return api
    .get(`payments/${slug}/partner-balance/`)
    .then(response => {
      dispatch(fetchPartnerBalanceSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchPartnerBalanceError()));
};
