import axios from "axios";
import {
  getUserServiceBaseUrl,
  checkSessionAndUpdateAxiosInstance,
  getAppSlugHeaderForAxios
} from "./utils";

const tournamentsAPI = axios.create({
  baseURL: `${getUserServiceBaseUrl()}/api/v2/tournaments`,
  headers: { "Content-Type": "application/json", ...getAppSlugHeaderForAxios() }
});

checkSessionAndUpdateAxiosInstance(tournamentsAPI);

export default tournamentsAPI;
