import React, { lazy } from "react";
import { css } from "@emotion/react";
import { Modal } from "react-bootstrap";
const EmailOnlyLoginForm = lazy(() => import("routes/auth/components/EmailOnlyLoginForm"));
import { APP_SLUGS } from "constants/constants";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { hideLoginPopup } from "actions";
interface IEmailOnlyLoginProps {
  header?: string;
  logo?: any;
  body?: string;
  defaultBody?: boolean;
  defaultTncLink?: boolean;
  hidePrivacyPolicy?: boolean;
  appName?: string;
  textColor?: string;
  bgColor?: string;
  tncText?: string;
  customClass?: string;
  tncLink?: string;
  show?: boolean;
  onHide?: any;
  customCheckboxElement?: any;
  style?: any;
  footerText?: any;
}

const EmailOnlyLogin = ({
  logo = null,
  textColor = "",
  header = "",
  appName = "",
  body = "",
  defaultBody = false,
  hidePrivacyPolicy = false,
  defaultTncLink = true,
  bgColor = "",
  customClass = "",
  tncLink = "",
  style,
  footerText,
  customCheckboxElement,
}: IEmailOnlyLoginProps) => {
  const { loginPopupVisibility } = useAppSelector((state: any) => state.layout);
  const dispatch = useAppDispatch();
  const baseCss = () => css`
    .${`${APP_SLUGS.CHOPE_STORMS}`} {
      .logo {
        height: 80px !important;
      }
    }
    .modal-header {
      border: 0;
      @media (max-width: 1021px) {
        padding: 10px;
        padding-bottom: 0;
      }
      .close {
        outline: none;
      }
    }
    .xctuality {
      .modal-header {
        .close {
          color: #ffffff;
        }
      }
    }
    .skibidi-toilet {
      .modal-header {
        .close {
          color: #ffffff;
        }
      }
    }
    .modal-content {
      background-color: ${bgColor};
    }
    .modal-body {
      padding: 0;
    }
  `;

  return (
    <Modal
      css={baseCss}
      show={loginPopupVisibility}
      onHide={() => {
        dispatch(hideLoginPopup());
      }}
      centered
      contentClassName={customClass}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <EmailOnlyLoginForm
          header={header}
          logo={logo}
          body={
            defaultBody
              ? `Welcome to ${appName} mobile game tournament powered by Goama Games! \n Play to WIN exciting prizes! Log-in now!`
              : body
          }
          appName={appName}
          textColor={textColor}
          bgColor={bgColor}
          tncLink={tncLink}
          defaultTncLink={defaultTncLink}
          hidePrivacyPolicy={hidePrivacyPolicy}
          customCheckboxElement={customCheckboxElement}
          style={style}
          footerText={footerText}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EmailOnlyLogin;
