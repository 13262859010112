import { css } from "@emotion/react";

const klookStyles = css`
  &.klook {
    body,
    .faq-page {
      background: #6152d4 !important;
    }

    .logo-sty {
      img {
        position: relative;
        z-index: 101;
        margin-top: 16px !important;
        margin-bottom: 0 !important;
        padding-bottom: 5px;
      }
    }
    .navbar-container {
      &.header-style {
        background-color: #4d40cc;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
    .tournament-games-page {
      &.logo-design {
        padding-top: 0;
      }
    }
    .desktop-footer {
      display: none;
    }
    .tournament-games-page {
      background: #6152d4 !important;
    }
    .single-game-upper-div {
      background: #6152d4 !important;
    }

    .leaderboard-popup-item {
      .modal_header {
        background: #6152d4 !important;
      }

      .leaderboard-wrapper {
        .leaderboard-container {
          .modal_body {
            background: #6152d4 !important;
          }
        }

        .close_wrapper {
          svg {
            fill: #ffffff !important;
          }
        }
      }
    }

    .faq-page {
      color: #ffffff !important;

      .button-group {
        .title {
          color: #ffffff !important;
        }
      }
    }

    .prizes-slider-container {
      .prizes-slider-item {
        background: #ffedcb !important;
      }
    }

    .prize-list-container {
      .card-prize {
        background: #ffedcb !important;
        border-radius: 10px !important;
        padding-top: 15px;
      }
    }

    .rank-list-container-first {
      .list-group-item {
        background: #ffedcb !important;
      }
    }

    .rank-list-container {
      .list-group-item {
        background: #ffedcb !important;
      }
    }

    .gamename-style {
      color: #ffffff !important;
    }

    .upper-div {
      .title-md {
        color: #ff5b00 !important;
      }
    }

    .lower-div {
      .lower-div-item {
        .lower-div-title {
          color: #ff5b00 !important;
        }

        .lower-div-footer {
          color: #ff5b00 !important;
        }

        .icon {
          background: #ff5b00 !important;

          svg {
            fill: #ffffff !important;
          }
        }
      }
    }

    .rank-list-card {
      .reward-sty {
        color: #ffffff !important;
      }

      .list-heading {
        color: #ffffff !important;
      }
    }

    .desktop-play-button-backward {
      .btn-footer {
        box-shadow: none !important;
        background: #ff5b00 !important;
      }
    }

    .navbar-container {
      .back-button-wrapper {
        svg {
          fill: #ffffff !important;
          stroke: #ffffff !important;
        }
      }
    }

    .fontHeadStyle {
      color: #ffffff !important;
    }

    .user-achievements {
      .title {
        color: #ffffff !important;
      }
    }

    .tournament-history-btn {
      color: #ffffff !important;
    }

    .show-all-text {
      color: #ffffff !important;
    }

    .history-container {
      .header-style {
        color: #ffffff !important;
      }

      .white-card {
        background: #ffedcb !important;

        .street-text {
          color: #ff5b00 !important;
        }

        .txt-style {
          color: #ff5b00 !important;
        }

        .won-label {
          .won-style {
            color: #ff5b00 !important;
          }
        }

        .second-row {
          .label,
          .label-value {
            color: #ff5b00 !important;
          }
        }
      }
    }

    .klook-play-btn {
      justify-content: center !important;
      .play-button {
        background: #00cbd1;
        color: #fff;
        min-width: 200px;
        border-radius: 50px;
        font-weight: 800;
      }
    }

    .tournament-info-list {
      .header-line {
        color: #ffffff !important;
      }

      .white-card-design {
        background: #ffedcb !important;

        .rank-style {
          color: #ff5b00 !important;
        }
      }
    }

    .leaderboard-body {
      .header-line {
        color: #ffffff !important;
      }
    }

    .tournament-info-popup {
      background: #6152d4 !important;

      .close_wrapper {
        svg {
          fill: #ffffff !important;
        }
      }
    }

    .modal-content {
      .half-style {
        background: #2e2ea6 !important;
      }
    }

    .btn-container {
      background: #00cbd1 !important;
      box-shadow: none !important;
    }

    .popup-style {
      background: #6152d4 !important;
    }

    .klook-profile {
      background: #6152d4 !important;
    }

    .progress-share-overlay {
      .half-style {
        padding-top: 5px;
      }
    }
    .card-banner-image {
      position: relative !important;
    }

    .terms-and-conditions {
      background: #6152d4 !important;

      .font-style {
        color: #ffffff !important;
      }
    }

    .footer-wrapper {
      .font-style {
        color: #ffffff !important;
      }
    }

    .avatar-update-modal {
      .update-button {
        color: #ffffff !important;
      }
    }
    .tournament-games-page {
      min-height: unset;
    }
    .game-page-inner {
      margin-bottom: 15px;
    }
    .campaign-message-modal {
      .close-button {
        background-color: #00cbd1;
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 8px;
        padding: 3px;
      }
      .image-style {
        margin-top: 0 !important;
      }
    }
    .head {
      position: absolute;
    }
    .mobile-footer-page {
      padding-bottom: 40px;
    }
  }
`;

export default klookStyles;
