import { css } from "@emotion/react";

const reddoorzStyles = css`
  &.reddoorz {
    .browse-game-item {
      .play-btn {
        svg {
          fill: #ffffff;
        }
      }
    }
    .browse-popup {
      .gamename-style {
        color: #000000;
      }
      .play-btn-browse-container {
        .rank-btn {
          svg {
            fill: #000000;
          }
        }
      }
    }
    .progress-share-overlay {
      .btn-container {
        box-shadow: none;
      }
    }
  }
`;

export default reddoorzStyles;
