import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { processGameNameResponse, processTournamentFromResponse } from 'utils';

type TUserTournamentsState = {
  data: any[];
  participants: IParticipant[] | null;
  tournaments: ITournament[];
  loading: boolean;
  error: boolean;
};

const defaultState: TUserTournamentsState = {
  data: [],
  participants: null,
  tournaments: [],
  loading: true,
  error: false,
};

const userTournamentsSlice = createSlice({
  name: 'userTournaments',
  initialState: defaultState,
  reducers: {
    fetchUserTournamentsLoading(state) {
      state.loading = true;
      state.error = false;
    },
    fetchUserTournamentsSuccess(state, action: PayloadAction<any[]>) {
      const tournaments: ITournament[] = [];
      const participants: IParticipant[] = [];
      const data = action.payload.map((data: any) => {
        const game_data = {
          ...data.game_data,
          name: processGameNameResponse(data.game_data.name),
        };
        participants.push(data.participant);
        tournaments.push(processTournamentFromResponse(data.tournament));
        return { ...data, game_data };
      });

      state.data = data;
      state.participants = participants;
      state.tournaments = tournaments;
      state.loading = false;
      state.error = false;
    },
    createTournamentParticipantSuccess(state, action: PayloadAction<IParticipant>) {
      if (state.participants) {
        state.participants = [action.payload, ...state.participants];
      } else {
        state.participants = [action.payload];
      }
      state.loading = false;
    },
    fetchUserTournamentsError(state) {
      state.loading = false;
      state.error = true;
    },
    fetchTournamentParticipantError(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchUserTournamentsLoading,
  fetchUserTournamentsSuccess,
  createTournamentParticipantSuccess,
  fetchUserTournamentsError,
  fetchTournamentParticipantError,
} = userTournamentsSlice.actions;

export default userTournamentsSlice.reducer;


/* export const userTournamentsReducer = (state: TUserTournamentsState = defaultState, action: any) => {
  let participants = state.participants && [...state.participants];
  let tournaments = [...state.tournaments];

  switch (action.type) {
    case ActionTypes.FETCH_USER_TOURNAMENTS_LOADING:
      return { ...state, loading: true, error: false };

    case ActionTypes.FETCH_USER_TOURNAMENTS_SUCCESS:
      tournaments = [];
      participants = [];
      // eslint-disable-next-line no-case-declarations
      const data = action.payload.map((data: any) => {
        const game_data = {
          ...data.game_data,
          name: processGameNameResponse(data.game_data.name),
        };
        participants?.push(data.participant);
        tournaments.push(processTournamentFromResponse(data.tournament));

        return { ...data, game_data };
      });

      return {
        ...state,
        data,
        participants,
        tournaments,
        loading: false,
        error: false,
      };

    case ActionTypes.CREATE_TOURNAMENT_PARTICIPANT_SUCCESS:
      return {
        ...state,
        participants: (state.participants && [action.payload, ...state.participants]) || [action.payload],
        loading: false,
      };

    case ActionTypes.FETCH_USER_TOURNAMENTS_ERROR:
      return { ...state, loading: false, error: true };

    case ActionTypes.FETCH_TOURNAMENT_PARTICIPANT_ERROR:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
 */