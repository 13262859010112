import GoamaPaymentService from "./goama/GoamaPaymentService";
import api from "actions/api";

/* interface ExtraData {
  extraData: {
    payment_type: "ooredoo_account" | "mpitesan";
    spin_quantity: number;
  };
}
*/

export default class OoredooPaymentService extends GoamaPaymentService {
  public async initiatePayment({ tournament, extraData = {} }: { tournament: any; extraData?: any }) {
    this.tournament = tournament;
    this.extraData = extraData;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve, reject) => {
      (async () => {
        this.baseURL = `${window.location.protocol}//${window.location.host}/tournament?tournament=${this.tournament?.id}`;
        const params = this.getParams();
        const url = `init/${this.application?.slug}/`;

        if (extraData.payment_type === "ooredoo_account") {
          try {
            const response = await this.setPaymentReferenceCode({ url, params });
            resolve(response);
          } catch (e) {
            reject(e);
          }
        } else if (extraData.payment_type === "mpitesan") {
          try {
            const response = await this.fetchMpitesanPaymentParams({
              url,
              params,
            });

            const {
              merchant_id,
              order_id,
              product_name,
              product_description,
              amount,
              currency,
              callbackurl1,
              callbackurl2,
              signature,
            } = response.data.payload_others;

            const element: any = document.getElementById("mpitesan-form");

            const temp = `<form id="formid" action="http://pgw.ooredoo.com.mm/MPGEnduserportal/mpitesan/payment" method="post">
                            <input type="hidden" name="merchant_id" value="${merchant_id}" />
                            <input type="hidden" name="order_id" value="${order_id}" />
                            <input type="hidden" name="product_name" value="${product_name}" />
                            <input type="hidden" name="product_description" value="${product_description}" />
                            <input type="hidden" name="amount" value="${amount}" />
                            <input type="hidden" name="currency" value="${currency}" />
                            <input type="hidden" name="callbackurl1" value="${callbackurl1}" />
                            <input type="hidden" name="callbackurl2" value="${callbackurl2}" />
                            <input type="hidden" name="signature" value="${signature}" />
                            <input type="submit" id="btnsubmit" value="confirm" />
                          </form>`;

            element.innerHTML = element.innerHTML + temp;
            setTimeout(() => {
              document.getElementById("btnsubmit")?.click();
            }, 3000);
          } catch (e) {
            reject(e);
          }
        }
      })();
    });
  }

  public async initiateSpinPayment() {
    return new Promise((resolve, reject) => {
      const url = `init/${this.application?.slug}/`;
      (async () => {
        if (this.extraData.payment_type === "ooredoo_account") {
          try {
            const response = await this.setPaymentReferenceCode({ url, params: this.extraData });
            resolve(response);
          } catch (e) {
            reject(e);
          }
        } else {
          reject(new Error(this.extraData.payment_type + " not supported at the moment"));
        }
      })();
    });
  }

  /* protected async processOoredooAccountPayment(url: string, params: any) {
    const response = await this.setPaymentReferenceCode({ url, params });
    return response;
  } */

  protected async fetchMpitesanPaymentParams({ url, params }: { url: string; params: any }) {
    return await api.post(`payments/${url}`, { ...params }).then(response => {
      this.paymentReferenceCode = response.data.reference;

      // Set last reference id into localStorage
      // For verifying upon callback redirect
      if (params?.redirect_link) {
        localStorage?.setItem(this.referenceStorageKey, response.data.reference);
      }
      return response;
    });
  }
}
