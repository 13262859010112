import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isInProductionMode } from "actions/api/utils";
import { decodeDataFromURL } from "utils";
import analytics, {
  rappiEvents,
  RappiAnalyticsEngine,
  RAPPI_AMPLITUDE_KEY,
  GoogleAnalyticsEngine,
  defaultEvents,
  AmplitudeAnalyticsEngine,
  GLOBAL_AMPLITUDE_KEY,
  GoogleAnalytics4Engine,
} from "utils/analytics";
interface ICommonReducerState {
  loading: boolean;
  error: null | "bad-request" | "unauthorized" | "outdated";
  application: any;
  country: null | string;
  user: any;
  faq: any;
  termsAndConditions: any;
  privacyPolicy: any;
  contactUs: any;
  translations: null | object;
  profilePic: null | string;
  entryLogged: boolean;
  queryParams: string;
  arcade?: any;
  campaigns?: any;
  showAds?: boolean;
}
const defaultData: ICommonReducerState = {
  loading: false,
  error: null,
  application: null,
  country: null,
  user: null,
  translations: null,
  faq: null,
  termsAndConditions: null,
  privacyPolicy: null,
  contactUs: null,
  profilePic: null,
  entryLogged: false,
  queryParams: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState: defaultData,
  reducers: {
    fetchCommonDataLoading(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCommonDataSuccess(state, action: PayloadAction<any>) {
      const { application, country, apiResponseTime } = action.payload || {};
      const user = action.payload?.user || {};
      const { directNavigation, utmSource, utmMedium, utmCampaign } = decodeDataFromURL();

      // Conditionally inject amplitude engines
      if (application?.slug === "rappi") {
        analytics.addProvider({
          name: "rappi",
          enabledEvents: rappiEvents,
          engines: [new RappiAnalyticsEngine(RAPPI_AMPLITUDE_KEY, "rappi")],
        });
      } else {
        analytics.addProvider({
          name: "default",
          enabledEvents: defaultEvents,
          engines: [new AmplitudeAnalyticsEngine(GLOBAL_AMPLITUDE_KEY, "default")],
        });
      }

      if (import.meta.env.VITE_GA4_ID && isInProductionMode()) {
        analytics.addProvider({
          name: application?.slug,
          enabledEvents: defaultEvents,
          engines: [new GoogleAnalytics4Engine(import.meta.env.VITE_GA4_ID, application?.slug)],
        });
      }
      if (application.custom_google_analytics_id) {
        analytics.addProvider({
          name: application?.slug,
          enabledEvents: defaultEvents,
          engines: [new GoogleAnalyticsEngine(application.custom_google_analytics_id, application?.slug)],
        });
      }

      analytics.setUserId(user?.username, application?.slug || "", country || "");

      analytics.enteredTournamentLandingPage({ apiResponseTime, utmSource, utmCampaign, utmMedium });

      if (directNavigation) {
        analytics.directNavigation();
      }

      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    updateUserAvatarSuccess(state, action: PayloadAction<any>) {
      if (state.user) {
        state.user.extra_data = {
          ...state.user.extra_data,
          profile_pic_id: action.payload.profile_pic_id,
        };
      }
    },
    updateUserNameSuccess(state, action: PayloadAction<any>) {
      if (state.user) {
        state.user.first_name = action.payload.first_name;
      }
    },
    updateUserReferrer(state, action: PayloadAction<any>) {
      if (state.user) {
        state.user.referrer = !!action.payload;
      }
    },
    showNotificationStatus(state) {
      if (state.user) {
        state.user.disable_notification = true;
      }
    },
    hideNotificationStatus(state) {
      if (state.user) {
        state.user.disable_notification = false;
      }
    },
    fetchCommonDataError(state) {
      state.loading = false;
      state.error = "bad-request";
    },
    fetchCommonDataUnauthorizedError(state) {
      state.loading = false;
      state.error = "unauthorized";
    },
    fetchCommonDataOutdatedError(state) {
      state.loading = false;
      state.error = "outdated";
    },
    fetchFaqSuccess(state, action: PayloadAction<any>) {
      state.faq = action.payload;
    },
    fetchTermsAndConditionsSuccess(state, action: PayloadAction<any>) {
      state.termsAndConditions = action.payload;
    },
    fetchPrivacyPolicySuccess(state, action: PayloadAction<any>) {
      state.privacyPolicy = action.payload;
    },
    fetchContactUsSuccess(state, action: PayloadAction<any>) {
      state.contactUs = action.payload;
    },
    fetchCampaignSuccess(state, action: PayloadAction<any>) {
      state.campaigns = action.payload;
    },
    fetchArcadeSuccess(state, action: PayloadAction<any>) {
      state.arcade = action.payload;
    },
    fetchTranslationsSuccess(state, action: PayloadAction<any>) {
      state.translations = action.payload;
    },
    clearCommonUser(state) {
      state.user = null;
    },
    setProfilePic(state, action: PayloadAction<string>) {
      state.profilePic = action.payload;
    },
    entryLogComplete(state) {
      state.entryLogged = true;
    },
    updateQueryParams(state, action: PayloadAction<string | undefined>) {
      let queryParams = action.payload;
      try {
        if (!action.payload) {
          queryParams = (localStorage && localStorage.getItem("query_params")) || "";
        }
        state.queryParams = queryParams || "";
      } catch (e) {
        // Handle error if necessary
      }
    },
  },
});

export const {
  fetchCommonDataLoading,
  fetchCommonDataSuccess,
  updateUserAvatarSuccess,
  updateUserNameSuccess,
  updateUserReferrer,
  showNotificationStatus,
  hideNotificationStatus,
  fetchCommonDataError,
  fetchCommonDataUnauthorizedError,
  fetchCommonDataOutdatedError,
  fetchFaqSuccess,
  fetchTermsAndConditionsSuccess,
  fetchPrivacyPolicySuccess,
  fetchContactUsSuccess,
  fetchCampaignSuccess,
  fetchArcadeSuccess,
  fetchTranslationsSuccess,
  clearCommonUser,
  setProfilePic,
  entryLogComplete,
  updateQueryParams,
} = commonSlice.actions;

export default commonSlice.reducer;

/* export const commonReducer: (state: ICommonReducerState, action: Action) => ICommonReducerState = (
  state = defaultData,
  action: any,
) => {
  const { application, country, apiResponseTime } = action?.payload || {};
  const user: IUser = action?.payload?.user || {};
  const { directNavigation, utmSource, utmMedium, utmCampaign } = decodeDataFromURL();
  switch (action.type) {
    case ActionTypes.FETCH_COMMON_DATA_LOADING:
      return { ...state, loading: true, error: null };

    case ActionTypes.FETCH_COMMON_DATA_SUCCESS:
      // Conditionally inject amplitude engines
      if (application?.slug === "rappi") {
        analytics.addProvider({
          name: "rappi",
          enabledEvents: rappiEvents,
          engines: [new RappiAnalyticsEngine(RAPPI_AMPLITUDE_KEY, "rappi")],
        });
      } else{
        analytics.addProvider({
          name: "default",
          enabledEvents: defaultEvents,
          engines: [new AmplitudeAnalyticsEngine(GLOBAL_AMPLITUDE_KEY, "default")],
        });
      }

    
      if (import.meta.env.VITE_GA4_ID && isInProductionMode()) {
        analytics.addProvider({
          name: application?.slug,
          enabledEvents: defaultEvents,
          engines: [new GoogleAnalytics4Engine(import.meta.env.VITE_GA4_ID, application?.slug)],
        });
      }
      if (application.custom_google_analytics_id) {
        analytics.addProvider({
          name: application?.slug,
          enabledEvents: defaultEvents,
          engines: [new GoogleAnalyticsEngine(application.custom_google_analytics_id, application?.slug)],
        });
      }

      analytics.setUserId(user?.username, application?.slug || "", country || "");

      analytics.enteredTournamentLandingPage({ apiResponseTime, utmSource, utmCampaign, utmMedium });

      if (directNavigation) {
        analytics.directNavigation();
      }
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case ActionTypes.UPDATE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          extra_data: {
            ...state.user.extra_data,
            profile_pic_id: action.payload.profile_pic_id,
          },
        },
      };
    case ActionTypes.UPDATE_USER_NAME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          first_name: action.payload.first_name,
        },
      };
    case ActionTypes.UPDATE_USER_REFERRER:
      return {
        ...state,
        user: {
          ...state.user,
          referrer: !!action.payload,
        },
      };

    case ActionTypes.SHOW_NOTIFICATIONSTATUS:
      return {
        ...state,
        user: {
          ...state.user,
          disable_notification: true,
        },
      };

    case ActionTypes.HIDE_NOTIFICATIONSTATUS:
      return {
        ...state,
        user: {
          ...state.user,
          disable_notification: false,
        },
      };

    case ActionTypes.FETCH_COMMON_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: "bad-request",
      };

    case ActionTypes.FETCH_COMMON_DATA_UNAUTHORIZED_ERROR:
      return {
        ...state,
        loading: false,
        error: "unauthorized",
      };

    case ActionTypes.FETCH_COMMON_DATA_OUTDATED_ERROR:
      return {
        ...state,
        loading: false,
        error: "outdated",
      };

    case ActionTypes.FETCH_FAQ_SUCCESS:
      return {
        ...state,
        faq: action.payload,
      };

    case ActionTypes.FETCH_T_AND_C_SUCCESS:
      return {
        ...state,
        termsAndConditions: action.payload,
      };

    case ActionTypes.FETCH_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.payload,
      };

    case ActionTypes.FETCH_CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUs: action.payload,
      };

    case ActionTypes.FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
      };

    case ActionTypes.FETCH_ARCADE:
      return {
        ...state,
        arcade: action.payload,
      };

    case ActionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.payload,
      };

    case ActionTypes.CLEAR_COMMON_USER:
      return {
        ...state,
        user: null,
      };

    case ActionTypes.SET_PROFILE_PIC:
      return {
        ...state,
        profilePic: action.payload,
      };

    case ActionTypes.ENTRY_LOG_COMPLETE:
      return {
        ...state,
        entryLogged: true,
      };

    case ActionTypes.UPDATE_QUERY_PARAMS: {
      let queryParams = action.payload;
      try {
        if (!action.payload) {
          queryParams = (localStorage && localStorage.getItem("query_params")) || "";
        }
        return {
          ...state,
          queryParams: queryParams,
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    }

    default:
      return state;
  }
};
 */
