import { AppDispatch } from "redux/Store";
// import { ActionTypes } from "./actionTypes";
// import tournamentsAPI from "./api/tournaments";
import api from "./api";
import { updateUserNameSuccess } from "redux/reducers/commonReducer";

export const updateUserName = (firstName: string) => async (dispatch: AppDispatch) => {
  const postData = { first_name: firstName };
  return api
    .put(`tournaments/users/update-user-name/`, postData)
    .then(response => {
      dispatch(updateUserNameSuccess(response.data));
      return response.data;
    });
    // .catch(() => dispatch({ type: ActionTypes.UPDATE_USER_AVATAR_ERROR }));
};
