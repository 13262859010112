import { css } from "@emotion/react";

const memepopStyles = css`
  &.memepop {
    .mission-page-container {
      .single-game-card {
        .active-card {
          .card-title {
            color: #000 !important;
          }
          .active_progress_bottom_curve {
            background-color: #000000;
            .game_info {
              span {
                color: #94ff2e !important;
              }
            }
          }
          .action_button {
            button {
              color: #000 !important;
              background-color: #94ff2e;
              border: 1px solid #000000;
            }
          }
        }
      }
    }
    .heroes {
      .heroes {
        color: #000 !important;
      }
      .first-part {
        background: #94ff2e !important;
        .winner-label,
        .competitor-name,
        .competitor-score,
        .own-name,
        .own-score {
          color: #000 !important;
        }
      }
      .rank-list {
        .list-heading {
          .text-style {
            color: #000 !important;
          }
        }
      }
    }
    .desktop-play-button-backward {
      button {
        color: #000 !important;
      }
    }
    .ticket-status {
      background-color: #94ff2e;
      box-shadow: 0pt 3pt 5px 0px #94ff2e;
      .icon {
        > * {
          color: #000 !important;
          fill: #000 !important;
        }
      }
    }
    .profile-history-wrapper {
      .back-ground {
        color: #000 !important;
      }
    }
    .tickets-page {
      padding-bottom: 0 !important;
      .page-wrapper {
        min-height: calc(90vh - 50px);
      }
      .top-bar-top {
        background: #84e329;
        .backbutton-off,
        .token-balance,
        .text-value {
          color: #000000 !important;
        }
        .back-arrow {
          fill: #000000 !important;
          stroke: #000000 !important;
        }
      }
    }
    .mobile-footer-page {
      /* padding-bottom: 0 !important; */
    }
    .desktop-footer {
      .footer-content {
        border-top: 1px solid #94ff2e !important;
        background-color: #000 !important;
      }
    }
    .tournament-games-page {
      min-height: calc(-125px + 100vh) !important;
      padding-bottom: 0 !important;
      .top-tournament-full-width {
        .rank-btn,
        .text-play {
          color: #000000 !important;
        }
      }
    }
    .half-style {
      background: #84e329;
      .latest-score-container {
        > * {
          color: #000000 !important;
        }
      }
      .second-half {
        .btn-container {
          border: 2px solid #000000 !important;
          svg {
            fill: #000000 !important;
          }
        }
      }
    }
    .player-rank-board,
    .btn-leaderboard-wrapper {
      .name-style,
      .label-style {
        color: #000000 !important;
        .score {
          color: #000000 !important;
        }
      }
      /* display: none !important; */
    }
    .text-inactive-tournament {
      color: #88e435 !important;
      height: 50vh;
    }
    .desktop-footer {
      /* display: none !important; */
    }
    .fontHeadStyle,
    .fontSubStyle {
      color: #ffffff !important;
    }

    .back-arrow {
      fill: #ffffff !important;
      stroke: #ffffff !important;
    }

    .mission-page-container {
      .section-header {
        color: #ffffff !important;
      }
    }

    .progress_bottom_curve {
      .game_info {
        .timer {
          .clock_icon,
          .timer-data {
            fill: #ffffff !important;
          }
        }
      }
    }

    .heroes-time-range {
      .activated-button {
        color: #88e435 !important;
      }
      .not-active-button {
        color: #ffffff !important;
      }
    }

    .no-info {
      color: #ffffff !important;
    }

    .custom-popup-container {
      .title-text {
        color: #ffffff !important;
      }
    }

    .skip-button {
      color: #ffffff !important;
    }
  }
`;

export default memepopStyles;
