const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export const abbreviateNumber = (number: number) => {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // Format number and add suffix
  // Check if the scaled number is a whole number
  return (scaled % 1 === 0 ? scaled.toString() : scaled.toFixed(2)) + suffix;
};

export const commaSeparatedNumber = (number: number) => {
  return number.toLocaleString("en-US");
};
