import api from "actions/api";
// import paymentsAPI from "actions/api/payments";
import { updateLocalQueryParams } from "utils";
import PaymentPoller from "utils/paymentPoller";

export interface IInitializePaymentArgs {
  country: string;
  application: IApplication;
  user: IUser;
  paymentReferenceCode: string | null;
}
export interface IPaymentArgs {
  country: string;
  application: IApplication;
  user: IUser;
  extraData?: any;
  getQueryParams: Function;
  initiatePayment: Function;
  paymentReferenceCode: string | undefined | null;
}
interface IPaymentKeyStorage {
  url: string;
  params: any;
}

abstract class PaymentService implements IPaymentArgs {
  public country: string;
  public application: IApplication;
  public user: IUser;
  public baseURL: string | undefined;
  public extraData: any;
  public paymentReferenceCode: string | undefined | null;
  public referenceStorageKey: string;
  public paymentInitializationResponse: any = null;
  public paymentVerificationResponse: any = null;

  constructor({ country, application, user, paymentReferenceCode = null }: IInitializePaymentArgs) {
    this.country = country;
    this.application = application;
    this.user = user;
    this.referenceStorageKey = "payment-ref";
    this.paymentReferenceCode = paymentReferenceCode;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public initiatePayment(params: any) {}

  protected generateRedirectURL(path: string, queryParams: any) {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const baseURL = `${protocol}//${host}/${path}`;
    // const baseURL = `${protocol}//${host}/tournament?tournament=${tournament.id}`;

    let params = "";
    Object.keys(queryParams).map(key => {
      const value = encodeURIComponent(`${queryParams[key]}`);
      params += `${key}=${value}&`;
      return null;
    });
    return `${baseURL}?${params}`;
  }

  public getQueryParams(): any {}

  protected getParams(): any {}

  protected async setPaymentReferenceCode({ url, params }: IPaymentKeyStorage) {
    return await api.post(`payments/${url}?paymentInitTimestamp=${Date.now()}`, { ...params }).then(response => {
      this.paymentReferenceCode = response.data.reference;
      // console.log("in setPaymentReferenceCode", this.paymentReferenceCode);
      // Set last reference id into localStorage
      // For verifying upon callback redirect
      // TODO: fix why this.paymentReferenceCode not getting any value during verfication in case of no redirect_link @mrimmoae (cash payment/googly)
      if (params?.redirect_link) {
        localStorage?.setItem(this.referenceStorageKey, response.data.reference);
      }
      // localStorage?.setItem(localStorageKey, response.data.reference);
      // Set new_userid into query_params if available
      if (response.data?.new_userid) {
        updateLocalQueryParams({ userid: response.data.new_userid });
      }
      return response;
    });
  }

  getPaymentReferenceCode() {
    if (this.paymentReferenceCode) return this.paymentReferenceCode;
    const code = localStorage && localStorage.getItem(this.referenceStorageKey);
    return code || null;
  }

  async verifyPayment(retires = 0, interval = 0) {
    const poller = new PaymentPoller(retires, interval); // 0 , 6000
    // let paymentRefKey = `active-ref-${tournament.id}`;
    // const referenceCode = localStorage?.getItem(paymentRefKey) || "";
    const paymentReferenceCode = this.getPaymentReferenceCode();
    return new Promise((resolve, reject) => {
      if (!paymentReferenceCode) {
        reject({
          status: "failed",
          reason: "No reference code",
        });
      } else {
        poller.pollPaymentStatus(paymentReferenceCode, (success, status, failedReason) => {
          if (success) {
            resolve({ status: "success" });
          } else if (status === "cancel") {
            reject({
              status: "cancel",
              reason: failedReason,
            });
          } else if (status === "failed") {
            reject({
              status: "failed",
              reason: failedReason,
            });
          } else {
            reject({
              status: "error",
              reason: failedReason,
            });
          }
        });
      }
    });
  }

  public getModalDesign() {}
}

export default PaymentService;
