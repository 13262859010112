import React from "react";
import { css } from "@emotion/react";
import { Handle } from "rc-slider";

const handleTooltipStyle = (theme: ITheme) => css`
  transition: opacity 0.3s ease-in-out;
  width: 23px;
  height: 20px;
  opacity: 1;
  background: ${theme.button.primaryGradientStart};
  border-radius: 132px / 119px;
  color: ${theme.button.buttonTextColor};
  position: absolute;
  top: -35px;
  line-height: 26px;
  text-align: center;
  left: -6px;
  font-size: 10px;
  font-weight: bold;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid ${theme.button.primaryGradientStart};
    position: absolute;
    bottom: -9px;
    left: 2px;
  }
`;

export default function SliderHandle(props: any) {
  const { value, ...restProps } = props;
  return (
    <Handle value={value} {...restProps}>
      <div css={handleTooltipStyle}>{value}</div>
    </Handle>
  );
}
