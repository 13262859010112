import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import subscriptionAPI from "actions/api/subscription";

export interface ISubscription {
  package_info: any[];
  user_info: any[];
  loading: boolean;
  error: boolean;
}

const initialState: ISubscription = {
  package_info: [],
  user_info: [],
  loading: false,
  error: false,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    fetchSubscriptionPackageLoading(state) {
      state.loading = true;
    },

    fetchSubscriptionPackageSuccess(state, action) {
      state.package_info = action.payload;
      state.loading = false;
      state.error = false;
    },

    fetchSubscriptionPackageError(state) {
      state.package_info = [];
      state.loading = false;
      state.error = true;
    },

    fetchSubscriptionUserInfoLoading(state) {
      state.loading = true;
    },

    fetchSubscriptionUserInfoSuccess(state, action) {
      state.user_info = action.payload;
      state.loading = false;
      state.error = false;
    },

    fetchSubscriptionUserInfoError(state) {
      state.user_info = [];
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchSubscriptionPackageLoading,
  fetchSubscriptionPackageSuccess,
  fetchSubscriptionPackageError,
  fetchSubscriptionUserInfoLoading,
  fetchSubscriptionUserInfoSuccess,
  fetchSubscriptionUserInfoError,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

export const fetchSubscriptionPackageInfo = () => async (dispatch: AppDispatch) => {
  dispatch(fetchSubscriptionPackageLoading());
  return api
    .get(`subscriptions/ooredoo/packages/info/`)
    .then(response => {
      dispatch(fetchSubscriptionPackageSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchSubscriptionPackageError()));
};

export const fetchSubscriptionUserInfo = (slug: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchSubscriptionUserInfoLoading());
  return api
    .get(`subscriptions/${slug}/user/info/`)
    .then(response => {
      dispatch(fetchSubscriptionUserInfoSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchSubscriptionUserInfoError()));
};
