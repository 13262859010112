// import paymentAPI from "actions/api/payments";
import { isTournamentTryBased } from "utils/tournamentUtils";
import GoamaPaymentService from "./GoamaPaymentService";
import api from "actions/api";

class GoamaVoucherPaymentService extends GoamaPaymentService {
  // public tournament: ITournament | undefined;
  // public voucerCode: string | number | null;

  public async initiatePayment({ tournament, extraData = {} }: { tournament: any; extraData?: any }) {
    this.tournament = tournament;
    this.extraData = extraData;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve, reject) => {
      (async () => {
        this.baseURL = `${window.location.protocol}//${window.location.host}/tournament?tournament=${this.tournament?.id}`;
        let params = this.getParams();
        delete params.amount;
        let url = `init/${this.application?.slug}/`;
        if (isTournamentTryBased(tournament)) {
          url = `init/trial/${this.application?.slug}/`;
          params = this.getTryBasedParams();
        }
        try {
          const response = await this.setPaymentReferenceCode({
            url,
            params,
          });
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }

  protected getTryBasedParams = () => {
    // const queryParams = this.getQueryParams();
    return {
      // amount: this.tournament?.entry_fee,
      tournament: this.tournament?.id,
      // currency: this.tournament?.entry_fee_currency,
      // redirect_link: this.generateRedirectURL(`tournament?tournament=${this.tournament?.id}`, queryParams),
      amount: this.tournament?.trial_packages[0]?.currency_value,
      trial_tournament: this.tournament?.id,
      package: this.tournament?.trial_packages[0]?.id,
      ...this.extraData,
    };
  };

  public async initiateRedeemVoucher(voucerCode: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve, reject) => {
      (async () => {
        // this.baseURL = `${window.location.protocol}//${window.location.host}/voucher/conversion/coin`;
        // const params = this.getParams();
        try {
          const response = await api.post(
            `payments/${this.application?.slug}/voucher/conversion/${this.application?.applicationsetting?.voucher_conversion_type}/`,
            {
              voucher_code: voucerCode,
              country: this.country,
            },
          );
          resolve(response.data);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }
}

export default GoamaVoucherPaymentService;
