import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import amplitude from "amplitude-js";
import { v4 as uuid4 } from "uuid";
import Store from "redux/Store";
// import biReportsAPI from "actions/api/biReportsApi";
import GameTimer from "./gameTimer";
import { getTournamentType, stringToAlphaNumeric } from "utils";
import { isInDevelopmentMode } from "actions/api/utils";
import api from "actions/api";

type TTournamentData = {
  gameSlug: string;
  tournamentId: string | number;
  isPaidTournament: boolean;
  tournamentType: string;
};

type TArcadeData = {
  gameSlug: string;
};

const EVENT_TYPES = {
  GAME_STARTED: "Game Started",
  GAME_ENDED: "Game Ended",
  SHARE_GAME_LINK: "Share Game Link",
  CLICK_CTA_BANNER: "Click CTA Banner",
  CLICK_WELCOME_BANNER: "Click Welcome Banner",
  ARCADE_GAME_STARTED: "Arcade Game Started",
  ARCADE_GAME_ENDED: "Arcade Game Ended",
  ARCADE_LANDING_PAGE: "Arcade Landing Page",
  CLICKED_LOAD_MORE: "Clicked Load More",
  CLICKED_PLAY_NOW: "Clicked Play Now",
  CLICKED_PAY_NOW: "Clicked Pay Now",
  CLICKED_GAME_BANNER: "Click Game Banner",
  ENTERED_TOURNAMENT_PLATFORM: "Entered Tournament Platform",
  ENTERED_GAME_PLATFORM: "Entered Game Platform",
  DIRECT_NAVIGATION: "Direct Navigation",
  CLICK_SHARE_BUTTON: "Click Share Button",
  TOURNAMENT_SESSION_ENDED: "Tournament Session Ended",
  PAYMENT_SUCCESSFUL: "Payment Successful",
  ENTERED_TICKET_PAGE: "Entered Ticket Page",
  ENTERED_TICKET_REDEMPTION_PAGE: "Entered Ticket Redemption Page",
  ENTERED_COIN_PAGE: "Entered Coin Page",
  ENTERED_COIN_REDEMPTION_PAGE: "Entered Coin Redemption Page",
  VIEWED_MISSION_BOARD: "Viewed Mission Board",
  PRESSED_SPIN_THE_WHEEL_BUTTON: "Pressed Spin The Wheel Button",
  CLICKED_ON_PLAY_AGAIN_BUTTON: "Clicked Play Again Button",
  GAME_OVER_PAGE: "Game Over Page",
  GOTO_HOME_BUTTON: "Goto Home Button",
  CLICKED_ON_PROFILE: "Clicked On Profile",
  CLICKED_ON_FAQ: "Clicked On FAQ",
  CLICKED_ON_REFER_FRIEND: "Clicked On Refer Friend",
  ENTER_GAME_TILE: "Enter Game Tile",
  CLICK_PLAY_ICON: "Click Play Icon",
  CLICK_PLAY_ON_LEADERBOARD: "Click Play On Leaderboard",
  GUEST_REGISTER_POPUP: "Guest Register Popup",
  LOGIN_BY_PHONE: "Login By Phone",
  LOGIN_OTP_VERIFICATION: "Login Otp Verification",
  GOOGLE_LOGIN: "Google Login",
  FACEBOOK_LOGIN: "Facebook Login",
  VIEWED_SUBSCRIPTION_POPUP: "Viewed Subscription Pop-up",
  CLICKED_UNSUBSCRIBE_BUTTON: "Clicked Unsubscribe Button",
  CLICKED_SUBSCRIBED: "Clicked Subscribed",
};

export const defaultEvents = [
  // Don't uncomment game start, game end event and entered game platform event,this decision has been taken from BI team.
  // EVENT_TYPES.GAME_STARTED,
  // EVENT_TYPES.GAME_ENDED,
  EVENT_TYPES.SHARE_GAME_LINK,
  EVENT_TYPES.ENTERED_TOURNAMENT_PLATFORM,
  EVENT_TYPES.DIRECT_NAVIGATION,
  // EVENT_TYPES.ENTERED_GAME_PLATFORM,
  EVENT_TYPES.ARCADE_GAME_STARTED,
  EVENT_TYPES.ARCADE_LANDING_PAGE,
  EVENT_TYPES.CLICK_CTA_BANNER,
  EVENT_TYPES.CLICK_WELCOME_BANNER,
  EVENT_TYPES.CLICK_SHARE_BUTTON,
  EVENT_TYPES.ENTERED_TICKET_PAGE,
  EVENT_TYPES.ENTERED_TICKET_REDEMPTION_PAGE,
  EVENT_TYPES.ENTERED_COIN_PAGE,
  EVENT_TYPES.ENTERED_COIN_REDEMPTION_PAGE,
  EVENT_TYPES.VIEWED_MISSION_BOARD,
  EVENT_TYPES.PRESSED_SPIN_THE_WHEEL_BUTTON,
  EVENT_TYPES.CLICKED_ON_PLAY_AGAIN_BUTTON,
  EVENT_TYPES.GAME_OVER_PAGE,
  EVENT_TYPES.GOTO_HOME_BUTTON,
  EVENT_TYPES.CLICKED_ON_PROFILE,
  EVENT_TYPES.CLICKED_ON_FAQ,
  EVENT_TYPES.CLICKED_ON_REFER_FRIEND,
  EVENT_TYPES.ENTER_GAME_TILE,
  EVENT_TYPES.CLICK_PLAY_ICON,
  EVENT_TYPES.CLICK_PLAY_ON_LEADERBOARD,
  EVENT_TYPES.GUEST_REGISTER_POPUP,
  EVENT_TYPES.LOGIN_BY_PHONE,
  EVENT_TYPES.LOGIN_OTP_VERIFICATION,
  EVENT_TYPES.GOOGLE_LOGIN,
  EVENT_TYPES.FACEBOOK_LOGIN,
  EVENT_TYPES.VIEWED_SUBSCRIPTION_POPUP,
  EVENT_TYPES.CLICKED_UNSUBSCRIBE_BUTTON,
  EVENT_TYPES.CLICKED_SUBSCRIBED,
];

export const rappiEvents = [
  EVENT_TYPES.ENTERED_TOURNAMENT_PLATFORM,
  EVENT_TYPES.ARCADE_LANDING_PAGE,
  EVENT_TYPES.GAME_STARTED,
  EVENT_TYPES.GAME_ENDED,
  EVENT_TYPES.ARCADE_GAME_STARTED,
  EVENT_TYPES.ARCADE_GAME_ENDED,
];

const GOOGLE_ANALYTICS_KEY = import.meta.env.VITE_GOOGLE_ANALYTICS_ID ?? "";
const GOOGLE_ANALYTICS_4_KEY = import.meta.env.VITE_GA4_ID ?? "";
export const GLOBAL_AMPLITUDE_KEY = import.meta.env.VITE_AMPLITUDE_KEY ?? "";
export const LATAM_AMPLITUDE_KEY = import.meta.env.VITE_LATAM_AMPLITUDE_KEY ?? "";
export const LATAM_COUNTRIES = ["CO", "MX", "BR", "AR", "CL", "PE", "EC", "UY", "CR"];
// const MIX_PANEL_TOKEN = import.meta.env.VITE_MIX_PANEL_TOKEN ?? "";
export const RAPPI_AMPLITUDE_KEY = import.meta.env.VITE_RAPPI_AMPLITUDE_KEY ?? "";

abstract class AnalyticsEngine {
  engineKey: string;
  constructor(engineKey: string) {
    this.engineKey = engineKey;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected contructUserId(userId: string, country?: string) {
    return `${userId}`;
  }

  abstract logEvent(eventType: string, eventData: any): void;
  abstract setUserId(userId: string, country?: string): void;
  abstract logRevenue(tournamentId: number, price: number, unit: string, extraProperties: any): void;
}

export class GoogleAnalyticsEngine extends AnalyticsEngine {
  private static trackers: string[] = [];

  constructor(engineKey: string, instanceName: string) {
    super(engineKey);
    const processedInstanceName = stringToAlphaNumeric(instanceName);
    if (engineKey === GOOGLE_ANALYTICS_KEY) {
      if (isInDevelopmentMode()) return;
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY, {
        gaOptions: { name: processedInstanceName },
        alwaysSendToDefaultTracker: false,
        debug: false,
      });
    } else {
      ReactGA.addTrackers([
        {
          trackingId: engineKey,
          gaOptions: {
            name: processedInstanceName,
          },
        },
      ]);
    }
    GoogleAnalyticsEngine.trackers.push(processedInstanceName);
  }

  logEvent = () => {};

  setUserId = (userId: string) => {
    ReactGA.set({ userId: this.contructUserId(userId) });
  };

  pageView = (pagePath: string) => {
    ReactGA.set({ page: pagePath }, GoogleAnalyticsEngine.trackers);
    ReactGA.pageview(pagePath, GoogleAnalyticsEngine.trackers);
  };

  logRevenue = () => {};
}

export class GoogleAnalytics4Engine extends AnalyticsEngine {
  private static trackers: string[] = [];

  constructor(engineKey: string, instanceName: string) {
    super(engineKey);
    const processedInstanceName = stringToAlphaNumeric(instanceName);
    if (engineKey === GOOGLE_ANALYTICS_4_KEY) {
      ReactGA4.initialize([
        {
          trackingId: GOOGLE_ANALYTICS_4_KEY,
          gaOptions: { name: processedInstanceName },
        },
      ]);
    } else {
      /* ReactGA4.addTrackers([
        {
          trackingId: engineKey,
          gaOptions: {
            name: processedInstanceName
          }
        }
      ]); */
    }
    GoogleAnalytics4Engine.trackers.push(processedInstanceName);
  }

  logEvent = () => {};

  setUserId = (userId: string) => {
    // ReactGA4.set({ userId: this.contructUserId(userId) });
    // ReactGA4.set({ user_id: this.contructUserId(userId) });
    ReactGA4.gtag("config", this.engineKey, {
      user_id: this.contructUserId(userId),
    });
    ReactGA4.gtag("config", this.engineKey, {
      goama_id: this.contructUserId(userId),
    });
    ReactGA4.set({
      user_properties: {
        goama_id: this.contructUserId(userId),
      },
    });
  };

  pageView = (pagePath: string) => {
    ReactGA4.set({ page: pagePath });
    ReactGA4.send({ hitType: "pageview", page: pagePath });
  };

  logRevenue = () => {};
}

export class AmplitudeAnalyticsEngine extends AnalyticsEngine {
  instanceName: string;
  constructor(engineKey: string, instanceName: string) {
    super(engineKey);
    this.instanceName = instanceName;
    amplitude.getInstance(instanceName).init(engineKey, undefined, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
    });
  }

  logEvent = (eventType: string, eventData: any = {}) => {
    amplitude.getInstance(this.instanceName).logEvent(eventType, eventData);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserId = (userId: string, country?: string) => {
    amplitude.getInstance(this.instanceName).setUserId(this.contructUserId(userId));
  };

  logRevenue = (tournamentId: number, price: number, unit: string, extraProperties: any = {}) => {
    const revenue = new amplitude.Revenue()
      .setProductId(`Tournament - ${tournamentId}`)
      .setPrice(price)
      .setQuantity(1)
      .setRevenueType("purchase")
      .setEventProperties({ unit, ...extraProperties });
    amplitude.getInstance(this.instanceName).logRevenueV2(revenue);
  };
}

export class RappiAnalyticsEngine extends AmplitudeAnalyticsEngine {
  logEvent = (eventType: string, eventData: any = {}) => {
    const newEventType = eventType.toUpperCase().replace(/ /g, "_");
    const newEventData: any = {};
    const rx = /[A-Z]/g;
    const replacement = "_$&";
    Object.keys(eventData).map((key: string) => {
      const newKey = key.replace(rx, replacement).toUpperCase();
      newEventData[newKey] = eventData[key];
      return null;
    });
    amplitude.getInstance(this.instanceName).logEvent(newEventType, newEventData);
  };

  setUserId = (userId: string, country?: string) => {
    amplitude.getInstance(this.instanceName).setUserId(`${country}_${userId}`);
  };

  logRevenue = () => {};
}

// export class MixPanelAnalyticsEngine extends AnalyticsEngine {
//   constructor(engineKey: string) {
//     super(engineKey);
//     mixpanel.init(engineKey);
//   }

//   logEvent = (eventType: string, eventData: any = {}) => {
//     mixpanel.track(eventType, eventData);
//   };

//   setUserId = (userId: string) => {
//     mixpanel.identify(this.contructUserId(userId));
//   };
// }

interface IProvider {
  name: string;
  enabledEvents: string[];
  engines: AnalyticsEngine[];
}

interface IAnalyticsProviderConfig {
  providers: IProvider[];
}

export class AnalyticsProvider {
  providers: IProvider[];
  gameTimer: GameTimer;
  partnerId = "";
  userId = "";

  defaultTournamentData: TTournamentData = {
    gameSlug: "",
    tournamentId: "",
    tournamentType: "",
    isPaidTournament: false,
  };

  arcadeDefaultData: TArcadeData = { gameSlug: "" };

  constructor({ providers = [] }: IAnalyticsProviderConfig) {
    this.providers = providers;
    this.gameTimer = new GameTimer();
  }

  addProvider = (provider: IProvider) => this.providers.push(provider);

  logEvent = (eventType: string, eventData: any = {}) => {
    this.providers.map(provider =>
      provider.engines.map(
        engine => provider.enabledEvents.includes(eventType) && engine.logEvent(eventType, eventData),
      ),
    );
  };

  logRevenue = (tournamentId: number, price: number, unit: string) => {
    this.providers.map(provider =>
      provider.engines.map(engine =>
        engine.logRevenue(tournamentId, price, unit, {
          tournamentId,
          partnerId: this.partnerId,
        }),
      ),
    );
  };

  setUserId = (userId: string | null, partnerId: string, country: string) => {
    this.partnerId = partnerId;
    this.userId = userId || `${uuid4()}__anonymous__${partnerId}`;
    this.providers.map(provider => provider.engines.map(engine => engine.setUserId(this.userId, country)));
  };

  pageView = (pagePath: string) => {
    this.providers.map(provider =>
      provider.engines.map((engine: any) => engine?.pageView && engine.pageView(pagePath)),
    );
  };

  prepareData = (data: any = {}, extraData: any = {}) => {
    const ad_partner_id = localStorage && localStorage.getItem("ad_partner_id");
    return {
      ...data,
      partnerId: this.partnerId,
      ad_partner_id: ad_partner_id,
      ...extraData,
    };
  };

  setArcadeDefaultData = ({ gameSlug }: TArcadeData) => {
    this.arcadeDefaultData.gameSlug = gameSlug;
  };

  setTournamentData = (tournament: ITournament) => {
    this.defaultTournamentData.gameSlug = tournament.game;
    this.defaultTournamentData.tournamentId = tournament.id;
    this.defaultTournamentData.isPaidTournament = tournament?.entry_fee > 0;
    this.defaultTournamentData.tournamentType = getTournamentType(tournament);
  };

  resetData = () => {
    this.gameTimer.reset();
  };

  clickedPayNow = () => {
    this.logEvent(EVENT_TYPES.CLICKED_PAY_NOW, this.prepareData(this.defaultTournamentData));
  };

  preparePerformanceData = () => {
    if (window?.performance?.timing) {
      const { timing } = window.performance;
      return {
        connectDuration: (timing.connectEnd - timing.connectStart) / 1000,
        domainLookupDuration: (timing.domainLookupEnd - timing.domainLookupStart) / 1000,
        responseDuration: (timing.responseEnd - timing.responseStart) / 1000,
      };
    }
    return {};
  };

  paymentCompleted = (amount: number) => {
    this.logEvent(EVENT_TYPES.PAYMENT_SUCCESSFUL, this.prepareData(this.defaultTournamentData, { amount }));
  };

  clickedGameBanner = () => {
    this.logEvent(EVENT_TYPES.CLICKED_GAME_BANNER, this.prepareData(this.defaultTournamentData));
    this.gameStarted();
  };

  arcadeGameStarted = () => {
    this.gameTimer.reset();
    this.gameTimer.start();
    this.logEvent(EVENT_TYPES.ARCADE_GAME_STARTED, this.prepareData(this.arcadeDefaultData));
  };

  arcadeGameEnded = () => {
    this.gameTimer.stop();
    const { duration } = this.gameTimer;
    this.logEvent(EVENT_TYPES.ARCADE_GAME_ENDED, this.prepareData(this.arcadeDefaultData, { duration }));
  };

  clickedPlayNow = () => {
    this.logEvent(EVENT_TYPES.CLICK_PLAY_ON_LEADERBOARD, this.prepareData(this.defaultTournamentData));
    this.gameTimer.reset();
  };

  enterGameTile = () => {
    this.logEvent(EVENT_TYPES.ENTER_GAME_TILE, this.prepareData(this.defaultTournamentData));
    api.post("bireports/clicked-game-tile/", {
      tournament: this.defaultTournamentData.tournamentId,
    });
  };

  clickPlayIcon = () => {
    this.logEvent(EVENT_TYPES.CLICK_PLAY_ICON, this.prepareData(this.defaultTournamentData));
    api.post("bireports/clicked-play-icon/", {
      tournament: this.defaultTournamentData.tournamentId,
    });
  };

  clickPlayNowLeaderboard = () => {
    this.logEvent(EVENT_TYPES.CLICK_PLAY_ON_LEADERBOARD, this.prepareData(this.defaultTournamentData));
    api.post("bireports/clicked-play-leaderboard/", {
      tournament: this.defaultTournamentData.tournamentId,
    });
  };

  clickGuestRegisterPopup = () => {
    this.logEvent(EVENT_TYPES.GUEST_REGISTER_POPUP, this.prepareData(this.defaultTournamentData));
  };

  clickLoginByPhoneNumber = () => {
    this.logEvent(EVENT_TYPES.LOGIN_BY_PHONE, this.prepareData(this.defaultTournamentData));
  };

  clickOtpVerification = () => {
    this.logEvent(EVENT_TYPES.LOGIN_OTP_VERIFICATION, this.prepareData(this.defaultTournamentData));
  };

  loginByGoogle = () => {
    this.logEvent(EVENT_TYPES.GOOGLE_LOGIN, this.prepareData(this.defaultTournamentData));
  };

  loginByFacebook = () => {
    this.logEvent(EVENT_TYPES.FACEBOOK_LOGIN, this.prepareData(this.defaultTournamentData));
  };

  gameStarted = () => {
    this.gameTimer.start();
    this.logEvent(EVENT_TYPES.GAME_STARTED, this.prepareData(this.defaultTournamentData));
  };

  gameEnded = (gameSlug: string) => {
    this.gameTimer.stop();
    this.logEvent(
      EVENT_TYPES.GAME_ENDED,
      this.prepareData(this.defaultTournamentData, {
        gameSlug,
        duration: this.gameTimer.duration,
      }),
    );
  };

  tournamentSessionEnded = () => {
    const gamePlayCount = this.gameTimer.allDurations.length;
    this.logEvent(
      EVENT_TYPES.TOURNAMENT_SESSION_ENDED,
      this.prepareData(this.defaultTournamentData, {
        gamePlayCount,
        durations: this.gameTimer.totalDuration,
        averageDuration: this.gameTimer.averageDuration,
      }),
    );
    this.gameTimer.reset();
    this.resetData();
  };

  gameShare = (clientType: string) => {
    this.logEvent(
      EVENT_TYPES.SHARE_GAME_LINK,
      this.prepareData(this.defaultTournamentData, {
        clientType,
        userId: this.userId,
      }),
    );
  };

  viewedMissionBoard = (boardType: string) => {
    this.logEvent(EVENT_TYPES.VIEWED_MISSION_BOARD, this.prepareData({ boardType }));
  };

  clickedShareButton = () => {
    this.logEvent(EVENT_TYPES.CLICK_SHARE_BUTTON, this.prepareData());
  };

  ctaBannerClick = (url: string) => {
    this.logEvent(EVENT_TYPES.CLICK_CTA_BANNER, this.prepareData(this.defaultTournamentData, { url }));
  };

  clickedWelcomeBanner = (actionURL = "") => {
    const { allTournaments } = Store.getState().tournaments;
    const tournamentIdMatches = actionURL.match(/(\d+)/gi);
    let gameSlug = "unknown";
    if (tournamentIdMatches) {
      const tournamentId = parseInt(tournamentIdMatches[0]);
      const tournament: ITournament | undefined = allTournaments.find((t: ITournament) => t?.id === tournamentId);
      if (tournament) {
        gameSlug = tournament.game;
      }
    }
    this.logEvent(
      EVENT_TYPES.CLICK_WELCOME_BANNER,
      this.prepareData(this.defaultTournamentData, { gameSlug, actionURL }),
    );
  };

  arcadeLandingPage = () => {
    this.logEvent(EVENT_TYPES.ARCADE_LANDING_PAGE, this.prepareData({}));
  };

  ticketPage = () => {
    this.logEvent(EVENT_TYPES.ENTERED_TICKET_PAGE, this.prepareData());
  };

  ticketRedemptionPage = () => {
    this.logEvent(EVENT_TYPES.ENTERED_TICKET_REDEMPTION_PAGE, this.prepareData());
  };

  coinPage = () => {
    this.logEvent(EVENT_TYPES.ENTERED_COIN_PAGE, this.prepareData());
  };

  coinRedemptionPage = () => {
    this.logEvent(EVENT_TYPES.ENTERED_COIN_REDEMPTION_PAGE, this.prepareData());
  };

  enteredTournamentLandingPage = ({ apiResponseTime, utmSource, utmCampaign, utmMedium }: any) => {
    this.logEvent(
      EVENT_TYPES.ENTERED_TOURNAMENT_PLATFORM,
      this.prepareData({ apiResponseTime, utmSource, utmMedium, utmCampaign, ...this.preparePerformanceData() }),
    );
  };

  directNavigation = () => {
    this.logEvent(EVENT_TYPES.DIRECT_NAVIGATION, this.prepareData());
  };

  clickedLoadMore = () => {
    this.logEvent(EVENT_TYPES.CLICKED_LOAD_MORE, this.prepareData(this.defaultTournamentData));
  };

  enteredGame = () => {
    this.logEvent(EVENT_TYPES.ENTERED_GAME_PLATFORM, this.prepareData(this.defaultTournamentData));
  };

  pressedSpinTheWheelButton = () => {
    this.logEvent(EVENT_TYPES.PRESSED_SPIN_THE_WHEEL_BUTTON, this.prepareData());
  };

  clickedPlayAgain = (game: string) => {
    // this.logEvent(EVENT_TYPES.CLICKED_ON_PLAY_AGAIN_BUTTON, this.prepareData());
    api.post("bireports/game-try-again/", { game: game });
  };

  gotoHome = () => {
    this.logEvent(EVENT_TYPES.GOTO_HOME_BUTTON, this.prepareData());
  };

  clickedProfileLink = () => {
    this.logEvent(EVENT_TYPES.CLICKED_ON_PROFILE, this.prepareData());
  };

  clickedFAQ = () => {
    this.logEvent(EVENT_TYPES.CLICKED_ON_FAQ, this.prepareData());
  };

  gameOverPage = (game: string) => {
    // this.logEvent(EVENT_TYPES.GAME_OVER_PAGE, this.prepareData());
    api.post("bireports/game-over-page/", { game: game });
  };

  clickReferFriend = () => {
    this.logEvent(EVENT_TYPES.CLICKED_ON_REFER_FRIEND, this.prepareData());
  };

  viewedSubscriptionPopup = ({ active }: any) => {
    const subscription_type = active;
    this.logEvent(EVENT_TYPES.VIEWED_SUBSCRIPTION_POPUP, this.prepareData({ subscription_type }));
  };

  clickUnsubscribe = ({ active }: any) => {
    const unsubscribe_package = active;
    this.logEvent(EVENT_TYPES.CLICKED_UNSUBSCRIBE_BUTTON, this.prepareData({ unsubscribe_package }));
  };

  clickSubscribed = ({ subscription_status, active }: any) => {
    const subscription_package = active;
    this.logEvent(EVENT_TYPES.CLICKED_SUBSCRIBED, this.prepareData({ subscription_status, subscription_package }));
  };
}

export default new AnalyticsProvider({
  providers: [
    {
      name: "default",
      enabledEvents: defaultEvents,
      engines: [
        // new AmplitudeAnalyticsEngine(AMPLITUDE_KEY, "default"),
        // new MixPanelAnalyticsEngine(MIX_PANEL_TOKEN),
        new GoogleAnalyticsEngine(GOOGLE_ANALYTICS_KEY, "default"),
      ],
    },
  ],
});
