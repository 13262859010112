import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
// import tournamentsAPI from "actions/api/tournaments";

import { Form } from "react-bootstrap";
import cn from "classnames";
import { useAppSelector } from "redux/Store";
import api from "actions/api";

const selectTeamCSS = (theme: ITheme) => css`
  .label-sty {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.text.primary};

    @media (max-width: 450px) {
      font-size: 12px;
    }
  }

  .form-sty {
    border-radius: 10px !important;
    background: #f9f9f8 !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 4px !important;
    height: calc(1.7em + 0.9rem + 3px);
  }

  .dropdown-link a {
    background: red;
    color: yellow;
  }

  &.text-center {
    text-align: center;
  }

  .modal-header-sty {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 5px;
  }
`;

export default function SelectTeam({ form, checkSignupPage }: any) {
  const { application, country } = useAppSelector((state: any) => state.common);
  const [data, setData] = useState([]);

  useEffect(() => {
    api
      .get(`tournaments/team-based-application/teams/${application?.slug}/${country}/`)
      .then(response => {
        setData(response.data);
      })
      .catch(() => {
        setData([]);
      });
  }, [country, application]);

  return (
    <div css={selectTeamCSS} className={cn(!checkSignupPage && "text-center")}>
      <label
        htmlFor="team"
        className={cn("ps-1", {
          "label-sty": checkSignupPage,
          "modal-header-sty": !checkSignupPage,
        })}
      >
        Select Your Team
      </label>

      <Form.Control
        style={{
          border: "1px solid black",
          boxShadow: "none",
        }}
        aria-label="Default select example"
        as="select"
        className="form-sty"
        id="team"
        name="team"
        ref={form.register({
          required: {
            value: true,
            message: "Please select a team",
          },
        })}
      >
        <option value="" selected disabled hidden>
          Select
        </option>
        {data.map((item: any, index: number) => (
          <option key={index} value={item?.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
    </div>
  );
}
