import { createSlice } from "@reduxjs/toolkit";
import { apiUnauthorized } from "actions/api";
import { AppDispatch } from "redux/Store";
// import passwordsAPI from "actions/api/passwords";

export interface IPasswords {
    user: null| string;
    otp: null| string;
    loading: boolean;
    error: boolean;
  }
  
  const initialState: IPasswords = {
    user: null,
    otp: null,
    loading: false,
    error: false
  };

  const statusSlice = createSlice({
    name: "userAuthentication",
    initialState,
    reducers: {
      fetchUserAuthenticationLoading(state) {
        state.loading = true;
      },
      fetchUserAuthenticationSuccess(state, action) {
        state.user = action.payload.user;
        state.loading = false;
        state.error = false;
      },
      fetchUserAuthenticationError(state) {
        state.user = null;
        state.loading = false;
        state.error = true;
      },
      setOTP(state, action){
        state.loading=false
        state.error = false;
        state.otp = action.payload;
      }
    }
  });

  export const {
    fetchUserAuthenticationError,
    fetchUserAuthenticationLoading,
    fetchUserAuthenticationSuccess,
    setOTP
  } = statusSlice.actions;
  
  export default statusSlice.reducer;


  export const fetchUserAuthenticationID = ( email: string) => async (dispatch: AppDispatch) => {
    dispatch(fetchUserAuthenticationLoading());
    return apiUnauthorized
      .post(`passwords/reset-otp/`, {email} )
      .then(response => {
        dispatch(fetchUserAuthenticationSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchUserAuthenticationError()));
  };


  export const verifyOTP = (user: string, otp: string) => async (dispatch: AppDispatch) => {
    dispatch(fetchUserAuthenticationLoading());
    return apiUnauthorized
      .post(`passwords/verify-otp/`, { user, otp} )
      .then(response => {
        dispatch(setOTP(otp));
        return response.data;
      })
      .catch(() => dispatch(fetchUserAuthenticationError()));
  };


  export const modifyPassword = (user: string, otp: string, password: string, confirm_password: string) => async (dispatch: AppDispatch) => {
    dispatch(fetchUserAuthenticationLoading());
    return apiUnauthorized
      .post(`passwords/change/`, { user, otp, password, confirm_password} )
      .then(response => {
        return response.data;
      })
      .catch(() => dispatch(fetchUserAuthenticationError()));
  };