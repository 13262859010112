import { css } from "@emotion/react";

const elTiempoStyles = css`
  &.eltiempo {
    .title-subtitle-container {
      .game-name,
      .tournament-subtitle {
        color: #feffff !important;
      }
    }

    .triangle-div {
      .triangle-button {
        .icon-play {
          fill: #feffff !important;
        }
      }
    }

    .desktop-footer {
      .link-img {
        fill: #ffffff !important;
      }
      .active-link {
        .link-img {
          fill: #000000 !important;
        }
        .link-label {
          color: #000000 !important;
        }
      }
    }

    .form-check {
      .form-check-label {
        a {
          color: #000000;
        }
      }
    }

    .icon-container {
      .input-wrapper {
        font-size: 10px !important;
      }

      .sub-button {
        color: #feffff !important;
      }

      .pass {
        color: #000000 !important;
      }
    }

    .play-btn-browse {
      .play-icon {
        fill: #feffff !important;
      }
    }

    .browse-popup {
      .gamename-style {
        color: #000000 !important;
      }
    }
    .recommended-tournament-container,
    .recommended-games {
      .header-style {
        color: #ffffff;
      }
    }
    .profile-history-wrapper {
      .subtitle {
        color: #ffffff;
      }
    }
    .swiper-wrapper {
      .ranking-button {
        color: #ffffff !important;
      }
    }
  }
`;

export default elTiempoStyles;
