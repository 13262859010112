import { APP_SLUGS } from "constants/constants";
import { decodeDataFromURL, isStringEqual } from "utils";

const { app_slug } = decodeDataFromURL();

const application = { slug: app_slug };

const isAppSlug = (slugToCompare: string) => {
  if (!application?.slug) return;
  return !!(application?.slug && isStringEqual(application?.slug, slugToCompare));
};

export const isBkash = isAppSlug(APP_SLUGS.BKASH);
export const isMetaVerseGo = isAppSlug(APP_SLUGS.METAVERSEGO);
export const isGoamaPH = isAppSlug(APP_SLUGS.GOAMA_PH);
export const isSabay = isAppSlug(APP_SLUGS.SABAY);
export const isElTiempo = isAppSlug(APP_SLUGS.EL_TIEMPO);
export const isLatam = isAppSlug(APP_SLUGS.LATAM);
export const isBtsDigital = isAppSlug(APP_SLUGS.BTS_DIGITAL);
export const isDemo = isAppSlug(APP_SLUGS.DEMO);
export const isFoodpanda = isAppSlug(APP_SLUGS.FOODPANDA);
export const isViva = isAppSlug(APP_SLUGS.VIVA);
export const isDana = isAppSlug(APP_SLUGS.DANA);
export const isRazer = isAppSlug(APP_SLUGS.RAZER);
export const isCricBuzz = isAppSlug(APP_SLUGS.CRIC_BUZZ);
export const isFoodpandaApp = isAppSlug(APP_SLUGS.FOODPANDA_APP);
export const isRappi = isAppSlug(APP_SLUGS.RAPPI);
export const isAstroPay = isAppSlug(APP_SLUGS.ASTROPAY);
export const isPepsico = isAppSlug(APP_SLUGS.PEPSICO);
export const isGcashBpo = isAppSlug(APP_SLUGS.GCASH_BPO);
export const isGcash = isAppSlug(APP_SLUGS.GCASH);
export const isTrendi = isAppSlug(APP_SLUGS.TRENDI);
export const isJazzcash = isAppSlug(APP_SLUGS.JASSCASH);
export const isCoffeeprint = isAppSlug(APP_SLUGS.COFFEEPRINT);
export const isGizer = isAppSlug(APP_SLUGS.GIZER);
export const isCervezaSol = isAppSlug(APP_SLUGS.SOLEPACK);
export const isSnickers = isAppSlug(APP_SLUGS.SNICKERS);
export const isMegafon = isAppSlug(APP_SLUGS.MEGAFON);
export const isChopeStorms = isAppSlug(APP_SLUGS.CHOPE_STORMS);
export const isGcashAliplus = isAppSlug(APP_SLUGS.GCASH_ALIPLUS);
export const isDanaAliplus = isAppSlug(APP_SLUGS.DANA_ALIPLUS);
export const isTngAliplus = isAppSlug(APP_SLUGS.TNG_ALIPLUS);
export const isTng = isAppSlug(APP_SLUGS.TNG);
export const isYippi = isAppSlug(APP_SLUGS.YIPPI);
export const isRappico = isAppSlug(APP_SLUGS.RAPPICO);
export const isNequi = isAppSlug(APP_SLUGS.NEQUI);
export const isGlobe = isAppSlug(APP_SLUGS.GLOBE);
export const isMpt4u = isAppSlug(APP_SLUGS.MPT4U);
export const isDarkui = isAppSlug(APP_SLUGS.DARKUI);
export const isGoamaLabs = isAppSlug(APP_SLUGS.GOAMALABS);
export const isValepass = isAppSlug(APP_SLUGS.VALEPASS);
export const isBountie = isAppSlug(APP_SLUGS.BOUNTIE);
export const isUnico = isAppSlug(APP_SLUGS.UNICO);
export const isKFC = isAppSlug(APP_SLUGS.KFC);
export const isCaracol = isAppSlug(APP_SLUGS.CARACOL);
export const isXctuality = isAppSlug(APP_SLUGS.XCTUALITY);
export const isCroydon = isAppSlug(APP_SLUGS.CROYDON);
export const isOoredoo = isAppSlug(APP_SLUGS.OOREDOO);
export const isGoogly = isAppSlug(APP_SLUGS.GOOGLY);
export const isTada = isAppSlug(APP_SLUGS.TADA);
export const isArcor = isAppSlug(APP_SLUGS.ARCOR);
export const isApostar = isAppSlug(APP_SLUGS.APOSTAR);
export const isWinsports = isAppSlug(APP_SLUGS.WINSPORTS);
export const isMok = isAppSlug(APP_SLUGS.MOK);
export const isPulzo = isAppSlug(APP_SLUGS.PULZO);
export const isPresente = isAppSlug(APP_SLUGS.PRESENTE);
export const isKlook = isAppSlug(APP_SLUGS.KLOOK);
export const isBurgerKingRu = isAppSlug(APP_SLUGS.BURGERKINGRU);
export const isLpl = isAppSlug(APP_SLUGS.LPL);
export const isBubbaloo = isAppSlug(APP_SLUGS.BUBBALOO);
export const isMvola = isAppSlug(APP_SLUGS.MVOLA);
export const isPoxipol = isAppSlug(APP_SLUGS.POXIPOL);
export const isSkibidiToilet = isAppSlug(APP_SLUGS.SKIBIDI_TOILET);
export const isB2cBd = isAppSlug(APP_SLUGS.B2C_BD);
export const isDibble = isAppSlug(APP_SLUGS.DIBBLE);
export const isJeda = isAppSlug(APP_SLUGS.JEDA);
export const isRidmik = isAppSlug(APP_SLUGS.RIDMIK);
export const isBonoxs = isAppSlug(APP_SLUGS.BONOXS);
export const isWave = isAppSlug(APP_SLUGS.WAVE);
export const isNiu = isAppSlug(APP_SLUGS.NIU);
export const isFegg = isAppSlug(APP_SLUGS.FEGG);
export const isClaro = isAppSlug(APP_SLUGS.CLARO) || isAppSlug(APP_SLUGS.CLARO_PAY);
export const isDetikCom = isAppSlug(APP_SLUGS.DETIKCOM);
export const isEGEsports = isAppSlug(APP_SLUGS.EGESPORTS);
export const isBarca = isAppSlug(APP_SLUGS.BARCA);
export const isWowGames = isAppSlug(APP_SLUGS.WOW_GAMES);
export const isOrange = isAppSlug(APP_SLUGS.ORANGE);
export const isGo3 = isAppSlug(APP_SLUGS.GO3);
export const isAvolta = isAppSlug(APP_SLUGS.AVOLTA);
export const isTourEast = isAppSlug(APP_SLUGS.TOUREAST);
export const isConverse = isAppSlug(APP_SLUGS.CONVERSE);

const isSameAsAppSlug = (slug: string) => {
  const { app_slug } = decodeDataFromURL();
  if (!app_slug || !slug) return false;
  return isStringEqual(app_slug, slug);
};

export const isGoama = () => {
  const { app_slug } = decodeDataFromURL();
  return isStringEqual(app_slug ?? "", APP_SLUGS.Goama);
};

export const isGo48 = () => {
  const { app_slug } = decodeDataFromURL();
  return isStringEqual(app_slug ?? "", APP_SLUGS.GO48);
};
export const isPaobc = () => isSameAsAppSlug(APP_SLUGS.PAOBC);
export const isMemePop = () => isSameAsAppSlug(APP_SLUGS.MEME_POP);
export const isPolemicGames = isAppSlug(APP_SLUGS.POLEMIC_GAMES);
