import { ActionTypes, fetchCommonData } from "actions";
import { AxiosError } from "axios";
// import history from "HistoryWrapper";
// import loginAPI from "actions/api/old_login";
// import { checkSessionAndUpdateAxiosInstance } from "actions/api/utils";
import { VerifyUserLoginDetailsWithUserID } from "redux/reducers/userLoginReducer";
import store from "redux/Store";
import { decodeDataFromURL, updateLocalQueryParams } from "utils";
// import guestStorage from "utils/guestStorage";
import RollbarClient from "utils/rollbarClient";
// import initApi from "actions/api/old_initApi";
import { fetchCommonDataLoading } from "redux/reducers/commonReducer";

const ridmikSdk: ISdkResource = {
  type: "built-in",
  goBackHome: () => {
    if (window.Ridmik) {
      window.Ridmik.exit();
    }
  },
  login: () => {
    if (window.Ridmik?.login) {
      window.Ridmik.login();
    }
  },
  postPaymentConfirmationCallback: payload => {
    if (payload?.redirect_url) {
      window.location.replace(payload.redirect_url);
    }
  },
};

/**
 * Gets called from Ridmik's side after auth is done
 */
window.Goama = {
  onLogin: function (userid: string, name: string) {
    store.dispatch(fetchCommonDataLoading());
    /* TODO: NEED TO RECHECK THIS */
    // checkSessionAndUpdateAxiosInstance(loginAPI); // NOTE: have to add guest user's auth token to axios instance header
    // RollbarClient.error("Ridimik onLogin callback", { userid, name });
    store
      .dispatch<any>(VerifyUserLoginDetailsWithUserID(userid, name))
      .then(async (response: any) => {
        // RollbarClient.error("Ridimik onLogin callback response", { ...response });
        if (response?.token?.token) {
          const { app_slug, country, userid } = decodeDataFromURL();
          if (userid) {
            updateLocalQueryParams({
              app_slug,
              country,
              userid: null,
              name: null,
              profile_pic: null,
            });
          }
          if (sessionStorage) {
            sessionStorage.setItem("init-token", response?.token?.token);
          }
          if (localStorage) {
            try {
              localStorage.setItem("init-token", response?.token?.token);
            } catch (e) {
              //
            }
            localStorage.setItem("rem", "true");
            try {
              localStorage.removeItem("user");
            } catch (e) {
              //
            }
          }
          // checkSessionAndUpdateAxiosInstance(initApi);
          // guestStorage.syncData();
          await store.dispatch(fetchCommonData() as any).finally(() => {
            const BASE_URL = `${window.location.origin}/tournament?app=${app_slug}&country=${country}`;
            window.location.replace(BASE_URL);
          });
          // history.repalce(`/tournament`); // NOTE: navigate throws exception as useNavigation hook can't be used outside a React component/hook
        }
      })
      .catch((error: AxiosError) => {
        console.error({ error });
        RollbarClient.error("ridmik callback error", { error });
      })
      .finally(() => {
        store.dispatch({ type: ActionTypes.FETCH_COMMON_DATA_SUCCESS });
      });
  },
};

export default ridmikSdk;
