// import paymentAPI from "actions/api/payments";
import GoamaPaymentService from "./goama/GoamaPaymentService";
import api from "actions/api";

export default class MvolaPaymentService extends GoamaPaymentService {
  public async initiatePayment({ tournament, extraData = {} }: { tournament: any; extraData?: any }) {
    this.tournament = tournament;
    this.extraData = extraData;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve, reject) => {
      (async () => {
        this.baseURL = `${window.location.protocol}//${window.location.host}/tournament?tournament=${this.tournament?.id}`;
        const params = this.getParams();
        const url = `init/${this.application?.slug}/`;

        try {
          const response = await this.fetchMvolaPaymentParams({
            url,
            params,
          });
          this.initiateGoamaPayment(response);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }

  protected async fetchMvolaPaymentParams({ url, params }: { url: string; params: any }) {
    return await api.post(`payments/${url}`, { ...params }).then(response => {
      this.paymentReferenceCode = response.data.originatorConservationID;

      // Set last reference id into localStorage
      // For verifying upon callback redirect
      if (params?.redirect_link) {
        localStorage?.setItem(this.referenceStorageKey, response.data.originatorConservationID);
      }
      return response;
    });
  }

  initiateGoamaPayment({ data }: any) {
    // RollbarClient?.error("Mvola payment parameters", JSON.stringify(data));
    window.initiateGoamaPayment.postMessage(JSON.stringify(data));
  }
}
