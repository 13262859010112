import { processGameNameResponse } from "utils";
import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";

// import tournamentsAPI from "actions/api/tournaments";

export interface IHistory {
  data: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IHistory = {
  data: [],
  loading: false,
  error: false
};

const historySlice = createSlice({
  name: "userHistory",
  initialState,
  reducers: {
    fetchHistoryLoading(state) {
      state.loading = true;
    },
    fetchHistorySuccess(state, action) {
      state.data = action.payload.map((history: any) => ({
        ...history,
        game_name: processGameNameResponse(history.game_name)
      }));
      state.loading = false;
      state.error = false;
    },
    fetchHistoryError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchHistoryError,
  fetchHistoryLoading,
  fetchHistorySuccess
} = historySlice.actions;

export default historySlice.reducer;

export const fetchUserHistory = () => async (dispatch: AppDispatch) => {
  dispatch(fetchHistoryLoading());
  return api
    .get(`tournaments/users/archive-tournament-history/`)
    .then(response => {
      dispatch(fetchHistorySuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchHistoryError()));
};
