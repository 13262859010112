import { css } from "@emotion/react";

export const avoltaStyles = css`
  &.avolta {
    .game-loading-overlay {
      .progress-bar-container {
        width: 70% !important;
      }
      .logo {
        &.partner {
          width: 70% !important;
        }
      }
    }
  }
`;

export default avoltaStyles;
