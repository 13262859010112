import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { FaPlus } from "react-icons/fa";

import { lighten } from "polished";
import {
  fetchPartnerBalance,
  fetchPartnerBalanceInfo,
  fetchPaymentPartnerBalance,
} from "redux/reducers/partnerInfoBalanceReducer";
import { getPartnerCurrencySymbol } from "utils";
import { useHistory } from "hooks/useHistory";
import { isPepsico, isBonoxs } from "utils/applicationSlug";
interface IPlusButtonProps {
  plusButton?: boolean;
}

export default function PartnerBalanceStatus({ plusButton = false }: IPlusButtonProps) {
  const { application } = useAppSelector((state: any) => state.common);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const { data, balance } = useAppSelector((state: any) => state.partnerBalance);

  const roundedNumber = Math.round(balance?.partner_balance);

  useEffect(() => {
    if (application) {
      if (!isPepsico) {
        dispatch(fetchPartnerBalanceInfo(application?.slug));
        dispatch(fetchPartnerBalance(application?.slug));
      } else {
        dispatch(fetchPaymentPartnerBalance(application?.slug));
        dispatch(fetchPartnerBalanceInfo(application?.slug));
      }
    }
    // eslint-disable-next-line
  }, [application]);

  const showPartnerBalance = application?.applicationsetting?.enable_partner_balance || isPepsico;

  if (!showPartnerBalance) return null;
  let pageLink = "/partner-balance";
  if (isBonoxs) {
    pageLink = "#";
  }

  return (
    showPartnerBalance && (
      <Link
        to={pageLink}
        css={link}
        className={cn("right-style partner-status-bar", {
          "page-profile": pathname === "/profile",
          // "pe-2": history.location.pathname === `/tournament`,
          "app-off":
            application?.applicationsetting?.enable_coin &&
            (application?.is_global_back_button_enabled || application?.is_back_button_enabled),
          "coin-notglobalbtn-backbtn":
            application?.applicationsetting?.enable_coin &&
            !application?.is_global_back_button_enabled &&
            !application?.is_back_button_enabled,
          "game-list-topbar":
            (!application?.is_back_button_enabled || !application?.enable_top_bar) &&
            history.location.pathname !== `/tournament` &&
            application?.applicationsetting?.layout_type === "REGULAR",
          "not-globalbackbtn-coin":
            !application?.applicationsetting?.enable_coin && application?.is_global_back_button_enabled,
          "not-globalbackbtn":
            !application?.is_global_back_button_enabled &&
            application?.is_back_button_enabled &&
            application?.applicationsetting?.enable_coin,
          "not-globalbtn-backbtn-coin":
            !application?.is_global_back_button_enabled &&
            !application?.is_back_button_enabled &&
            !application?.applicationsetting?.enable_coin,
          "not-globalbtn-coin":
            !application?.is_global_back_button_enabled && !application?.applicationsetting?.enable_coin,
        })}
      >
        <div
          css={plusButton ? backgroundStyle : tokenStyle}
          className={cn(`upper partner-status ${application?.slug}-partner`, {
            "d-flex flex-row": plusButton,
            "userprofile-background": pathname === "/profile",
            "homepage-background": pathname !== "/profile",
          })}
          id="partner-status"
        >
          <div className="d-flex flex-row justify-content-center align-items-center back-design pe-1 me-1 ms-1">
            {data?.custom_currency_icon ? (
              <img
                id="img-partner-status"
                src={getPartnerCurrencySymbol(data)}
                alt="partner-icon"
                css={css`
                  width: 18px;
                  transform: rotate(10deg);
                  padding-left: 5px;
                `}
              />
            ) : (
              <span className="currencyIcon"> {getPartnerCurrencySymbol(data)}</span>
            )}

            {/* <span className="label-sty  ps-2">{abbreviateNumber(balance?.partner_balance || 0)}</span> */}

            <span className="label-sty  ps-2">{roundedNumber || 0}</span>
          </div>
          {plusButton ? (
            <div className="d-flex align-items-center justify-content-center icon pe-2 desktop-plus">
              <FaPlus style={{ color: "green" }} className="icon-style" />
            </div>
          ) : null}
        </div>
      </Link>
    )
  );
}

const backgroundStyle = (theme: ITheme) => css`
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  width: 100%;
  min-width: 100px;
  justify-content: space-between;

  .currencyIcon {
    font-weight: 6;
    color: #ffffff;
    font-size: 20px;
    padding-left: 10px;

    @media screen and (min-width: 1022px) {
      padding-right: 5px;
    }
  }

  &.homepage-background {
    background-color: ${theme.button.tertiaryButtonBackground};
    box-shadow: -1pt 6pt 5px 0px ${lighten(0.4, theme.button.primaryGradientStart)};
  }
  &.globe-ticket {
    background-color: ${theme.button.primaryGradientStart};
    .label-sty {
      color: #fff;
    }
    .icon svg {
      color: #fff !important;
    }
  }
  &.userprofile-background {
    background-color: ${theme.foreground.primary};
    box-shadow: -1pt 6pt 5px 0px ${lighten(0.4, theme.button.primaryGradientStart)};
    &.darkui-ticket {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 350px) {
    height: 35px;
    /* width: 90px; */
  }

  @media screen and (min-width: 1022px) {
    height: 35px;
  }

  .token-radius {
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );

    border-radius: 50%;
    width: 35px;
    height: 35px;
    right: 50%;
    margin-right: 5px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 350px) {
      height: 30px;
      width: 30px;
    }
  }

  .label-sty {
    color: ${theme.text.primary};
    margin-top: 0;
    font-size: 15px;

    @media screen and (max-width: 350px) {
      padding-left: 37px;
    }
    @media screen and (min-width: 1022px) {
      font-size: 15px;
    }
  }
`;

const tokenStyle = (theme: ITheme) => css`
  /* background-color: ${theme.background.primary}; */
  /* background-color: yellow; */
  box-shadow: 0px 0px 4px 0px ${theme.button.primaryShadow};
  position: relative;
  display: flex;
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  svg,
  svg g,
  svg circle,
  svg path,
  svg rect {
    fill: ${theme.foreground.primary};
  }

  .icon-style {
    width: 15px;
    height: 15px;
    color: ${theme.text.primary};
  }

  &.homepage-background {
    background-color: ${theme.button.tertiaryButtonBackground};
  }
  &.userprofile-background {
    background-color: ${theme.foreground.primary};
    box-shadow: -4pt 7pt 5px 0px ${theme.button.primaryGradientStart};
  }

  .currencyIcon {
    font-weight: 6;
    color: #ffffff;
    font-size: 20px;
  }

  .token-border {
    background-color: white;
    border-radius: 5px;
    color: white;
    font-size: 15px;
  }

  .token-radius {
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );

    border-radius: 50%;
    width: 29px;
    height: 29px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token {
    width: 15px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 0 auto;
    top: 9px;
  }
`;

const link = (theme: ITheme) => css`
  border: none;
  text-decoration: none;
  display: inline-block;

  &.right-style {
    right: 0;
    min-width: 96px;
    margin-right: 0.7rem;
  }

  &.page-profile {
    margin-right: 0.3rem;
  }

  &.app-off {
    /* margin-left: auto; */
    /* margin-right: 12px; */
  }

  &.coin-notglobalbtn-backbtn {
    margin-right: 12px;
  }

  &.game-list-topbar {
    /*  margin-left: auto; */
    /* margin-right: 10px; */
  }

  &.style {
    right: 50px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  .icon {
    fill: white !important;
  }

  .icon-style {
    width: 15px;
    height: 15px;
    color: ${theme.text.primary};
    @media (max-width: 360px) {
      width: 12px;
      height: 12px;
    }
  }

  .token-svg {
    z-index: 999;
    fill: ${theme.foreground.primary};
  }

  .label-sty {
    color: ${theme.text.primary};
    font-size: 15px;
    font-weight: 600;
    @media (max-width: 360px) {
      font-size: 12px;
    }
    @media screen and (min-width: 1022px) {
      font-size: 15px;
    }
  }

  &.not-globalbackbtn-coin {
    margin-left: auto;
  }

  &.not-globalbackbtn {
    /* margin-right: 12px; */
    margin-left: 0 !important;
  }

  &.not-globalbtn-backbtn-coin {
    margin-left: auto;
  }

  &.not-globalbtn-coin {
    margin-left: auto;
  }
`;