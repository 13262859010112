import React from "react";
import { css, keyframes } from "@emotion/react";

interface ITextMarqueeProps {
  activationLength?: number;
  width: number;
  fullWidth?: boolean;
  text: string;
  duration?: number;
  repeat?: number;
  responsive?: {
    [key: number]: {
      width: number;
    };
  };
}

export default function TextMarquee({
  activationLength = 15,
  duration = 5,
  repeat = 4,
  width,
  text,
  responsive,
  fullWidth = false,
}: ITextMarqueeProps) {
  if (text?.length < activationLength) return <span>{text}</span>;

  const marqueeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-29.8%);
  }
`;

  const marqueeCSS = css`
    display: inline-block;
    white-space: nowrap;
    animation: ${duration * 3}s ${marqueeAnimation} infinite linear;
  `;

  let textToRender = "";
  for (let i = 0; i < repeat + 1; i++) textToRender += `${text} `;
  textToRender = textToRender.trim();

  let spanStyle = `
    width: ${fullWidth ? "100%" : width + "px"};
    max-width: ${fullWidth ? "100%" : width + "px"};
    overflow: hidden;
    display: inline-block;

    .marquee-wrap .marquee-text {
      padding-right: 4px;
    }
  `;

  if (responsive) {
    Object.keys(responsive).map((r: any) => {
      spanStyle += `     
        @media (min-width: ${r}px) {
          max-width: ${responsive[r].width}px;
          width: ${responsive[r].width}px;
        }
      `;
      return null;
    });
  }

  return (
    <span css={css(spanStyle)} className="text-marquee">
      <span css={marqueeCSS}>{textToRender}</span>
    </span>
  );
}
