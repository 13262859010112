import { css } from "@emotion/react";

const presenteStyles = css`
  &.presente {
    .desk-width {
      .icon-container {
        .sub-button {
          background: #ffea00 !important;
          color: #ffffff !important;
        }
      }
    }

    .half-style {
      background: #174f81 !important;

      .label-style {
        color: #174f81 !important;
      }

      .latest-score {
        color: #ffe700 !important;
      }

      .score {
        color: #ffe700 !important;
      }
    }

    .player-rank-board {
      .board-container {
        .first-board,
        .middle-board,
        .last-board {
          .tile {
            fill: #1d71b8 !important;
          }
        }
      }
    }
  }
`;

export default presenteStyles;
