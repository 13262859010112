import { isOoredoo } from "utils/applicationSlug";
import OoredooPaymentService from "./OoredooPaymentService";
import GoamaPaymentService from "./goama/GoamaPaymentService";
class WheelPaymentService extends GoamaPaymentService {
  public async initiatePayment({ extraData }: { extraData?: any }) {
    if (!extraData) return;
    this.extraData = extraData;
    return new Promise((resolve, reject) => {
      (async () => {
        if (isOoredoo) {
          try {
            const ooredooPS = new OoredooPaymentService({
              country: this.country,
              application: this.application,
            });
            ooredooPS.extraData = extraData;
            const response = await ooredooPS.initiateSpinPayment();
            resolve(response);
          } catch (e) {
            reject(e);
          }
        }
      })();
    });
  }
}

export default WheelPaymentService;
