import { css } from "@emotion/react";

const mokStyles = css`
  &.mok {
    .desk-width {
      .icon-container {
        .sub-button {
          background: #168b56 !important;
          color: #ffffff !important;
        }
      }
    }

    .user-profile-customization {
      .support-style {
        .editor-icon {
          fill: #168b56 !important;
        }
      }
    }

    .browse-popup {
      .gamename-style {
        color: #000000 !important;
      }
    }

    .partner-status-bar {
      .homepage-background,
      .userprofile-background {
        background-color: #168b56 !important;
        box-shadow: none !important;
      }

      .icon-style {
        color: #ffffff !important;
      }
    }

    .partner-status {
      .label-sty {
        color: #ffffff !important;
      }
    }

    .active_progress_bottom_curve {
      background-color: #168b56 !important;
    }

    .square-card {
      .action_button {
        button {
          color: #168b56 !important;
        }
      }
    }

    .circle_image {
      .currencyIcon {
        color: #168b56 !important;
        padding-left: 0 !important;
      }
    }
    .desktop-container {
      .custom-popup {
        background-color: #e6e6e6;
      }
    }
    .registration-text {
      color: #000 !important;
    }

    .progressbar_wrapper {
      .circle_content {
        .helper_text {
          color: #168b56 !important;
        }
      }
    }

    .small_button {
      .button_icon {
        svg {
          path {
            fill: #ffffff !important;
          }
        }
      }
    }
    .top-tournament-card {
      .clock-participant-text {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
`;

export default mokStyles;
