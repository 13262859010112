import api from "actions/api";
// import authAPI from "actions/api/auth";
class GuestStorage {
  storageKey = "guest";

  getStorageData = () => {
    return JSON.parse(sessionStorage.getItem(this.storageKey) || "{}");
  };

  storeData = (data: any) => {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  };

  joinTournament = (tournamentId: number) => {
    const data = this.getStorageData();
    const tournaments = data?.tournaments || [];
    const existingTournament = tournaments.find(
      (t: any) => t?.id === tournamentId
    );
    const currentTime = new Date();
    if (existingTournament) {
      existingTournament.game_play_count++;
      /* tournaments.map((element: any) => {
          if(tournamentId === existingTournament.id) {
              return existingTournament;
          }
          return element;
      }); */
    } else {
      tournaments.push({
        id: tournamentId,
        game_play_count: 1,
        joined_date_time: currentTime.toISOString(),
        events: []
      });
    }
    this.storeData({ ...data, tournaments });
  };

  gameOver = ({
    tournamentId,
    score,
    duration,
    timestamp
  }: {
    tournamentId: number;
    score: number;
    duration: number | null;
    timestamp: any;
  }) => {
    const data = this.getStorageData();
    const tournaments = data?.tournaments || [];
    const existingTournament = tournaments.find(
      (t: any) => t?.id === tournamentId
    );
    if (existingTournament) {
      existingTournament.events.push({
        event_type: "game-over",
        duration,
        score,
        timestamp,
        checksum: "checksum"
      });
      this.storeData({ ...data, tournaments });
    }
  };

  getMaxScore = (tournamentId: number) => {
    const data = this.getStorageData();
    const tournaments = data?.tournaments || [];
    const existingTournament = tournaments.find(
      (t: any) => t?.id === tournamentId
    );

    const events = existingTournament?.events || [];
    if (existingTournament && events.length > 0) {
      const latestScore = events[events.length - 1]?.score || 0;
      let maxScore = latestScore;
      events.forEach((element: any) => {
        if (element.score > maxScore) maxScore = element.score;
      });
      return maxScore;
    }
    return null;
  };

  getRankingsFromMaxScore = (tournamentId: number, rankings: any[]) => {
    const maxScore = this.getMaxScore(tournamentId);
    const result = [...rankings];
    if (!maxScore) return { rankings: result, userRank: null };

    let insertIndex = rankings.findIndex((r: any) => r.value < maxScore);
    if (insertIndex === -1) {
      if (rankings.length === 0) insertIndex = 0;
      else if (rankings.length === 100) insertIndex = 100;
      else insertIndex = result.length;
    }
    const userRank = {
      rank: insertIndex < 100 ? insertIndex + 1 : `100+`,
      participant: { extra_data: { name: "Guest" } },
      value: maxScore,
      timestamp: maxScore.timestamp,
      guest: true
    };

    result.splice(insertIndex, 0, userRank);
    for (let i = insertIndex + 1; i < result.length; i++) {
      result[i].rank++;
    }

    return {
      rankings: result,
      userRank
    };
  };

  joinUser = () => {
    const storageData = this.getStorageData();
    const currentTime = new Date().toISOString();
    if (storageData.extra && !storageData.extra.user_joined) {
      this.storeData({
        ...storageData,
        extra: {
          ...storageData.extra,
          user_joined: currentTime
        }
      });
    } else if (!storageData.extra) {
      this.storeData({
        ...storageData,
        extra: {
          user_joined: currentTime
        }
      });
    }
  };

  syncData = () => {
    const data = this.getStorageData();
    if (Object.keys(data).length !== 0) {
      api.post("auth/action/", { action: { ...data } });
    }
    this.clearData();
  };
  clearData = () => {
    sessionStorage.removeItem(this.storageKey);
  };
  totalGameCount = () => {
    let total = 0;
    const data = this.getStorageData();
    const tournaments = data?.tournaments || [];
    tournaments.forEach((element: any) => {
      total += element?.game_play_count || 0;
    });
    return total || 0;
  };
  totalGameCountByTournamentId = (tournamentId: number) => {
    let total = 0;
    const data = this.getStorageData();
    const tournaments = data?.tournaments || [];
    tournaments.forEach((element: any) => {
      if (element?.id === tournamentId) {
        total += element?.game_play_count || 0;
      }
    });
    return total || 0;
  };
}

export default new GuestStorage();
