import React from "react";
// import paymentsAPI from "actions/api/payments";
import { css } from "@emotion/react";
import { sdkResources } from "./resources";
import { generateRedirectURL } from "hooks/usePlayNowButton";
import Store from "redux/Store";
import { getUserServiceBaseUrl } from "actions/api/utils";
import axios from "axios";
import { updatePartnerPaymentStatus } from "redux/reducers/paymentReducer";
import api from "actions/api";

let paymentID = "";
let reference = "";

export const triggerBkash = () => {
  const createdButton: HTMLElement | null = document.querySelector("#bKash_button");
  if (createdButton) {
    createdButton.click();
    createdButton.remove();
    return;
  }
  throw new Error("Could not find bkash button on document");
};

export const createBkashButton = () => {
  const button = document.createElement("button");
  button.style.display = "none";
  button.id = "bKash_button";
  const body = document.querySelector("body");
  if (body) {
    body.appendChild(button);
    return;
  }
  throw new Error("Could not find document body to attach bkash button");
};

export const initBkash = (tournament: ITournament) => {
  const state = Store.getState();

  const { application, country, user } = state.common;

  // const { entry_fee_type, entry_fee } = tournament;
  // console.log({ entry_fee, entry_fee_type });
  bKash?.init({
    paymentMode: "checkout", //fixed value ‘checkout’
    //paymentRequest format: {amount: AMOUNT, intent: INTENT}
    //intent options
    //1) ‘sale’ – immediate transaction (2 API calls)
    //2) ‘authorization’ – deferred transaction (3 API calls)
    paymentRequest: {
      amount: `${tournament?.entry_fee}`, //max two decimal points allowed
      intent: "sale",
    },

    onClose() {
      const frameWrapper: HTMLElement | null = document.querySelector("#bKashFrameWrapper");
      if (frameWrapper) {
        frameWrapper.remove();
      }
      axios
        .post(`${getUserServiceBaseUrl()}/api/v2/tournaments/callbacks/bkash/`, {
          paymentID: paymentID,
          reference: reference,
          amount: tournament?.entry_fee,
          status: "cancel",
        })
        .then(response => {
          Store.dispatch(updatePartnerPaymentStatus(response?.data) as any);
          return response.data;
        })
        .catch(() => {
          // dispatch({ type: ActionTypes.FETCH_USER_TOURNAMENTS_ERROR });
          // throw e;
        });
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createRequest: (request: any) => {
      // console.log("=> createRequest");
      // //request object is basically the paymentRequest object, automatically pushed by the script in createRequest method
      // paymentsAPI
      //   .post(`init/bkash/`, {
      //     amount: 20,
      //     tournament: 317728,
      //     redirect_link:
      //       "http://localhost:3000/tournament?app=bkash&country=bd&userid=gg-aziza888"
      //   })
      //   .then((data: any) => {
      //     paymentID = data.data.paymentID;
      //     bKash.create().onSuccess(data.data); //pass the whole response data in bKash.create().onSucess() method as a parameter
      //   })
      //   .catch(e => {
      //     console.log("cerate error ===================", e);
      //     bKash.create().onError();
      //   });
    },
    executeRequestOnAuthorization: function () {
      api
        .post(`payments/execute/bkash/`, {
          paymentID,
        })
        .then((data: any) => {
          if (data && data.paymentID != null) {
            // window.location.href = "success.html"; //Merchant’s success page
            // paymentHandler.verifyPayment(tournament, paymentID);
          } else {
            bKash.execute().onError();
          }
        })
        .catch(() => {
          bKash.execute().onError();
        })
        .finally(() => {
          window.location.replace(
            generateRedirectURL(tournament, {
              country,
              callback: true,
              app: application?.slug,
              userid: user?.username.split("__")[0],
              // tournament: tournament.id
            }),
          );
        });
    },
  });
};

export const bKashSdk: ISdkResource = {
  type: "external-script",
  goBackHome: () => window?.webViewJSBridge?.goBackHome("GOGAMES"),
  keepAlive: () => window?.webViewJSBridge?.keepSessionAlive("GOGAMES"),
  resourceLink: "https://capp-cdn.labs.bka.sh/scripts/webview_bridge.js",
  progressExitButton: (
    <button
      id="bkash_btn"
      type="button"
      onClick={() => sdkResources?.bkash?.goBackHome && sdkResources?.bkash?.goBackHome()}
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        height: 50,
        fontSize: 18,
        borderRadius: 0,
        marginTop: "2%",
        color: "white",
        textAlign: "left",
        backgroundColor: "#E2136E",
        borderColor: "#E2136E",
      }}
    >
      Back to bKash App Home
      <img
        src="https://capp-cdn.labs.bka.sh/images/arrow.svg"
        alt="bkash arrow"
        style={{ float: "right", marginTop: "1%", paddingRight: "1%" }}
      />
    </button>
  ),
  postPaymentShowVerifyModal: false,
  postInitCallback: () => {
    /* import("jquery").then((result: any) => {
      // window.$ = result.default;
      const script = document.createElement("script");
      script.src = "";
      if (isInProductionMode()) {
        script.src = "https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js";
      } else {
        script.src = "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js";
      }
      // "https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js"; // for Production env
      // "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js"; // for Staging env
      script.id = "bKash";
      script.async = false;
      document.body.appendChild(script);
    }); */
    /** IMPORT BKASH WEBVIEW BRIDGE JS TO PREVENT SESSION TIMEOUT AFTER 30 MINUTES */
    /* const script = document.createElement("script");
    script.src = "https://cdn.capp.bka.sh/scripts/webview_bridge.js";
    script.id = "bKash_webview_bridge";
    script.async = false;
    document.body.appendChild(script); */
  },
  prePaymentConfirmationCallback: tournament => {
    createBkashButton();
    initBkash(tournament);
    triggerBkash();
  },
  postPaymentConfirmationCallback: response => {
    paymentID = response.paymentID;
    reference = response.reference;
    bKash.create().onSuccess(response);
  },
  customStyles: () => css`
    body {
      #bKashFrameWrapper {
        /* height: 100vh !important;
        position: fixed !important;
        z-index: 999999999 !important;
        width: 100vw !important;
        top: 0 !important; */
      }
    }
  `,
};

export default bKashSdk;
