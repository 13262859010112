import React, { useContext, useEffect, useState } from "react";
import { css, useTheme } from "@emotion/react";
import cn from "classnames";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { CommonModal } from "../../Modals/CommonModal";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { SdkContext } from "providers/client-sdk-providers";
import { BuyTokenModal } from "../../Modals/BuyTokenModal";
import { APP_SLUGS } from "constants/constants";
import PaymentVerificationModal from "./PaymentVerificationModal";
import { useHistory } from "hooks/useHistory";
import { showLoginPopup } from "actions";
import { useLocation } from "react-router-dom";
import {
  isRegularTournament,
  isTournamentEntryFeeVoucher,
  isTournamentTryBased,
  isPVPTournament,
} from "utils/tournamentUtils";
import EntryFee from "components/common/EntryFee";
import { type IPaymentArgs } from "services/payment";
import VoucherPaymentModal from "../VoucherPaymentModal";
import { slugifyStringForTranslation } from "utils";
import OoredooPaymentTypeModal from "../OoredooPaymentModal/OoredooPaymentTypeModal";
import PaymentProvider from "services/payment/PaymentProvider";
import PaymentErrorModal from "./PaymentErrorModal";
import { resetPaymentState, updatePaymentStatus } from "redux/reducers/paymentReducer";
import { isBkash, isBonoxs, isDana, isGoogly, isOoredoo, isPepsico, isTng } from "utils/applicationSlug";
import { sleep } from "actions/api/utils";
import { Watch } from "react-loader-spinner";
import { toggleGameListPopup } from "redux/reducers/modalReducer";

const PaymentModal = ({
  show,
  onHide = () => {},
  tournament,
  amount,
  onShow = () => {},
  onSuccess = () => {},
}: any) => {
  const { search: queryParams } = useLocation();
  const { application } = useAppSelector((state: any) => state.common);
  const { balance: ticketBalance } = useAppSelector((state: any) => state.statusPoint);
  const { balance: partnerBalance } = useAppSelector((state: any) => state.partnerBalance);
  const { balance } = useAppSelector((state: any) => state.walletToken);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { useSuspense: false });

  const [modalVisibility, setModalVisibility] = useState(false);
  const [payment, setPayment] = useState<IPaymentArgs | null>(null);
  const sdk = useContext(SdkContext);
  const { isRazerGuestUser = null } = sdk;

  const isFsecureEnabled = isTng && tournament?.entry_fee_type === "f-secure-sub";
  const directPaymentEnabled = isBkash || isFsecureEnabled; // NOTE: In future add more checks like isBkash || isOredoo

  const { partnerPaymentStatus } = useAppSelector((state: any) => state.paymentState);

  const [showInsufficientBalance, setInsufficientBalance] = useState(false);
  const [paymentModalState, setPaymentModalState] = useState<"success" | "verification" | "error" | null>();
  const [buttonLoading, setButtonLoading] = useState(false);

  const [ageRestriction, setAgeRestriction] = useState(false);

  useEffect(() => {
    setModalVisibility(show);
    let params: any = { sdk };
    if (show) {
      if (payment) {
        payment.paymentReferenceCode = tournament?.payment_reference;
      } else {
        if (tournament?.payment_reference) {
          setPaymentModalState("verification");
          params = { paymentReferenceCode: tournament?.payment_reference, ...params };
        }
        const paymentService = PaymentProvider.getPaymentService(tournament, {
          ...params,
        });
        setPayment(paymentService);
      }
    } else {
      setPayment(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (partnerPaymentStatus?.status === "CANCELED") {
      dispatch(resetPaymentState());
      // dispatch(updatePartnerPaymentStatus(null) as any);
    }
  }, [dispatch, partnerPaymentStatus?.status]);

  useEffect(() => {
    setTimeout(() => {
      if (queryParams.includes("ordid")) {
        setPaymentModalState("verification");
        onShow();
        localStorage.removeItem("tournament-callback");
      }
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, isOoredoo]);

  useEffect(() => {
    if (queryParams.includes("callback=true")) {
      setPaymentModalState("verification");
      onShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (modalVisibility && directPaymentEnabled && paymentModalState !== "verification") {
      if (isTournamentTryBased(tournament)) {
        trialTournamentCheckBalance(tournament);
      } else {
        checkBalance({ tournament });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisibility]);

  // useEffect(() => {
  //   if (payment && tournament?.payment_reference) {
  //     // setModalVisibility(true);
  //     setPaymentModalState("verification");
  //   }

  // }, [tournament?.payment_reference, payment]);

  function checkBalance({ tournament }: any) {
    if (isPepsico && tournament?.entry_fee_type === "cash") {
      // NOTE - Handle the case where entry_fee_type is "cash" and app is "pepsico"
      checkTournamentCashBalanceAvailability({ tournament });
    } else {
      switch (tournament?.entry_fee_type) {
        case "coin":
          tournamentTypeCoinPayment({ tournament });
          break;
        case "ticket":
          tournamentTypeTicketPayment({ tournament });
          break;
        default:
          tournamentTypeCashPayment({ tournament });
          break;
      }
    }
  }

  const trialTournamentCheckBalance = (tournament: any) => {
    if (
      application?.applicationsetting?.enable_coin ||
      (application?.applicationsetting?.enable_user_journey && application?.applicationsetting?.enable_journey_country)
    ) {
      {
        /* // NOTE - Only for Pepsico Cash Balance Checking Added: should be available in wip/joy-2 */
      }
      /* if (tournament?.trial_packages?.length > 0 && (isPepsico || tournament?.trial_packages[0]?.currency !== "cash")) {
        if (
          isPepsico &&
          tournament?.trial_packages[0]?.currency === "cash" &&
          tournament?.trial_packages[0]?.currency_value <= partnerBalance?.partner_balance
        ) {
          handlePayment({ tournament });
        } else if (
          tournament?.trial_packages[0]?.currency === "ticket" &&
          tournament?.trial_packages[0]?.currency_value <= ticketBalance.ticket_balance
        ) { */
      if (tournament?.trial_packages?.length > 0 && (isPepsico || tournament?.trial_packages[0]?.currency !== "cash")) {
        if (
          isPepsico &&
          tournament?.trial_packages[0]?.currency === "cash" &&
          tournament?.trial_packages[0]?.currency_value <= partnerBalance?.partner_balance
        ) {
          handlePayment({ tournament });
        } else if (
          tournament?.trial_packages[0]?.currency === "ticket" &&
          tournament?.trial_packages[0]?.currency_value <= ticketBalance.ticket_balance
        ) {
          handlePayment({ tournament });
        } else if (
          tournament?.trial_packages[0]?.currency === "coin" &&
          tournament?.trial_packages[0]?.currency_value <= balance.balance
        ) {
          handlePayment({ tournament });
        } else {
          setInsufficientBalance(true);

          // onHide();
        }
      } else if (tournament?.trial_packages?.length > 0 && tournament?.trial_packages[0]?.currency === "cash") {
        handlePayment({ tournament });
      }
    }
  };

  // const { balance: partnerBalance } = useAppSelector((state: any) => state.partnerBalance);

  const tournamentTypeCashPayment = ({ tournament }: any) => {
    if (tournament?.entry_fee > 0) {
      if (sdk?.prePaymentConfirmationCallback) {
        sdk.prePaymentConfirmationCallback(tournament);
      }

      handlePayment({ tournament });
    }
  };

  const checkTournamentCashBalanceAvailability = ({ tournament }: any) => {
    if (isRegularTournament(tournament) || isPVPTournament(tournament)) {
      if (tournament?.entry_fee > partnerBalance?.partner_balance) {
        return setInsufficientBalance(true);
      } else {
        handlePayment({ tournament });
      }
    }
  };

  const tournamentTypeCoinPayment = ({ tournament }: any) => {
    if (isRegularTournament(tournament)) {
      if (tournament?.entry_fee > balance.balance) {
        return setInsufficientBalance(true);
      } else {
        handlePayment({ tournament });
      }
    } else if (isPVPTournament(tournament)) {
      handleFreeInstantPVPGame(tournament);
      if (application?.applicationsetting?.enable_coin && tournament?.entry_fee_type === "coin") {
        if (tournament.entry_fee <= balance.balance) {
          handlePayment({ tournament });
        } else {
          setInsufficientBalance(true);
        }
      }
    }
  };

  const tournamentTypeTicketPayment = ({ tournament }: any) => {
    if (isRegularTournament(tournament)) {
      if (tournament?.entry_fee > ticketBalance.ticket_balance) {
        return setInsufficientBalance(true);
      } else {
        handlePayment({ tournament });
      }
    } else if (isPVPTournament(tournament)) {
      handleFreeInstantPVPGame(tournament);

      if (isRazerGuestUser) {
        dispatch(showLoginPopup());
      } else if (
        application?.applicationsetting?.enable_user_journey &&
        application?.applicationsetting?.enable_journey_country
      ) {
        if (tournament?.entry_fee_type === "ticket" && tournament?.entry_fee <= ticketBalance.ticket_balance) {
          handlePayment({ tournament });
        } else {
          setInsufficientBalance(true);
        }
      }
    }
  };

  const handleFreeInstantPVPGame = ({ tournament }: any) => {
    if (tournament?.entry_fee === 0) {
      history.push(`/pvp-page/${tournament?.id}`);
      dispatch(toggleGameListPopup());
      return;
    }
  };

  const theme: ITheme = useTheme();

  const handlePayment = async ({ tournament, extraData = {} }: any) => {
    let params: any = {
      tournament,
      extraData,
    };

    if (tournament && tournament?.entry_fee_type === "ticket") {
      params = {
        tournament,
        extraData: {
          ticket: true,
          ...extraData,
        },
      };
    }

    setButtonLoading(true);
    await sleep(1000);

    return payment
      ?.initiatePayment(params)
      .then((response: any) => {
        if (response?.initiated === true || response?.data?.initiated === true) {
          if (sdk?.postPaymentConfirmationCallback && tournament?.entry_fee_type === "cash") {
            sdk.postPaymentConfirmationCallback(response);
          } else if (window.top && response?.top_redirect) {
            window.top.location.href = response?.checkoutURL || response?.deeplinkURL;
          } else if (
            (response?.deeplinkURL && response?.deeplinkURL !== "#") ||
            (response?.checkoutURL && response?.checkoutURL !== "#")
          ) {
            window.location.href = response?.deeplinkURL || response?.checkoutURL;
          } else {
            setPaymentModalState("verification");
            dispatch(updatePaymentStatus("verification"));
          }
        }
        return response;
      })
      .catch((error: any) => {
        if (error?.response?.data?.error_title === "Age Restriction") setAgeRestriction(true);
        setPaymentModalState("error");
        dispatch(updatePaymentStatus("error"));
      })
      .finally(() => {
        // setButtonLoading(false);
        setButtonLoading(false);
      });
  };

  if (isTournamentEntryFeeVoucher(tournament)) {
    return (
      <VoucherPaymentModal
        tournament={tournament}
        onSuccess={onSuccess}
        show={show}
        onHide={() => {
          onHide();
        }}
        type="payment"
      />
    );
  }

  const payAmount = () => {
    if (isTournamentTryBased(tournament)) {
      return (
        <EntryFee
          entry_fee={tournament?.trial_packages?.[0]?.currency_value}
          foodpandaAppIconColor={application?.slug === APP_SLUGS.FOODPANDA_APP}
          payMethod={tournament?.trial_packages?.[0]?.currency}
          trialMethod
        />
      );
    } else if (isGoogly) {
      return (
        <EntryFee
          entry_fee={tournament?.entry_fee}
          payMethod={tournament?.entry_fee_type}
          tournamentFeeCurrency={tournament?.entry_fee_type}
        />
      );
    } else {
      return <b>{amount}</b>;
    }
  };

  if (isOoredoo && !paymentModalState && show) {
    return (
      <OoredooPaymentTypeModal
        loading={buttonLoading}
        onSuccess={onSuccess}
        tournament={tournament}
        // application={application}
        // type={setOoredooPaymentType}
        onPayment={handlePayment}
        // clickBtn={setClickOoredooPaymentBtn}
        show={show}
        onHide={() => {
          onHide();
          setPaymentModalState(null);
        }}
      />
    );
  }

  const getConfirmationPromptTranslatedTextWithNumber = () => {
    const pepsicoPaidModalText = `Are you want to pay ${payAmount().props.entry_fee} ?`;
    let n;
    const numberMatch = pepsicoPaidModalText?.match(/(\d+)/);
    if (numberMatch) {
      n = numberMatch[0];
    }
    return t(slugifyStringForTranslation(pepsicoPaidModalText?.replace(/\d+/g, "{{n}}")), {
      defaultValue: pepsicoPaidModalText,
      n: n,
    });
  };

  const getConfirmationPromptText = () => {
    const watchColor = isBonoxs ? "#000000" : theme.button.primaryGradientStart;

    if (buttonLoading) {
      return (
        <div className="w-100">
          <Watch
            height="80"
            width="80"
            radius="48"
            color={watchColor}
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClass="justify-content-center"
            visible={true}
          />
        </div>
      );
    }
    if (isPepsico) {
      return (
        <span className={isPepsico && "font-width"}>
          {getConfirmationPromptTranslatedTextWithNumber()}{" "}
          <EntryFee payMethod={tournament?.entry_fee_type} onlyCurrencyIcon={true} /> ?
        </span>
      );
    }
    return (
      <>
        <Trans i18nKey="are-you-sure-yo">Are you sure you want to pay</Trans>&nbsp;{payAmount()}?
      </>
    );
  };

  return (
    <>
      <CommonModal
        hideCrossIcon={buttonLoading}
        show={modalVisibility && !paymentModalState && !directPaymentEnabled}
        onHide={onHide}
        // css={voucherModalDesign}
        header={<Trans i18nKey="payment-confirm">Payment Confirmation</Trans>}
        footer={
          <div>
            {!(buttonLoading && isPepsico) && (
              <Button disabled={buttonLoading} css={buttonStyle} className={`cancel-btn me-2`} onClick={onHide}>
                {isDana ? <Trans i18nKey="cancel">CANCEL</Trans> : <Trans i18nKey="cancel">Cancel</Trans>}
              </Button>
            )}

            <Button
              disabled={buttonLoading}
              css={buttonStyle}
              className={cn("confirm-btn confirm-btn--payment", {
                "confirm-btn--loading": buttonLoading,
              })}
              onClick={() => {
                if (!buttonLoading) {
                  if (isTournamentTryBased(tournament)) {
                    trialTournamentCheckBalance(tournament);
                  } else {
                    checkBalance({ tournament });
                  }
                }
              }}
            >
              {isDana ? <Trans i18nKey="confirm">CONFIRM</Trans> : <Trans i18nKey="confirm">Confirm</Trans>}
            </Button>
          </div>
        }
      >
        <div className="text-center title-md payment-class" css={modalTextCss}>
          <div
            className={cn("d-flex col-12 p-0", {
              "justify-content-center": isPepsico,
            })}
          >
            {getConfirmationPromptText()}
          </div>
        </div>
      </CommonModal>

      {showInsufficientBalance && (
        <BuyTokenModal
          show={showInsufficientBalance}
          onHide={() => {
            setInsufficientBalance(false);
          }}
          entryFeeType={tournament?.entry_fee_type}
          tournament={tournament}
        />
      )}

      {paymentModalState === "verification" && payment && (
        <PaymentVerificationModal
          tournament={tournament}
          show={paymentModalState === "verification"}
          paymentService={payment}
          onSuccess={onSuccess}
          onHide={() => {
            // setVerificationModalVisibility(false);
            setPaymentModalState(null);
            dispatch(updatePaymentStatus(null));
            onHide();
          }}
        />
      )}

      {paymentModalState === "error" && payment && (
        <PaymentErrorModal
          // tournament={tournament}
          // type={tournament}
          show={paymentModalState === "error"}
          // paymentService={payment}
          // onSuccess={onSuccess}
          onHide={() => {
            // setVerificationModalVisibility(false);
            setPaymentModalState(null);
            dispatch(updatePaymentStatus(null));
            onHide();
          }}
          message={ageRestriction ? "Para ingresar, debes ser mayor de edad" : ""}
        />
      )}
    </>
  );
};

export default PaymentModal;

const modalTextCss = () => css`
  &.payment-class {
    font-size: 14px;

    @media (min-width: 320px) and (max-width: 380px) {
      font-size: 13px;
    }
  }

  .font-width {
    width: 100%;

    @media (min-width: 320px) and (max-width: 750px) {
      font-size: 80%;
    }
  }
`;

const buttonStyle = (theme: ITheme) => css`
  border: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
  padding: 8px 20px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  background: linear-gradient(
    270deg,
    ${theme.button.primaryGradientStart} 0%,
    ${theme.button.primaryGradientStop} 122.67%
  );

  &:hover {
    text-decoration: none;
    color: white;
  }
  &.confirm-btn--loading {
    /* loading specific styles here */
    filter: brightness(0.2);
  }
`;
