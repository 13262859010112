import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import redeemAPI from "actions/api/redeem";
// import rewardsAPI from "actions/api/rewards";

export interface IReward {
  coupon_list: null | string;
  user_reward: null | string;
  loading: boolean;
  error: boolean;
}

const initialState: IReward = {
  coupon_list: null,
  user_reward: null,
  loading: false,
  error: false,
};

const statusSlice = createSlice({
  name: "prizeRedemption",
  initialState,
  reducers: {
    fetchPrizeRedemptionLoading(state) {
      state.loading = true;
    },
    fetchPrizeRedemptionSuccess(state, action) {
      state.coupon_list = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchUserRewardSuccess(state, action) {
      state.user_reward = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchPrizeRedemptionError(state) {
      state.coupon_list = null;
      state.loading = false;
      state.error = true;
    },
    fetchUserRewardError(state) {
      state.user_reward = null;
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchPrizeRedemptionLoading,
  fetchPrizeRedemptionSuccess,
  fetchUserRewardSuccess,
  fetchPrizeRedemptionError,
  fetchUserRewardError,
} = statusSlice.actions;

export default statusSlice.reducer;

export const fetchPrizeRedemption = (slug: string, country: string, userid: string) => async (dispatch: AppDispatch) => {
  if (!slug || !country || !userid) return;
  dispatch(fetchPrizeRedemptionLoading());
  return api
    .get(`rewards/${slug}/${country}/${userid}/`)
    .then(response => {
      dispatch(fetchPrizeRedemptionSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchPrizeRedemptionError()));
};

export const fetchUserRewards = (userid: string) => async (dispatch: AppDispatch) => {
  if (!userid) return;
  dispatch(fetchPrizeRedemptionLoading());
  return api
    .get(`user/redemptions/${userid}/`)
    .then(response => {
      dispatch(fetchUserRewardSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchUserRewardError()));
};
