import { css } from "@emotion/react";

const nurtureStyles = css`
  .narture-wrapper {
    position: absolute;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    z-index: 10000;
  }
  .footer-wrapper {
    position: relative;
    z-index: 9999;
  }
`;

export default nurtureStyles;
