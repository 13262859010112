import React from "react";
import { useTheme } from "@emotion/react";
import Spinner from "react-bootstrap/Spinner";
interface LoadingIndicatorProps {
  size: number;
  color?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ size, color }) => {
  const theme = useTheme();

  return (
    <Spinner
      animation="grow"
      role="status"
      className="loading-style"
      style={{
        width: `${size}rem`,
        height: `${size}rem`,
        color: color ? color : theme?.button?.primaryGradientStart,
        transition: "color 0.2s ease-in-out",
      }}
    >
      <span className="sr-only" />
    </Spinner>
  );
};

export default LoadingIndicator;
