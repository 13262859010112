import { css } from "@emotion/react";

const skibidiToiletStyles = (theme: ITheme) => css`
  &.skibidi-toilet {
    .header-style {
      background-color: rgb(255, 188, 13) !important;
      .icon-style {
        color: #ffffff !important;
      }
    }
    .mobile-home-page {
      min-height: calc(100vh - 150px);
    }
    .desktop-footer {
      .footer-content {
        background-color: #000000;
        border-top: 1px solid #666666;
      }
      .active-link {
        .link-img {
          fill: rgb(255, 255, 255);
          color: rgb(255, 255, 255);
        }
        .link-label {
          color: rgb(255, 255, 255, 0.8);
        }
      }
      .link-img {
        fill: rgb(254, 238, 28);
        color: rgb(254, 238, 28);
      }
      .link-label {
        color: rgb(4, 208, 245);
      }
    }
    .label-sty {
      color: #04bcf5;
    }
    .rank-button {
      background-color: #ffffff;
      color: #000000 !important;
      font-weight: 700;
      box-shadow: 0px 14px 20px 1px rgba(219, 0, 7, 0.15);
    }
    .play-button {
      background-color: #fe5e23 !important;
      color: #ffffff;
      font-weight: 700;
    }
    .modal_header,
    .modal_body {
      background-color: #000000;
    }
    .modal_header {
      color: #ffffff;
    }
    .game-loading-overlay {
      background-color: #000000;
    }
    .ticket-status {
      box-shadow: none;
      background-color: rgb(254, 238, 28);
      .icon {
        svg {
          color: #04bcf5 !important;
        }
      }
    }
    .progress-share-overlay {
      .half-style {
        background: #000000;
      }
    }
    .card-win-prizes-text {
      padding-left: 50px;
    }
    .redeem {
      background-color: #fe5e23;
      color: #ffffff;
    }
    .top-bar-bottom {
      .content-table {
        p {
          color: #ffffff;
        }
      }
    }
    .payment-modal-2 {
      .modal-header,
      .body,
      .footer {
        background-color: #ffffff;
      }
    }
    .user-profile-wrapper {
      svg {
        box-shadow: none;
      }
    }
    .tns-inner {
      .header-first,
      .played-count {
        color: #ffffff;
      }
      .icon {
        color: #ffffff;
        fill: #ffffff;
      }
    }
    button {
      &.text-play {
        background-color: rgb(254, 238, 28);
        color: #04bcf5;
      }
      &.rank-btn {
        background-color: rgb(254, 238, 28);
        color: ${theme.text.tertiaryTextColor};
        .rank-icon {
          fill: ${theme.text.tertiaryTextColor};
        }
      }
    }
    .button-alignment {
      justify-content: center;
      margin-bottom: 8px;
    }
    .btn-style {
      background: rgb(254, 238, 28) !important;
      color: #fe5e23 !important;
      box-shadow: none;
    }
    .action_button {
      button {
        background-color: rgb(254, 238, 28);
        color: #04bcf5;
      }
    }
    .email-login-submit-btn {
      background-color: rgb(254, 238, 28) !important;
      color: #fe5e23 !important;
    }
    .navbar-home {
      &.dark {
        &.navbar-home-active {
          background: transparent;
        }
      }
    }
    .auth-btn {
      .button {
        &:hover {
          background: linear-gradient(270deg, #fe5e23 0%, #fe5e23 122.67%) !important;
          border-color: #fe5e23 !important;
          color: #ffffff !important;
        }
      }
    }
    .recommended-games {
      background-color: #000000;
      .header-style {
        background-color: transparent !important;
        color: #ffffff;
      }
    }
    .validation {
      .valid-till {
        display: none;
      }
    }
    .swiper-button-next-unique {
      display: none;
    }
    .redeem-banner-wrapper {
      background: ${theme.background.secondary};
      box-shadow: ${theme.background.primary} -9px -20px 42px 9px inset;
      border: 1px solid ${theme.background.secondary};
      color: ${theme.foreground.primary};
      .btn-redeem {
        margin-top: 5px;
        background-color: ${theme.button.tertiaryButtonBackground};
        color: ${theme.text.secondary};
      }
    }
    .close_wrapper {
      svg {
        fill: ${theme.foreground.primary};
      }
    }
    .contain-style {
      .title-md {
        color: ${theme.foreground.primary};
      }
    }
    .play-btn-browse {
      background: ${theme.button.tertiaryButtonBackground};
      .play-icon {
        fill: ${theme.text.secondary};
      }
    }
    .background-contain {
      .header-style {
        background-color: ${theme.button.primaryGradientStart} !important;
        color: ${theme.foreground.primary};
      }
    }
  }
`;

export default skibidiToiletStyles;
