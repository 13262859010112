import api from "actions/api";
// import subscriptionAPI from "actions/api/subscription";

export default class GoamaSubscriptionPaymentService {
  public subscription: ISubscriptionPayment | undefined;
  public extraData: any;

  public async initiatePayment({ subscription, extraData = {} }: { subscription: any; extraData?: any }) {
    this.subscription = subscription;
    this.extraData = extraData;
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          // Step 1: Initiate payment
          const initiateResponse = await api.post(`subscriptions/payment/init/`, {
            config_slug: subscription?.config_slug,
            package_id: subscription?.package_id,
          });

          // Step 2: Verify payment using the payment reference obtained in Step 1
          const paymentReference = initiateResponse?.data?.reference;
          const verifyResponse = await api.get(`subscriptions/${paymentReference}/payment/verify/`);

          // Resolve with the verification response
          resolve(verifyResponse.data);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }

  public async UnsubscribeService() {
    try {
      const res = await api.get(`subscriptions/user/unsubscribe/`);
      if (res?.data) {
        // Resolve with the response
        return res.data;
      }
    } catch (e) {
      console.error(e);
      // Reject with the error
      throw e;
    }
  }
}
