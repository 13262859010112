import React from "react";
import { useAppSelector } from "redux/Store";
import LeaderboardPopupItem from "components/feature-specific/leaderboard/LeaderboardPopUp/LeaderboardPopupItem";
import { RootState } from "redux/Store";

const LeaderboardPopup = () => {
  const { tournament } = useAppSelector((state: RootState) => state.layout);

  if (!tournament) return null;

  return <LeaderboardPopupItem />;
};

export default LeaderboardPopup;
