import { css } from "@emotion/react";

const poxipolStyles = css`
  &.poxipol {
    .leaderboard-popup-item {
      .prizes-slider-container {
        .upper-div {
          .title-header {
            color: #ffffff !important;
          }
        }
      }

      .lower-div {
        .lower-div-item {
          .lower-div-title {
            color: #ffffff !important;
          }
        }

        .lower-div-footer {
          color: #ffffff !important;
        }
      }

      .browse-popup {
        .contain-style {
          color: #ffffff !important;
        }
      }
    }

    .rank-list-container-first {
      .text-style-transaction {
        span {
          color: #ffffff !important;
        }
      }
    }

    .ranks-wrapper {
      .text-style-transaction {
        color: #ffffff !important;
      }
    }

    .half-style {
      .latest-score-label {
        color: #ffffff !important;
      }

      .latest-score {
        color: #ffffff !important;
      }
    }

    .ticket-status-bar {
      .label-sty {
        color: #000000 !important;
      }
    }

    .form-check-label {
      color: #000000 !important;
    }

    .prizes-slider-container {
      .prizes-slider-item {
        .upper-div {
          .title-header {
            color: #000000 !important;
          }
        }

        .lower-div {
          .lower-div-item {
            .lower-div-title,
            .lower-div-footer {
              color: #000000 !important;
            }
          }
        }
      }
    }

    .icon-container {
      .input-wrapper {
        input {
          color: #ffffff !important;
        }
      }
    }

    .profile-page-body {
      .show-all-text {
        color: #b0a8a4 !important;
      }
    }

    .sidebar-right-container {
      .history-container {
        .header-style {
          color: #b0a8a4 !important;
        }
      }
    }

    .leaderboard-popup-item {
      background: #001733 !important;

      .modal_header {
        background: #001733 !important;
      }

      .gamename-style {
        color: #b0a8a4 !important;
      }
    }

    #draggable-modal-content {
      background: #001733 !important;
    }

    [data-rsbs-overlay] {
      background: #001733 !important;
    }

    .desktop-browse-icon,
    .active-dark-bg > svg {
      fill: #f9de00 !important;
    }

    .swiper-pagination-clickable {
      display: none !important;
    }

    .sign-up,
    .update-button,
    .registration-text,
    .text-inactive-tournament {
      color: #f9de00 !important;
    }

    .icon-right > svg > path,
    .icon-play > path,
    .play-btn > svg > path {
      fill: #f5f5f5 !important;
    }

    .button.button-link:hover {
      color: white !important;
    }

    .subtitle-text {
      color: #202020 !important;
    }

    .recommended-tournament-container {
      display: none !important;
    }

    .mobile-footer-page .text-center {
      color: #f9de00 !important;
    }

    .terms-condition-page {
      padding-top: 0.5rem;
    }
  }
`;

export default poxipolStyles;
