import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import Modal from "react-bootstrap/Modal";

// import tournamentsAPI from "actions/api/tournaments";
import api from "actions/api";

const rewardPoint = (theme: ITheme) => css`
  z-index: 100004 !important;

  .modal {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    width: 90%;
    border-radius: 1.3rem;
  }

  .modal-header {
    border-bottom: none;
  }

  .text-style {
    font-size: 15px;
  }

  .header-color {
    color: ${theme.button.primaryGradientStart};
  }

  .close {
    font-size: 2.7rem;
    font-weight: 400 !important;

    &:focus {
      outline: none;
    }
  }
`;

export default function RewardPointModal() {
  const Url = new URL(window.location.href);
  const searchUrl = Url.search;
  const lastSegment: any = searchUrl.split("&").pop();
  const matchword: any = lastSegment.split("=").shift();
  const keyword: any = lastSegment.split("=").pop();
  const [rewardValue, setRewardValue] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (matchword === "uri_ref") {
      api
        .get(`tournaments/users/aplus-reward/verify/${keyword}/`)
        .then(response => {
          setShow(response.data.eligible);
          setRewardValue(response.data);
        })
        .catch((e: any) => console.error(e));
    }
    // eslint-disable-next-line
  }, []);

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        closeModal();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 100000 }}
      css={rewardPoint}
      className="desktop-modal p-0"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton className="pb-0" />
      <div className="share-container pb-5 text-center px-2">
        <span className="text-style">
          <Trans i18nKey="reward-point">
            {" "}
            <h4>
              <b className="header-color">
                <Trans i18nKey="congratulations">Congratulations!</Trans>
              </b>
            </h4>{" "}
            <h6>You have been rewarded {`${rewardValue.point}`} points </h6>
          </Trans>
        </span>
      </div>
    </Modal>
  );
}
