import { css } from "@emotion/react";
import EUCLID from "assets/fonts/Euclid/Euclid_Circular_B_Light.ttf";

const valepassStyles = css`
  @font-face {
    font-family: "Euclid";
    src: local("Euclid"), url(${EUCLID}) format("truetype");
  }

  &.valepass {
    .body,
    * {
      font-family: "Euclid";
    }

    .token-sty {
      .right-style {
        .userprofile-background {
          box-shadow: none !important;
        }
      }
    }

    .user-profile-wrapper {
      svg {
        box-shadow: none !important;
      }

      svg circle:nth-of-type(1) {
        stroke: #c567f2 !important;
      }
    }

    .history-border {
      h6 {
        color: #000000 !important;
      }
    }

    .user-profile-customization {
      .support-style {
        background: none !important;
      }
    }

    .avatar-update-modal {
      .avatarStyle {
        color: #000000 !important;
      }

      button {
        color: #e6d2c9 !important;
      }
    }

    .back-design {
      .label-sty {
        color: #000000 !important;
      }
    }

    .desktop-footer {
      .active-link {
        .link-label {
          color: #c567f2 !important;
        }
      }
    }
    .custom-page {
      background: #1b0c3f !important;
      .heroes {
        color: #e6d2c9 !important;
      }

      .winner-label {
        color: #e6d2c9 !important;
      }
    }

    .back-button-wrapper {
      svg {
        fill: #e6d2c9 !important;
        stroke: #e6d2c9 !important;
      }
    }

    .player-numb {
      svg {
        fill: #ffff !important;
      }
    }

    .leaderboard-popup-item {
      .modal_header {
        background: #1b0c3f !important;
      }

      .leaderboard-wrapper {
        .leaderboard-container {
          .modal_body {
            background: #1b0c3f !important;
          }
        }
      }
    }

    .leaderboard-popup-item {
      .gamename-style {
        color: #e6d2c9 !important;
      }
    }

    .rank-list-container-first {
      .text-style-transaction {
        color: #000000 !important;
      }
    }

    .rank-list-container {
      .list-group-item {
        .body-md {
          color: #000000 !important;
        }
      }
    }

    .show-all-text {
      color: #e6d2c9 !important;
    }

    .history-border {
      .list-group-item {
        color: #000000 !important;
      }
    }

    .history-container {
      .header-style {
        color: #e6d2c9 !important;
      }
    }

    .leaderboard-body {
      .header-line {
        color: #e6d2c9 !important;
      }
    }

    .rank-list-container {
      .text-style-transaction {
        color: #000000 !important;
      }
    }

    .cards-lists-container {
      .single-game-card {
        .square-card {
          background: #c567f2;
          .card-title {
            color: #ffffff !important;
          }
        }
      }
    }

    .progress_bottom_curve {
      background: #c567f2 !important;

      .game_info {
        .time_counter_title {
          color: #ffffff !important;
        }
      }

      .timer {
        span {
          color: #ffffff !important;
        }
        .clock_icon {
          span {
            color: #ffffff !important;
          }

          .clockIcon {
            fill: #ffffff !important;
          }
        }
      }
    }

    .progressbar_wrapper {
      .RCP__track {
        stroke: #c567f2 !important;
      }

      .circle_content {
        .small_circle {
          border: 7px solid #c567f2 !important;
        }
      }
    }

    .contain-style {
      .title-md {
        color: #e6d2c9 !important;
      }
    }

    .browse-popup {
      .gamename-style {
        color: #e6d2c9 !important;
      }
    }

    .button-alignment {
      .play-btn-browse-container {
        .rank-btn {
          border: 1px solid #000000;
        }
      }
    }

    .border-style {
      .name-sty {
        color: #000000;
      }
    }

    .name-sty {
      .user {
        fill: #000000;
      }

      .clock-icon {
        fill: #000000;
      }
    }

    .col-browse {
      .labelbold-style {
        .game-name {
          color: #000000;
        }
      }
    }

    .row {
      .subtitle-text {
        .rank-list-price,
        .list-price {
          color: #c567f2 !important;
        }
      }
    }

    .icon-container {
      .input-wrapper {
        input {
          background: #e8f0fe !important;
        }
      }
    }

    .registration-text {
      color: #000000 !important;
    }
  }
`;

export default valepassStyles;
