import React from "react";
import { css } from "@emotion/react";
import Store from "redux/Store";

import noRanksIcon from "assets/images/jazzcash-no-rank-image.svg";

const jazzcashSdk: ISdkResource = {
  goBackHome: () => {
    // console.log("JC back button");
    if (window.login?.navback) {
      // console.log("JC android navBack");
      window.login.navback();
    } else if (window?.webkit?.messageHandlers?.navBack?.postMessage) {
      // console.log("JC iOS navBack");
      window.webkit.messageHandlers.navBack.postMessage("");
    }
  },
  type: "built-in",
  postPaymentShowVerifyModal: false,
  postPaymentConfirmationCallback: payload => {
    /*
    payload structure
    {
          "amount": amount,
          "reference": payment.reference,
          "initiated": True,
          "redirect_url": generated_redirect_url,
          "auth_token": auth_token.token,
          "transaction_remarks": transaction_remarks
      } */
    const username = `${Store.getState()?.common?.user?.username}`;
    const processedUsername = username.split("__")[0];
    const debugOverlayElement = document.getElementById("debug-overlay");

    if (debugOverlayElement) {
      debugOverlayElement.innerHTML = `
        login = ${window.login}
        login.loadInstruments = ${window?.login?.loadInstruments}
        login.shareonsocialmedia = ${window?.login?.shareonsocialmedia}`;
    }
    if (window.login?.loadInstruments) {
      window?.login?.loadInstruments(
        payload?.reference,
        payload?.auth_token,
        processedUsername,
        payload?.amount,
        payload?.transaction_remarks,
        payload?.redirect_url
      );
    } else if (window.webkit?.messageHandlers?.loadInstruments) {
      window.webkit.messageHandlers.loadInstruments?.postMessage({
        reference: payload?.reference,
        sessionId: payload?.auth_token,
        userId: processedUsername,
        amount: payload?.amount,
        gameName: payload?.transaction_remarks,
        redirectUrl: payload?.redirect_url
      });
    }
  },
  shareHandler: (
    text: string,
    score?: number,
    url?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tournamentId?: number
  ) => {
    const shareMessage = `${text} ${url}`;
    if (window.login?.shareonsocialmedia) {
      window.login.shareonsocialmedia(shareMessage);
    } else if (window.webkit?.messageHandlers?.shareonsocialmedia) {
      window.webkit.messageHandlers.shareonsocialmedia.postMessage(
        shareMessage
      );
    }
  },
  customNoRanksIcon: (
    <img src={noRanksIcon} className="mx-auto" alt="No Ranks to Show" />
  ),
  customStyles: () => css`
    body {
      .leaderboard-popup-footer button {
        color: #ffc20c !important;
      }
      .tournament-list-popup {
        .gamename-style {
          color: #000000 !important;
        }
      }
      .tournament-list-item {
        .rank-btn {
          .rank-icon {
            fill: rgba(0, 0, 0, 1) !important;
          }
        }
      }
      .play-btn-browse {
        background: #ae413d !important;
      }

      .swiper-slide {
        .play-button {
          background: #ae413d !important;
        }
      }

      .browse-game-card {
        .triangle-button {
          background: #ae413d !important;
        }
      }

      .play-btn.browse-game-item {
        background: #ae413d !important;

        .play-icon {
          fill: #fff !important;
        }
      }

      .half-style {
        background: #000 !important;
      }

      .first-board,
      .middle-board,
      .last-board {
        svg {
          fill: #fff !important;
        }

        .label-style {
          background-color: rgba(0, 0, 0, 0.1) !important;
          .score {
            color: #000 !important;
          }
        }
        .name-style {
          color: #000 !important;
        }
      }

      .avatar-update-modal,
      .name-update-modal {
        .update-button {
          color: #ffc20c !important;
        }
      }

      .label-no-ranks {
        color: #2e2f30 !important;
        font-size: 16px;
        line-height: 20px;
      }
      .show-all-text,
      .competitor-name,
      .competitor-score,
      .own-score {
        color: #000000 !important;
      }
    }
  `
};

export default jazzcashSdk;
