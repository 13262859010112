import { AppDispatch } from "redux/Store";
// import { ActionTypes } from "./actionTypes";
import {
  showLoginPopup as baseShowLoginPopup,
  hideLoginPopup as baseHideLoginPopup,
} from "redux/reducers/layoutReducer";

export const showLoginPopup = () => (dispatch: AppDispatch) => {
  dispatch(baseShowLoginPopup());
}
export const hideLoginPopup = () => (dispatch: AppDispatch) => {
  dispatch(baseHideLoginPopup());
};
