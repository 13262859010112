import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
// import { ActionTypes } from 'actions/actionTypes';

export interface LoginState {
  token: string;
  exp: string;
  scopes: any[];
  loading: boolean;
  error: boolean;
}

const defaultState: LoginState = {
  token: '',
  exp: '',
  scopes: [],
  loading: false,
  error: false,
};

const authLinkKeySlice = createSlice({
  name: 'authLinkKey',
  initialState: defaultState,
  reducers: {
    fetchAuthAssignLoading(state) {
      state.loading = true;
      state.error = false;
    },
    fetchAuthAssignSuccess(state, action: PayloadAction<{ token: string; exp: string; scopes: any[] }>) {
      state.token = action.payload.token;
      state.exp = action.payload.exp;
      state.scopes = action.payload.scopes;
      state.loading = false;
      state.error = false;
    },
    fetchAuthAssignError(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { fetchAuthAssignLoading, fetchAuthAssignSuccess, fetchAuthAssignError } = authLinkKeySlice.actions;

export default authLinkKeySlice.reducer;

/* export const authLinkKey = (state: LoginState = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_AUTH_ASSIGN_LOADING:
      return { ...state, loading: true, error: false };
    case ActionTypes.FETCH_AUTH_ASSIGN_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: false
      };
    case ActionTypes.FETCH_AUTH_ASSIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}; */
