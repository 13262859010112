function calculateDurationInSec(startTime: Date, endTime: Date) {
  return (endTime.getTime() - startTime.getTime()) / 1000;
}

export default class GameTimer {
  startTime: null | Date = null;
  endTime: null | Date = null;
  duration: number | null = null;
  allDurations: number[] = [];

  private tempDuration = 0;

  reset = () => {
    this.startTime = null;
    this.endTime = null;
    this.duration = null;
    this.allDurations = [];
    this.tempDuration = 0;
  };

  start = () => {
    this.startTime = new Date();
  };

  stop = () => {
    this.endTime = new Date();
    if (this.startTime) {
      this.duration =
        this.tempDuration +
        calculateDurationInSec(this.startTime, this.endTime);
    } else {
      this.duration = this.tempDuration;
    }
    this.allDurations.push(this.duration);
    this.startTime = null;
    this.tempDuration = 0;
  };

  pause = () => {
    if (this.startTime) {
      this.tempDuration += calculateDurationInSec(this.startTime, new Date());
      this.startTime = null;
    }
  };

  resume = () => {
    this.startTime = new Date();
  };

  private formatDuration(duration: number) {
    return parseFloat(duration.toFixed(2));
  }

  get totalDuration() {
    const total = this.allDurations.reduce((a, b) => a + b, 0);
    return this.formatDuration(total);
  }

  get averageDuration() {
    if (this.allDurations.length) {
      const avg = this.totalDuration / this.allDurations.length;
      return this.formatDuration(avg);
    }
    return null;
  }
}
