import React, { useReducer, useEffect } from "react";
import { useAppSelector } from "redux/Store";
import Webfont from "webfontloader";
import {type Theme, ThemeProvider as EmotionThemeProvider } from "@emotion/react";

const defaultTheme: Theme = {
  layout: "TRIANGLE",
  font: "Poppins",
  background: {
    primary: "#f9f9f8",
    secondary: "#eeeded",
    tertiary: "#eeeded",
    gradientStart: "#8B00F0",
    gradientStop: "#250040",
    layout: "#f9f9f8"
  },
  foreground: {
    primary: "#ffffff",
    secondary: "#131010"
  },
  text: {
    primary: "#332927",
    secondary: "#706967",
    tertiaryTextColor: "#706967"
  },
  button: {
    primaryGradientStart: "red",
    primaryGradientStop: "#f9f9f8",
    primaryShadow: "rgba(0,0,0,0.05)",
    secondaryButtonBackground: "#322d2C",
    buttonTextColor: "#feffff",
    tertiaryButtonBackground: "#FFDF00"
  }
};

const UPDATE_THEME_FROM_UI_CUSTOMIZATION = "UPDATE_THEME_FROM_UI_CUSTOMIZATION";

function themeReducer(theme: ITheme, action: any) {
  if (action.type === UPDATE_THEME_FROM_UI_CUSTOMIZATION) {
    const uiCustomization: IUICustomization = action.payload;
    theme.layout = uiCustomization.layout_type;
    theme.font = uiCustomization.font;
    theme.background.layout = uiCustomization.desktop_layout_background;
    theme.background.gradientStart = uiCustomization.background_gradient_start;
    theme.background.gradientStop = uiCustomization.background_gradient_stop;
    theme.background.primary = uiCustomization.primary_background_color;
    theme.background.secondary = uiCustomization.secondary_background_color;
    theme.background.tertiary = uiCustomization.tertiary_background_color;
    theme.foreground.primary = uiCustomization.primary_foreground_color;
    theme.foreground.secondary = uiCustomization.secondary_foreground_color;
    theme.text.primary = uiCustomization.primary_text_color;
    theme.text.secondary = uiCustomization.secondary_text_color;
    theme.button.primaryGradientStart = uiCustomization.button_gradient_start;
    theme.button.primaryGradientStop = uiCustomization.button_gradient_stop;
    theme.button.primaryShadow = uiCustomization.box_shadow_color;
    theme.button.buttonTextColor = uiCustomization.button_text_color;
    theme.button.secondaryButtonBackground =
      uiCustomization.secondary_button_background;
    theme.button.tertiaryButtonBackground =
      uiCustomization.tertiary_button_background;
    theme.text.tertiaryTextColor = uiCustomization.tertiary_text_color;

    return { ...theme };
  }
  return theme;
}

export default function ThemeProvider({ children }: any) {
  const [theme, dispatch] = useReducer(themeReducer, defaultTheme);
  const { application } = useAppSelector((state: any) => state.common);

  useEffect(() => {
    if (application?.ui_customization) {
      dispatch({
        type: UPDATE_THEME_FROM_UI_CUSTOMIZATION,
        payload: application.ui_customization
      });
      Webfont.load({
        google: {
          families: [`${application.ui_customization.font}:400,500,600,700`]
        }
      });
    }
  }, [application]);

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>;
}
