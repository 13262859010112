export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function isHTML(str: any) {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export function getEndTime() {
  let endTime = new Date();
  const dd = endTime.getDate();
  const mm = endTime.getMonth(); //January is 0!
  const yyyy = endTime.getFullYear();
  endTime = new Date(yyyy, mm, dd);
  endTime.setDate(endTime.getDate() + 1);
  return endTime;
}

export function getParsedDate(date: string) {
  const dateFormat = new Date(date);
  const d = dateFormat.getDate();
  const m = dateFormat.getMonth();
  return d + " " + monthNames[m];
}

export function getParsedFullDate(date: string) {
  const dateFormat = new Date(date);
  const d = dateFormat.getDate();
  const m = dateFormat.getMonth();
  const y = dateFormat.getFullYear();
  return d + " " + monthNames[m] + " " + y;
}

export function getParsedNewFullDate(date: string) {
  const dateFormat = new Date(date);
  const d = dateFormat.getDate();
  const m = dateFormat.getMonth();
  const y = dateFormat.getFullYear();
  return d + "-" + m + "-" + y?.toString()?.slice(-2);
}

export const digitizeNumber = (n: number) => {
  if (typeof n !== "number") return n;
  else return n > 9 ? `${n}` : `0${n}`;
};

export const calculateTimeLeft = (fromTime: Date) => {
  const difference = +fromTime - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / 1000 / 60 / 60 / 24),
      hours: Math.floor((difference / 1000 / 60 / 60) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
};

type TDecodeURLFromDataParams = {
  app_slug: string | null;
  country: string | null;
  userid?: string | null;
  uuid?: string | null;
  name?: string | null;
  profile_pic?: string | null;
  tournament?: string | number | null;
  open_prize?: string | null;
  showAds?: boolean;
  callback?: boolean;
  uri_ref?: string | null;
  directNavigation?: boolean;
  old_user?: string | null;
  identity_id?: string | null;
  token?: string | null;
  partner_id?: string | null;
  sub_partner_id?: string | null;
  walletId?: string | null;
  loyaltyCardNumber?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  nftazos_image?: string | null; // nftazos pepsico
  tokenid?: string | null; // nftazos pepsico
  subscription_modal?: string | null;
  lang?: string | null;
};

export function getSearchParams(url: string): { [key: string]: string } {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const paramsObj: { [key: string]: string } = {};

  params.forEach((value, key) => {
    paramsObj[key] = value;
  });

  return paramsObj;
}

export const getQueryParamValue = (key: string, url: string = window.location.href) => {
  const urlInstance = new URL(url);
  const searchParams = new URLSearchParams(urlInstance.search);
  return searchParams.get(key);
};

export const findTournamentById = (id: number, tournaments: ITournament[]) => {
  return tournaments.find(t => t.id === id);
};

export const extractParams = (params: any): TDecodeURLFromDataParams => {
  return {
    app_slug: decodeURIComponent(params.get("app") || "") || null,
    country: decodeURIComponent(params.get("country")?.toUpperCase() || "").toUpperCase() || null,
    userid: decodeURIComponent(params.get("userid") || "") || null,
    uuid: decodeURIComponent(params.get("uuid") || "") || null,
    token: decodeURIComponent(params.get("token") || "") || null,
    tournament: decodeURIComponent(params.get("tournament") || "") || null,
    open_prize: decodeURIComponent(params.get("open_prize") || "") || null,
    callback: decodeURIComponent(params.get("callback") || "") === "true",
    uri_ref: decodeURIComponent(params.get("uri_ref") || "") || null,
    name: decodeURIComponent(params.get("name") || "") || null,
    profile_pic: decodeURIComponent(params.get("profile_pic") || "") || null,
    partner_id: decodeURIComponent(params.get("partner_id") || "") || null,
    sub_partner_id: decodeURIComponent(params.get("sub_partner_id") || "") || null,
    old_user: decodeURIComponent(params.get("old_user") || "") || null,
    identity_id: decodeURIComponent(params.get("identity_id") || "") || null,
    directNavigation: decodeURIComponent(params.get("directNavigation") || "false") === "true",
    showAds: !(decodeURIComponent(params.get("showAds") || "true") === "false"),
    walletId: decodeURIComponent(params.get("walletId") || "") || null,
    loyaltyCardNumber: decodeURIComponent(params.get("loyaltyCardNumber") || "") || null,
    utmSource: decodeURIComponent(params.get("utm_source") || "") || null,
    utmMedium: decodeURIComponent(params.get("utm_medium") || "") || null,
    utmCampaign: decodeURIComponent(params.get("utm_campaign") || "") || null,
    nftazos_image: decodeURIComponent(params.get("nftazos_image") || "") || null, // nftazos pepsico
    tokenid: decodeURIComponent(params.get("tokenid") || "") || null, // nftazos pepsico
    lang: decodeURIComponent(params.get("lang") || "") || null, // paobc
  };
};

/**
 *
 * @param won't work if no app_slug & country present in the url
 * @returns
 */
export const decodeDataFromURL = (url: string = window.location.href): TDecodeURLFromDataParams => {
  const values = new URL(url);
  const storedParams = (localStorage && localStorage.getItem("query_params")) || "";
  const searchParamsLocations = [values.search, storedParams];
  for (let i = 0; i < searchParamsLocations.length; i++) {
    const params = new URLSearchParams(searchParamsLocations[i]);
    const inUrl = extractParams(params);
    // for anonymous user
    if (inUrl.app_slug && inUrl.country) return inUrl;
  }
  return {
    app_slug: null,
    uri_ref: null,
    country: null,
    userid: null,
    uuid: null,
    name: null,
    profile_pic: null,
    partner_id: null,
    walletId: null,
    loyaltyCardNumber: null,
    lang: null,
  };
};

export const decodeSearchDataFromURL = (url: string = window.location.href): TDecodeURLFromDataParams => {
  const values = new URL(url);
  const storedParams = (localStorage && localStorage.getItem("query_params")) || "";
  const searchParamsLocations = [values.search, storedParams];
  for (let i = 0; i < searchParamsLocations.length; i++) {
    const params = new URLSearchParams(searchParamsLocations[i]);
    const inUrl = extractParams(params);
    // for anonymous user
    return inUrl;
  }
  return {
    app_slug: null,
    uri_ref: null,
    country: null,
    userid: null,
    uuid: null,
    identity_id: null,
    name: null,
    profile_pic: null,
    partner_id: null,
    subscription_modal: null,
  };
};

export const updateLocalQueryParams = (params: any) => {
  const storedParamsString = (localStorage && localStorage.getItem("query_params")) || "";
  const urlParams = new URLSearchParams(storedParamsString);
  Object.keys(params).map((paramKey: string) => urlParams.set(paramKey, params[paramKey]));
  if (localStorage) {
    localStorage.setItem("query_params", `?${urlParams.toString()}`);
    // store.dispatch({ type: ActionTypes.UPDATE_QUERY_PARAMS, payload: `?${urlParams.toString()}` });
  }
};

export function parseDuration(duration: string) {
  const regex = /^(\d+):(\d+):(\d+)$/;
  const match = duration.match(regex);

  let result = 0;
  const hours = parseInt(match ? match[1] : "0");
  result += hours * 60 * 60 * 1000;
  const minutes = parseInt(match ? match[2] : "0");
  result += minutes * 60 * 1000;
  const seconds = parseInt(match ? match[3] : "0");
  result += seconds * 1000;
  return result;
}

export const shuffle = (a: any) => {
  if (a?.length && a.length > 0) {
    const result = [...a];
    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [result[i], result[j]] = [result[j], result[i]];
    }
    return result;
  }
  return a;
};

export const convertNumberToInt = (prize: string) => {
  const result = parseInt(prize);
  if (isNaN(result)) return prize;
  return result;
};

export function formatCurrencySeparator(num: string) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const formatPrize = (tournament: ITournament, prize: number | string) =>
  `${tournament.currency_symbol || ""}${prize}`;

export function formatPlayCount(playCount: number) {
  if (playCount === 0) return 0;
  const divisorSymbolTable = [
    { divisor: 1000000, symbol: "M" },
    { divisor: 1000, symbol: "K" },
  ];

  if (playCount && playCount > 0) {
    const divisorItem = divisorSymbolTable.find(d => playCount > d.divisor);
    if (!divisorItem) {
      return playCount;
    }

    const divisor = divisorItem.divisor;
    const divisorSymbol = divisorItem.symbol;
    const playCountDivided = playCount / divisor;

    if ((playCountDivided * 10) % 10 > 1) {
      return `${playCountDivided.toFixed(1)}${divisorSymbol}+`;
    } else {
      return `${playCountDivided.toFixed(0)}${divisorSymbol}+`;
    }
  }

  return "";
}

export function processTournamentFromResponse(t: ITournament): ITournament {
  const processedTournament = { ...t };
  processedTournament.start_time = new Date(t.start_time);
  processedTournament.end_time = new Date(t.end_time);
  return processedTournament;
}

export function processGameNameResponse(name: string): string {
  const regex = /\s?\([a-z]{2}\)$/gi;
  return name.replace(regex, "").trim();
}

export function slugifyStringForTranslation(str: string): string {
  return str?.substring(0, 15)?.replace(/\s+/g, "-")?.toLowerCase();
}

export const updateRecentlyPlayed = (gameSlug: string) => {
  const keyId = localStorage && localStorage.getItem("recentlyPlayed");
  const maxItems = 7;

  if (!keyId) {
    localStorage && localStorage.setItem("recentlyPlayed", JSON.stringify([gameSlug]));
  } else {
    let keys = JSON.parse(keyId);

    const index = keys.indexOf(gameSlug);
    if (index < 0) {
      // key does not exist, make room for this key.
      if (keys.length >= maxItems) {
        keys = keys.slice(1, maxItems);
      }

      //most recent stays at the end.
      keys.push(gameSlug);
      localStorage && localStorage.setItem("recentlyPlayed", JSON.stringify(keys));
    } else {
      // push this item at the end, so that it is the most recent item.
      const currItem = keys[index];
      for (let currIndex = index; currIndex < keys.length - 1; ++currIndex) {
        keys[currIndex] = keys[currIndex + 1];
      }
      keys[keys.length - 1] = currItem;
      localStorage && localStorage.setItem("recentlyPlayed", JSON.stringify(keys));
    }
  }
};

export const stringToAlphaNumeric = (s: string) => {
  return s
    .split("")
    .filter((char: string) => {
      const charCode: number = char.charCodeAt(0);
      return (charCode > 47 && charCode < 58) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123);
    })
    .join("");
};

export const isParent = (element: HTMLElement, className: string, baseClass: string): boolean => {
  const currentElementClassAttr = element.getAttribute("class") || "";
  if (currentElementClassAttr.includes(baseClass)) return false;
  else if (currentElementClassAttr.includes(className)) return true;
  else return isParent(element.parentElement as HTMLElement, className, baseClass);
};

export function getTournamentType(tournament: ITournament): string {
  if (tournament?.entry_fee === 0) {
    return tournament.is_prize_available ? "Free Sponsored" : "Practice";
  }
  return tournament.tournament_type;
}

/**
 * Converts text to Trans key
 * e.g., This is a text -> this-is-a-text
 * @param str
 * @returns String
 */
export const getTransKeyFromText = (str: string) => {
  return str.trim().replace(/\s+/g, "-").toLowerCase().slice(0, 15);
};

export function getOS(): string {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = "";
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function isStringEqual(appSlug1: string, appSlug2: string): boolean {
  return appSlug1?.toLowerCase() === appSlug2?.toLowerCase();
}

export function klookTranslatePrize(prize: any, country: string): any {
  return prize?.klook_prize.filter((d: any) => d.country.toLowerCase() === country.toLowerCase())[0]?.translated_field;
}

export function getPartnerCurrencySymbol(data: any): any {
  if (data?.custom_currency_icon) {
    return data?.custom_currency_icon;
  } else {
    return data?.currency_symbol;
  }
}

export const croydonColorsByGame: Record<string, string> = {
  "crazy-cookie": "#b400ff",
  "croydon-penalty": "#b400ff",
  "croydon-crush": "#0036ad",
  croydon: "#0099dc",
  "recicla-con-gomy": "#0099dc",
};

export const unifyTextMarqueeDuration = (text: string) => {
  const baseDuration = 8;
  const textLength = text.length;
  const durationPerLetter = baseDuration / 50;

  return textLength * durationPerLetter;
};
