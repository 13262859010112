import { css } from "@emotion/react";
import BodyBgImage from "assets/clients/bubbaloo/body_bg_image.png";
import BARLOW_CONDENSED from "assets/fonts/Bubbaloo/BarlowCondensed-Regular.ttf";
import BARLOW_CONDENSED_SEMI from "assets/fonts/Bubbaloo/BarlowCondensed-SemiBold.ttf";

const bubbalooStyles = (theme: ITheme) => css`
  @font-face {
    font-family: "Barlow Condensed Semi-Bold";
    src: local("Barlow Condensed Semi-Bold"), url(${BARLOW_CONDENSED_SEMI}) format("truetype");
  }

  @font-face {
    font-family: "Barlow Condensed";
    src: local("Barlow Condensed"), url(${BARLOW_CONDENSED}) format("truetype");
  }

  &.bubbaloo {
    overflow: unset !important;
    * {
      font-family: "Barlow Condensed";
    }
    body {
      font-family: "Barlow Condensed";
    }
    .title-md,
    .title-lg,
    .title-sm,
    .title-xs {
      font-family: "Barlow Condensed Semi-Bold";
    }
    body {
      background: url(${BodyBgImage});
      /* background-repeat: no-repeat; */
      background-attachment: fixed;
      background-size: cover;
      @media (min-width: 1021px) {
        background-size: contain;
      }
    }
    .header-color,
    .background-sty {
      background-color: transparent;
    }
    .desktop-layout {
      background: transparent;
    }
    .subtitle {
      color: #000000;
    }
    .redeem {
      color: #000000;
    }
    .label-sty {
      color: #000000;
    }
    input {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #ffffff;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: #ffffff;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: #ffffff;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: #ffffff;
      }
    }
    .my-games-popup {
      background-color: #ffffff;
      .page-title {
        h1 {
          color: #fe0070;
        }
      }
    }
    .play-icon {
      fill: #ffffff;
    }
    .bubbaloo-profile {
      &.background-desktop {
        background-color: #8d0085;
      }
      .fontSubStyle {
        color: #ffffff;
      }
    }
    .list-group-item {
      &.back-ground {
        background-color: #faef00;
        color: #2c0e81;
      }
    }
    .play-button {
      background-color: #fe0070 !important;
    }
    .page-container,
    .top-bar {
      background-color: #ffffff !important;
    }
    .background-contain {
      background: #ffffff !important;
    }
    .header-style {
      background: #fe0070 !important;
      color: #ffffff !important;
      width: 100%;
      height: 10px !important;
      display: flex;
    }
    .header-position {
      &.header-style {
        background: transparent !important;
      }
    }
    .home-container {
      background-color: #ffacd0 !important;
    }
    .triangle-button {
      background-color: #fe0070 !important;
    }
    .top-bar-top {
      box-shadow: none;
    }
    .ticket-redeem-page {
      background-color: #ffffff !important;
    }
    .coupon-card-container {
      border: 1px solid #ffacd0;
    }
    .first-section {
      box-shadow: none;
    }
    .ticket-reward-page {
      background: #ffffff;
    }
    .first-part {
      box-shadow: none;
    }
    .activated-button {
      color: #fe0070;
      &:before {
        content: "";
        border-color: #fe0070;
      }
    }
    [data-rsbs-overlay] {
      background: #ffacd0;
    }
    .browse-popup {
      background-color: transparent;
    }
    .header-style {
      &.position-fixed {
        background-color: transparent !important;
      }
    }
    .my-games-page {
      .bubbaloo-game-page {
        background-color: transparent !important;
      }
    }
    .show-more {
      color: #000000;
    }
    .desktop-banner-wrapper {
      display: flex;
      justify-content: space-evenly;
    }
    @media (max-width: 1021px) {
      .page-container,
      .top-bar {
        background-color: transparent !important;
      }
      .background-contain,
      .ticket-reward-page,
      .token-reward-page {
        background: transparent !important;
      }
      .ticket-redeem-page,
      .token-page,
      .token-redeem-page,
      .bubbaloo-profile {
        background-color: transparent !important;
      }
      .subtitle-4,
      .message-card,
      .tournament-history-btn {
        color: #ffffff;
      }
      .game-page-inner {
        .title-md {
          color: #ffffff;
        }
      }
      .my-games-page {
        .page-title {
          color: #ffffff;
        }
        .btn {
          &.btn-link {
            color: #ffffff;
          }
        }
      }
    }
    .update-button {
      color: #ffffff;
    }
    .sticky-header-game-over-page {
      margin-top: -10px;
    }
    .registration-text {
      color: #fe0070 !important;
    }
    .not-login {
      padding-top: 0;
      margin-top: -55px;
    }
    input[name="voucher"] {
      background-color: #ffacd0 !important;
      height: 45px;
      border-radius: 5px;
    }
    .top-bar-wrapper {
      .title-md {
        color: #ffffff;
      }
    }
    .bubbaloo-profile {
      .section-title {
        color: #ffffff;
      }
    }
    .show-all-text {
      color: #ffffff;
    }
    .footer-wrapper {
      .font-style {
        color: #ffffff;
      }
      .logo {
        filter: invert(60%) sepia(60%) saturate(6166%) hue-rotate(246deg) brightness(87%) contrast(156%);
      }
    }
    .auth-btn {
      .button {
        border-color: #6c757d;
        &:hover {
          background: linear-gradient(
            270deg,
            ${theme.button.primaryGradientStart} 0%,
            ${theme.button.primaryGradientStop} 122.67%
          ) !important;
          color: #ffffff !important;
        }
      }
    }
    @media (max-width: 1021px) {
      .second-part {
        .activated-button {
          color: #ffffff;
          &:before {
            border-color: #ffffff;
          }
        }
      }
    }
    .sorry-text {
      padding: 0.5rem 1.5rem;
    }
    @media (width < 30rem) {
      .sorry-text {
        font-size: 1.618rem;
      }
    }
  }
`;

export default bubbalooStyles;
