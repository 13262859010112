import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface IVoucherCodeRewardsState {
  error: boolean;
  loading: boolean;
  voucherData: any;
}

const defaultState: IVoucherCodeRewardsState = {
  error: false,
  loading: false,
  voucherData: [],
};

const voucherCodeRewardsSlice = createSlice({
  name: 'voucherCodeRewards',
  initialState: defaultState,
  reducers: {
    fetchVoucherCodeRewardsLoading(state) {
      state.loading = true;
      state.error = false;
    },
    fetchVoucherCodeTournamentRewardsSuccess(state, action: PayloadAction<any>) {
      state.voucherData = action.payload.data;
      state.error = false;
      state.loading = false;
    },
    fetchVoucherCodeTournamentRewardsError(state) {
      state.error = true;
      state.loading = false;
    },
  },
});

export const {
  fetchVoucherCodeRewardsLoading,
  fetchVoucherCodeTournamentRewardsSuccess,
  fetchVoucherCodeTournamentRewardsError,
} = voucherCodeRewardsSlice.actions;

export default voucherCodeRewardsSlice.reducer;