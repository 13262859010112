import React from "react";
import LoadingIndicator from "./LoadingIndicator";

const MediumLoadingIndicator = ({ height = 280 }: any) => (
  <div
    className="d-flex justify-content-center align-items-center w-100"
    style={{
      minHeight: height,
    }}
  >
    <LoadingIndicator size={3} />
  </div>
);

export default MediumLoadingIndicator;
