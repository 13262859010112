import { useAppDispatch } from "redux/Store";
import {
  togglePaymentFailedModal,
  togglePaymentSuccessModal,
  toggleOpenSubscriptionModal,
  toggleCloseSubscriptionModal,
  toggleUnsubscribeModal,
  toggleUnsubscribeSuccessModal,
} from "redux/reducers/modalReducer";

export default function useModalHandler() {
  const dispatch = useAppDispatch();

  // Subscription Modal
  const openSubscriptionModal = () => {
    dispatch(toggleOpenSubscriptionModal());
  };

  const closeSubscriptionModal = () => {
    dispatch(toggleCloseSubscriptionModal());
  };

  // Payment Success Modal
  const openPaymentSuccessModal = () => {
    dispatch(togglePaymentSuccessModal());
  };

  const closePaymentSuccessModal = () => {
    dispatch(togglePaymentSuccessModal());
  };

  // Payment Failed Modal
  const openPaymentFailedModal = () => {
    dispatch(togglePaymentFailedModal());
  };

  const closePaymentFailedModal = () => {
    dispatch(togglePaymentFailedModal());
  };

  // Unsubscribe Modal
  const openUnsubscribeModal = () => {
    dispatch(toggleUnsubscribeModal());
  };

  const closeUnsubscribeModal = () => {
    dispatch(toggleUnsubscribeModal());
  };

  // Unsubscribe Success Modal
  const openUnsubscribeSuccessModal = () => {
    dispatch(toggleUnsubscribeSuccessModal());
  };

  const closeUnsubscribeSuccessModal = () => {
    dispatch(toggleUnsubscribeSuccessModal());
  };

  return {
    openSubscriptionModal,
    closeSubscriptionModal,
    openPaymentSuccessModal,
    closePaymentSuccessModal,
    openPaymentFailedModal,
    closePaymentFailedModal,
    openUnsubscribeModal,
    closeUnsubscribeModal,
    openUnsubscribeSuccessModal,
    closeUnsubscribeSuccessModal,
  };
}
