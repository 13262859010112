import { useEffect } from "react";
import { isBubbaloo } from "utils/applicationSlug";
import { isInProductionMode } from "actions/api/utils";

const discoveryPlus = `var _rl_cn = _rl_cn || 0,_rl_ptc = ("https:" == window.location.protocol ? "https" : "http"); window._rl_ids = window._rl_ids || []; window._rely = window._rely || []; _rl_ids.push({pid:375,src:0}); _rely.send = _rely.send?_rely.send:function() {}; (function() { var rl = document.createElement("script"); rl.type = "text/javascript"; rl.async = true; rl.src = _rl_ptc + "://api.retargetly.com/loader?id=" + _rl_ids[_rl_ids.length-1].pid; rl.id = "rely-api-"+(_rl_cn++); var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(rl, s); })();`;

const loadDiscoveryPlus = () => {
  const existingScript = document.getElementById("discoveryPlus");

  if (!existingScript) {
    const script = document.createElement("script");
    script.text = discoveryPlus;
    document.body.appendChild(script);
  }
};

const useDiscoveryPlus = () => {
  useEffect(() => {
    if (isBubbaloo && isInProductionMode()) {
      loadDiscoveryPlus();
    }
  }, []);
};

export default useDiscoveryPlus;
