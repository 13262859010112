import React from "react";
import { useTranslation } from "react-i18next";
import { slugifyStringForTranslation } from "utils";

export const DynamicTranslation = ({ text = "" }: { text: string }) => {
  const { t } = useTranslation("translation", { useSuspense: false });
  if (!text) return <span id="empty-text">{text}</span>;
  try {
    let n;
    const numberMatch = text.match(/(\d+)/);
    if (numberMatch) {
      n = numberMatch[0];
    }
    const temp = {
      defaultValue: text,
      n,
      key: slugifyStringForTranslation(text.replace(/\d+/g, "{{n}}")),
    };
    return (
      <span id="dynamic-text">
        {t(temp.key, {
          defaultValue: temp?.defaultValue,
          n: temp?.n,
        })}
      </span>
    );
  } catch (e) {
    return null;
  }
};
