import { css } from "@emotion/react";
import React from "react";

const snowStyle = () => css`
  margin: 0;
  padding: 0;
  height: 100vh;
  h1 {
    font-size: 100px;
  }
  &.snowflakes-container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    top: 0;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    z-index: 999;
    pointer-events: none;
    /* animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in; */
  }

  .Snowflake {
    min-height: 100vh;
    display: inline-block;
    width: 0.1%;
    color: white;
    opacity: 0;
    font-size: 150px;
    margin: 0;
    padding: 0;
    animation: fall 12s linear infinite;
    /* transform: scale(2) rotate(12deg); */
  }

  @keyframes fall {
    0% {
      opacity: 0;
    }

    3% {
      opacity: 0.9;
    }

    70% {
      opacity: 0.4;
      transform: translate(70px, 93vh);
    }

    90% {
      opacity: 0;
      transform: translate(75px, 93vh);
    }

    92% {
      opacity: 0;
      transform: translate(80px, 93vh);
    }
  }
`;

export default function SnowFlakes() {
  const Snowflake = ({ style }: any) => {
    return (
      <p style={style} className="Snowflake">
        .
      </p>
    );
  };

  const snow = () => {
    const arr = Array.from(
      "Snowflakes are awesome!!!Love snowflakes!!! Love snowflakes!!!Snowflakes are awesome!!!They are like little pieces of magic!!!Love snowflakes!!!Love snowflakes!!!Snowflakes are awesome!!!They are like little pieces of magic!! Snowflakes are awesome!!!Love snowflakes!!!Snowflakes are awesome!!!They are like little pieces"
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return arr.map((el: any, i: number) => {
      let animationDelay = "0s";
      let fontSize = "1000px";
      const position = Math.random() * 70;
      const left = position + "%";

      animationDelay = `${(Math.random() * 15).toFixed(2)}s`;
      fontSize = `${Math.floor(Math.random() * 10) + 40}px`;

      const design = {
        animationDelay,
        fontSize,
        left
      };
      return <Snowflake key={i} css={design} style={design} />;
    });
  };

  return (
    <div css={snowStyle} className="snowflakes-container">
      {snow()}
    </div>
  );
}
