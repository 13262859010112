export const ActionTypes = {
  PLATFORM_LOADED: "PLATFORM_LOADED",
  // TournamentPage Actions
  INITIATE_TOURNAMENT_PAGE_LOADING: "INITIATE_TOURNAMENT_PAGE_LOADING",
  INITIATE_TOURNAMENT_PAGE_SUCCESS: "INITIATE_TOURNAMENT_PAGE_SUCCESS",
  INITIATE_TOURNAMENT_PAGE_ERROR: "INITIATE_TOURNAMENT_PAGE_ERROR",
  RESET_TOURNAMENT_DATA: "RESET_TOURNAMENT_DATA",
  SHOW_TOURNAMENT_END_MODAL: "SHOW_TOURNAMENT_END_MODAL",
  HIDE_TOURNAMENT_END_MODAL: "HIDE_TOURNAMENT_END_MODAL",
  SET_CURRENT_MODAL_NAME: "SET_CURRENT_MODAL_NAME",
  RESET_CURRENT_MODAL_NAME: "RESET_CURRENT_MODAL_NAME",

  // // Tournament Country Rankings
  // FETCH_TOURNAMENT_RANKINGS_LOADING: "FETCH_TOURNAMENT_RANKINGS_LOADING",
  // FETCH_TOURNAMENT_RANKINGS_ERROR: "FETCH_TOURNAMENT_RANKINGS_ERROR",
  // FETCH_TOURNAMENT_RANKINGS_SUCCESS: "FETCH_TOURNAMENT_RANKINGS_SUCCESS",

  // User Ranking
  // FETCH_USER_RANKING_LOADING: "FETCH_USER_RANKING_LOADING",
  // FETCH_USER_RANKING_SUCCESS: "FETCH_USER_RANKING_SUCCESS",
  // FETCH_USER_RANKING_ERROR: "FETCH_USER_RANKING_ERROR",
  // FETCH_TOURNAMENT_RANKINGS_LOAD_MORE: "FETCH_TOURNAMENT_RANKINGS_LOAD_MORE",

  // FETCH COMMON DATA
  FETCH_COMMON_DATA_LOADING: "FETCH_COMMON_DATA_LOADING",
  FETCH_COMMON_DATA_SUCCESS: "FETCH_COMMON_DATA_SUCCESS",
  FETCH_ADVERTISEMENTS_LOADING: "FETCH_ADVERTISEMENTS_LOADING",
  FETCH_ADVERTISEMENTS_SUCCESS: "FETCH_ADVERTISEMENTS_SUCCESS",
  FETCH_TRANSLATIONS_SUCCESS: "FETCH_TRANSLATIONS_SUCCESS",
  FETCH_ADVERTISEMENTS_ERROR: "FETCH_ADVERTISEMENTS_ERROR",
  FETCH_TOURNAMENT_ADVERTISEMENTS_SUCCESS: "FETCH_TOURNAMENT_ADVERTISEMENTS_SUCCESS",
  FETCH_COMMON_DATA_UNAUTHORIZED_ERROR: "FETCH_COMMON_DATA_UNAUTHORIZED_ERROR",
  FETCH_COMMON_DATA_OUTDATED_ERROR: "FETCH_COMMON_DATA_OUTDATED_ERROR",
  FETCH_COMMON_DATA_ERROR: "FETCH_COMMON_DATA_ERROR",
  FETCH_FAQ_LOADING: "FETCH_FAQ_LOADING",
  FETCH_FAQ_SUCCESS: "FETCH_FAQ_SUCCESS",
  FETCH_FAQ_ERROR: "FETCH_FAQ_ERROR",
  FETCH_T_AND_C_LOADING: "FETCH_T_AND_C_LOADING",
  FETCH_T_AND_C_SUCCESS: "FETCH_T_AND_C_SUCCESS",
  FETCH_T_AND_C_ERROR: "FETCH_T_AND_C_ERROR",

  FETCH_CONTACT_US_LOADING: "FETCH_CONTACT_US_LOADING",
  FETCH_CONTACT_US_SUCCESS: "FETCH_CONTACT_US_SUCCESS",
  FETCH_CONTACT_US_ERROR: "FETCH_CONTACT_US_ERROR",

  FETCH_PRIVACY_POLICY_LOADING: "FETCH_PRIVACY_POLICY_LOADING",
  FETCH_PRIVACY_POLICY_SUCCESS: "FETCH_PRIVACY_POLICY_SUCCESS",
  FETCH_PRIVACY_POLICY_ERROR: "FETCH_PRIVACY_POLICY_ERROR",

  FETCH_ARCADE: "FETCH_ARCADE",
  FETCH_CAMPAIGN_LOADING: "FETCH_CAMPAIGN_LOADING",
  FETCH_CAMPAIGN_SUCCESS: "FETCH_CAMPAIGN_SUCCESS",
  FETCH_CAMPAIGN_ERROR: "FETCH_CAMPAIGN_ERROR",
  CLEAR_COMMON_USER: "CLEAR_COMMON_USER",
  ENTRY_LOG_COMPLETE: "ENTRY_LOG_COMPLETE",
  UPDATE_QUERY_PARAMS: "UPDATE_QUERY_PARAMS",

  // LAYOUT DATA
  FETCH_LAYOUT_DATA: "FETCH_LAYOUT_DATA",
  SHOW_DESKTOP_VIEW: "SHOW_DESKTOP_VIEW",
  HIDE_DESKTOP_VIEW: "HIDE_DESKTOP_VIEW",
  SHOW_NOTIFICATIONS: "SHOW_NOTIFICATIONS",
  HIDE_NOTIFICATIONS: "HIDE_NOTIFICATIONS",
  SHOW_USER_PROFILE: "SHOW_USER_PROFILE",
  HIDE_USER_PROFILE: "HIDE_USER_PROFILE",
  SHOW_ACHIEVEMENT_PAGE: "SHOW_ACHIEVEMENT_PAGE",
  HIDE_ACHIEVEMENT_PAGE: "HIDE_ACHIEVEMENT_PAGE",
  RESET_LAYOUT_DATA: "RESET_LAYOUT_DATA",
  SHOW_RED_DOT: "SHOW_RED_DOT",
  HIDE_RED_DOT: "HIDE_RED_DOT",
  SHOW_ARCHIEVE_TOURNAMENT_HISTORY: "SHOW_ARCHIEVE_TOURNAMENT_HISTORY",
  HIDE_ARCHIEVE_TOURNAMENT_HISTORY: "HIDE_ARCHIEVE_TOURNAMENT_HISTORY",
  SHOW_POP_UP: "SHOW_POP_UP",
  HIDE_POP_UP: "HIDE_POP_UP",
  SHOW_THEME_LAYOUT: "SHOW_THEME_LAYOUT",
  FETCH_LOGINPOPUP_COUNTER: "FETCH_LOGINPOPUP_COUNTER",
  DEDUCT_LOGINPOPUP_COUNTER: "DEDUCT_LOGINPOPUP_COUNTER",

  // FETCH ACTIVE TOURNAMENTS
  FETCH_ACTIVE_TOURNAMENTS_LOADING: "FETCH_ACTIVE_TOURNAMENTS_LOADING",
  FETCH_ACTIVE_TOURNAMENTS_SUCCESS: "FETCH_ACTIVE_TOURNAMENTS_SUCCESS",
  FETCH_ACTIVE_TOURNAMENTS_ERROR: "FETCH_ACTIVE_TOURNAMENTS_ERROR",
  FETCH_TOURNAMENT_DETAILS_SUCCESS: "FETCH_TOURNAMENT_DETAILS_SUCCESS",
  FETCH_GAME_DETAILS_SUCCESS: "FETCH_GAME_DETAILS_SUCCESS",
  // User History
  FETCH_USER_HISTORY_LOADING: "FETCH_USER_HISTORY_LOADING",
  FETCH_USER_HISTORY_SUCCESS: "FETCH_USER_HISTORY_SUCCESS",
  FETCH_USER_HISTORY_ERROR: "FETCH_USER_HISTORY_ERROR",

  // AuthLink Account
  FETCH_AUTH_LOGIN_URL_LOADING: "FETCH_AUTH_LOGIN_URL_LOADING",
  FETCH_AUTH_LOGIN_URL_SUCCESS: "FETCH_AUTH_LOGIN_URL_SUCCESS",
  FETCH_AUTH_LOGIN_URL_ERROR: "FETCH_AUTH_LOGIN_URL_ERROR",

  // AuthLink related data
  FETCH_AUTH_ASSIGN_LOADING: "FETCH_AUTH_ASSIGN_LOADING",
  FETCH_AUTH_ASSIGN_SUCCESS: "FETCH_AUTH_ASSIGN_SUCCESS",
  FETCH_AUTH_ASSIGN_ERROR: "FETCH_AUTH_ASSIGN_ERROR",

  // User Stats
  FETCH_USER_STATS_LOADING: "FETCH_USER_STATS_LOADING",
  FETCH_USER_STATS_SUCCESS: "FETCH_USER_STATS_SUCCESS",
  FETCH_USER_STATS_ERROR: "FETCH_USER_STATS_ERROR",

  // PvP Stats
  FETCH_PVP_STATS_LOADING: "FETCH_PVP_STATS_LOADING",
  FETCH_PVP_STATS_SUCCESS: "FETCH_PVP_STATS_SUCCESS",
  FETCH_PVP_STATS_ERROR: "FETCH_PVP_STATS_ERROR",

  // Play Game
  FETCH_C2P_GAMES_LOADING: "FETCH_C2P_GAMES_LOADING",
  FETCH_C2P_GAMES_SUCCESS: "FETCH_C2P_GAMES_SUCCESS",
  FETCH_C2P_GAMES_ERROR: "FETCH_C2P_GAME_ERROR",
  FETCH_C2P_GAMES_DETAIL_SUCCESS: "FETCH_C2P_GAMES_DETAIL_SUCCESS",

  // Game Over Count
  INCREASE_GAME_OVER_COUNT: "INCREASE_GAME_OVER_COUNT",
  RESET_GAME_OVER_COUNT: "RESET_GAME_OVER_COUNT",

  // Try again count
  INCREASE_TRY_AGAIN_COUNT: "INCREASE_TRY_AGAIN_COUNT",
  RESET_TRY_AGAIN_COUNT: "RESET_TRY_AGAIN_COUNT",

  // Show Ad Modal
  SHOW_AD_MODAL: "SHOW_AD_MODAL",
  HIDE_AD_MODAL: "HIDE_AD_MODAL",

  SET_AD_ERROR: "SET_AD_ERROR",
  RESET_AD_ERROR: "RESET_AD_ERROR",

  // Arcade Play To Arcade Back Button
  BACK_BUTTON_PRESS_TRUE: "BACK_BUTTON_PRESS_TRUE",
  BACK_BUTTON_PRESS_FALSE: "BACK_BUTTON_PRESS_FALSE",

  // My Tournaments
  FETCH_USER_TOURNAMENTS_LOADING: "FETCH_USER_TOURNAMENTS_LOADING",
  FETCH_USER_TOURNAMENTS_SUCCESS: "FETCH_USER_TOURNAMENTS_SUCCESS",
  FETCH_USER_TOURNAMENTS_ERROR: "FETCH_USER_TOURNAMENTS_ERROR",
  CREATE_TOURNAMENT_PARTICIPANT_SUCCESS: "CREATE_TOURNAMENT_PARTICIPANT_SUCCESS",
  FETCH_TOURNAMENT_PARTICIPANT_ERROR: "FETCH_TOURNAMENT_PARTICIPANT_ERROR",

  //Avatar Action
  UPDATE_USER_AVATAR_LOADING: "UPDATE_USER_AVATAR_LOADING",
  UPDATE_USER_AVATAR_SUCCESS: "UPDATE_USER_AVATAR_SUCCESS",
  UPDATE_USER_AVATAR_ERROR: "UPDATE_USER_AVATAR_ERROR",

  //Avatar Action
  UPDATE_USER_NAME_SUCCESS: "UPDATE_USER_NAME_SUCCESS",

  //Referrer Action
  UPDATE_USER_REFERRER: "UPDATE_USER_REFERRER",

  // Voucher Code Reward
  FETCH_VOUCHER_CODE_REWARDS_LOADING: "FETCH_VOUCHER_CODE_REWARDS_LOADING",
  FETCH_VOUCHER_CODE_TOURNAMENT_REWARDS_SUCCESS: "FETCH_VOUCHER_CODE_TOURNAMENT_REWARDS_SUCCESS",
  FETCH_VOUCHER_CODE_TOURNAMENT_REWARDS_ERROR: "FETCH_VOUCHER_CODE_TOURNAMENT_REWARDS_ERROR",

  // Tournament Reward
  FETCH_TOURNAMENT_REWARDS_LOADING: "FETCH_TOURNAMENT_REWARDS_LOADING",
  FETCH_TOURNAMENT_REWARDS_SUCCESS: "FETCH_TOURNAMENT_REWARDS_SUCCESS",
  FETCH_TOURNAMENT_REWARDS_ERROR: "FETCH_TOURNAMENT_REWARDS_ERROR",

  // Remove Rewards
  REMOVE_TOURNAMENT_REWARDS_SUCCESS: "REMOVE_TOURNAMENT_REWARDS_SUCCESS",
  REMOVE_TOURNAMENT_REWARDS_ERROR: "REMOVE_TOURNAMENT_REWARDS_ERROR",

  // Login Popup
  SHOW_LOGIN_POPUP: "SHOW_LOGIN_POPUP",
  HIDE_LOGIN_POPUP: "HIDE_LOGIN_POPUP",

  // Claimed Modal
  UPDATE_USER_CLAIMED_SUCCESS: "UPDATE_USER_CLAIMED_SUCCESS",
  UPDATE_USER_CLAIMED_ERROR: "UPDATE_USER_CLAIMED_ERROR",

  //GamelistPopUp
  SHOW_GAMELIST_POPUP: "SHOW_GAMELIST_POPUP",
  HIDE_GAMELIST_POPUP: "HIDE_GAMELIST_POPUP",
  SHOW_PVPTOURNAMENT: "SHOW_PVPTOURNAMENT",
  HIDE_PVPTOURNAMENT: "HIDE_PVPTOURNAMENT",

  // Congrats Modal
  SHOW_CONGRATSMODAL: "SHOW_CONGRATSMODAL",
  HIDE_CONGRATSMODAL: "HIDE_CONGRATSMODAL",

  // Notification
  SHOW_NOTIFICATIONSTATUS: "SHOW_NOTIFICATIONSTATUS",
  HIDE_NOTIFICATIONSTATUS: "HIDE_NOTIFICATIONSTATUS",

  SET_PROFILE_PIC: "SET_PROFILE_PIC",

  // Max-Score
  SHOW_MAX_SCORE: "SHOW_MAX_SCORE",

  // Leaderboard-Popup
  SHOW_LEADERBOARDPOPUP: "SHOW_LEADERBOARDPOPUP",
  HIDE_LEADERBOARDPOPUP: "HIDE_LEADERBOARDPOPUP",

  // Subscription Modal
  SHOW_SUBSCRIPTION_MODAL: "SHOW_SUBSCRIPTION_MODAL",
  HIDE_SUBSCRIPTION_MODAL: "HIDE_SUBSCRIPTION_MODAL",

  // Payment Success Modal
  SHOW_PAYMENT_SUCCESS_MODAL: "SHOW_PAYMENT_SUCCESS_MODAL",
  HIDE_PAYMENT_SUCCESS_MODAL: "HIDE_PAYMENT_SUCCESS_MODAL",

  // Payment Failed Modal
  SHOW_PAYMENT_FAILED_MODAL: "SHOW_PAYMENT_FAILED_MODAL",
  HIDE_PAYMENT_FAILED_MODAL: "HIDE_PAYMENT_FAILED_MODAL",

  // Unsubscribe Modal
  SHOW_UNSUBSCRIBE_MODAL: "SHOW_UNSUBSCRIBE_MODAL",
  HIDE_UNSUBSCRIBE_MODAL: "HIDE_UNSUBSCRIBE_MODAL",

  // Unsubscribe Success Modal
  SHOW_UNSUBSCRIBE_SUCCESS_MODAL: "SHOW_UNSUBSCRIBE_SUCCESS_MODAL",
  HIDE_UNSUBSCRIBE_SUCCESS_MODAL: "HIDE_UNSUBSCRIBE_SUCCESS_MODAL",

  // Recommended Game
  SHOW_RECOMMENDED_GAME: "SHOW_RECOMMENDED_GAME",
  HIDE_RECOMMENDED_GAME: "HIDE_RECOMMENDED_GAME",

  // Play Game Button Click
  SHOW_PLAY_GAME_BUTTON: "SHOW_PLAY_GAME_BUTTON",
  HIDE_PLAY_GAME_BUTTON: "HIDE_PLAY_GAME_BUTTON",
};
