import React from "react";
import { css } from "@emotion/react";
import Modal from "react-bootstrap/Modal";

import UpdateNameForm from "./UpdateNameForm";
import UpdateAvatarForm from "./UpdateAvatarForm";
import { useAppSelector } from "redux/Store";

const modalStyle = (theme: ITheme) => css`
  .modal-content {
    border-radius: 10px;
  }

  .update-button {
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );
    color: ${theme.button.buttonTextColor};
    border-radius: 10px;
    border-color: transparent;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      border-color: transparent;
    }
  }
`;

export default function UpdateInfoModal(props: any) {
  const { application } = useAppSelector((state: any) => state.common);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      css={modalStyle}
      contentClassName={`${application?.name}-modal-content`}
    >
      <Modal.Body>
        {application?.applicationsetting?.enable_name_change && <UpdateNameForm onSubmit={props.onHide} />}
        <UpdateAvatarForm onSubmit={props.onHide} />
      </Modal.Body>
    </Modal>
  );
}
