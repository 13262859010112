import React from "react";
import { css } from "@emotion/react";
import cn from "classnames";
import { useHistory } from "hooks/useHistory";
import { useAppSelector } from "redux/Store";
import { RAZER_CURRENCY_CODES } from "constants/constants";
import { commaSeparatedNumber } from "utils/numberParser";
import { showCortexPurchaseCurrencyDialog } from "providers/client-sdk-providers/razer";
import Coin from "assets/images/razer/razer-gold.png";

interface IPlusButtonProps {
  plusButton?: boolean;
}

export default function RazerGoldStatus({ plusButton = false }: IPlusButtonProps) {
  const { application, user } = useAppSelector(state => state.common);
  const { razer } = useAppSelector(state => state.sdkReducer);
  const { gold_balance } = razer;
  const history = useHistory();
  const walletCurrency = user?.extra_data?.wallet_currency || "";
  const currencySupported = RAZER_CURRENCY_CODES.includes(walletCurrency);
  if (!currencySupported) return null;
  return (
    <div
      tabIndex={0}
      role="button"
      css={link}
      className={cn("right-style justify-content-space-between me-1 razer-gold-status", {
        "pe-2": history.location.pathname === `/tournament` && application?.applicationsetting?.enable_user_journey,
        "pb-2": history.location.pathname === `/profile`,
        "app-off": !application?.is_back_button_enabled || !application?.enable_top_bar,
        "ticket-disable": !application?.applicationsetting?.enable_user_journey,
      })}
      onClick={() => {
        if (gold_balance === null || gold_balance <= 5) showCortexPurchaseCurrencyDialog();
      }}
      onKeyDown={() => {
        if (gold_balance === null || gold_balance <= 5) showCortexPurchaseCurrencyDialog();
      }}
    >
      <div className={plusButton ? "d-flex flex-row" : "upper"} css={plusButton ? backgroundStyle : tokenStyle}>
        <div className="d-flex flex-row justify-content-center align-items-center pe-1 ms-1">
          <img
            src={Coin}
            alt="coin"
            css={css`
              width: 30px;
            `}
          />
          <span className="label-sty ps-1">{gold_balance === null ? 0 : commaSeparatedNumber(gold_balance)}</span>
        </div>
        {/* <div
          className={cn(
            "d-flex align-items-center  icon pe-2 desktop-plus ms-auto",
            {
              "ps-1": history.location.pathname === `/profile`
            }
          )}
        >
          <FaPlus
            style={{ color: "green" }}
            className="icon-style"
          />
        </div> */}
      </div>
    </div>
  );
}

const backgroundStyle = () => css`
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  width: 100px;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (max-width: 350px) {
    height: 35px;
    width: 90px;
  }

  .token-radius {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    right: 50%;
    margin-right: 5px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 350px) {
      height: 30px;
      width: 30px;
    }
  }

  .icon-style {
    color: #fdb32b !important;
    fill: #fdb32b !important;
  }

  .icon {
    color: #fdb32b !important;
    fill: #fdb32b !important;
  }

  .label-sty {
    color: #fdb32b !important;
    margin-top: 0;
    font-size: 12px;

    @media screen and (max-width: 350px) {
      padding-left: 37px;
    }
  }
`;

const tokenStyle = (theme: ITheme) => css`
  position: relative;
  display: flex;
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  cursor: pointer;
  /* svg,
  svg g,
  svg circle,
  svg path,
  svg rect {
    fill: ${theme.foreground.primary};
  } */

  .token-border {
    /* background-color: white; */
    border-radius: 5px;
    /* color: #fff; */
    font-size: 15px;
  }

  .token-radius {
    border-radius: 50%;
    width: 29px;
    height: 29px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token {
    width: 15px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 0 auto;
    top: 9px;
  }

  .icon-style {
    color: #fdb32b !important;
    fill: #222222 !important;
  }

  .icon {
    color: #fdb32b !important;
    fill: #fdb32b !important;
  }
`;

const link = (theme: ITheme) => css`
  border: none;
  text-decoration: none;
  display: inline-block;
  /* position: absolute; */

  &.right-style {
    margin-right: 0 !important;
    right: 0;
    min-width: 100px;
  }

  &.app-off {
    margin-left: auto;
  }

  &.ticket-disable {
    margin-left: auto;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  .icon {
    /* fill: white !important; */
    fill: #fdb32b !important;
  }

  .icon-style {
    width: 15px;
    height: 15px;
    /* color: ${theme.foreground.primary} !important; */
    color: #fdb32b !important;
    fill: #fdb32b !important;
  }

  .token-svg {
    z-index: 999;
    fill: ${theme.foreground.primary};
  }

  .label-sty {
    /* color: ${theme.foreground.primary} !important; */
    color: #fdb32b !important;
    fill: #fdb32b !important;
    font-weight: 600;
    font-size: 15px;
  }
`;
