import { createSlice } from "@reduxjs/toolkit";

// import { AppThunk } from "redux/Store";
// import tournamentsAPI from "actions/api/tournaments";
import api from "actions/api";
import { AppDispatch } from "redux/Store";

export interface IStat {
  total_winnings: number;
  total_tournament: number;
}

export interface PvPStat {
  played: number;
  won: number;
  win_percentage: number;
}

export interface IStatsState {
  data: IStat | null;
  pvpstats: PvPStat | null;
  loading: boolean;
  error: boolean;
}

const defaultState: IStatsState = {
  data: null,
  pvpstats: null,
  loading: false,
  error: false
};

const statsSlice = createSlice({
  name: "stats",
  initialState: defaultState,
  reducers: {
    fetchUserStatsLoading(state) {
      state.loading = true;
    },
    fetchUserStatsSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.data = action.payload;
    },
    fetchUserStatsError(state) {
      state.error = true;
      state.loading = true;
    },
    fetchPVPStatsSuccess(state, action) {
      state.pvpstats = action.payload;
    },
    fetchPVPStatsError(state) {
      state.pvpstats = null;
    }
  }
});

export const {
  fetchPVPStatsSuccess,
  fetchPVPStatsError,
  fetchUserStatsLoading,
  fetchUserStatsSuccess,
  fetchUserStatsError
} = statsSlice.actions;

export default statsSlice.reducer;

export const fetchUserStats = () => (dispatch: AppDispatch) => {
  dispatch(fetchUserStatsLoading());
  return api
    .get(`tournaments/users/tournaments-stats/`)
    .then(response => {
      dispatch(fetchUserStatsSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchUserStatsError()));
};

export const fetchPvpStats = (country: string) => (dispatch: AppDispatch) => {
  return api
    .get(`tournaments/users/${country}/pvp-stats/`)
    .then(response => {
      dispatch(fetchPVPStatsSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchPVPStatsError()));
};
