import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
// import { ActionTypes } from 'actions/actionTypes';
import { AppDispatch } from 'redux/Store';

interface ILayoutReducerState {
  platformLoaded: boolean;
  isDesktop: boolean;
  showNotifications: boolean;
  showUserProfile: boolean;
  showAchievementPage: boolean;
  showAd: boolean;
  loginPopupVisibility: boolean;
  showTournamentArchieveHistory: boolean;
  redDot: boolean;
  popUp: boolean;
  notifyStatus: boolean;
  currentRightSidebarState: null | string;
  openPrize: string | null | boolean | undefined;
  tournament: number | null;
  layoutType: string;
  currentModalName: string;
  loginPopUpCounter: number;
  recommendedGame: boolean;
  playGameButtonClick: boolean;
}

const defaultState: ILayoutReducerState = {
  platformLoaded: false,
  isDesktop: false,
  showNotifications: false,
  showUserProfile: false,
  showTournamentArchieveHistory: false,
  showAchievementPage: false,
  showAd: true,
  loginPopupVisibility: false,
  redDot: false,
  popUp: false,
  notifyStatus: false,
  currentRightSidebarState: null,
  openPrize: false,
  tournament: null,
  layoutType: '',
  currentModalName: '',
  loginPopUpCounter: 0,
  recommendedGame: false,
  playGameButtonClick: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: defaultState,
  reducers: {
    fetchLayoutData(state) {
      return state;
    },
    showDesktopView(state) {
      state.isDesktop = true;
      state.currentRightSidebarState = 'ad';
      state.showAd = true;
    },
    setCurrentModalName(state, action: PayloadAction<{ modal_name: string }>) {
      state.currentModalName = action.payload.modal_name;
    },
    resetCurrentModalName(state) {
      state.currentModalName = '';
    },
    hideDesktopView(state) {
      state.isDesktop = false;
      state.currentRightSidebarState = null;
      state.showUserProfile = false;
      state.showAd = false;
      state.showNotifications = false;
      state.showAchievementPage = false;
      state.showTournamentArchieveHistory = false;
    },
    showNotifications(state) {
      state.currentRightSidebarState = 'notification';
    },
    hideNotifications(state) {
      state.currentRightSidebarState = 'ad';
    },
    showUserProfile(state) {
      state.currentRightSidebarState = 'profile';
    },
    hideUserProfile(state) {
      state.currentRightSidebarState = 'ad';
    },
    showAchievementPage(state) {
      state.currentRightSidebarState = 'achievement';
    },
    hideAchievementPage(state) {
      state.currentRightSidebarState = 'profile';
    },
    resetLayoutData(state) {
      state.currentRightSidebarState = null;
      state.redDot = false;
    },
    showRedDot(state) {
      state.redDot = true;
    },
    hideRedDot(state) {
      state.redDot = false;
    },
    showPopUpAll(state) {
      state.popUp = true;
    },
    hidePopUpAll(state) {
      state.popUp = false;
    },
    showTournamentArchieveHistory(state) {
      state.currentRightSidebarState = 'tournamentArchieveHistory';
    },
    hideTournamentArchieveHistory(state) {
      state.currentRightSidebarState = 'profile';
    },
    fetchCommonDataSuccess(state, action: PayloadAction<{ user: any }>) {
      if (!action.payload.user) {
        state.loginPopupVisibility = true;
      }
    },
    showLoginPopup(state) {
      state.loginPopupVisibility = true;
    },
    hideLoginPopup(state) {
      state.loginPopupVisibility = false;
    },
    platformLoaded(state) {
      state.platformLoaded = true;
    },
    showNotificationStatus(state) {
      state.notifyStatus = true;
    },
    hideNotificationStatus(state) {
      state.notifyStatus = false;
    },
    showLeaderboardPopup(state, action: PayloadAction<{ openPrize: string | null | boolean | undefined; tournament: number | null }>) {
      state.openPrize = action.payload.openPrize;
      state.tournament = action.payload.tournament;
    },
    hideLeaderboardPopup(state) {
      state.openPrize = false;
      state.tournament = null;
    },
    showThemeLayout(state, action: PayloadAction<{ layoutType: string }>) {
      state.layoutType = action.payload.layoutType;
    },
    fetchLoginPopupCounter(state, action: PayloadAction<{ loginPopUpCounter: number }>) {
      state.loginPopUpCounter = action.payload.loginPopUpCounter;
    },
    showRecommendedGame(state) {
      state.recommendedGame = true;
    },
    hideRecommendedGame(state) {
      state.recommendedGame = false;
    },
    showPlayGameButton(state) {
      state.playGameButtonClick = true;
    },
    hidePlayGameButton(state) {
      state.playGameButtonClick = false;
    },
    deductLoginPopupCounter(state) {
      if (state.loginPopUpCounter > 0) {
        state.loginPopUpCounter -= 1;
      }
    },
  },
});

export const {
  fetchLayoutData,
  showDesktopView,
  setCurrentModalName,
  resetCurrentModalName,
  hideDesktopView,
  showNotifications,
  hideNotifications,
  showUserProfile,
  hideUserProfile,
  showAchievementPage,
  hideAchievementPage,
  resetLayoutData,
  showRedDot,
  hideRedDot,
  showPopUpAll,
  hidePopUpAll,
  showTournamentArchieveHistory,
  hideTournamentArchieveHistory,
  fetchCommonDataSuccess,
  showLoginPopup,
  hideLoginPopup,
  platformLoaded,
  showNotificationStatus,
  hideNotificationStatus,
  showLeaderboardPopup,
  hideLeaderboardPopup,
  showThemeLayout,
  fetchLoginPopupCounter,
  showRecommendedGame,
  hideRecommendedGame,
  showPlayGameButton,
  hidePlayGameButton,
  deductLoginPopupCounter,
} = layoutSlice.actions;

export default layoutSlice.reducer;

// Thunk actions
export const fetchLoginPopCounter = () => (dispatch: AppDispatch, getState: any) => {
  const {
    common: {
      application: {
        applicationsetting: { login_popup_counter },
      },
    },
  } = getState() || {};
  if (!isNaN(login_popup_counter)) {
    dispatch(fetchLoginPopupCounter({ loginPopUpCounter: login_popup_counter }));
  }
};

export const deductLoginPopUpCounter = () => (dispatch: AppDispatch) => {
  dispatch(deductLoginPopupCounter());
};

/* export const layoutReducer: (state: ILayoutReducerState, action: Action) => ILayoutReducerState = (
  state = defaultData,
  action: any,
) => {
  const loggedIn = !!action?.payload?.user;

  switch (action.type) {
    case ActionTypes.FETCH_LAYOUT_DATA:
      return { ...state };
    case ActionTypes.SHOW_DESKTOP_VIEW:
      return {
        ...state,
        isDesktop: true,
        currentRightSidebarState: "ad",
        showAd: true,
      };
    case ActionTypes.SET_CURRENT_MODAL_NAME:
      return {
        ...state,
        currentModalName: action?.payload?.modal_name,
      };
    case ActionTypes.RESET_CURRENT_MODAL_NAME:
      return {
        ...state,
        currentModalName: "",
      };
    case ActionTypes.HIDE_DESKTOP_VIEW:
      return {
        ...state,
        currentRightSidebarState: null,
        isDesktop: false,
        showUserProfile: false,
        showAd: false,
        showNotifications: false,
        showAchievementPage: false,
        showTournamentArchieveHistory: false,
      };
    case ActionTypes.SHOW_NOTIFICATIONS:
      return {
        ...state,
        currentRightSidebarState: "notification",
        // showNotifications: true,
        // showUserProfile: false,
        // showAchievementPage: false,
        // showAd: false,
        // showTournamentArchieveHistory: false
      };
    case ActionTypes.HIDE_NOTIFICATIONS:
      return {
        ...state,
        currentRightSidebarState: "ad",
        // showNotifications: false,
        // showUserProfile: false,
        // showAchievementPage: false,
        // showAd: true,
        // showTournamentArchieveHistory: false
      };
    case ActionTypes.SHOW_USER_PROFILE:
      return {
        ...state,
        currentRightSidebarState: "profile",
        // showNotifications: false,
        // showUserProfile: true,
        // showAchievementPage: false,
        // showAd: false,
        // showTournamentArchieveHistory: false
      };
    case ActionTypes.HIDE_USER_PROFILE:
      return {
        ...state,
        currentRightSidebarState: "ad",
        // currentRightSidebarState: 'ad',
        // showNotifications: false,
        // showUserProfile: false,
        // showAchievementPage: false,
        // showAd: true,
        // showTournamentArchieveHistory: false
      };
    case ActionTypes.SHOW_ACHIEVEMENT_PAGE:
      return {
        ...state,
        currentRightSidebarState: "achievement",
        // showNotifications: false,
        // showUserProfile: false,
        // showAchievementPage: true,
        // showAd: false,
        // showTournamentArchieveHistory: false
      };
    case ActionTypes.HIDE_ACHIEVEMENT_PAGE:
      return {
        ...state,
        currentRightSidebarState: "profile",
        // showNotifications: false,
        // showUserProfile: true,
        // showAchievementPage: false,
        // showAd: false,
        // showTournamentArchieveHistory: false
      };
    case ActionTypes.RESET_LAYOUT_DATA:
      return {
        ...state,
        currentRightSidebarState: null,
        // isDesktop: false,
        //  showNotifications: false,
        // showUserProfile: false,
        // showAd: false, 
        redDot: false,
      };
    case ActionTypes.SHOW_RED_DOT:
      return {
        ...state,
        redDot: true,
      };
    case ActionTypes.HIDE_RED_DOT:
      return {
        ...state,
        redDot: false,
      };
    case ActionTypes.SHOW_POP_UP:
      return {
        ...state,
        popUp: true,
      };
    case ActionTypes.HIDE_POP_UP:
      return {
        ...state,
        popUp: false,
      };
    case ActionTypes.SHOW_ARCHIEVE_TOURNAMENT_HISTORY:
      return {
        ...state,
        currentRightSidebarState: "tournamentArchieveHistory",
        // showNotifications: false,
        // showUserProfile: false,
        // showAchievementPage: false,
        // showAd: false,
        // showTournamentArchieveHistory: true
      };
    case ActionTypes.HIDE_ARCHIEVE_TOURNAMENT_HISTORY:
      return {
        ...state,
        currentRightSidebarState: "profile",
        // showNotifications: false,
        // showUserProfile: true,
        // showAchievementPage: false,
        // showAd: false,
        // showTournamentArchieveHistory: false
      };

    case ActionTypes.FETCH_COMMON_DATA_SUCCESS:
      if (loggedIn) return state;
      else return { ...state, loginPopupVisibility: true };

    case ActionTypes.SHOW_LOGIN_POPUP:
      return { ...state, loginPopupVisibility: true };
    case ActionTypes.PLATFORM_LOADED:
      return { ...state, platformLoaded: true };
    case ActionTypes.HIDE_LOGIN_POPUP:
      return { ...state, loginPopupVisibility: false };
    case ActionTypes.SHOW_NOTIFICATIONSTATUS:
      return { ...state, notifyStatus: true };
    case ActionTypes.HIDE_NOTIFICATIONSTATUS:
      return { ...state, notifyStatus: false };
    case ActionTypes.SHOW_LEADERBOARDPOPUP:
      return {
        ...state,
        openPrize: action.payload.openPrize,
        tournament: action.payload.tournament,
      };
    case ActionTypes.HIDE_LEADERBOARDPOPUP:
      return { ...state, openPrize: false, tournament: null };
    case ActionTypes.SHOW_THEME_LAYOUT:
      return { ...state, layoutType: action.layoutType };
    case ActionTypes.FETCH_LOGINPOPUP_COUNTER: {
      return {
        ...state,
        loginPopUpCounter: action?.loginPopUpCounter,
      };
    }

    case ActionTypes.SHOW_RECOMMENDED_GAME:
      return { ...state, recommendedGame: true };

    case ActionTypes.HIDE_RECOMMENDED_GAME:
      return { ...state, recommendedGame: false };

    case ActionTypes.SHOW_PLAY_GAME_BUTTON:
      return { ...state, playGameButtonClick: true };

    case ActionTypes.HIDE_PLAY_GAME_BUTTON:
      return { ...state, playGameButtonClick: false };

    case ActionTypes.DEDUCT_LOGINPOPUP_COUNTER:
      if (state.loginPopUpCounter > 0) {
        state.loginPopUpCounter = state.loginPopUpCounter - 1;
      }

      return { ...state };
    default:
      return state;
  }
};
 */
/* export const fetchLoginPopCounter = () => (dispatch: AppDispatch, getState: Function) => {
  const {
    common: {
      application: {
        applicationsetting: { login_popup_counter },
      },
    },
  } = getState() || {};
  if (!isNaN(login_popup_counter)) {
    dispatch({
      type: ActionTypes.FETCH_LOGINPOPUP_COUNTER,
      loginPopUpCounter: login_popup_counter,
    });
  }
};

export const deductLoginPopUpCounter = () => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.DEDUCT_LOGINPOPUP_COUNTER,
  });
};
 */