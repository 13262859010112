import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/Store";

export interface ITeam {
  userTeam: any;
  teamList?: [];
  loading: boolean;
  error: boolean;
}

const initialState: ITeam = {
  userTeam: null,
  loading: false,
  error: false
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    fetchTeamLoading(state) {
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchTeamSuccess(state, action) {
      state.loading = false;
      state.error = false;
    },
    fetchTeamError(state) {
      state.loading = false;
      state.error = true;
    },
    setTeam(state, action) {
      state.userTeam = {
        ...action?.payload
      };
      state.error = true;
    }
  }
});

export const {
  fetchTeamLoading,
  fetchTeamSuccess,
  fetchTeamError,
  setTeam
} = teamSlice.actions;

export default teamSlice.reducer;

export const setUserTeam = (team: any) => (dispatch: AppDispatch) => {
  dispatch(setTeam(team));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const clearUserTeam = (team: any) => (dispatch: AppDispatch) => {
  dispatch(setTeam(null));
};
