import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import monopolyAPI from "actions/api/monopoly";
// import { uniqueId } from "lodash";

export interface IMonopoly {
  config: any;
  userState: any;
  leaderboard: any[] | null;
  activeCards: any[] | null;
  rollBalance: number;
  pointBalance: number;
  loading: boolean;
  error: boolean;
  layout: {
    showUserInfoEdit: boolean;
    leaderboardLoading: boolean;
  };
}

const initialState: IMonopoly = {
  config: null,
  userState: null,
  leaderboard: null,
  activeCards: null,
  rollBalance: 0,
  pointBalance: 0,
  loading: false,
  error: false,
  layout: {
    showUserInfoEdit: false,
    leaderboardLoading: false,
  },
};

const monopolySlice = createSlice({
  name: "monopoly",
  initialState,
  reducers: {
    fetchMonopolyConfigBegin(state) {
      state.loading = true;
    },
    fetchMonopolyConfigSuccess(state, action) {
      const { user_state } = action.payload;
      const { roll_remain } = user_state;
      state.rollBalance = roll_remain;
      state.userState = user_state;
      state.config = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchMonopolyConfigError(state) {
      state.config = null;
      state.loading = false;
      state.error = true;
    },
    fetchMonopolyUserStateBegin(state) {
      state.loading = true;
    },
    fetchMonopolyUserStateSuccess(state, action) {
      state.userState = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchMonopolyUserStateError(state) {
      state.userState = null;
      state.loading = false;
      state.error = true;
    },
    updatePosition(state, action) {
      // state.userState = null;
      try {
        state.config.user_state.dice_position = action.payload;
      } catch (e) {
        // handle error
      }
    },
    fetchMonopolyLeaderboardBegin(state) {
      state.layout.leaderboardLoading = true;
    },
    fetchMonopolyLeaderboardSuccess(state, action) {
      state.leaderboard = action.payload;
      state.layout.leaderboardLoading = false;
      // state.loading = false;
      // state.error = false;
    },
    fetchMonopolyLeaderboardError(state) {
      state.leaderboard = null;
      state.layout.leaderboardLoading = false;
      // state.loading = false;
      // state.error = true;
    },
    fetchMonopolyActiveCardsBegin() {
      // state.loading = true;
    },
    fetchMonopolyActiveCardsSuccess(state, action) {
      state.activeCards = action.payload;
      // state.loading = false;
      state.error = false;
    },
    fetchMonopolyActiveCardsError(state) {
      state.activeCards = [];
      // state.loading = false;
      state.error = true;
    },
    fetchMonopolyRollBalanceBegin(state) {
      state.loading = true;
    },
    fetchMonopolyRollBalanceSuccess(state, action) {
      const { rolls } = action.payload;
      state.rollBalance = rolls;
      state.loading = false;
      state.error = false;
    },
    deductMonopolyRollBalanceSuccess(state) {
      state.rollBalance = state.rollBalance - 1;
      // state.loading = false;
      // state.error = false;
    },
    addMonopolyRollBalanceSuccess(state) {
      state.rollBalance = state.rollBalance + 1;
      // state.loading = false;
      // state.error = false;
    },
    fetchMonopolyRollBalanceError(state) {
      // state.userState = null;
      state.loading = false;
      state.error = true;
    },
    fetchMonopolyPointBalanceBegin(state) {
      state.loading = true;
    },
    fetchMonopolyPointBalanceSuccess(state, action) {
      state.pointBalance = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchMonopolyPointBalanceError(state) {
      // state.userState = null;
      state.loading = false;
      state.error = true;
    },
    beginMonopolyLoading(state) {
      state.loading = true;
    },
    stopMonopolyLoading(state) {
      state.loading = false;
    },
    showMonopolyUserInfoEdit(state) {
      state.layout.showUserInfoEdit = true;
    },
    hideMonopolyUserInfoEdit(state) {
      state.layout.showUserInfoEdit = false;
    },
  },
});

export const {
  beginMonopolyLoading,
  stopMonopolyLoading,
  fetchMonopolyConfigBegin,
  fetchMonopolyConfigSuccess,
  fetchMonopolyConfigError,
  fetchMonopolyUserStateBegin,
  fetchMonopolyUserStateSuccess,
  fetchMonopolyUserStateError,
  fetchMonopolyLeaderboardBegin,
  fetchMonopolyLeaderboardSuccess,
  fetchMonopolyLeaderboardError,
  fetchMonopolyPointBalanceBegin,
  fetchMonopolyPointBalanceError,
  fetchMonopolyPointBalanceSuccess,
  deductMonopolyRollBalanceSuccess,
  addMonopolyRollBalanceSuccess,
  fetchMonopolyRollBalanceBegin,
  fetchMonopolyRollBalanceSuccess,
  fetchMonopolyRollBalanceError,
  fetchMonopolyActiveCardsBegin,
  fetchMonopolyActiveCardsSuccess,
  fetchMonopolyActiveCardsError,
  updatePosition,
  showMonopolyUserInfoEdit,
  hideMonopolyUserInfoEdit,
} = monopolySlice.actions;

export default monopolySlice.reducer;

export const fetchMonopolyConfig =
  (
    app_slug: string,
    // params?: { limit?: number; claimed?: boolean }
  ) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const monopoly: IMonopoly = getState()?.monopoly;
    if (monopoly?.loading) return;
    dispatch(fetchMonopolyConfigBegin());
    return api
      .post(`monopoly/init/`, { app_slug })
      .then(response => {
        dispatch(fetchMonopolyConfigSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchMonopolyConfigError()));
  };

export const fetchMonopolyUserState =
  (
    boardSlug: string,
    // params?: { limit?: number; claimed?: boolean }
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchMonopolyConfigBegin());
    return api
      .post(`monopoly/userstate/${boardSlug}`, {})
      .then(response => {
        dispatch(fetchMonopolyConfigSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchMonopolyConfigError()));
  };

export const fetchMonopolyLeaderboard =
  (
    boardSlug: string,
    // params?: { limit?: number; claimed?: boolean }
  ) =>
  async (dispatch: any, getState: any) => {
    const monopoly: IMonopoly = getState().monopoly;
    const loading = monopoly?.layout?.leaderboardLoading;
    return new Promise((resolve, reject) => {
      // resolve([]); //ODO - UNDO
      // return;
      // eslint-disable-next-line no-unreachable
      if (loading) {
        resolve(monopoly?.leaderboard);
      } else {
        dispatch(fetchMonopolyLeaderboardBegin());
        api
          .get(`monopoly/leaderboard/${boardSlug}/`)
          .then((response: any) => {
            dispatch(fetchMonopolyLeaderboardSuccess(response.data));
            resolve(response.data);
          })
          .catch(error => {
            dispatch(fetchMonopolyLeaderboardError());
            reject(error);
          });
      }
    });
  };

export const fetchMonopolyDiceValue = (boardSlug: string) => async (dispatch: any, getState: any) => {
  // dispatch(beginMonopolyLoading());
  const monopoly = getState()?.monopoly;
  const campaign = monopoly?.config?.campaign_data?.slug || null;
  return new Promise((resolve, reject) => {
    api
      .post(`monopoly/user/${boardSlug}/roll/`, { campaign })
      .then(response => {
        resolve(response.data);
        /*  resolve({
            roll: 1,
            request_id: "9939a1c6d59e40abb270"
          }); */
      })
      .catch(error => {
        reject(error);
      });
  });
};

// /user/action/8be4da3b7185405eb30e/

export const fetchMonopolyCardPrize =
  (request_id: string, params: { user_approval?: boolean } = {}) =>
  async (dispatch: AppDispatch, getState: any) => {
    const monopoly = getState().monopoly;
    const campaign = monopoly?.config?.campaign_data?.slug || null;
    // dispatch(beginMonopolyLoading());
    return new Promise((resolve, reject) => {
      api
        .post(`monopoly/user/action/${request_id}/`, { ...params, campaign })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

export const fetchMonopolyPointBalance =
  ({ boardSlug = null }) =>
  async (dispatch: any) => {
    // dispatch(beginMonopolyLoading());
    return new Promise((resolve, reject) => {
      api
        .post(`monopoly/user/partner/balance/${boardSlug}/`, {}, { timeout: 20000 })
        .then(response => {
          const { amount } = response.data;
          dispatch(fetchMonopolyPointBalanceSuccess(amount));
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

export const fetchMonopolyRollBalance =
  ({ boardSlug = null }) =>
  async (dispatch: AppDispatch) =>
    new Promise((resolve, reject) => {
      api
        .post(`monopoly/user/roll/balance/${boardSlug}/`)
        .then(response => {
          dispatch(fetchMonopolyRollBalanceSuccess(response.data));
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

export const deductMonopolyRollBalance = () => (dispatch: AppDispatch, getState: any) => {
  const { rollBalance = 0 } = getState().monopoly;
  if (rollBalance > 0) {
    dispatch(deductMonopolyRollBalanceSuccess());
  }
};

export const addMonopolyRollBalance = () => (dispatch: AppDispatch) => {
  dispatch(addMonopolyRollBalanceSuccess());
};

export const updateMonopolyUserPosition = (position: number) => (dispatch: AppDispatch) => {
  dispatch(updatePosition(position));
};

export const showMonopolyUserInfoEditModal = () => (dispatch: AppDispatch) => {
  dispatch(showMonopolyUserInfoEdit());
};

export const hideMonopolyUserInfoEditModal = () => (dispatch: AppDispatch) => {
  dispatch(hideMonopolyUserInfoEdit());
};

export const fetchMonopolyActiveCards =
  (boardSlug = "") =>
  async (dispatch: AppDispatch, getState: any) => {
    const monopoly = getState().monopoly;
    const campaign = monopoly?.config?.campaign_data?.slug || null;
    // if (loading) dispatch(fetchMonopolyRollBalanceBegin());
    return new Promise((resolve, reject) => {
      api
        .post(`monopoly/user/active/cards/${boardSlug}/`, { campaign })
        .then((response: any) => {
          dispatch(fetchMonopolyActiveCardsSuccess(response.data));
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

export const beginMonopolyPayment =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (boardSlug: string, params: any) => async (dispatch: any) => {
    // dispatch(beginMonopolyLoading());
    return new Promise((resolve, reject) => {
      api
        .post(`monopoly/payment/${boardSlug}/init/`, params)
        .then((response: any) => {
          // dispatch(stopMonopolyLoading());
          resolve(response.data);
        })
        .catch(error => {
          // dispatch(stopMonopolyLoading());
          reject(error);
        });
      // .finally(() => dispatch(stopMonopolyLoading()));
    });
  };

export const fetchUserState = () => async (dispatch: AppDispatch, getState: any) => {
  const monopoly = getState().monopoly;
  const boardSlug = monopoly?.config?.board?.slug;
  return new Promise((resolve, reject) => {
    api
      .get(`monopoly/user/state/${boardSlug}/`)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    // .finally(() => dispatch(stopMonopolyLoading()));
  });
};

export const fetchMonopolyUserRank = () => async (dispatch: AppDispatch, getState: any) => {
  const monopoly = getState().monopoly;
  const boardSlug = monopoly?.config?.board?.slug;
  return new Promise((resolve, reject) => {
    api
      .get(`monopoly/leaderboard/${boardSlug}/user/rank/`)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    // .finally(() => dispatch(stopMonopolyLoading()));
  });
};
