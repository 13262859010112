import { css } from "@emotion/react";
import FLAME_REGULAR from "assets/fonts/Burger_king/FlameRegularSans.ttf";

const ooredooStyles = (theme: ITheme) => css`
  @font-face {
    font-family: "Flame Regular";
    src: local("Flame Regular"), url(${FLAME_REGULAR}) format("truetype");
    font-weight: normal;
  }

  &.ooredoo {
    * {
      font-family: "Flame Regular";
    }

    .ticket-entry-fee-container {
      img {
        transform: none !important;
      }
    }

    .fontHeadStyle,
    .fontSubStyle,
    .package-title,
    .tournament-history-btn {
      color: #ffd100 !important;
    }

    .stats-empty {
      color: #ffd100 !important;
    }

    #profile-page,
    .mobile-footer-page {
      background-color: #000000 !important;
    }

    .top-tournament,
    .browse-style {
      color: #ffd100 !important;
    }

    .btn-container > svg,
    .rank-icon {
      fill: #000000 !important;
    }

    .clock-participant-bg-svg {
      fill: #ed1c24 !important;
      bottom: 33px !important;
    }

    .padd-sty,
    .header-color,
    .background-sty {
      background: #000000 !important;
    }

    .icon-style {
      fill: #ffd100 !important;
    }

    .icon-play {
      fill: #ffd100 !important;
    }

    .change-package-button,
    .modal-footer-btn {
      color: #ffffff !important;
    }

    .clock-participant-text,
    .rank-list-container-first,
    .rank-list-container,
    .modal_header,
    .leaderboard-wrapper,
    .prize-list-container,
    .list-group-item,
    .text-header,
    .balance,
    .subtitle-4,
    .header-style,
    .mobile-footer-page .text-center {
      color: #ffff00 !important;
    }

    .clock-participant-text > div > svg {
      fill: #ffff00 !important;
    }

    .clock-participant-text {
      bottom: 35px !important;
    }

    .button-ooredoo {
      .activated-button {
        color: #ffd100 !important;
      }
      .activated-button:before {
        border-bottom: 2px solid #ffd100 !important;
      }
      .not-active-button {
        color: #ffffff !important;
      }
    }

    .custom-page {
      background: #000000 !important;
    }

    .profile-page-body {
      .show-all-text {
        color: #ffffff;
      }
    }

    .profile-pvp-stats > .title-md,
    .button_icon > svg,
    .btn-style,
    .custom-page .list-heading > .text-style,
    .redeem,
    .img-sty,
    .background-contain > div > div,
    .name-style,
    .score,
    .prizes-slider-item,
    .btn-orange > .button-style,
    .btn-orange,
    .dollar {
      color: #ffffff !important;
    }

    .notification-page {
      background-color: transparent !important;
      color: #ffffff !important;
    }

    .list-group-item > div > div,
    .list-group-item > div > div > span {
      color: #000000 !important;
    }

    .active_progress_bottom_curve,
    .modal_header,
    .desktop-loader,
    .game-loading-overlay,
    .modal_body,
    button > .loading-style,
    .recommended-games,
    .tournament-history-page,
    .leaderboard-body {
      background-color: #000000 !important;
    }

    .tournament-history-page {
      .header-style {
        background-color: transparent !important;
      }
    }

    .desktop-footer {
      .footer-content {
        background-color: #000000 !important;
      }
    }
    .leaderboard-popup-item {
      .gamename-style {
        color: #ffff00 !important;
      }
    }

    .back-arrow {
      fill: #ed1c24 !important;
      stroke: #ed1c24 !important;
    }

    .background-contain > div > div,
    .loading-style,
    .unlock-style {
      background-color: #ffff00 !important;
      > * {
        color: #000000 !important;
      }
      &.ticket-history-body {
        background-color: #ffffff !important;
      }
    }

    .square-card,
    .single-game-card > .square-card.active-card {
      background-color: #333333 !important;
    }
    /* .prize-list-container, */
    .leaderboard-wrapper,
    .rank-list-container-first,
    .white-card-design {
      background-color: #878787 !important;
    }

    .prizes-slider-item {
      background-color: #ff2425;
    }

    .browse-popup {
      background-color: #000000 !important;
      border: 2px solid #ffd100 !important;

      .gamename-style {
        color: #ffd100 !important;
      }

      .modal_container_2 .title-md {
        color: #ffd100 !important;
      }

      .close_wrapper {
        svg {
          fill: #ffd100 !important;
        }
      }
    }

    .leaderboard-popup-item {
      .leaderboard-wrapper {
        border: 2px solid #ffd100 !important;
      }
      .modal_header {
        border-top: 2px solid #ffd100 !important;
        border-left: 2px solid #ffd100 !important;
        border-right: 2px solid #ffd100 !important;
      }

      .close {
        fill: #ffd100 !important;
      }
    }

    .subscription-modal-header {
      .back-btn {
        svg {
          stroke: #ffd100 !important;
          fill: #ffd100 !important;
        }
      }
    }

    .subs-close-icon {
      fill: #ffd100 !important;
    }

    .play-btn-browse-container {
      .play-btn-browse {
        svg {
          fill: #ffd100 !important;
        }
      }
    }

    .play-btn-browse {
      svg {
        fill: #ffd100 !important;
      }
    }

    .mission-page-container {
      padding-top: 6.8rem !important;
    }

    .section-header > h4 {
      color: #ffd100 !important;
    }

    .mission-page-container {
      background: #000000 !important;
    }

    .reward-sty,
    .label-no-ranks,
    .billing-type {
      color: #ffd100 !important;
    }

    .list-heading > div {
      color: #ffffff !important;
    }

    .small_button {
      box-shadow: #ffff00 0px 5px 10px;
    }

    .recommended-tournament-container {
      display: none !important;
    }

    .userprofile-background,
    .progressContainer,
    .rounded-div,
    .ticket-status,
    .partner-status {
      background-color: #ed1c24 !important;
    }

    .ticket-status > div > img,
    .img-sty,
    .reward-img,
    .heroes img {
      transform: none !important;
    }

    .partner-status > div > img,
    .img-sty,
    .reward-img,
    .heroes img {
      transform: none !important;
    }

    .active-card > .card-title,
    .square-card > .card-title,
    .list-group-item > div > .prize-amount,
    .list-group-item > div > .prize-amount > span {
      color: #ffd100 !important;
    }

    .token-circle > .img-sty {
      background-color: transparent !important;
    }

    .img-sty {
      padding-bottom: 0 !important;
    }

    .square-card > .action_button {
      bottom: 0.5rem !important;
    }

    .list-group-item > div > .prize-amount > span,
    .helper_text {
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }

    .partner-status > div > span,
    .font-play,
    .ticket-info-title,
    .ticket-info-container > p,
    .modal-content > .modal-footer > div > button {
      color: #ffffff !important;
    }

    .homepage-background,
    .userprofile-background {
      box-shadow: -1pt 3pt 5px 0px #ffd100;
    }

    .ticket-status > div > span {
      color: #ffffff !important;
    }

    .top-tournament-card > div > div > .img-style {
      max-width: 100%;
    }

    .small-card {
      background-color: #e6e7e8;
    }

    .modal_container_2 {
      .title-md {
        color: #ffffff !important;
      }
    }

    .play-btn-browse {
      svg {
        fill: #000000 !important;
      }
    }

    .second-half {
      .btn-circle-with-icon {
        background: #ffd100 !important;
        box-shadow: 0 12px 17px -7px #ffd100 !important;
      }
    }
    .board-container {
      .label-style {
        background: #73da91 !important;
      }
      .tile {
        fill: #ffd100 !important;
      }
    }

    .custom-page {
      .first-part {
        background: #ed1c24 !important;
      }
    }

    .game-over-mission-wrapper {
      .missions__item {
        border: 3px solid #ffd100;
        &.claimed {
          filter: grayscale(0%);
        }
        .play-button {
          color: #000000;
          background: #ffd100;
          box-shadow: none;
          font-weight: bold;
        }
        .text-claimed {
          color: #ffd100;
        }

        background: #000000;
        .text-progress {
          color: #ffffff;
        }
        .text-title {
          color: #ffffff;
        }
        .clock-icon {
          fill: #ffffff;
        }
        .text-timer {
          color: #ffffff;
        }
      }
    }

    .recommended-games-container {
      position: relative;
      top: 0;

      .recommended-games {
        padding-top: 7px !important;
      }
    }

    .arcade-game-page,
    .arcade-game {
      background-color: #000000 !important;
    }

    .arcade-headerSty2,
    .heading-ooredoo,
    .trending-game,
    .arcade-all-game {
      color: #ffd100 !important;
    }

    .header-first,
    .played-count {
      color: #ffffff !important;
    }

    /* .icon {
      fill: #ffffff !important;
    } */

    .progress-share-overlay {
      .half-style {
        background: #ed1c24 !important;
      }
    }

    .modal-content {
      .background {
        background: #000000 !important;
      }
    }

    .share-icon {
      .icon {
        fill: #000000 !important;
      }
    }

    .score-body {
      .label-style {
        background: #333333 !important;
      }

      .name-style {
        color: #000000 !important;
      }
    }

    .text-header {
      color: #000000 !important;
    }

    .top-bar,
    .background-contain {
      background: #000000 !important;
    }

    .top-bar-top {
      background: #ed1c24 !important;
    }

    .redeem {
      color: #ed1c24 !important;
    }

    .ticket-history-body {
      .header-style {
        background: #ed1c24 !important;
      }
    }
    .no-back-btn {
      .button-group {
        width: calc(100% + 5000px);
      }
    }

    .link-img {
      .active-link {
        .icon {
          fill: #ed1c24 !important;
        }
      }
    }

    .validity {
      color: #ffd100 !important;
    }

    .ticket-redeem-page {
      background: #000000 !important;
      .page-wrapper {
        background: #000000 !important;
        .text-value {
          color: #ffd100 !important;
        }

        .first-section {
          background: #ed1c24 !important;
        }
      }
    }

    .ticket-reward-page {
      background: #000000 !important;

      .expired-notice {
        background: #ed1c24 !important;
      }
      .page-wrapper {
        .first-section {
          background: #ed1c24 !important;
          .header-text {
            color: #ffd100 !important;
          }
        }
      }
    }

    .back-button-wrapper {
      .back-arrow {
        fill: #ffd100 !important;
        stroke: #ffd100 !important;
      }
    }

    .small-right-header-2 {
      .header-section {
        color: #ffd100 !important;
      }
    }

    .backbutton-off {
      color: #ffd100 !important;
    }

    .game-card-container {
      .game-card {
        .game-title {
          background: linear-gradient(
            167deg,
            rgba(0, 0, 0, 0.88) 0%,
            rgb(217 6 6 / 53%) 50%,
            rgba(0, 0, 0, 0.88) 100%
          ) !important;
        }
      }
    }

    .subs-modal {
      .modal-content {
        background-color: #000000 !important;
        border: 1px solid #ffd100 !important;
      }
      .modal-title {
        h6 {
          color: #ffd100 !important;
        }
      }

      .benefit-list {
        color: #ffd100 !important;
      }
    }

    .mission-page-container {
      .section-header {
        padding-top: 25px !important;
      }
    }

    .subscribe-confirmation-modal {
      .modal-content {
        background-color: #000000 !important;
        border: 1px solid #ffd100 !important;

        .subscription-modal-title {
          color: #ffd100 !important;
        }

        .subscription-modal-body {
          color: #ffd100 !important;
        }
      }
    }

    .subs-close-icon {
      svg {
        fill: #ffd100 !important;
      }
    }

    .back-btn {
      fill: #ffd100 !important;
    }

    .desktop-layout {
      background-color: #000000 !important;

      .home-container {
        background-color: #000000 !important;
      }
    }

    .desktop-sidebar {
      .sidebar-container a:not(.active-link) .link-img {
        fill: #9d9191 !important;
        color: #9d9191 !important;
      }

      .sidebar-container a:not(.active-link) .link-label {
        fill: #9d9191 !important;
        color: #9d9191 !important;
      }

      .active-link .link-label {
        color: #ed1c24 !important;
      }
    }
  }
`;

export default ooredooStyles;
