import React from "react";
import { css } from "@emotion/react";
import downArrowIcon from "assets/images/subscription/arrow_down.svg";
import upArrowIcon from "assets/images/subscription/arrow_up.svg";
import { Trans } from "react-i18next";
import { useAppSelector } from "redux/Store";
import { Accordion } from "react-bootstrap";

const accordionStyle = css`
  .faq-accordion {
    width: 90%;
    margin: 0 auto;
  }
  .accordion-item {
    margin-bottom: 15px;
    &:has(.collapse) {
      border-color: #ed1c24;
    }
  }
  .arrow-icon {
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .accordion-content {
    border: none;
    border-radius: 0 0 20px 20px;
    max-height: 0;
    font-size: 14px;
    overflow: hidden;
    padding: 0px 14px;
    color: #949494;
    ul {
      list-style: none;
      padding-inline-start: 0px;
      li {
        line-height: px;
      }
    }
  }
  .accordion-item {
    border-radius: 23px !important;
  }
  .accordion-button {
    border-top-left-radius: 23px !important;
    border-top-right-radius: 23px !important;
    background-color: #f7f7f7;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 1.125rem;
    justify-content: space-between;
    min-height: 20px;
    padding: 14px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: #ed1c24;
    &.collapsed {
      background-color: #ed1c24;
      color: #ffffff;
      border-radius: 23px !important;
      &:after {
        background-image: url(${downArrowIcon});
      }
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &:after {
      background-image: url(${upArrowIcon});
      background-position: center;
    }
  }
`;

const FaqAccordion = () => {
  const { package_info } = useAppSelector((state: any) => state.subscription);

  return (
    <div css={accordionStyle}>
      <div className="text-center px-3 mb-4">
        <h4>
          <Trans i18nKey="faq">FAQ</Trans>
        </h4>
      </div>
      <div className="faq-accordion">
        <Accordion>
          {package_info?.faq.map((data: any, index: number) => (
            <div key={index}>
              <Accordion.Item eventKey={`event-${index}`}>
                <Accordion.Header> {data?.title} </Accordion.Header>
                <Accordion.Body>{data?.faq_text}</Accordion.Body>
              </Accordion.Item>
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqAccordion;
