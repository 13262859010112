import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
// import tournamentsAPI from "actions/api/tournaments";
import { css } from "@emotion/react";
import api from "actions/api";

const closeButtonStyle = css`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
`;

export default function WinnerNotificationPopUp() {
  const [imageSrc, _setImageSrc] = useState();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    api
      .get(`tournaments/winner-notification/`)
      .then(response => {
        _setImageSrc(response?.data?.response?.popup_winner_notification_url);
        return response.data;
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (!imageSrc) return null;

  return (
    <Modal show={showModal} centered>
      <div>
        <span css={closeButtonStyle} onClick={handleCloseModal}>
          &times;
        </span>
        <img src={imageSrc} alt="Modal" css={{ width: "100%", height: "100%", objectFit: "contain" }} />
      </div>
    </Modal>
  );
}
