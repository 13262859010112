import React, { useEffect, useCallback, useContext, useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import cn from "classnames";

// import useQueryParams from "hooks/useQueryParams";
// import motion from "utils/polyFilledMotion";
import { WindowSizeContext } from "providers/WindowSizeProvider";
// import { AnimatePresence } from "framer-motion";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { fetchActiveTournaments } from "actions";
import { Trans } from "react-i18next";
import { darken, lighten, rgba } from "polished";

import { ReactComponent as Close } from "assets/images/closedown.svg";
// import { useHistory } from "react-router-dom";
import PvpTournamentListItem from "components/feature-specific/pvp/PvpTournametListItem";
import TournamentListItem from "components/feature-specific/tournaments/TournamentListItem";

import DraggableModal from "components/feature-specific/Modals/DraggableModal";
import { useHistory } from "hooks/useHistory";
import useImageError from "hooks/useImageError";
import { toggleGameListPopup, togglePvPTournament } from "redux/reducers/modalReducer";

export default function TournamentListPopUp() {
  const { gamelistpopup, gameSlug, pvpTournamentType } = useAppSelector((state: any) => state.modal);
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const { allTournaments, allGames } = useAppSelector(({ tournaments }: any) => tournaments);
  const history = useHistory();
  // const queryParams = useQueryParams();
  const { width } = useContext(WindowSizeContext);
  const dispatch = useAppDispatch();
  const theme: ITheme = useTheme();
  const { application, country } = useAppSelector((state: any) => state.common);
  const slug = application?.slug;

  // const tournamentIdParam = queryParams.get("tournament");
  // const tournamentId = tournamentIdParam ? parseInt(tournamentIdParam) : 0;

  // |-------------PVP Tournament List---------------|
  const { onImageError } = useImageError();

  const gameWisePVPTournaments = allTournaments?.filter(
    (tournament: ITournament) => tournament.game === gameSlug && tournament.tournament_type === "instant-pvp",
  );

  const gameWisePVPTournamentData = allGames?.filter((d: any) => d?.slug === gameSlug);

  const pvpGame: IGame = gameWisePVPTournamentData[0];
  const pvpTournaments: ITournament[] = useMemo(() => gameWisePVPTournaments || [], [gameWisePVPTournaments]);

  // |--------------PVP Tournament List---------------|

  // |------Browse Game Tournament List---------------|
  const gameWiseBrowseTournaments = allTournaments?.filter(
    (tournament: ITournament) => tournament.game === gameSlug && tournament.tournament_type === "regular",
  );

  const gameWiseBrowseTournamentData = allGames?.filter((d: any) => d?.slug === gameSlug);

  const game: IGame = allGames.find((g: IGame) => g?.slug === gameSlug);

  const browseGame: IGame = gameWiseBrowseTournamentData[0];
  const browseTournaments: ITournament[] = useMemo(() => gameWiseBrowseTournaments || [], [gameWiseBrowseTournaments]);

  const tournamentWithListTitle = gameWiseBrowseTournaments.find((t: any) => !!t.list_title);
  const gameTitle = tournamentWithListTitle ? tournamentWithListTitle?.list_title : game?.name;

  const coverImage = pvpGame?.cover_image;

  // |------Browse Game Tournament List---------------|

  const handleDragEnd = useCallback(
    (event: any, info: any) => {
      if (info.offset.y > 0) {
        if (pvpTournamentType) {
          dispatch(togglePvPTournament());
          dispatch(toggleGameListPopup());
        } else {
          dispatch(toggleGameListPopup());
        }
      }
      dispatch(fetchActiveTournaments(slug, country));
    },
    [country, dispatch, pvpTournamentType, slug],
  );

  const commonDragProps: any = {
    drag: "y",
    dragelastic: 0.01,
    dragconstraints: { top: 0, bottom: 0 },
    onDragEnd: handleDragEnd,
  };

  const onDismiss = () => {
    if (pvpTournamentType) {
      dispatch(togglePvPTournament());
      dispatch(toggleGameListPopup());
      return;
    }
    dispatch(toggleGameListPopup());
  };

  useEffect(() => {
    if (gamelistpopup) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    }
    return () => {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    };
  }, [gamelistpopup]);

  /**
   * logic to disable scroll in background body
   * when popup is open
   * * FOR iOS
   */
  useEffect(() => {
    if (gamelistpopup) {
      document.body.classList.add("body-overflow-hidden");
      document?.getElementsByClassName("custom-page")[0]?.classList?.add("custom-page-modal-open");
    } else {
      document.body.classList.remove("body-overflow-hidden");
      document.body.classList.remove("body-top-tournament");
      document?.getElementsByClassName("custom-page")[0]?.classList.remove("custom-page-modal-open");
    }
    return () => {
      document.body.classList.remove("body-overflow-hidden");
      document.body.classList.remove("body-top-tournament");
      document?.getElementsByClassName("custom-page")[0]?.classList?.remove("custom-page-modal-open");
    };
  }, [
    gamelistpopup,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document?.getElementsByClassName("custom-page")[0],
    history.location.pathname,
  ]);

  if (!pvpTournaments || !pvpGame) return null;

  if (!browseTournaments || !browseGame) return null;

  return (
    <div>
      <DraggableModal openModal={gamelistpopup} onDismiss={onDismiss}>
        <div
          css={wrapModalStyle}
          className={cn(`leaderboard-container ${slug}`, {
            desktop: isDesktop,
            "tournament-list-popup": true,
            container: width >= 1022,
            triangle: theme.layout === "TRIANGLE",
            boxed: theme.layout === "BOXED",
          })}
        >
          <div css={cardCSS} className={`browse-popup ${slug}`}>
            <div className="img-div-style col-12 text-center">
              <img src={coverImage} alt="coverimage" className="img-style" onError={onImageError} />
            </div>
            <span
              className={cn("gamename-style text-center mb-3 title-md", {
                "ps-4": isDesktop,
              })}
            >
              {gameTitle}
            </span>
            <div className="mt-4">
              <div className="drag-handle" {...commonDragProps} />
            </div>

            <div className="modal_container_2">
              <div className="close_wrapper">
                <Close
                  fill={theme.text.secondary}
                  className={cn("close", {
                    "mob-close": !isDesktop,
                    "desk-close": isDesktop,
                  })}
                  onClick={() => {
                    if (pvpTournamentType) {
                      dispatch(togglePvPTournament());
                      dispatch(toggleGameListPopup());
                      return;
                    }
                    dispatch(toggleGameListPopup());
                  }}
                />
              </div>
              <div {...commonDragProps}>
                <div className="container contain-style">
                  <span className="title-md">
                    <Trans i18nKey="tournaments">Tournaments</Trans>
                  </span>
                  <div
                    className={cn("small-card mt-2", {
                      "me-3 ms-3 small-card": theme.layout === "BOXED",
                    })}
                  >
                    {pvpTournamentType
                      ? pvpTournaments.map((tournament: ITournament) => (
                          <PvpTournamentListItem key={tournament.id} tournament={tournament} game={pvpGame} />
                        ))
                      : browseTournaments.map((tournament: ITournament) => (
                          <TournamentListItem key={tournament.id} tournament={tournament} game={browseGame} />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DraggableModal>
    </div>
  );
}

const wrapModalStyle = (theme: ITheme) => css`
  &.triangle,
  &.dark {
    .play-button {
      background: ${theme.button.primaryGradientStart};
      padding-left: 9px;
      padding-right: 9px;
      padding-top: 23px;
      padding-bottom: 15px;
      border-radius: 19px;
      top: 0;
      right: 20px;
      box-shadow: 2px 10px 10px 0px ${lighten(0.4, theme.button.primaryGradientStart)};
      border: none;
      &:focus {
        outline: none;
      }
    }
    .play-btn-browse,
    .play-btn {
      color: ${theme.button.buttonTextColor};
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );

      width: 78px;
      height: 34px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: 500;
      border: none;
      box-shadow: 0px 11px 10px ${rgba(theme.button.primaryGradientStart, 0.15)};
      &:focus {
        outline: none;
      }
      svg {
        fill: ${theme.foreground.primary};
        width: 20%;
        height: 100%;
      }
      @media (max-width: 320px) {
        width: 65px !important;
      }
    }

    .tri-style {
      position: relative;
      height: 60vh;
    }

    .frame {
      position: absolute;
      width: 100%;
      height: auto;
      bottom: 0%;
      z-index: 99;
      filter: drop-shadow(10px 10px 15px ${theme.button.primaryShadow});
    }

    .game-detail {
      position: absolute;
      z-index: 100;
      bottom: 5%;
      padding-left: 30px;

      @media (min-width: 600px) and (max-width: 800px) {
        bottom: 20%;
      }

      @media (min-width: 450px) and (max-width: 599px) {
        bottom: 13%;
      }
    }

    .font-style {
      color: ${theme.background.primary};
    }

    .free {
      color: ${theme.background.primary};
    }

    .text-sty {
      color: ${theme.background.primary};
      font-size: 10px;
    }

    /* .user {
      width: 9.72pt;
      height: 11.65pt;
    } */

    .clock {
      width: 12px;
      height: 12px;
      font-size: 10px;
    }

    .font-play {
      font-size: 10px;
      color: ${theme.background.primary};
    }

    .rank-button {
      position: absolute;
      right: 80px;
      background-color: white;
      border-radius: 13px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 15px;
      padding-left: 15px;
      font-size: 10px;
      top: 37%;
      box-shadow: 2px 10px 10px 0px ${lighten(0.2, theme.button.primaryShadow)};
      border: none;
      &:focus {
        outline: none;
      }
    }

    .rank-btn {
      border: 1px solid ${theme.button.primaryShadow};
      background: ${theme.foreground.primary};
      width: 78px;
      height: 34px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: 500;
      top: 0px;
      box-shadow: 0px 11px 10px ${rgba(theme.button.primaryShadow, 0.15)};
      &:focus {
        outline: none;
      }
      svg {
        fill: ${theme.background.tertiary};
        width: 35%;
        height: 100%;
      }

      @media (max-width: 320px) {
        width: 65px;
      }
    }

    /* .play-btn {
      color: ${theme.button.buttonTextColor};
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );
      width: 100px;
      max-width: 85px;
      font-weight: 500;
      font-size: 11px;
      border-radius: 10px;
      height: 37px;
      border: none;
      box-shadow: 2px 10px 10px 0px
        ${rgba(theme.button.primaryGradientStart, 0.25)};
      &:focus {
        outline: none;
      }
    } */

    .btn-disabled {
      opacity: 0.8;
    }

    .footer-style {
      background-color: ${theme.background.secondary};
    }

    .clock-style {
      width: 150px;
    }
  }
  &.globe {
    &.triangle {
      .play-btn-browse,
      .play-btn {
        background: #2274e5;
        box-shadow: 2px 10px 10px 0px ${lighten(0.4, rgba(34, 116, 229, 1))};
      }
      .rank-btn svg {
        fill: #2274e5;
      }
    }
  }
  &.boxed {
    .card-style {
      margin-top: 54vh;
      margin-bottom: 0;
      padding-bottom: 1.5rem;
      min-height: calc(46vh - 75px - 13px);
    }

    .btn-sty {
      color: ${theme.button.buttonTextColor};
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );

      width: 70px;
      height: 34px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: 500;
      border: none;
      box-shadow: 0px 11px 10px ${rgba(theme.button.primaryGradientStart, 0.15)};
      &:focus {
        outline: none;
      }
      svg {
        fill: ${theme.foreground.primary};
        width: 20%;
        height: 100%;
      }
      @media (max-width: 320px) {
        width: 65px !important;
      }
    }
    .second-card {
      padding-bottom: 20px;
    }
  }
`;

const cardCSS = (theme: ITheme) => css`
  padding-top: 24px;
  height: 100vh;
  background-color: ${darken(0.1, theme.background.primary)};
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  &.globe {
    background-color: #f3f6ff;
    .small-card {
      color: #2c4763;
    }
    .play-btn {
      background: #2274e5;
      box-shadow: 2px 10px 10px 0px ${lighten(0.4, "#2274E5")};
    }
  }
  .modal_container_2 {
    .close_wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999999 !important;
      cursor: pointer;
    }
  }

  .leaderboard-container {
    flex-grow: 1;
    overflow-y: scroll;

    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .drag-handle {
    padding: 0.5rem 0 0.7rem;
    width: 100%;
    position: absolute;
    top: 75px;
    margin-top: 3px;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      width: 60px;
      height: 4px;
      margin: auto;
      border-radius: 5px;
      background-color: ${theme.text.secondary};
    }
  }

  .img-div-style {
    position: absolute;
    top: -50px;
    color: white;
  }

  .img-style {
    border-radius: 15px;
    border: 1pt solid white;
    height: 100px;
    /* box-shadow: -1px 12px 8px 0 rgb(79 81 87 / 30%); */
  }

  .gamename-style {
    font-weight: 600;
    color: white;
    padding-top: 30px;

    @media (min-width: 1022px) and (max-width: 2000px) {
      padding-top: 30px;
    }
  }

  .close {
    width: 15px;
    height: 22px;
    position: absolute;
    opacity: 1.5 !important;
    top: 15px;
    right: 15px;
  }

  .mob-close {
    right: 15px;
  }

  /* .desk-close {
    right: 40px;
  } */

  .contain-style {
    /* padding-top: 65px; */
    font-size: 18px;
    font-weight: 500;
  }

  .small-card {
    background-color: ${theme.foreground.primary};
    border-radius: 15px;
    box-shadow: 0px 10px 20px 0px ${lighten(0.7, theme.button.primaryShadow)};
    overflow-y: scroll;
    max-height: 375px;
    .border-style {
      border-bottom: 0.1px solid #e0e5ea;
    }

    .border-style:last-child {
      border-bottom: none;
    }
  }

  .second-card {
    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-y: scroll;
  }

  .text-sty-label {
    font-size: 0.6rem;

    @media (min-width: 300px) and (max-width: 330px) {
      /* font-size: 7pt; */
    }
  }

  .text-browse {
    @media (min-width: 350px) and (max-width: 375px) {
      /* font-size: 7pt; */
    }

    @media (min-width: 300px) and (max-width: 330px) {
      /* font-size: 6pt !important; */
    }
  }
  b .footer-style {
    padding-bottom: 121px;
  }
`;
