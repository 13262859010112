import React from "react";
import { css } from "@emotion/react";
import Modal from "react-bootstrap/Modal";

import TotalStats from "components/feature-specific/auth/TotalStats";

const modalStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};

  .modal-content {
    width: 70%;
    border-radius: 15px;
    padding-top: 50px;
  }

  .modal-header {
    font-size: 10px;
    background-color: white;
    padding: 8px 13px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: none;
  }

  .modal-body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .auth-style {
    font-size: 35px;
    font-weight: 700;
    color: ${theme.button.primaryGradientStart};
    justify-content: center;
  }

  .body-text {
    margin-left: 45px;
    margin-right: 45px;
  }

  .auths {
    margin-bottom: 30px;
  }
  .text-style {
    font-weight: 700;
    color: ${theme.text.secondary};
  }
`;

const backgroundStyle = (theme: ITheme) => css`
  color: black;
  background-color: white;

  &.header {
    border: none;

    button.close {
      color: ${theme.text.primary};
      text-shadow: none;
    }
  }

  .label-style {
    justify-content: space-between;
    font-size: 10px;
  }

  .token {
    width: 10px;
  }

  .token-style {
    font-weight: 600;
    color: ${theme.text.primary};
  }

  .square-design {
    justify-content: space-around;
  }

  .square-style {
    background-color: white;
    width: 100%;
    border-radius: 5px;
    font-size: 10px;
    color: ${theme.text.primary};
    font-weight: 500;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px ${theme.button.primaryShadow};

    svg,
    svg g,
    svg circle,
    svg path,
    svg rect {
      fill: ${theme.button.primaryGradientStart};
    }
  }

  .close {
    &:focus {
      outline: none;
    }
  }

  .border-style {
    border-bottom: 1px solid #f2f2f2;
    font-size: 10px;
    padding: 10px 13px !important;
  }
`;

const innerSocialAuths = () => css`
  .inner {
    display: inline-block;
  }
`;

const outerSocialAuths = () => css`
  .outer {
    width: 100%;
    text-align: center;
  }
`;

export default function AuthModal({ show, onHide, tournamentId }: any) {
  if (show && localStorage) {
    localStorage.setItem("pvp", tournamentId);
  }
  function closeModal() {
    onHide();
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 100000 }}
      centered
      css={modalStyle}
      className="desktop-modal p-0"
      backdrop="static"
      show={show}
      onHide={closeModal}
    >
      <Modal.Header className="d-flex justify-content-center">
        <h2 className="auth-style">Sign In</h2>
      </Modal.Header>

      <Modal.Body className="body-style d-flex flex-column p-0" css={backgroundStyle}>
        <div className="d-flex justify-content-center body-text">
          <p className="text-center text-style">Please authenticate yourself to proceed further</p>
        </div>

        <div className="d-flex justify-content-center auths">
          <div css={outerSocialAuths}>
            <div css={innerSocialAuths}>
              <TotalStats />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
