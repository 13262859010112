import React, { useContext, useState, useEffect } from "react";
import { css, useTheme } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import cn from "classnames";
import { Trans } from "react-i18next";

import motion from "utils/polyFilledMotion";
import GTimer from "components/common/GTimer";
import useRankingButton from "hooks/useRankingButton";
import usePlayNowButton from "hooks/usePlayNowButton";

import { FaBan } from "react-icons/fa";
import { lighten, rgba } from "polished";

import { ReactComponent as Prize } from "assets/images/Icon-Prize.svg";
import { ReactComponent as Clock } from "assets/images/icon-clock2.svg";
// import { ReactComponent as JoystickIcon } from "assets/images/gaming.svg";
import { ReactComponent as IconRanking } from "assets/images/Ranking.svg";
import { ReactComponent as IconPlay } from "assets/images/Icon-Play.svg";
import { ReactComponent as NiuClock } from "assets/clients/Niu/Clock.svg";
import EntryFee from "components/common/EntryFee";
import { fetchTournamentDetails, hideLoginPopup, showLoginPopup } from "actions";
import { SdkContext } from "providers/client-sdk-providers";
import { handleCortexAnalyticsEvent } from "providers/client-sdk-providers/razer";
// import ConfirmedPurchase from "Shared/Components/Modals/TrialBased/ConfirmedPurchase";
import { APP_SLUGS } from "constants/constants";
import { isHTML, isStringEqual } from "utils";
import {
  getTournamentTryText,
  isTournamentEntryFeeVoucher,
  isTournamentTryAvailable,
  isTournamentTryBased,
} from "utils/tournamentUtils";

import PaymentModal from "components/feature-specific/payment/PaymentModal/PaymentModal";
import { selectedTournament } from "redux/reducers/paymentReducer";
import { isBarca, isBkash, isClaro, isNiu, isOoredoo, isRazer } from "utils/applicationSlug";
import useModalHandler from "hooks/useModalHandler";
import { hideProgressShareOverlay } from "redux/reducers/gameplayReducer";
import history from "HistoryWrapper";
import { IoPlayOutline } from "react-icons/io5";
import { ReactComponent as BarcaRanking } from "assets/clients/Barca/Ranking.svg";
import { AiOutlineClockCircle } from "react-icons/ai";
import { deductLoginPopUpCounter, showRecommendedGame } from "redux/reducers/layoutReducer";
import { toggleGameListPopup } from "redux/reducers/modalReducer";
import { UserIcon } from "utils/Icons";
import { ReactComponent as ClaroClock } from "assets/clients/Claro/Clock.svg";

// import { truncate } from "lodash";

/* const token = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  .token-radius {
    background: ${theme.background.primary};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    right: 50%;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token-icon {
    width: 12px;
    height: 12px;
  }
`;
 */
const payText = () => css`
  font-size: 12px;
  color: white;
`;

interface ITournamentListItemProps {
  tournament: ITournament;
  game: IGame;
  check?: any;
}

const itemStyle = (theme: ITheme) => css`
  .text-title {
    font-size: 10pt;
    font-weight: 600;

    @media (min-width: 300px) and (max-width: 330px) {
      font-size: 7pt;
    }

    @media (min-width: 451px) and (max-width: 900px) {
      font-size: 15px;
    }

    @media (min-width: 1000px) {
      padding-left: 92px;
    }
  }

  .btn-sty {
    color: #fefffb;
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );
    width: 65px;
    height: 34px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
    border: none;

    box-shadow: 0px 11px 10px ${rgba(theme.button.primaryGradientStart, 0.15)};
    &:focus {
      outline: none;
    }
  }

  .play-icon {
    fill: #ffffff;
    width: 20%;
    height: 100%;
  }

  .play-btn-browse {
    background: linear-gradient(270deg, rgb(176, 37, 51) 0%, rgb(255, 120, 77) 122.67%);
    width: 78px;
    height: 38px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 500;
    border: none;
    box-shadow: rgb(176 37 51 / 15%) 0px 11px 10px;
  }

  .rank-btn {
    background: linear-gradient(270deg, rgb(176, 37, 51) 0%, rgb(255, 120, 77) 122.67%);
    /* background: linear-gradient(270deg, rgb(176, 37, 51) 0%, rgb(255, 120, 77) 122.67%); */
    border: none;
    height: 38px;
    width: 78px;
    border-radius: 8px;
    font-size: 10px;
    position: relative;
    top: 0px;
    border: none;
    box-shadow: 2px 10px 10px 0px ${lighten(0.2, theme.button.primaryShadow)};
    &:focus {
      outline: none;
    }
    .rank-icon {
      fill: #ffffff;
      width: 35%;
      height: 100%;
    }
  }

  .name-sty {
    font-size: 10pt;
  }

  .style {
    @media (min-width: 451px) and (max-width: 900px) {
      margin-left: 80px;
    }
  }

  .clock-icon {
    width: 9.72pt;
    height: 11.65pt;
  }

  .button-alignment {
    @media (min-width: 300px) and (max-width: 370px) {
      padding: 0 5px 0 5px;
    }
  }

  .tries-text {
    font-size: 12px;
    text-decoration: underline;
    font-weight: 600;
    color: ${theme.text.tertiaryTextColor};

    @media (min-width: 300px) and (max-width: 320px) {
      font-size: 10px;
    }

    @media (min-width: 321px) and (max-width: 370px) {
      font-size: 11px;
    }
  }

  .button-style {
    border: none;
    background-color: transparent;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }

  .text-nowrap {
    white-space: nowrap;
    display: inline-block;
  }
`;
// TODO: change variable name "check" to "isPVP"
export default function TournamentListItem({ tournament, game, check }: ITournamentListItemProps) {
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const sdk = useContext(SdkContext);
  const { isRazerGuestUser = null } = sdk;
  const { openLeaderBoardPopUp } = useRankingButton();
  const [isExpired, setIsExpired] = useState(false);
  const { application, user } = useAppSelector((state: any) => state.common);
  const [modalShow, setModalShow] = useState(false);
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
  const { participants } = useAppSelector((state: any) => state.userTournaments);
  const { onPlayNowButtonClick } = usePlayNowButton(tournament, game);
  const { user_info } = useAppSelector((state: any) => state.subscription);

  const enableSubscriptionPackage = application?.enable_subscription && user_info.length === 0;

  const { openSubscriptionModal } = useModalHandler();

  const directPaymentEnabled = isBkash || isOoredoo; // NOTE: In future add more checks like isBkash || isOredoo

  const participant: IParticipant = participants?.find((p: IParticipant) => p.tournament === tournament?.id);

  const { allTournaments } = useAppSelector(({ tournaments }: any) => tournaments);

  const { participant_display_type, participant_count, max_participant } = tournament;

  const url = window.location.href;
  const isPlayInUrl = url.includes("play");

  const maxParticipantText = max_participant > 0 && max_participant;
  // const history = useHistory();

  useEffect(() => {
    const endTime = new Date(tournament.end_time);

    const currentTime = new Date();
    if (+currentTime >= +endTime) {
      setIsExpired(true);
    }
  }, [tournament]);

  useEffect(() => {
    if (tournament.id) {
      dispatch(fetchTournamentDetails(tournament?.id));
    }
  }, [dispatch, tournament?.id]);

  const tournaments = allTournaments?.filter(
    (tournamentDetail: ITournament) => tournamentDetail?.id === tournament?.id,
  );

  const currentTournament = (tournaments && tournaments.length > 0 && tournaments[0]) || null;

  const currentPlayButtonIcon = () => {
    if (isBarca) return <IoPlayOutline />;
    return <IconPlay />;
  };

  const getPlayButtonIcon = () => {
    if (!(user || isGuestUser)) {
      return <Trans i18nKey="sign-in">Sign In</Trans>;
    }
    if (isExpired) {
      return <FaBan />;
    }
    if (isTournamentTryBased(currentTournament)) {
      if (isTournamentTryAvailable(currentTournament)) {
        return currentPlayButtonIcon();
      } else {
        return (
          <div css={payText}>
            <span>
              <Trans i18nKey="pay">Pay</Trans>
            </span>
          </div>
        );
      }
    } else {
      if ((tournament?.entry_fee === 0 && tournament?.entry_fee_type !== "voucher") || participant) {
        return currentPlayButtonIcon();
      } else {
        return (
          <EntryFee
            entry_fee={currentTournament?.entry_fee}
            payMethod={currentTournament?.entry_fee_type}
            tournamentFeeCurrency={tournament?.entry_fee_currency}
          />
        );
      }
    }
  };

  const getTryBasedDescription = () => {
    if (!isTournamentTryBased(currentTournament)) {
      return null;
    }
    const textTry = getTournamentTryText(currentTournament);

    if (isTournamentTryAvailable(currentTournament)) {
      return (
        <div className="text-center tries-text pt-3 pe-1">
          <span className="title-xs">
            {currentTournament?.trial_balance}
            {""} <Trans i18nKey={textTry}>{textTry}</Trans>&nbsp;left
          </span>
        </div>
      );
    } else {
      return (
        <div className="text-center tries-text pt-3 pe-1">
          <button
            className="button-style title-xs"
            onClick={() => {
              if (isRazerGuestUser && isRazerGuestUser()) {
                if (
                  (!isTournamentTryBased(currentTournament) && currentTournament?.entry_fee > 0) ||
                  !isTournamentTryAvailable(currentTournament)
                ) {
                  dispatch(showLoginPopup()); // ANCHOR show login pop up
                  setTimeout(() => {
                    dispatch(hideLoginPopup()); // ANCHOR hide login pop up
                  }, 50);
                  return;
                }
              }
              if (sdk?.handlePayment) {
                sdk.handlePayment(currentTournament);
              } else {
                setModalShow(true);
              }
            }}
          >
            <div className="w-100 d-flex flex-row flex-wrap justify-content-center align-items-center">
              {isTournamentEntryFeeVoucher(tournament) ? (
                <>
                  <Trans i18nKey="apply-code">Apply Code</Trans>
                  &nbsp; <Trans i18nKey="for">for</Trans>&nbsp;
                </>
              ) : (
                <>
                  <Trans i18nKey="pay">Pay</Trans> &nbsp;
                  <EntryFee
                    entry_fee={currentTournament?.trial_packages?.[0]?.currency_value}
                    foodpandaAppIconColor={application?.slug === APP_SLUGS.FOODPANDA_APP}
                    payMethod={currentTournament?.trial_packages?.[0]?.currency}
                    trialMethod
                  />
                  &nbsp; <Trans i18nKey="for">for</Trans>&nbsp;
                </>
              )}
              {currentTournament?.trial_packages?.[0]?.trial_value}&nbsp;
              {textTry && <Trans i18nKey={textTry}>{textTry}</Trans>}
            </div>
          </button>
        </div>
      );
    }
  };

  const currentClockIcon = () => {
    if (isBarca) return <AiOutlineClockCircle />;
    if (isNiu) return <NiuClock className="niu-clock-icon" height={13} width={13} />;
    if (isClaro) return <ClaroClock height={14} />;
    return <Clock fill={theme.text.secondary} className="clock-icon" />;
  };

  const currentTournamentListSubtitle = () => {
    if (isHTML(tournament.list_subtitle)) {
      return (
        <span
          dangerouslySetInnerHTML={{ __html: tournament.list_subtitle }}
          className="coin text-title mb-2 align-self-start ps-1 title-xs"
        />
      );
    }

    return <Trans i18nKey="tournament-subt">{tournament.list_subtitle}</Trans>;
  };

  // const userIconSize = isNiu ? 15 : undefined;

  return (
    <div className="border-style tournament-list-item px-2" css={itemStyle}>
      <div
        className={
          // FIXME
          // eslint-disable-next-line no-constant-condition
          false
            ? "ms-2 me-2 pt-3 pb-3 d-flex flex-row justify-content-between"
            : "pt-3 pb-3 d-flex flex-row justify-content-between col-12 pe-0 flex-wrap"
        }
      >
        <div className="d-flex flex-column name-sty col ps-0 pe-0 align-self-center">
          {tournament.list_subtitle ? (
            currentTournamentListSubtitle()
          ) : (
            <div>
              <Prize />
              <span className="ps-1 coin">{tournament.prize_pool}</span>
            </div>
          )}
          {/* FIXME */}
          {/* eslint-disable-next-line no-constant-condition */}
          {true ? (
            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center pe-1">
                <UserIcon fill={!isBarca && !isNiu ? theme.text.secondary : "#ffffff"} size={15} />
                {/* <JoystickIcon width={20} height={20} fill={theme.text.secondary} /> */}

                <span
                  className={cn("ps-1 text-sty-label body-xs text-nowrap", {
                    "text-browse": application?.slug !== APP_SLUGS.FOODPANDA_APP,
                  })}
                >
                  {participant_display_type === "participant_only" && participant_count}
                  {participant_display_type === "participant" && (
                    <>
                      {participant_count} {maxParticipantText && "/" + maxParticipantText}
                    </>
                  )}
                  {participant_display_type === "game_play" && game.played_count}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center">
                {currentClockIcon()}

                <span
                  className={cn("ps-1 text-sty-label body-xs text-nowrap", {
                    "text-browse": application?.slug !== APP_SLUGS.FOODPANDA_APP,
                  })}
                >
                  <GTimer
                    onEnd={() => {
                      if (!isExpired) {
                        setIsExpired(true);
                      }
                    }}
                    endTime={tournament.end_time}
                    fields={["days", "hours", "minutes", "seconds"]}
                  />
                </span>
              </div>
            </div>
          ) : null}
        </div>

        <div className="d-flex flex-column">
          <div className="col d-flex justify-content-end button-alignment align-items-center ps-0 mb-0">
            {!check && (
              <div
                className={cn("play-btn-browse-container", {
                  "h-100": !isRazer,
                })}
                onClick={() => {
                  if (isRazerGuestUser && isRazerGuestUser() && tournament?.entry_fee > 0) {
                    dispatch(showLoginPopup()); // ANCHOR show login pop up
                    setTimeout(() => {
                      dispatch(hideLoginPopup()); // ANCHOR hide login pop up
                    }, 50);
                  } else if (isGuestUser) {
                    openLeaderBoardPopUp(tournament);
                  } else {
                    if (tournament?.entry_fee > 0 && !user && !isGuestUser) {
                      dispatch(showLoginPopup()); // ANCHOR show login pop up
                    } else {
                      openLeaderBoardPopUp(tournament);
                    }
                  }

                  // Call razer analytics event
                  if (tournament?.entry_fee === 0) {
                    handleCortexAnalyticsEvent("free-entry-tap");
                  } else if (tournament?.entry_fee_type === "razer-gold") {
                    handleCortexAnalyticsEvent("gold-entry-tap");
                  } else if (tournament?.entry_fee_type === "razer-silver") {
                    handleCortexAnalyticsEvent("silver-entry-tap");
                  }
                }}
              >
                <motion.button
                  // Disable Ranking Button if not a (Guest)User
                  disabled={!(isGuestUser || user)}
                  className={cn("me-2", {
                    "btn-sty rank-btn": theme.layout === "BOXED",
                    "rank-btn": theme.layout === "TRIANGLE" || theme.layout === "DARK",
                  })}
                  whileTap={theme.layout === "TRIANGLE" ? { scale: 0.9 } : { scale: 1 }}
                >
                  {isBarca ? <BarcaRanking /> : <IconRanking />}
                </motion.button>
              </div>
            )}

            <div
              className={cn("play-btn-browse-container", {
                "h-100": !isRazer,
              })}
              onClick={(e: any) => {
                if (tournament) {
                  dispatch(selectedTournament(tournament));
                }

                if (
                  (isPlayInUrl &&
                    enableSubscriptionPackage &&
                    currentTournament?.trial_gameplay_counter > currentTournament?.user_total_gameplay) ||
                  (isPlayInUrl && !enableSubscriptionPackage)
                ) {
                  if (tournament?.entry_fee === 0) {
                    dispatch(showRecommendedGame());

                    dispatch(hideProgressShareOverlay());
                  }
                }

                if (isExpired) return;

                // Call razer analytics event
                if (tournament?.entry_fee === 0) {
                  handleCortexAnalyticsEvent("free-entry-tap");
                } else if (tournament?.entry_fee_type === "razer-gold") {
                  handleCortexAnalyticsEvent("gold-entry-tap");
                } else if (tournament?.entry_fee_type === "razer-silver") {
                  handleCortexAnalyticsEvent("silver-entry-tap");
                }

                if (isRazerGuestUser && isRazerGuestUser()) {
                  if (!(!isTournamentTryBased(currentTournament) && currentTournament?.entry_fee === 0)) {
                    if (
                      (!isTournamentTryBased(currentTournament) && currentTournament?.entry_fee > 0) ||
                      !isTournamentTryAvailable(currentTournament)
                    ) {
                      dispatch(showLoginPopup()); // ANCHOR show login pop up
                      setTimeout(() => {
                        dispatch(hideLoginPopup()); // ANCHOR hide login pop up
                      }, 50);
                      return;
                    }
                  }
                }

                if (
                  isStringEqual(application?.slug, APP_SLUGS.GO3) &&
                  sdk?.handlePayment &&
                  isGuestUser &&
                  tournament?.entry_fee > 0
                ) {
                  // NOTE: TO SUPPORT GO3 Guest
                  sdk?.handlePayment(tournament);
                  return;
                }
                if (!isTournamentTryBased(currentTournament)) {
                  if ((tournament.entry_fee === 0 && tournament?.entry_fee_type !== "voucher") || participant) {
                    if (!isGuestUser && !user) {
                      dispatch(showLoginPopup());
                      return;
                    }

                    if (isGuestUser && application?.applicationsetting?.login_popup_counter > 0) {
                      if (enableSubscriptionPackage) {
                        dispatch(showLoginPopup());
                      } else {
                        dispatch(deductLoginPopUpCounter());
                        onPlayNowButtonClick(e);
                      }
                    } else if (enableSubscriptionPackage) {
                      //NOTE - SUBSCRIPTION_PURPOSE

                      if (currentTournament?.trial_gameplay_counter > currentTournament?.user_total_gameplay) {
                        dispatch(toggleGameListPopup());
                        onPlayNowButtonClick(e);
                      } else {
                        openSubscriptionModal();
                      }
                    } else {
                      dispatch(toggleGameListPopup());
                      onPlayNowButtonClick(e);
                    }
                  } else {
                    // ANCHOR show login pop up
                    if (isGuestUser) {
                      // TODO: Need to refactor for user and guestUser loginpopup
                      //-----------------------------------------------------------//
                      if (isStringEqual(application?.slug, APP_SLUGS.CROYDON)) {
                        history.push(`/signup`);
                        dispatch(toggleGameListPopup());
                      }
                      //-----------------------------------------------------------//

                      dispatch(showLoginPopup());
                    } else if (user) {
                      if (sdk?.handlePayment) {
                        sdk.handlePayment(currentTournament);
                      } else {
                        if (!directPaymentEnabled) {
                          dispatch(toggleGameListPopup());
                        }

                        onPlayNowButtonClick(e);
                      }
                    } else {
                      dispatch(showLoginPopup());
                    }
                  }
                } else {
                  if (isTournamentTryAvailable(currentTournament)) {
                    if (!directPaymentEnabled) {
                      dispatch(toggleGameListPopup());
                    }
                    onPlayNowButtonClick(e);
                  } else {
                    // ANCHOR show login pop up
                    if (isGuestUser) {
                      dispatch(showLoginPopup());
                    } else if (user) {
                      if (sdk?.handlePayment) {
                        sdk.handlePayment(currentTournament);
                      } else {
                        setModalShow(true);
                      }
                    } else {
                      dispatch(showLoginPopup());
                    }
                  }
                }
              }}
            >
              <motion.button
                className={cn({
                  "play-btn-browse": theme.layout === "TRIANGLE" || theme.layout === "DARK",
                  "btn-sty play": theme.layout === "BOXED",
                  "btn-disabled": isExpired,
                })}
                disabled={isExpired}
                whileTap={theme.layout === "TRIANGLE" ? { scale: 0.9 } : { scale: 1 }}
              >
                {getPlayButtonIcon()}
              </motion.button>
            </div>
          </div>

          {getTryBasedDescription()}
        </div>
      </div>

      <PaymentModal tournament={tournament} show={modalShow} onHide={() => setModalShow(false)} />

      {/* <ConfirmedPurchase
        tournament={tournament}
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
    </div>
  );
}
