import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "actions/api/tournaments";

export interface ITransactionState {
  data: any[];
  loading: boolean;
  error: boolean;
}

const initialState: ITransactionState = {
  data: [],
  loading: false,
  error: false,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    fetchTransactionLoading(state) {
      state.loading = true;
    },
    fetchTransactionSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchTransactionError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    },
  },
});

export const { fetchTransactionLoading, fetchTransactionSuccess, fetchTransactionError } = transactionSlice.actions;

export default transactionSlice.reducer;

export const fetchUserTransactions = () => async (dispatch: AppDispatch) => {
  dispatch(fetchTransactionLoading());
  return api
    .get(`tournaments/users/transaction-history/`)
    .then(response => {
      dispatch(fetchTransactionSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchTransactionError()));
};
