/* eslint-disable react-refresh/only-export-components */
import { fetchUserTournaments } from "actions";
import {
  hidePaymentLoading,
  onPaymentSuccess,
  setSelectedTournament,
  showPaymentLoading,
  updateRazerAccountInfo,
  updateRazerUserAuthInfo,
} from "redux/reducers/sdkReducer";
import i18n from "I18n";
import Store from "redux/Store";
import paymentHandler from "utils/paymentHandler";
import analytics from "utils/analytics";
import { isTournamentTryBased } from "utils/tournamentUtils";

const WINDOW_PARENT_URL = "*";
const SERVICE_NAME = "RazerCortexGoama";

type CortexPCMessagePayloadType = {
  serviceName: "RazerCortexGoama";
  type: string;
  content: any;
};

type CortexPCMessageDataType = {
  serviceName: string;
  type: string;
  content: {
    code: number; // code is 1 means success
    data: any;
    message: string; // it will has a error message when excute error
  };
};

export function sendEventsInfoToCortex(titles: string[], tournament: ITournament, username: string) {
  if (window?.Cortex) {
    const razer_id = username.split("__")[0];
    const body: any = {
      slug_id: tournament.game,
      tournament_id: tournament?.id,
      razer_id: razer_id,
    };

    if (window.Cortex.sendAchievementEvent) {
      titles.forEach(title => {
        if (title === "action_goama_tournament_entered" || title === "action_goama_tournament_play") {
          if (tournament?.entry_fee === 0 && tournament?.entry_fee_type === null) {
            body.tournament_type = "free";
          } else {
            body.tournament_type = tournament?.entry_fee_type;
          }
        }
        window.Cortex.sendAchievementEvent(title, JSON.stringify(body));
      });
    }
  }
}

export function showCortexGenericDialog(title: string, subtitle: string, body: string) {
  if (window?.Cortex) {
    window.Cortex.showGenericDialog(title, subtitle, body);
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: SERVICE_NAME,
      type: "showGenericDialog",
      content: { title, subtitle, body },
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  } else {
    console.error("Could not show Generic Dialog");
  }
}

export function showEarnMoreSilverDialog() {
  if (window.Cortex) {
    window.Cortex.showEarnMoreSilverDialog();
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: "RazerCortexGoama",
      type: "showEarnMoreSilverDialog",
      content: "",
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function showCortexPaymentNotice(status: "success" | "failed", tournamentType: "razer-silver" | "razer-gold") {
  if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: SERVICE_NAME,
      type: "showPaymentNotice",
      content: {
        status: status === "success" ? "success" : "fail",
        currency: tournamentType === "razer-gold" ? "gold" : "silver",
      },
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function getCortexAccountInfo() {
  if (window?.Cortex) {
    CortexRequestAsync("AccountInfo");
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: SERVICE_NAME,
      type: "getUserBalances",
      content: null,
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function showCortexAd() {
  if (window.Cortex) {
    //
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: "RazerCortexGoama",
      type: "showCortexAD",
      content: null,
    };
    window.parent.postMessage(JSON.stringify(payload), "*");
  }
}

export function hideCortexAd() {
  if (window.Cortex) {
    //
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: "RazerCortexGoama",
      type: "hideCortexAD",
      content: null,
    };
    window.parent.postMessage(JSON.stringify(payload), "*");
  }
}

export function handleCortexAnalyticsEvent(
  event: "tournament-entered" | "free-entry-tap" | "gold-entry-tap" | "silver-entry-tap",
) {
  if (window.Cortex) {
    try {
      switch (event) {
        case "tournament-entered":
          window.Cortex.eventActionTournamentEntered();
          break;
        case "free-entry-tap":
          window.Cortex.eventActionFreeEntryTap();
          break;
        case "gold-entry-tap":
          window.Cortex.eventActionGoldEntryTap();
          break;
        case "silver-entry-tap":
          window.Cortex.eventActionSilverEntryTap();
          break;
      }
    } catch (e) {
      console.log("Could not call Razer Analytics Event", event);
    }
  }
}

export function showCortexRedeemPrompt(type: string, amount: number) {
  if (window.Cortex) {
    const requestName = type === "razer-gold" ? "RedeemGoldPrompt" : "RedeemSilverPrompt";
    const params = [`${amount}`, "b"];
    CortexRequestAsync(requestName, params);
  } else if (window.parent) {
    const requestType = type === "razer-gold" ? "redeemGoldBalances" : "redeemSilverBalances";
    const totalSilver = Store.getState()?.sdkReducer?.razer?.silver_balance;
    const payload: CortexPCMessagePayloadType = {
      serviceName: SERVICE_NAME,
      type: requestType,
      content: {
        amount, // ready to deduct amount,
        action: "deduct", // default is deduct
      },
    };

    if (type === "razer-silver") {
      payload.content.total = totalSilver;
    }
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function showCortexPurchaseCurrencyDialog() {
  if (window.Cortex) {
    window.Cortex.showPurchaseCurrencyDialog();
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: "RazerCortexGoama",
      type: "showPurchaseCurrency",
      content: null,
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function getCortexUserAuth() {
  if (window.Cortex) {
    CortexRequestAsync("CortexUserAuth");
  } else if (window.parent) {
    const payload: CortexPCMessagePayloadType = {
      serviceName: "RazerCortexGoama",
      type: "getAccessToken",
      content: null,
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function handleCortexSocialShare(text: string, score?: number, url = "", tournamentId?: number) {
  const state = Store.getState();
  const { allTournaments, allGames } = state.tournaments;

  const tournament: ITournament | undefined = allTournaments.find((t: ITournament) => t?.id === tournamentId);

  const game: IGame | undefined = allGames.find((g: IGame) => g?.slug === tournament?.game);

  const isMobile = !!window.Cortex;
  const desktopURL = "https://www.razer.com/cortex";
  let shareUrl: string = isMobile ? url : desktopURL;

  // Set query parameters for razer mobile sharing
  if (isMobile && url) {
    shareUrl += `?nav=goama`;
    if (tournamentId) {
      shareUrl += `&tournament_id=${tournamentId}`;
    }
  }

  const cortexShareMessage = `I just scored {{ score }} in ${
    tournament?.list_title || game?.name
  } on Razer Cortex Games Tournament. You can download it here too.`;
  const desktopShareMessage = `I just scored {{ score }} in ${
    tournament?.list_title || game?.name
  } on Razer Cortex Tournament. you can download it here too.`;

  const defaultShareMessage = isMobile ? cortexShareMessage : desktopShareMessage;

  const shareMessage = i18n.t("global-share-me", {
    defaultValue: defaultShareMessage,
    score,
  });

  const shareText = `${shareMessage} ${shareUrl}`;
  if (isMobile) {
    window?.Cortex?.shareMessage(shareText);
  } else {
    const payload: CortexPCMessagePayloadType = {
      serviceName: "RazerCortexGoama",
      type: "showShareDialog",
      content: {
        type: "facebook",
        url: shareUrl, // the url wants to be shared
        title: shareMessage, // the text
        // content?: '', // just for weibo
        // pic?: '', // the content pics url, just for weibo
        hashtags: ["Razer", "Goama"], // hashtags for share
      },
    };
    window.parent.postMessage(JSON.stringify(payload), WINDOW_PARENT_URL);
  }
}

export function handleRazerPayment(tournament: ITournament) {
  const razerInfo = Store.getState()?.sdkReducer?.razer;
  const { entry_fee_type } = tournament;
  const isTryBased = isTournamentTryBased(tournament);
  if (isTryBased) {
    if (
      tournament?.trial_packages[0].currency === "razer-silver" &&
      razerInfo.silver_balance !== null &&
      razerInfo?.silver_balance < tournament?.trial_packages[0].currency_value
    ) {
      showEarnMoreSilverDialog();
    } else if (
      entry_fee_type === "razer-gold" &&
      razerInfo.gold_balance !== null &&
      razerInfo.gold_balance < tournament?.trial_packages[0].currency_value
    ) {
      showCortexPurchaseCurrencyDialog();
    } else {
      Store.dispatch(showPaymentLoading());
      Store.dispatch(setSelectedTournament(tournament));
      showCortexRedeemPrompt(tournament?.trial_packages[0].currency, tournament?.trial_packages[0].currency_value);
    }
  } else if (
    entry_fee_type === "razer-silver" &&
    razerInfo.silver_balance !== null &&
    razerInfo.silver_balance < tournament?.entry_fee
  ) {
    showEarnMoreSilverDialog();
  } else if (
    entry_fee_type === "razer-gold" &&
    razerInfo.gold_balance !== null &&
    razerInfo.gold_balance < tournament?.entry_fee
  ) {
    showCortexPurchaseCurrencyDialog();
  } else {
    Store.dispatch(showPaymentLoading());
    Store.dispatch(setSelectedTournament(tournament));
    showCortexRedeemPrompt(entry_fee_type, tournament?.entry_fee);
  }
}

function handlePaymentConfirmationFromRazer(isConfirmed: boolean) {
  if (isConfirmed) {
    const state = Store.getState();
    const { application, user, country } = state.common;
    const { selectedTournament: tournament, razer } = state.sdkReducer;
    const { razer_jwt } = razer;
    if (!tournament) {
      return;
    }
    Store.dispatch(showPaymentLoading());
    paymentHandler
      .initializePayment({
        application,
        country: country || "",
        user,
        tournament,
        extraData: { jwt_token: razer_jwt },
      })
      .then(() =>
        paymentHandler.verifyPayment(tournament).then(vr => {
          if (vr.data?.status === "done") {
            if (window.Cortex) {
              showCortexGenericDialog(
                i18n.t("success", { defaultValue: "SUCCESS" }),
                i18n.t("payment-success", {
                  defaultValue: "Payment Successful",
                }),
                i18n.t("tap-the-button-", {
                  defaultValue: "Tap the button to get started",
                }),
              );
            } else if (window.parent) {
              showCortexPaymentNotice("success", tournament?.entry_fee_type as "razer-silver" | "razer-gold");
            }
            getCortexAccountInfo();
            analytics.logRevenue(tournament?.id, tournament?.entry_fee, tournament?.entry_fee_type);
            if (tournament?.entry_fee_type === "razer-silver") {
              sendEventsInfoToCortex(
                ["action_goama_silver_entered", "action_goama_tournament_entered"],
                tournament,
                user.username,
              );
            } else if (tournament?.entry_fee_type === "razer-gold") {
              sendEventsInfoToCortex(
                ["action_goama_gold_entered", "action_goama_tournament_entered"],
                tournament,
                user.username,
              );
            }

            Store.dispatch(onPaymentSuccess());
          } else {
            Store.dispatch(onPaymentFailed());
            throw Error("Payment not successful");
          }
        }),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(e => {
        if (window.Cortex) {
          showCortexGenericDialog(
            i18n.t("oops!", { defaultValue: "OOPS!" }),
            "",
            i18n.t("transaction-fai", {
              defaultValue: "Transaction Failed - Please try again later",
            }),
          );
        } else if (window.parent) {
          showCortexPaymentNotice("failed", tournament?.entry_fee_type as "razer-silver" | "razer-gold");
        }
      })
      .finally(() => {
        Store.dispatch<any>(fetchUserTournaments(application?.slug, country || ""));
        Store.dispatch(hidePaymentLoading());
      });
  } else {
    Store.dispatch(hidePaymentLoading());
  }
}

export function CortexResponseAsync(requestName: string, requestUuid: string, data: any, error: any) {
  // console.log("Response uuid: " + requestUuid);
  // console.log("Response name: " + requestName);
  if (error) {
    alert(error);
  } else {
    switch (requestName) {
      case "AccountInfo":
        Store.dispatch(updateRazerAccountInfo(data));
        break;
      case "CortexUserAuth":
        Store.dispatch(updateRazerUserAuthInfo(data));
        break;
      case "RedeemGoldPrompt":
      case "RedeemSilverPrompt":
        handlePaymentConfirmationFromRazer(data.is_confirmed);
        break;
      default:
        alert(JSON.stringify(data));
        break;
    }
  }
}

export function CortexRequestAsync(requestName: string, params: string[] = ["a", "b"]) {
  if (window.Cortex) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const uuid = window.Cortex.requestAsync(requestName, params);
    // console.log("Request uuid: " + uuid);
  } else {
    // alert("Please use this via Cortex WebView. requestAsync ");
  }
}

export const isRazerGuestUser = () => {
  const { common } = Store.getState();
  return common?.user?.extra_data?.guest;
};

function handleCortexPCResponse(event: MessageEvent) {
  if (window.parent) {
    let parsedData: CortexPCMessageDataType;
    try {
      parsedData = JSON.parse(event.data);
      if (parsedData?.serviceName !== "RazerCortexGoama") {
        return;
      }
    } catch {
      // console.error("Could not parse JSON");
      return;
    }

    if (parsedData?.content?.code === 1) {
      switch (parsedData.type) {
        case "getUserBalances":
          {
            const { data } = parsedData.content;
            const goldBalance = data.gold;
            const silverBalance = data.silver;
            Store.dispatch(
              updateRazerAccountInfo({
                gold_balance: goldBalance,
                silver_balance: silverBalance,
              }),
            );
          }
          break; // Store balances into redux store
        case "getAccessToken":
          {
            const razer_jwt = parsedData?.content?.data;
            Store.dispatch(updateRazerUserAuthInfo({ razer_jwt }));
          }
          break;
        case "redeemGoldBalances":
        case "redeemSilverBalances":
          {
            const { isConfirmed } = parsedData.content.data;
            handlePaymentConfirmationFromRazer(isConfirmed);
          }
          break;
      }
    } else {
      // console.log("Request not successful:", parsedData);
    }
  }
}

window.addEventListener("message", handleCortexPCResponse, false);

window.responseAsync = CortexResponseAsync;
function onPaymentFailed(): any {
  throw new Error("Function not implemented.");
}
