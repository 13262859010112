import { css } from "@emotion/react";
import PROXIMA_NOVA_REGULAR from "assets/fonts/Converse/proximanova-regular.otf";
import PROXIMA_NOVA_BOLD from "assets/fonts/Converse/proximanova-bold.otf";

const converseStyles = (theme: ITheme) => css`
  @font-face {
    font-family: "Proxima Nova";
    src: local("Proxima Nova"), url(${PROXIMA_NOVA_REGULAR}) format("opentype");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "Proxima Nova";
    src: local("Proxima Nova"), url(${PROXIMA_NOVA_BOLD}) format("opentype");
    font-weight: 600;
  }

  &.converse {
    * {
      font-family: "Proxima Nova", sans-serif !important;
    }

    .button-group {
      img {
        max-width: 80%;
      }

      @media (width < 20rem) {
        img {
          max-width: 70%;
        }
      }
    }

    .navbar-logos {
      img {
        max-width: 80%;
      }
    }

    .signup-page,
    .login-page {
      .input-wrapper {
        border-radius: 0.9375rem;
        box-shadow: 0.0625rem 0.0625rem 0.625rem 0.0625rem ${theme.button.primaryShadow};
      }
    }

    .forget-password-page {
      form {
        input {
          box-shadow: 0.0625rem 0.0625rem 0.625rem 0.0625rem ${theme.button.primaryShadow};
        }
      }
    }

    .user-profile-customization {
      .support-style {
        box-shadow: 0rem 0.25rem 0.5rem ${theme.button.primaryShadow};
      }
    }

    .my-games-page {
      .browse-game-item {
        .rank-list-price {
          color: #00c3d7;
        }

        .play-btn {
          svg {
            fill: #ffffff;
          }
        }
      }
    }

    .leaderboard-container {
      .browse-popup {
        background-color: #ffffff;

        .gamename-style {
          color: #191414;
        }

        .small-card {
          box-shadow: 0rem 0.625rem 1.25rem 0rem ${theme.button.primaryShadow};
        }
      }
    }
  }
`;

export default converseStyles;
