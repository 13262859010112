import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  gamelistpopup: boolean;
  pvpTournamentType: boolean;
  congratsModalVisibility: boolean;
  gameSlug: string;
  showSubscriptionModal: boolean;
  showPaymentSuccessModal: boolean;
  showPaymentFailedModal: boolean;
  showUnsubscribeModal: boolean;
  showUnsubscribeSuccessModal: boolean;
}

const defaultState: ModalState = {
  gamelistpopup: false,
  pvpTournamentType: false,
  congratsModalVisibility: false,
  gameSlug: "",
  showSubscriptionModal: false,
  showPaymentSuccessModal: false,
  showPaymentFailedModal: false,
  showUnsubscribeModal: false,
  showUnsubscribeSuccessModal: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState: defaultState,
  reducers: {
    toggleGameListPopup(state, action: PayloadAction<{ gameSlug: string | null } | undefined | null>) {
      state.gamelistpopup = !state.gamelistpopup;
      if (!action?.payload?.gameSlug) {
        state.gamelistpopup = false;
        state.gameSlug = "";
      }
      state.gameSlug = action?.payload?.gameSlug ?? "";
    },
    togglePvPTournament(state) {
      state.pvpTournamentType = !state.pvpTournamentType;
    },
    toggleCongratsModal(state) {
      state.congratsModalVisibility = !state.congratsModalVisibility;
    },
    toggleOpenSubscriptionModal(state) {
      state.showSubscriptionModal = true;
    },
    toggleCloseSubscriptionModal(state) {
      state.showSubscriptionModal = false;
    },
    togglePaymentSuccessModal(state) {
      state.showPaymentSuccessModal = !state.showPaymentSuccessModal;
    },
    togglePaymentFailedModal(state) {
      state.showPaymentFailedModal = !state.showPaymentFailedModal;
    },
    toggleUnsubscribeModal(state) {
      state.showUnsubscribeModal = !state.showUnsubscribeModal;
    },
    toggleUnsubscribeSuccessModal(state) {
      state.showUnsubscribeSuccessModal = !state.showUnsubscribeSuccessModal;
    },
  },
});

export const {
  toggleGameListPopup,
  togglePvPTournament,
  toggleCongratsModal,
  toggleOpenSubscriptionModal,
  toggleCloseSubscriptionModal,
  togglePaymentSuccessModal,
  togglePaymentFailedModal,
  toggleUnsubscribeModal,
  toggleUnsubscribeSuccessModal,
} = modalSlice.actions;

export default modalSlice.reducer;

/* 
export const modalReducer = (state: ModalState = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.SHOW_GAMELIST_POPUP:
      return { ...state, gamelistpopup: true, gameSlug: action.gameSlug };
    case ActionTypes.HIDE_GAMELIST_POPUP:
      return { ...state, gamelistpopup: false };
    case ActionTypes.SHOW_PVPTOURNAMENT:
      return { ...state, pvpTournamentType: true };
    case ActionTypes.HIDE_PVPTOURNAMENT:
      return { ...state, pvpTournamentType: false };
    case ActionTypes.SHOW_CONGRATSMODAL:
      return { ...state, congratsModalVisibility: true };
    case ActionTypes.HIDE_CONGRATSMODAL:
      return { ...state, congratsModalVisibility: false };
    case ActionTypes.SHOW_SUBSCRIPTION_MODAL:
      return { ...state, showSubscriptionModal: true };
    case ActionTypes.HIDE_SUBSCRIPTION_MODAL:
      return { ...state, showSubscriptionModal: false };
    case ActionTypes.SHOW_PAYMENT_SUCCESS_MODAL:
      return { ...state, showPaymentSuccessModal: true };
    case ActionTypes.HIDE_PAYMENT_SUCCESS_MODAL:
      return { ...state, showPaymentSuccessModal: false };
    case ActionTypes.SHOW_UNSUBSCRIBE_MODAL:
      return { ...state, showUnsubscribeModal: true };
    case ActionTypes.HIDE_UNSUBSCRIBE_MODAL:
      return { ...state, showUnsubscribeModal: false };
    case ActionTypes.SHOW_UNSUBSCRIBE_SUCCESS_MODAL:
      return { ...state, showUnsubscribeSuccessModal: true };
    case ActionTypes.HIDE_UNSUBSCRIBE_SUCCESS_MODAL:
      return { ...state, showUnsubscribeSuccessModal: false };
    case ActionTypes.SHOW_PAYMENT_FAILED_MODAL:
      return { ...state, showPaymentFailedModal: true };
    case ActionTypes.HIDE_PAYMENT_FAILED_MODAL:
      return { ...state, showPaymentFailedModal: false };
    default:
      return state;
  }
};
 */
