import { css } from "@emotion/react";

const nequiStyles = css`
  &.nequi {
    .gamename-style {
      color: #000000;
    }

    .recommended-games-container {
      top: 0.625rem;
    }
  }
`;

export default nequiStyles;
