import React, { useState } from "react";
import { css } from "@emotion/react";
import cn from "classnames";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { updateUserName } from "actions";

type TUpdateNameFormProps = {
  onSubmit?: (e?: any) => void;
  hideTitle?: boolean;
};

export default function UpdateNameForm({ onSubmit, hideTitle = false }: TUpdateNameFormProps) {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAppSelector((state: any) => state.common);
  const { handleSubmit, register, errors } = useForm();
  const onFormSubmit = (data: any) => {
    setSubmitting(true);
    dispatch<any>(updateUserName(data.firstName))
      .then(() => onSubmit && onSubmit())
      .finally(() => setSubmitting(false));
  };
  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <div className="mb-3 name-update-modal" css={baseStyle}>
      {!hideTitle && (
        <Modal.Title id="contained-modal-title-vcenter" className="avatarStyle text-capitalize">
          <Trans i18nKey="update-name">Update Name</Trans>
        </Modal.Title>
      )}
      <div className="mt-2">
        <form method="post" className="row align-items-center" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="col-8">
            <input
              type="text"
              className={cn("input-field form-control", {
                "is-invalid": errors?.firstName,
              })}
              name="firstName"
              placeholder="Display Name"
              defaultValue={user?.first_name}
              ref={register({
                required: "This field is required",
                minLength: {
                  value: 2,
                  message: `${t("name-must-be-at", {
                    defaultValue: "Name must be at least 2 characters long",
                  })}`,
                },
                maxLength: {
                  value: 30,
                  message: `${t("name-can-be-at", {
                    defaultValue: "Name can be at most 30 characters long.",
                  })}`,
                },
              })}
            />
          </div>
          <div className="col ps-0">
            <Button disabled={submitting} type="submit" className="update-button">
              <Trans i18nKey="update">Update</Trans>
            </Button>
          </div>
        </form>
        {errors.firstName && (
          <div className="row">
            <div className="invalid-feedback d-block col-8">{errors.firstName.message}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const baseStyle = (theme: ITheme) => css`
  .input-field {
    width: 100%;
    background: ${theme.background.primary};
    border: none;
    padding: 5px 10px;
    border-radius: 10px;
    &:hover {
      outline: none;
    }
  }
`;
