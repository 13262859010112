import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import { Trans } from "react-i18next";

import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import RankListItem from "./RankListItem";
import { fetchUserContacts } from "redux/reducers/userFriendListReducer";

const containerCss = css`
  height: 220px;
  text-align: center;
  margin-top: 10%;
`;

const textCss = css`
  padding: 15px 20px 15px 20px;
  font-style: normal;
  letter-spacing: 0.8px;
  font-weight: 600;
`;

const rankListContainerCss = () => css`
  border: none;
  box-shadow: 0px 0px 12px rgba(80, 80, 80, 0.15);
  background: transparent;
  background-color: black;
  border-radius: 10px;
  & .list-group-item {
    border: none;
    border-bottom: 2px solid #f8fafa;
  }
  & .list-group-item:last-child {
    border-bottom: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .column-design {
    font-size: 12px;
    line-height: 16px;
  }
`;

const loadingStyle = css`
  min-height: 250px;
`;

const noRankDesign = css`
  height: 250px;
  display: flex;
  align-items: center;
`;

interface IFriendListProps {
  tournamentId: number;
  tournament: ITournament;
  participantId: number;
}

export default function FriendList({ tournamentId, tournament, participantId }: IFriendListProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [synchronized, setSynchronized] = useState<boolean>(false);

  // eslint-disable-next-line
  const [data, setData] = useState<any>({});

  const dispatch = useAppDispatch();
  const { results } = useAppSelector((state: any) => state.userFriendList);
  const { application } = useAppSelector((state: any) => state.common);
  const tournamentResults = results[tournamentId] ? results[tournamentId] : [];

  window.updateFriends = (data: any) => {
    const allowed = data.synchronized;
    setSynchronized(allowed);
    if (allowed) {
      dispatch<any>(fetchUserContacts(tournamentId, application?.slug, data.contacts)).then(() => setLoading(false));
    } else {
      setData(data);
      setLoading(false);
    }
  };

  const onSyncContacts = () => {
    if (window?.webkit !== undefined) {
      window?.webkit?.messageHandlers?.onSyncContacts?.postMessage("");
    } else if (typeof window?.app !== "undefined" && window?.app !== null) {
      window?.app?.onSyncContacts();
    }
  };

  useEffect(() => {
    onSyncContacts();
  }, []);

  const ownRank = tournamentResults.find((rank: any) => rank?.participant?.id === participantId);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center" css={loadingStyle}>
        <LoadingIndicator size={3} />
      </div>
    );
  else if (synchronized && tournamentResults.length > 1)
    return (
      <>
        {ownRank && (
          <div className="my-3" css={rankListContainerCss}>
            <RankListItem tournament={tournament} score={ownRank} key={ownRank} displayPrize={false} />
          </div>
        )}
        <ul className="ps-0 mb-0" css={rankListContainerCss}>
          <li className="li list-group-item">
            <div className="row column-design">
              <div className="col-2 col-items text-center">
                <Trans i18nKey="rank">Rank</Trans>
              </div>
              <div className="col col-items ps-2">
                <Trans i18nKey="name">Name</Trans>
              </div>
              <div className="col-2  col-items text-center">
                <Trans i18nKey="score">Score</Trans>
              </div>
            </div>
          </li>

          {tournamentResults.map((score: any, index: number) => (
            <RankListItem tournament={tournament} score={score} key={index} displayPrize={false} />
          ))}
        </ul>
      </>
    );
  else if (synchronized && tournamentResults.length <= 1)
    return (
      <div css={noRankDesign}>
        <h4 className="text-center w-100 px-3 label-no-ranks">
          <Trans i18nKey="no-ranks-to-sho">No Ranks to show yet!</Trans>
        </h4>
      </div>
    );
  return (
    <div css={containerCss}>
      <div css={containerCss}>
        {/*
          <div css={textCss}>{data.copy}</div>
          <div>
            <a href={data.deeplink} css={activeButtonCss} className="btn">
              {data.callToAction}
            </a>
          </div> 
          */}
        <div css={textCss}>Próximamente podrás ver el ranking de tus amigos</div>
      </div>
    </div>
  );
}
