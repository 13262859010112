import React, { useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";


const adUnitSizesByPosition = {
  "ooredoo-sticky-footer-home-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22889644592",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "234x60", "300x50", "320x50"]
  },
  "wave-mm-browse-game-section-1": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22453323821",
    sizes: ["88x31", "120x20", "120x30", "120x60", "125x125", "168x28", "168x42", "216x36", "216x54", "234x60", "240x133", "250x250", "300x50", "300x100", "300x250", "320x50", "320x100", "336x280", "360x50"],
  },
  "wave-mm-featured-game": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22771150516",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "300x50", "320x50"],
  },
  "wave-mm-play-game-page-bottom-tournament": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23142210495",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "300x50", "320x50"],
  },
  "wave-mm-play-game-page-top-tournament": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23141636768",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "300x50", "320x50"],
  },
  "wave-mm-sticky-footer-home-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22888848462",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "234x60", "300x50", "320x50"],
  },
  "wave-mm-sticky-header-game-over-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22888340015",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "300x50", "320x50"],
  },
  "ooredoo-arcade-in-game": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872334949",
    sizes: ["88x31", "120x20", "120x30", "168x28", "168x42", "216x36", "216x54", "234x60", "300x50", "320x50"],
  },
  
  "ooredoo-arcade-section-1": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872282874",
    sizes: ["88x31", "120x20", "120x30", "120x60", "168x28", "168x42", "216x36", "216x54", "234x60", "300x50", "300x100", "320x50", "320x100", "360x50"],
  },
  "ooredoo-arcade-section-2": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872283645",
    sizes: ["88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"],
  },
  "ooredoo-arcade-section-3": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872867534",
    sizes: [  "88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "ooredoo-arcade-section-4": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872283750",
    sizes: [  "88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "ooredoo-browse-game-section-1": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872867675",
    sizes: [  "88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100",   "320x50", "320x100", "360x50"]
  },
  "ooredoo-browse-game-section-2": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872284488",
    sizes: [  "88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100",   "320x50", "320x100", "360x50"]
  },
  "ooredoo-browse-game-section-3": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872335477",
    sizes: [  "88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100",   "320x50", "320x100", "336x280", "360x50"]
  },
  "ooredoo-browse-game-section-4": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872284767",
    sizes: [  "88x31", "120x20", "120x30", "120x60", "125x125",   "168x28", "168x42", "216x36", "216x54", "234x60",   "240x133", "250x250", "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "ooredoo-featured-game": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872870570",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "320x50"]
  },
  "ooredoo-interstitial-ad-before-game-play": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23006990348",
    sizes: []
  },
  "ooredoo-rewarded-ad": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872290521",
    sizes: []
  },
  "ooredoo-sticky-footer-ranking-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22889592139",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "ooredoo-sticky-header-game-over-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22890117045",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "ooredoo-top-sticky-ranking-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22890117108",
    sizes: [  "88x31", "120x20", "120x30",   "168x28", "168x42", "216x36",   "216x54", "234x60", "300x50",   "320x50"]
  },
  "ooredoo-tournament-game-over": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22872290749",
    sizes: [  "88x31", "120x20", "120x30", "125x125",   "168x28", "168x42", "216x36", "216x54",   "234x60", "240x133", "250x250",   "300x50", "300x100", "320x50",   "320x100", "360x50"]
  },
  "ooredoo-interstitial-ad-after-game-over": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23006991002",
    sizes: []
  },

  // GcashAliplus
  "gcashaliplus-arcade-section-1": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22907940740",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-arcade-section-3": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22951769560",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-arcade-section-4": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22951770262",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-browse-game-section-1": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23007691320",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-browse-game-section-2": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22988617397",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-browse-game-section-3": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23001191159",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-browse-game-section-4": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23006857108",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-play-game-page-bottom-tournament": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23141842032",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "gcashaliplus-play-game-page-top-tournament": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23141841879",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "gcashaliplus-sticky-footer-home-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22951890746",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "gcashaliplus-sticky-footer-ranking-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23006921491",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "gcashaliplus-sticky-header-game-over-page": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22896474388",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "234x60", "300x50", "320x50"]
  },
  "gcashaliplus-tournament-game-over": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/22896518654",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "125x125", "168x28", "168x42", "216x36",   "216x54", "234x60", "240x133", "250x250",   "300x50", "300x100", "300x250",   "320x50", "320x100", "336x280", "360x50"]
  },
  "gcashaliplus-interstitial-ad-before-game-play": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23006847781",
    sizes: []
  },
  "gcashaliplus-rewarded-ad": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23074118163",
    sizes: []
  },
  "gcashaliplus-play-game-page-bottom-arcade": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23204171293",
    sizes: [  "300x50", "320x50"]
  },
  "gcashaliplus-play-game-page-top-arcade": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23204171656",
    sizes: [  "300x50", "320x50"]
  },
  "gcashaliplus-featured-game": {
    ad_unit_path: "/22022010600/prod-parent-ad-unit/23205175749",
    sizes: [  "88x31", "120x20", "120x30", "120x60",   "168x28", "168x42", "216x36", "216x54",   "300x50", "320x50", "360x50"]
  }

}

const getAdUnitInfo = (name) => {
  const adUnit = adUnitSizesByPosition[name] || {};
  const sizes = adUnit.sizes || [];
  return {
    ad_unit_path: adUnit.ad_unit_path || "",
    sizes: sizes.map(size => {
      const [width, height] = size.split('x').map(Number);
      return [width, height];
    })
  };
};


export const HeaderBiddingAdvertisement = ({
    name,
    is_outofpage=false,
    height = "100%",
  }: {
    name: string;
    is_outofpage?: boolean;
    height?: string;
  }) => {

  const advertRef = useRef(null);
  const adUnitInfo = useMemo(() => getAdUnitInfo(name), [name]);
  const [adsProcessed, setAdsProcessed] = useState(false);
  // const [isGameOverPage, setGameOverPage] = useState(false);
  // const pathname = window.location.pathname;

  useEffect(() => {
    const existingScript = document.getElementById("hba");
      if (!existingScript) {
        const hbScript = document.createElement("script");
        hbScript.src = "https://cdn.adapex.io/hb/aaw.go-games.js";
        hbScript.async = false;
        hbScript.id = "hba";
        document.head.appendChild(hbScript);
      }
  }, []);

    // Check if the current URL matches the pattern "/tournaments/:id/play"
  // useEffect(() => {
  //   const tournamentPlayPattern = /^\/tournaments\/\d+\/play$/;

  //   // If the URL matches the pattern, return null
  //   if (tournamentPlayPattern.test(pathname)) {
  //       setGameOverPage(true);
  //   }
  // }, [name, pathname]);

  useLayoutEffect(() => {
    if (!adsProcessed && advertRef?.current) {
      (window.aaw = window.aaw || { cmd: [] }).cmd.push(function () {
        window.aaw.processAdsOnPage();
      });
      setAdsProcessed(true);
    }
    // Cleanup function to destroy the ad slots when the component unmounts
    // return () => {
    //   if (window?.aaw?.destroyAdunits) {
    //     if (isGameOverPage && name.includes("sticky-footer-home-page")) {
    //         window?.aaw?.destroyAdunits([adUnitInfo.ad_unit_path]);
    //     }
    //     if (!isGameOverPage && name.includes("sticky-header-game-over-page")) {
    //         window?.aaw?.destroyAdunits([adUnitInfo.ad_unit_path]);
    //     }
    //   }
    // };
    
  }, [adsProcessed, advertRef, adUnitInfo]);


  if (!name || !adUnitInfo) return null;

  return (
    <div
        ref={advertRef}
        id={adUnitInfo?.ad_unit_path}
        data-full-width-responsive="true"
        data-aaad="true"
        data-aa-adunit={adUnitInfo?.ad_unit_path}
        data-aa-outofpage={is_outofpage ? "true" : null}
        data-aa-lazy-loaded="true"
        {...(!is_outofpage && { "data-aa-sizes": JSON.stringify(adUnitInfo.sizes) })}
        style={{ width: "100%", minWidth: 250, height: "100%", textAlign: "center" }}
      />
  );
};
export default HeaderBiddingAdvertisement;