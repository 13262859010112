import React, { ReactNode, UIEvent, useState, useEffect } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import useQueryParams from "hooks/useQueryParams";
import "react-spring-bottom-sheet/dist/style.css";
import { Trans } from "react-i18next";
import { getTransKeyFromText } from "utils";
import useAsyncState from "hooks/useAsyncState";

interface DraggableModalProps {
  children?: ReactNode;
  openModal: boolean;
  onDismiss: Function;
  title?: string;
}

const DraggableModal = ({ children, openModal, onDismiss, title = "" }: DraggableModalProps) => {
  const [scrollHeight, setScrollHeight] = useState(0);
  const [draggable, setDraggable] = useState(true);
  const [isDismissed, setIsDismissed] = useAsyncState(false);
  const queryParams = useQueryParams();
  const tournamentIdParam = queryParams.get("tournament");
  const tournamentId = tournamentIdParam ? parseInt(tournamentIdParam) : 0;

  useEffect(() => {
    if (tournamentId) {
      document.body.classList.add("body-overflow-hidden");
      document?.getElementsByClassName("custom-page")[0]?.classList?.add("custom-page-modal-open");
    } else {
      document.body.classList.remove("body-overflow-hidden");
      document.body.classList.remove("body-top-tournament");
      document?.getElementsByClassName("custom-page")[0]?.classList.remove("custom-page-modal-open");
    }
    return () => {
      document.body.classList.remove("body-overflow-hidden");
      document.body.classList.remove("body-top-tournament");
      document?.getElementsByClassName("custom-page")[0]?.classList?.remove("custom-page-modal-open");
    };
  }, [tournamentId]);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const currentScrollHeight = event?.currentTarget?.scrollTop;

    setScrollHeight(currentScrollHeight);

    if (scrollHeight === 0) {
      setDraggable(true);
    } else if (scrollHeight > 10) {
      setDraggable(false);
    } else {
      setDraggable(true);
    }
  };

  const handleDismiss = async () => {
    if (!isDismissed) {
      await setIsDismissed(true);
      onDismiss();
    }
  };

  useEffect(() => {
    if (openModal) {
      setIsDismissed(false);
    }
  }, [openModal, setIsDismissed]);

  if (!openModal) return null;

  return (
    <BottomSheet
      className="draggable-modal"
      open={openModal}
      onDismiss={() => {
        (async () => {
          await handleDismiss();
        })();
      }}
      blocking={true}
      scrollLocking={false}
      expandOnContentDrag={draggable}
      skipInitialTransition={true}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 0.8]}
      initialFocusRef={false}
      /* onSpringEnd={event => {
        if (event.type === "SNAP" && event.source === "snap-to-bottom") {
          onDismiss();
        }
      }} */
    >
      <div className="d-flex flex-column justify-content-center">
        {title && (
          <span className="mb-3 title-text text-center pt-3">
            <Trans i18nKey={getTransKeyFromText(title)}>{title}</Trans>
          </span>
        )}

        <div id="draggable-modal-content" onScroll={handleScroll} draggable>
          {children}
        </div>
      </div>
    </BottomSheet>
  );
};

export default DraggableModal;
