import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/Store";

export interface IPaymentInfo {
  paymentModalVisibility: boolean;
  tournament?: any;
  partnerPaymentStatus?: any;
  paymentStatus?: string;
}

// Define the initial state
const initialPaymentState: IPaymentInfo = {
  paymentModalVisibility: false,
  tournament: null,
  partnerPaymentStatus: null,
  paymentStatus: "",
};

// Create a slice
const paymentStatusSlice = createSlice({
  name: "payment",
  initialState: initialPaymentState,
  reducers: {
    showPayment(state) {
      state.paymentModalVisibility = true;
    },
    hidePayment(state) {
      state.paymentModalVisibility = false;
    },
    setSelectedTournament(state, action) {
      state.tournament = {
        ...action?.payload,
      };
    },
    setPartnerPaymentStatus(state, action) {
      state.partnerPaymentStatus = {
        ...action?.payload,
      };
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload;
    },
    resetState(state) {
      state.paymentModalVisibility = false;
      state.tournament = null;
      state.partnerPaymentStatus = null;
    },
  },
});

// Export the action creators generated by createSlice
export const {
  showPayment,
  hidePayment,
  setSelectedTournament,
  setPartnerPaymentStatus,
  setPaymentStatus,
  resetState,
} = paymentStatusSlice.actions;

export const selectedTournament = (tournament: any) => (dispatch: AppDispatch) => {
  dispatch(setSelectedTournament(tournament));
};

export const updatePartnerPaymentStatus = (statusData: any) => (dispatch: AppDispatch) => {
  dispatch(setPartnerPaymentStatus(statusData));
};

export const updatePaymentStatus = (statusData: any) => (dispatch: AppDispatch) => {
  dispatch(setPaymentStatus(statusData));
};

export const resetPaymentState = () => (dispatch: AppDispatch) => {
  dispatch(resetState());
};

// Export the reducer
export default paymentStatusSlice.reducer;
