import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import paymentsAPI from "actions/api/payments";

export interface ISdkState {
  error: boolean;
  loading: boolean;
  razer: {
    gold_balance: number | null;
    silver_balance: number | null;
    razer_jwt: string | null;
  };
  foodpanda: {
    foodpanda_loading: boolean;
    coin_balance: any[];
  };
  gizer: {
    gizer_loading: boolean;
    coin_balance: number | null;
  };
  selectedTournament: ITournament | null;
  paymentSuccessful: boolean | null;
  paymentLoading: boolean;
}

const initialState: ISdkState = {
  error: false,
  loading: false,
  razer: {
    gold_balance: null,
    silver_balance: null,
    razer_jwt: null
  },
  foodpanda: {
    foodpanda_loading: false,
    coin_balance: []
  },
  gizer: {
    gizer_loading: false,
    coin_balance: null
  },
  selectedTournament: null,
  paymentSuccessful: null,
  paymentLoading: false
};

const sdkSlice = createSlice({
  name: "sdkReducer",
  initialState,
  reducers: {
    setSelectedTournament(state, action) {
      state.selectedTournament = action.payload;
    },

    updateRazerAccountInfo(state, action) {
      // state.loading = true;
      const { gold_balance, silver_balance } = action.payload;
      state.razer = {
        ...state.razer,
        gold_balance,
        silver_balance
      };
    },

    updateRazerUserAuthInfo(state, action) {
      // state.loading = true;
      const { razer_jwt = null } = action.payload;
      state.razer = {
        ...state.razer,
        razer_jwt
      };
    },

    foodpandaCoinInfoLoading(state) {
      state.loading = true;
    },

    updateFoodpandaCoinInfo(state, action) {
      state.foodpanda.coin_balance = action.payload;
      state.loading = false;
    },

    updateGizerCoinInfo(state, action) {
      state.gizer.coin_balance = action.payload;
      state.loading = false;
    },

    onPaymentSuccess(state) {
      state.paymentSuccessful = true;
      state.paymentLoading = false;
    },

    onPaymentFailed(state) {
      state.paymentSuccessful = false;
      state.paymentLoading = false;
    },

    resetPaymentStatus(state) {
      state.paymentSuccessful = null;
    },

    hidePaymentLoading(state) {
      state.paymentLoading = false;
    },

    showPaymentLoading(state) {
      state.paymentLoading = true;
    }
  }
});

export const {
  hidePaymentLoading,
  setSelectedTournament,
  onPaymentSuccess,
  showPaymentLoading,
  resetPaymentStatus,
  onPaymentFailed,
  updateRazerAccountInfo,
  foodpandaCoinInfoLoading,
  updateFoodpandaCoinInfo,
  updateGizerCoinInfo,
  updateRazerUserAuthInfo
} = sdkSlice.actions;

export default sdkSlice.reducer;

export const fetchFoodpandaCoinInfo = () => async (dispatch: AppDispatch) => {
  dispatch(foodpandaCoinInfoLoading());

  return api.get(`payments/foodpanda/coin/`).then(response => {
    dispatch(updateFoodpandaCoinInfo(response.data.data.balance));
    return response.data.data.balance;
  });
};

export const fetchGizerCoinInfo = () => async (dispatch: AppDispatch) => {
  dispatch(foodpandaCoinInfoLoading());

  return api.get(`payments/gizer/balance/`).then(response => {
    dispatch(updateGizerCoinInfo(response.data.balance));
    return response.data.balance;
  });
};
