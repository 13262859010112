import MS from "my-sabay-js-sdk";

export const setSabayAccessAndRefreshToken = (
  instance: any,
  accessTokenString: string,
  refreshTokenString: string
) => {
  const accessToken = new MS.AccessToken(accessTokenString);
  const refreshToken = new MS.RefreshToken(refreshTokenString);
  instance.setRefreshToken(refreshToken);
  instance.setAccessToken(accessToken);
};

export const fetchSabayUserProfileId = (instance: any) => {
  return instance
    .fetchProfile()
    .then((response: any) => {
      return response?.mysabay_user_id;
    })
    .catch((error: any) => {
      console.log(error);
    });
};
