import GoamaPaymentService from "./GoamaPaymentService";

class GoamaTryBasedPaymentService extends GoamaPaymentService {
  // public tournament: ITournament | undefined;

  public async initiatePayment({ tournament }: { tournament: any }) {
    this.tournament = tournament;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve, reject) => {
      (async () => {
        const params = this.getParams();

        params["amount"] = this.tournament?.trial_packages[0]?.currency_value;
        const paymentType = this.tournament?.trial_packages[0]?.currency;

        if (paymentType === "coin") {
          params["token"] = true;
        } else if (paymentType === "ticket") {
          params["ticket"] = true;
        } else {
          params["redirect_link"] = this.generateRedirectURL(tournament, this.getQueryParams());
        }

        params["package"] = this.tournament?.trial_packages[0]?.id;
        params["trial_tournament"] = this.tournament?.id;
        delete params.redirect_link;
        delete params.tournament;

        try {
          const response = await this.setPaymentReferenceCode({
            url: `init/trial/${this.application?.slug}/`,
            params: params,
          });
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }
}

export default GoamaTryBasedPaymentService;
