export const SABAY_USERID_KEY = "sabay-userid";
export const EL_TIEMPO_USERID_KEY = "el-tiempo-userid";

export const APP_SLUGS = {
  BKASH: "bkash",
  METAVERSEGO: "metaversego",
  GOAMA_PH: "goama-ph",
  SABAY: "sabay",
  EL_TIEMPO: "el-tiempo",
  LATAM: "latamgate",
  BTS_DIGITAL: "bts-digital",
  DEMO: "demo",
  FOODPANDA: "foodpanda",
  VIVA: "viva",
  DANA: "dana",
  RAZER: "razer",
  CRIC_BUZZ: "cricbuzz",
  FOODPANDA_APP: "foodpandaapp",
  RAPPI: "rappi",
  ASTROPAY: "astropay",
  PEPSICO: "pepsico", // "pepsico-demo" ||
  GCASH_BPO: "gcashbpo",
  GCASH: "gcash",
  TRENDI: "trendi",
  JASSCASH: "jazzcash",
  COFFEEPRINT: "coffeeprint",
  GIZER: "gizer",
  SOLEPACK: "cerveza-sol",
  SNICKERS: "snickers",
  MEGAFON: "megafon",
  CHOPE_STORMS: "chope-storms",
  GCASH_ALIPLUS: "gcashaliplus",
  DANA_ALIPLUS: "danaaliplus",
  TNG_ALIPLUS: "tngaliplus",
  TNG: "tng",
  YIPPI: "yippi",
  RAPPICO: "rappico",
  NEQUI: "nequi",
  GLOBE: "globe",
  MPT4U: "mpt4u",
  DARKUI: "darkui",
  GOAMALABS: "goamalabs",
  VALEPASS: "valepass",
  BOUNTIE: "bountie",
  UNICO: "unico-unilever",
  KFC: "kfc",
  CARACOL: "caracol",
  XCTUALITY: "xctuality",
  CROYDON: "croydon",
  OOREDOO: "ooredoo",
  GOOGLY: "googly",
  TADA: "tada",
  ARCOR: "arcor",
  APOSTAR: "apostar",
  WINSPORTS: "winsports",
  MOK: "mok",
  PULZO: "pulzo",
  PRESENTE: "presente",
  KLOOK: "klook",
  BURGERKINGRU: "burger-king-ru",
  LPL: "lpl",
  BUBBALOO: "bubbaloo",
  MVOLA: "mvola",
  POXIPOL: "poxipol",
  SKIBIDI_TOILET: "skibidi-toilet",
  B2C_BD: "b2c_bd",
  DIBBLE: "dibble",
  JEDA: "jeda",
  RIDMIK: "ridmik",
  BONOXS: "bonoxs",
  WAVE: "wave-mm",
  NIU: "niu",
  FEGG: "fegg",
  CLARO: "claro",
  CLARO_PAY: "claro-pay",
  DETIKCOM: "detikcom",
  EGESPORTS: "egsports",
  BARCA: "barca-games",
  WOW_GAMES: "wow_games",
  ORANGE: "orange",
  GO3: "go3",
  AVOLTA: "avolta",
  TOUREAST: "toureast",
  CONVERSE: "converse",
  PAOBC: "paobc",
  Goama: "goama",
  POLEMIC_GAMES: "test-register-team",
  MEME_POP: "memepop",
  GO48: "go48",
};

export const RAZER_CURRENCY_CODES: string[] = ["MYR", "SGD", "AUD", "USD", "BRL", "CAD", "TRY"];

export const LAYOUT_TYPES = {
  DARK_V2: "DARK",
  REGULAR: "REGULAR",
};

export const MINI_APP_SLUGS = [
  APP_SLUGS.TNG,
  APP_SLUGS.TNG_ALIPLUS,
  APP_SLUGS.GCASH,
  APP_SLUGS.GCASH_ALIPLUS,
  "gcashbpo",
  "alipay-hk",
  APP_SLUGS.DANA,
  APP_SLUGS.DANA_ALIPLUS,
  APP_SLUGS.JEDA,
];

export const MINI_APP_CACHED_ID_KEY = "MINIAPP_CACHED_USERID";

/**
 * https://bitbucket.org/gogamesteam/js-game-integration/src/master/
 * package.json dependency
 * "js-game-integration": "git+https://goama_guest:zZLQPUAhGSLnQeL7jeJc@bitbucket.org/gogamesteam/js-game-integration.git
 */
export const GG_GET_GAME_DATA = "GG_GET_GAME_DATA";
export const GG_UPDATE_GAME_DATA = "GG_UPDATE_GAME_DATA";
export const GG_SET_GAME_DATA = "GG_SET_GAME_DATA";
export const GG_PAUSED_FROM_GAME = "GG_PAUSED_FROM_GAME";
export const GG_PAUSED_FROM_PARENT = "GG_PAUSED_FROM_PARENT";
export const GG_QUIT_FROM_PARENT = "GG_QUIT_FROM_PARENT";
export const GG_GAME_OVER = "GG_GAME_OVER";
export const GG_RESUMED_FROM_GAME = "GG_RESUMED_FROM_GAME";
export const GG_RESUMED_FROM_PARENT = "GG_RESUMED_FROM_PARENT";
export const GG_GAME_LOAD_FINISHED = "GG_GAME_LOAD_FINISHED";
