import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";

// import statusAPI from "actions/api/status";

export interface IMission {
  data: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IMission = {
  data: [],
  loading: false,
  error: false
};

const missionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {
    fetchMissionLoading(state) {
      state.loading = true;
    },
    fetchMissionSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchMissionError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const { fetchMissionLoading, fetchMissionSuccess, fetchMissionError } =
  missionSlice.actions;

export default missionSlice.reducer;

export const fetchMissionInfo =
  (
    name: string,
    country: string,
    params?: { limit?: number; claimed?: boolean }
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchMissionLoading());
    return api
      .get(`statuspoints/missions/${name}/${country}/`, { params })
      .then(response => {
        dispatch(fetchMissionSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchMissionError()));
  };
