// import paymentsAPI from "actions/api/payments";
import { useAppSelector } from "redux/Store";
import { useState, useContext } from "react";

import { SdkContext } from "providers/client-sdk-providers";
import api from "actions/api";

// function generateRedirectURL(queryParams: any) {
//   const location = window.location;
//   const baseURL = `${location.protocol}//${location.host}/token-profile`;

//   let params = "";

//   Object.keys(queryParams).map(key => {
//     const value = encodeURIComponent(`${queryParams[key]}`);
//     params += `${key}=${value}&`;
//     return null;
//   });
//   return `${baseURL}?${params}`;
// }

interface IUseAddTokenNowButtonConfig {
  handlePayment?: {
    onSuccess?: Function;
    onError?: Function;
    onFinally?: Function;
  };

  footer?: {
    onClick: Function;
  };
}

export default function useTokenTournament(
  value: any,
  tournament_id: any,
  paymentType?: any,
  config?: IUseAddTokenNowButtonConfig,
) {
  const { application, country, user } = useAppSelector((state: any) => state.common);
  const { isDesktop } = useAppSelector((state: any) => state.layout) || null;
  const sdk = useContext(SdkContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onPaymentConfirmationClick = (): any => {
    const queryParams: any = {
      country,
      callback: true,
      app: application?.slug,
      userid: user.username.split("__")[0],
    };

    if (application.is_custom_avatar_enabled && user.extra_data.profile_pic) {
      queryParams.profile_pic = user.extra_data.profile_pic;
    }

    if (application.is_custom_avatar_enabled && user.first_name) {
      queryParams.name = user.first_name;
    }

    setButtonLoading(true);

    // Make call to paymentsAPI

    if (paymentType !== "ticket") {
      return api
        .post(`payments/init/${application?.slug}/`, {
          amount: value,
          tournament: tournament_id,
        })
        .then(response => {
          // Set last reference id into localStorage
          // For verifying upon callback redirect
          localStorage?.setItem(`pvp-token-ref${tournament_id}`, response.data.reference);
          return response;
        })
        .then(response => {
          if (config?.handlePayment?.onSuccess) config.handlePayment.onSuccess(response);
          return response;
        })
        .then(response => {
          if (sdk?.postPaymentConfirmationCallback) {
            sdk.postPaymentConfirmationCallback(response.data);
          } else {
            if (!isDesktop) {
              window.location.replace(response.data?.deeplinkURL || response.data?.checkoutURL || "#");
            }
          }
        })
        .catch(e => config?.handlePayment?.onError && config.handlePayment.onError(e))
        .finally(() => {
          setButtonLoading(false);
          config?.handlePayment?.onFinally && config.handlePayment.onFinally();
        });
    } else {
      return api
        .post(`payments/init/${application?.slug}/`, {
          amount: value,
          tournament: tournament_id,
          ticket: true,
        })
        .then(response => {
          // Set last reference id into localStorage
          // For verifying upon callback redirect
          localStorage?.setItem(`pvp-token-ref${tournament_id}`, response.data.reference);
          return response;
        })
        .then(response => {
          if (config?.handlePayment?.onSuccess) config.handlePayment.onSuccess(response);
          return response;
        })
        .then(response => {
          if (sdk?.postPaymentConfirmationCallback) {
            sdk.postPaymentConfirmationCallback(response.data);
          } else {
            if (!isDesktop) {
              window.location.replace(response.data?.deeplinkURL || response.data?.checkoutURL || "#");
            }
          }
        })
        .catch(e => config?.handlePayment?.onError && config.handlePayment.onError(e))
        .finally(() => {
          setButtonLoading(false);
          config?.handlePayment?.onFinally && config.handlePayment.onFinally();
        });
    }
  };

  return {
    onPaymentConfirmationClick,
    buttonLoading,
    setButtonLoading,
  };
}
