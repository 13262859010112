import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { css, useTheme } from "@emotion/react";
import { Modal } from "react-bootstrap";
// import { ReactComponent as SpinBg } from "assets/images/wheel/spin-wheel-bg.svg";
import { Trans } from "react-i18next";
import spinSound from "assets/sounds/spin-sound.mp3";
import { useAppSelector } from "redux/Store";
import EntryFee from "components/common/EntryFee";

type ChildHandle = {
  forceSpin: () => void;
};

// eslint-disable-next-line react/display-name
const Wheel = forwardRef<ChildHandle, any>(
  (
    {
      wheelOptions,
      stopAngle,
      numberOfTries,
      spinWheelApi,
      setShowWheel,
      setShowSpinReward,
      updateTicketAndCoin,
      purchaseSpin,
      loading,
    }: any,
    ref,
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const sliceCount: number = wheelOptions.length || 8;
    const [isSpinning, setIsSpinning] = useState(false);
    const theme = useTheme();
    const spinAudio = useRef(new Audio(spinSound));
    const { application } = useAppSelector(state => state.common);
    const perSpinCost = application?.per_spin_cost;

    useImperativeHandle(ref, () => ({
      forceSpin: () => {
        handleSpinWheel();
      },
    }));
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (!ctx) return;
      const centerX = canvas?.width ? canvas.width / 2 : 0;
      const centerY = canvas?.height ? canvas.height / 2 : 0;
      const outerCircleRadius = 200;
      const distanceFromEdge = 10;
      const innerCircleRadius = 182;
      const drawOuterCircle = () => {
        ctx.beginPath();
        ctx.arc(centerX, centerY, outerCircleRadius, 0, Math.PI * 2);
        ctx.fillStyle = "#775639";
        ctx.fill();
        ctx.closePath();
      };
      const drawDots = () => {
        for (let angle = 0; angle < 360; angle += 26) {
          const radians = (angle * Math.PI) / 180;
          const smallCircleX = centerX + (outerCircleRadius - distanceFromEdge) * Math.cos(radians);
          const smallCircleY = centerY + (outerCircleRadius - distanceFromEdge) * Math.sin(radians);
          ctx.beginPath();
          ctx.arc(smallCircleX, smallCircleY, 5, 0, Math.PI * 2);
          ctx.fillStyle = theme.button.primaryGradientStop;
          ctx.shadowColor = "rgba(0, 0, 0, 0.1)";
          ctx.shadowBlur = 5;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 5;
          ctx.fill();
          ctx.closePath();
        }
      };

      const drawSlices = (): void => {
        const imagesLoaded: HTMLImageElement[] = [];
        const shadowBlur = 3;
        const loadImage = (url: string, callback: () => void): void => {
          const img: HTMLImageElement = new Image();
          img.onload = callback;
          img.src = url;
          imagesLoaded.push(img);
        };

        for (let i = 0; i < sliceCount; i++) {
          loadImage(wheelOptions[i]?.image, () => {
            if (imagesLoaded.length === sliceCount) {
              drawSliceImages(imagesLoaded);
            }
          });
        }

        const drawSliceImages = (imagesLoaded: HTMLImageElement[]): void => {
          for (let i = 0; i < sliceCount; i++) {
            const img: HTMLImageElement = imagesLoaded[i];
            const isPhysicalReward: boolean = wheelOptions[i]?.type === "PHYSICAL_REWARD";
            const startAngle: number = (i * 360) / sliceCount;
            const endAngle: number = ((i + 1) * 360) / sliceCount;
            const sliceAngle: number = (startAngle + endAngle) / 2;

            ctx.save();
            ctx.shadowColor = "rgba(0, 0, 0, 0.1)";
            ctx.shadowBlur = shadowBlur;
            const shadowOffsetX: number = shadowBlur * Math.cos((startAngle * Math.PI) / 180);
            const shadowOffsetY: number = shadowBlur * Math.sin((startAngle * Math.PI) / 180);
            ctx.shadowOffsetX = shadowOffsetX;
            ctx.shadowOffsetY = shadowOffsetY;
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(
              centerX,
              centerY,
              innerCircleRadius,
              (startAngle * Math.PI) / 180,
              (endAngle * Math.PI) / 180,
              false,
            );
            ctx.closePath();
            if (wheelOptions[i]?.background_color) {
              ctx.fillStyle = wheelOptions[i].background_color;
            } else {
              ctx.fillStyle = i % 2 === 0 ? theme.button.tertiaryButtonBackground : theme.button.primaryGradientStart;
            }
            ctx.fill();
            ctx.restore();

            const text = wheelOptions[i]?.text;
            const minFontSize = 19;
            const maxFontSize = 36;
            const fontSizeIncrease = 4.5;
            const fontSize = Math.min(maxFontSize, minFontSize + (8 - sliceCount) * fontSizeIncrease);
            ctx.font = `800 ${fontSize}px Poppins`;
            ctx.fillStyle = "#2d2d2d";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            const baseFactor = 0.48;
            const factorDecrease = 0.02;
            const factor = sliceCount >= 8 ? baseFactor : baseFactor - (8 - sliceCount) * factorDecrease;
            const textX = centerX + innerCircleRadius * factor * Math.cos((sliceAngle * Math.PI) / 180);
            const textY = centerY + innerCircleRadius * factor * Math.sin((sliceAngle * Math.PI) / 180);

            ctx.save();
            ctx.translate(textX, textY);
            ctx.rotate((sliceAngle * Math.PI) / 180 + Math.PI / 2);
            if (!isPhysicalReward) {
              ctx.fillText(text, 0, 0);
            }
            ctx.restore();

            let scale: number;

            if (isPhysicalReward) {
              scale = 60 / Math.max(img.width, img.height);
            } else {
              scale = 60 / Math.max(img.width, img.height);
            }
            const imgX = centerX + (innerCircleRadius / 1.35) * Math.cos((sliceAngle * Math.PI) / 180);
            const imgY = centerY + (innerCircleRadius / 1.35) * Math.sin((sliceAngle * Math.PI) / 180);
            ctx.save();
            ctx.translate(imgX, imgY);
            ctx.rotate((sliceAngle * Math.PI) / 180 + Math.PI / 2);
            ctx.drawImage(
              img,
              (-img.width * scale) / 2,
              (-img.height * scale) / 2,
              img.width * scale,
              img.height * scale,
            );
            ctx.restore();
          }
        };
      };

      drawOuterCircle();
      drawDots();
      drawSlices();
    }, [wheelOptions, sliceCount, theme]);
    const calcSliceAngle = slice => {
      if (slice >= 7 && slice <= 10) {
        return 67.5;
      }
      if (slice >= 5 && slice <= 6) {
        return 52.5;
      }
      switch (slice) {
        case 4:
          return 45;
        case 3:
          return 27.5;
        case 2:
          return 182.5;
        default:
          return 0;
      }
    };

    const wheelAnimation = useCallback(() => {
      const fullRotations = 7 * 360;
      const finalRotation = fullRotations - stopAngle - (stopAngle === 0 ? 90 : calcSliceAngle(wheelOptions.length));

      const existingStyleTag = document.getElementById("spin-animation-style");
      if (existingStyleTag) {
        existingStyleTag.remove();
      }
      const spinAnimation = `
      @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(${finalRotation}deg); }
      }
    `;
      const styleTag = document.createElement("style");
      styleTag.id = "spin-animation-style";
      styleTag.textContent = spinAnimation;
      document.head.appendChild(styleTag);
      const canvas = canvasRef.current;

      if (canvas) {
        canvas.style.animation = "spin 4500ms  cubic-bezier(0.1, -0.01, 0, 1) forwards";
        canvas.addEventListener(
          "animationend",
          () => {
            setTimeout(() => {
              setIsSpinning(false);
              setShowWheel(false);
              setShowSpinReward(true);
              updateTicketAndCoin();
            }, 2500);
          },
          { once: true },
        );
      }
    }, [stopAngle, setShowWheel, setShowSpinReward, updateTicketAndCoin, wheelOptions.length]);

    const handleSpinWheel = () => {
      setIsSpinning(true);
      spinAudio.current.currentTime = 0;
      spinAudio.current.play();
      spinWheelApi().then(() => {
        wheelAnimation();
        setTimeout(() => {
          spinAudio.current.pause();
          spinAudio.current.currentTime = 0;
        }, 5000);
      });
    };

    useEffect(() => {
      if (stopAngle !== undefined && isSpinning) {
        wheelAnimation();
      }
    }, [stopAngle, wheelAnimation, isSpinning]);

    if (sliceCount <= 0) {
      return (
        <div>
          <Trans i18nKey="something-went">Something went wrong try again</Trans>
        </div>
      );
    }
    return (
      <Modal.Body>
        {/* <div className="spin-bg">
          <SpinBg />
        </div> */}
        <div css={spinWheelStyles}>
          <div className="wheel-container">
            <div className="arrow"> </div>
            <div className="wheel">
              <canvas ref={canvasRef} id="wheel" width="400" height="400" />
            </div>

            {numberOfTries === 0 && !isSpinning ? (
              <button
                disabled={isSpinning || loading}
                id="btn-spin"
                onClick={() => {
                  (async () => {
                    await purchaseSpin();
                  })();
                }}
              >
                {/* <Trans i18nKey="buy-spin">Spin with</Trans>&nbsp; */}
                <Trans i18nKey="pay">Pay</Trans>&nbsp;
                <EntryFee payMethod={"cash"} entry_fee={perSpinCost} />
                &nbsp;
                <Trans i18nKey="to-spin">to Spin</Trans>&nbsp;
              </button>
            ) : (
              <button id="btn-spin" onClick={handleSpinWheel} disabled={isSpinning ? true : false}>
                {isSpinning ? <Trans i18nKey="spinning">Spinning</Trans> : <Trans i18nKey="spin">Spin</Trans>}
              </button>
            )}
          </div>
          <span className={`spin-left ${numberOfTries <= 0 ? "spin-left-disable" : "spin-left-enable"} `}>
            <Trans i18nKey="free-spins">Free Spins: </Trans>
            {numberOfTries}
          </span>
        </div>
      </Modal.Body>
    );
  },
);

export default Wheel;

const spinWheelStyles = (theme: ITheme) => css`
  padding-top: 10px;
  position: relative;
  .wheel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    border-radius: 12px;
  }

  #btn-spin {
    border: none;
    outline: none;
    background-color: ${theme.button.primaryGradientStart};
    height: 43px;
    width: 100%;
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    max-width: 60%;
    border-radius: 12px;
    color: ${theme.button.buttonTextColor};
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
    &:disabled {
      border-radius: 14px;
      background: #c4c4c4;
      box-shadow: 0px 1px 12px 0px rgba(87, 87, 87, 0.3);
      cursor: not-allowed;
    }
  }

  .arrow {
    border-color: ${theme.button.primaryGradientStop} transparent transparent;
    border-style: solid;
    border-width: 30px 20px 0;
    height: 0;
    width: 0;
    position: absolute;
    top: -15px;
    z-index: 999;
  }
  .wheel {
    box-shadow: 0px 9px 20px 4px rgba(92, 67, 44, 0.77);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
  }
  canvas {
    width: 100%;
    height: 100%;
    transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
  }
  .spin-left {
    width: 140px;
    padding: 5px;
    border-radius: 14px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
    color: #fef;
    text-align: center;
    bottom: -60px;
    left: 30%;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .spin-left-disable {
    background-color: ${theme.text.tertiaryTextColor};
  }
  .spin-left-enable {
    background-color: ${theme.button.secondaryButtonBackground};
  }
`;
