import React, { useState } from "react";
import { css, useTheme } from "@emotion/react";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/Store";
import cn from "classnames";
import RankList from "./RankList";
import FriendList from "./FriendList";
import DummyRankList from "./DummyRankList";
import { ReactComponent as FaqIcon } from "assets/images/info.svg";
import useRankingButton from "hooks/useRankingButton";
import { useHistory } from "hooks/useHistory";
import { isRappico } from "utils/applicationSlug";
import { toggleGameListPopup } from "redux/reducers/modalReducer";

const headerStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};

  &.triangle {
    color: ${theme.foreground.primary};
    .faq-icon {
      fill: ${theme.foreground.primary};
    }

    .reward-sty {
      color: ${theme.text.primary};
    }
    a {
      text-decoration: none;
    }
    a:focus {
      outline: 0;
    }
  }
  &.globe {
    .reward-sty {
      color: #1a458b;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  .faq-icon {
    fill: ${theme.text.primary}!important;
  }
  .faqCircleStyle {
    padding: 0;
    border: 0;
    background-color: transparent;
    &:focus {
      outline: 0;
    }
  }
`;

const activeButtonCss = (theme: ITheme) => css`
  background-color: ${theme.button.secondaryButtonBackground};
  color: ${theme.button.buttonTextColor};
  font-weight: 600;
  margin-top: 2%;
  margin-bottom: 3%;
  border-radius: 2.5rem;
  text-align: center;
  outline: none;
  width: 185px;
  height: 41px;
  font-size: 15px;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: white;
  }

  @media screen and (max-width: 360px) {
    width: 175px;
    height: 35px;
    font-size: 14px;
  }
`;

const defaultButtonCss = (theme: ITheme) => css`
  color: ${theme.text.secondary};
  text-align: center;
  border: none;
  border-radius: 17px;
  background: none;
  width: 170px;
  font-size: 15px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    color: #706967;
  }

  @media screen and (max-width: 360px) {
    width: 175px;
    font-size: 14px;
  }
`;

const themeStyles = () => css`
  &.triangle {
    .card,
    .rank-list-container {
      box-shadow: none;
      padding-bottom: 15%;
    }
  }
`;

interface IRankListContainerProps {
  tournament: ITournament;
  participant?: IParticipant;
}

export default function RankListContainer({ tournament, participant }: IRankListContainerProps) {
  const theme: ITheme = useTheme();
  const { application } = useAppSelector((state: any) => state.common);
  const [visibleRankList, setVisibleRankList] = useState<"country" | "friends">("country");
  const { t } = useTranslation("translation", { useSuspense: false });
  const { hideLeaderboardPopUp } = useRankingButton();
  const dispatch = useAppDispatch();
  const history = useHistory();

  if (tournament?.leaderboard_setting === "disabled") return null;

  const handleFaq = () => {
    dispatch(toggleGameListPopup());
    hideLeaderboardPopUp();
    history.push("/tournaments/faq");
  };

  return (
    <>
      <div
        className={cn(`d-flex justify-content-between align-items-center rank-list-card ${application?.slug}`, {
          triangle: theme.layout === "TRIANGLE",
          boxed: theme.layout === "BOXED",
        })}
        css={headerStyle}
      >
        <span className="pt-3 pb-2 reward-sty title-md">
          <b>
            <Trans i18nKey="ranking-and-rew">Ranking & Rewards</Trans>
          </b>
        </span>

        {application?.is_faq_enabled && (
          <button className="faqCircleStyle" onClick={handleFaq}>
            <FaqIcon className="faq-icon" />
          </button>
        )}
      </div>

      <div
        css={themeStyles}
        className={cn({
          triangle: theme.layout === "TRIANGLE",
          boxed: theme.layout === "BOXED",
        })}
      >
        <div>
          {application.is_friend_ranking_enabled && (
            <div className="d-flex justify-content-between mb-3">
              <button
                onClick={() => setVisibleRankList("country")}
                css={visibleRankList === "country" ? activeButtonCss : defaultButtonCss}
                className={cn({
                  "default-color": visibleRankList !== "country" && isRappico,
                })}
              >
                <Trans i18nKey="leaderboard">Ranking</Trans>
              </button>

              <button
                onClick={() => setVisibleRankList("friends")}
                css={visibleRankList === "friends" ? activeButtonCss : defaultButtonCss}
                className={cn({
                  "default-color": visibleRankList !== "friends" && isRappico,
                })}
              >
                <Trans i18nKey="friends">Friends</Trans>
              </button>
            </div>
          )}
          <div>
            <div
              className={cn({
                "d-none": visibleRankList !== "country",
              })}
            >
              {tournament?.entry_fee === 0 || participant ? (
                <RankList tournament={tournament} />
              ) : (
                <DummyRankList
                  overlayBackgroundColor="#61149b"
                  overlayText={t("play-one-game-t", {
                    defaultValue: "Play one game to unlock leaderboard",
                  })}
                />
              )}
            </div>
            {application.is_friend_ranking_enabled && (
              <div
                className={cn({
                  "d-none": visibleRankList !== "friends",
                })}
              >
                {participant ? (
                  <FriendList tournament={tournament} tournamentId={tournament?.id} participantId={participant?.id} />
                ) : (
                  <DummyRankList
                    overlayBackgroundColor="#168099"
                    overlayText={t("you-have-not-pr", {
                      defaultValue: "You have not provided access to your contact list",
                    })}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
