import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";

export interface IWheelInfo {
  spin_balance: number;
  loading: boolean;
  error: boolean;
}

const initialState: IWheelInfo = {
  spin_balance: 0,
  loading: false,
  error: false,
};

const wheelInfoSlice = createSlice({
  name: "spinWheelInfo",
  initialState,
  reducers: {
    fetchSpinBalanceLoading(state) {
      state.loading = true;
    },
    fetchSpinBalanceSuccess(state, action) {
      state.spin_balance = action.payload.spin_balance;
      state.loading = false;
      state.error = false;
    },
    fetchSpinBalanceError(state) {
      state.spin_balance = 0;
      state.loading = false;
      state.error = true;
    },
    deductSpinBalance(state) {
      state.spin_balance = state.spin_balance - 1;
    },
    addSpinBalance(state) {
      state.spin_balance = state.spin_balance + 1;
    },
    setSpinBalance(state, action) {
      state.spin_balance = action.payload;
    },
  },
});

export const {
  fetchSpinBalanceLoading,
  fetchSpinBalanceSuccess,
  fetchSpinBalanceError,
  deductSpinBalance,
  addSpinBalance,
  setSpinBalance,
} = wheelInfoSlice.actions;

export default wheelInfoSlice.reducer;

export const fetchSpinTheWheelBalance = () => async (dispatch: AppDispatch, getState) => {
  const { country } = getState().common;
  if (!country) return;
  dispatch(fetchSpinBalanceLoading());
  return api
    .get(`balance/spin/${country}/`)
    .then(response => {
      dispatch(fetchSpinBalanceSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchSpinBalanceError()));
};
