import { css } from "@emotion/react";
import GT_WALSHEIM from "assets/fonts/GT Walsheim/GTWalsheimPro-Medium.ttf";

const cervezaSolStyles = css`
  @font-face {
    font-family: "GT_Walsheim";
    src: local("GT_Walsheim"), url(${GT_WALSHEIM}) format("truetype");
  }

  &.cerveza-sol {
    .body,
    * {
      font-family: "GT_Walsheim";
    }
    .registration-text {
      color: #000;
    }
  }
`;

export default cervezaSolStyles;
