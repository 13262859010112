import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
export interface LoginState {
  login: string;
  loading: boolean;
  error: boolean;
}

const defaultState: LoginState = {
  login: '',
  loading: false,
  error: false,
};

const authUserLoginSlice = createSlice({
  name: 'authUserLogin',
  initialState: defaultState,
  reducers: {
    fetchAuthLoginUrlLoading(state) {
      state.loading = true;
      state.error = false;
    },
    fetchAuthLoginUrlSuccess(state, action: PayloadAction<string>) {
      state.login = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchAuthLoginUrlError(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchAuthLoginUrlLoading,
  fetchAuthLoginUrlSuccess,
  fetchAuthLoginUrlError,
} = authUserLoginSlice.actions;

export default authUserLoginSlice.reducer;


/* export const authUserLoginReducers = (
  state: LoginState = defaultState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.FETCH_AUTH_LOGIN_URL_LOADING:
      return { ...state, loading: true, error: false };
    case ActionTypes.FETCH_AUTH_LOGIN_URL_SUCCESS:
      return {
        login: action.payload,
        loading: false,
        error: false
      };
    case ActionTypes.FETCH_AUTH_LOGIN_URL_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
 */