import Modal from "react-bootstrap/Modal";
import React from "react";
import { css, useTheme } from "@emotion/react";
import { Watch } from "react-loader-spinner";
import { Trans } from "react-i18next";

const loadingModalStyle = () => css`
  z-index: 100004 !important;

  .modal-content {
    border-radius: 15px;
    width: 70%;

    @media (min-width: 1022px) {
      width: 70%;
    }
  }

  .checking-text {
    font-size: 15px;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    font-weight: 600;
  }
`;

export default function PaymentLoaderModal({ show }: { show: boolean }) {
  const theme: ITheme = useTheme();
  const watchColor = theme.button.primaryGradientStart;

  return (
    <Modal
      show={show}
      closeButton
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 100000 }}
      css={loadingModalStyle}
      className="desktop-modal p-0"
      backdrop="static"
    >
      <div className="p-4">
        <Watch
          height="80"
          width="80"
          radius="48"
          color={watchColor}
          ariaLabel="watch-loading"
          wrapperStyle={{}}
          wrapperClass="justify-content-center"
          visible={true}
        />
        <div className="checking-text">
          <Trans i18nKey="checking-subscr">Checking Subscription!</Trans>
        </div>
      </div>
    </Modal>
  );
}
