import { createSlice } from "@reduxjs/toolkit";
import { apiUserServiceBase } from "actions/api";
import { AppDispatch } from "redux/Store";
// import notificationAPI from "actions/api/notification";

export interface INotification {
  notifyData: any[];
  notifyPopUp: any[];
  loading: boolean;
  error: boolean;
}

const initialState: INotification = {
  notifyData: [],
  notifyPopUp: [],
  loading: false,
  error: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    fetchNotificationLoading(state) {
      state.loading = true;
    },
    fetchNotificationSuccess(state, action) {
      state.notifyData = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchNotificationError(state) {
      state.notifyData = [];
      state.loading = false;
      state.error = true;
    },
    pushNotificationPopUpItem(state, action) {
      state.notifyPopUp = [action.payload, ...state.notifyPopUp];
    },
    clearNotificationPopUpItem(state, action) {
      if (action.payload) {
        state.notifyPopUp = state.notifyPopUp.filter((n: any) => action.payload !== n.notification_id);
      } else {
        state.notifyPopUp = [];
      }
    },
  },
});

export const {
  fetchNotificationLoading,
  fetchNotificationSuccess,
  fetchNotificationError,
  pushNotificationPopUpItem,
  clearNotificationPopUpItem,
} = notificationSlice.actions;

export default notificationSlice.reducer;

export const fetchNotificationList =
  (user_id: string, app_slug: string, last_notification_id: any, status: string, limit: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchNotificationLoading());
    return apiUserServiceBase
      .post("api/user-notification/", {
        user_id,
        app_slug,
        last_notification_id,
        status,
        limit,
      })
      .then(response => {
        dispatch(fetchNotificationSuccess(response.data));
        return response.data;
      })
      .catch(() => dispatch(fetchNotificationError()));
  };

// export const notificationReducer = (
//   state: INotification = initialState,
//   action: any
// ) => {
//   switch (action.type) {
//     case ActionTypes.SHOW_NOTIFICATIONSTATUS:
//       return { ...state, notifyStatus: true };
//     case ActionTypes.HIDE_NOTIFICATIONSTATUS:
//       return { ...state, notifyStatus: false };
//     default:
//       return state;
//   }
// };
