import { css } from "@emotion/react";

const yippiStyles = css`
  &.yippi {
    .title-text {
      color: black !important;
    }
  }
`;

export default yippiStyles;
