import { css } from "@emotion/react";

const tadaStyles = (theme: ITheme) => css`
  &.tada {
    .icon-container {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: #ffffff;
        color: #ffffff;
        box-shadow: 0 0 0px 1000px ${theme.background.primary} inset;
        transition: background-color 3000s ease-in-out 0s;
        background-color: ${theme.background.primary} !important;
      }
      .input-wrapper {
        input {
          color: #ffffff;
          &:focus {
            background-color: ${theme.background.primary};
          }
        }
      }
      .icon-right {
        svg {
          color: #ffffff;
        }
      }
      select {
        background-color: ${theme.background.primary};
        border-radius: 15px;
        color: #ffffff;
        &:focus {
          border: 0;
          box-shadow: none;
        }
      }
      .input-wrapper:focus-within input {
        background-color: ${theme.background.primary};
      }
    }
  }
`;

export default tadaStyles;
