import { SyntheticEvent } from "react";
import DefaultThumbnail from "assets/images/default_thumbnail.jpg";
import DefaultIcon from "assets/images/default_icon.png";

type ImageLoadEvent = SyntheticEvent<HTMLImageElement, Event>;

const useImageError = (): {
  onImageError: (event: ImageLoadEvent) => void;
  onIconError: (event: ImageLoadEvent) => void;
} => {
  const onImageError = (event: ImageLoadEvent): void => {
    event.currentTarget.src = DefaultThumbnail;
  };

  const onIconError = (event: ImageLoadEvent): void => {
    event.currentTarget.src = DefaultIcon;
  };

  return {
    onImageError,
    onIconError,
  };
};

export default useImageError;
