import React, { useCallback } from "react";
import { css } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import Button from "react-bootstrap/Button";
import { FaRedo } from "react-icons/fa";
import { fetchActiveTournaments, fetchUserTournaments } from "actions";

const pageTitleStyles = (theme: ITheme) => css`
  h1 {
    font-size: 22px;
    margin-bottom: 0%;
    font-weight: 600;
  }
  h6.subtitle {
    color: ${theme.text.secondary};
    padding-bottom: 5px;
  }
  .btn.btn-link {
    color: ${theme.text.primary};
    &:hover {
      color: ${theme.text.primary};
    }
  }
`;

interface IPageTitleProps {
  children: React.ReactNode;
  showReload?: boolean;
}

export default function PageTitle({
  children,
  showReload = true
}: IPageTitleProps) {
  const dispatch = useAppDispatch();

  const { application, country } = useAppSelector((state: any) => state.common);

  const { loading: tournamentsLoading } = useAppSelector(
    (state: any) => state.tournaments
  );

  const refresh = useCallback(() => {
    if (!tournamentsLoading) {
      dispatch(fetchActiveTournaments(application?.slug, country));
      dispatch(fetchUserTournaments(application?.slug, country));
    }
    // eslint-disable-next-line
  }, [dispatch, tournamentsLoading]);

  return (
    <div className="container page-title" css={pageTitleStyles}>
      <div className="d-flex justify-content-between align-items-center">
        {children}
        {showReload && (
          <Button variant="link" aria-label="Reload">
            <FaRedo onClick={refresh} />
          </Button>
        )}
      </div>
    </div>
  );
}
