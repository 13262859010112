import { css } from "@emotion/react";

const winSportsStyles = css`
  &.winsports {
    * {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .list-group-item {
      border-radius: 0 !important;
    }
    .avatarCss,
    .avatar-container,
    .desktop-loader,
    .loader-wrapper,
    .loading-style {
      border-radius: 50% !important;
    }
    .desk-width {
      .icon-container {
        .sub-button {
          background: #ff540e !important;
          color: #ffffff !important;
        }
      }
    }
    .registration-text {
      color: #000000;
    }
    .top-bar-right {
      width: fit-content !important;
    }
    @media (width > 48rem) {
      .top-bar-right {
        width: 47.5rem !important;
      }
    }
    .top-bar-container {
      background-color: #ffffff;
    }
    .recommended-tournament-container {
      display: none !important;
    }
  }
`;

export default winSportsStyles;
