import React, { createContext, useCallback, useState, useEffect } from "react";

interface IWindowSize {
  breakpoint: number;
  width: number;
  height: number;
}

const defaultSize: IWindowSize = {
  breakpoint: 1022,
  width: window.innerWidth,
  height: window.innerHeight
};

export const WindowSizeContext = createContext(defaultSize);

export default function WindowSizeProvider({ children }: any) {
  const [size, setSize] = useState<IWindowSize>(defaultSize);

  const handleResize = useCallback(() => {
    setSize({
      ...defaultSize,
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <WindowSizeContext.Provider value={size}>
      {children}
    </WindowSizeContext.Provider>
  );
}
