export const isTournamentTryBased = (tournament: ITournament) => {
  return tournament?.trial_packages?.length > 0 && tournament?.entry_fee === 0;
};

export const isTournamentTryAvailable = (tournament: ITournament) => {
  return isTournamentTryBased(tournament) && tournament?.trial_balance > 0;
};

export const getTournamentTryText = (tournament: ITournament) => {
  return tournament?.trial_balance === 1 ? "try" : "tries";
};

export const isTournamentEntryFeeVoucher = (tournament: ITournament) => {
  return tournament?.entry_fee_type === "voucher";
};

export const getVoucherApplyText = () => {
  return "Apply Coupon";
};

export const isRegularTournament = (tournament: ITournament) => {
  return tournament?.tournament_type === "regular";
};

export const isPVPTournament = (tournament: ITournament) => {
  return tournament?.tournament_type === "instant-pvp";
};
