import { css } from "@emotion/react";
import ALEGREYA_SC_BLACK from "assets/fonts/EG Esports/AlegreyaSC-Black.ttf";
import POPPINS_BLACK from "assets/fonts/EG Esports/Poppins-Black.ttf";

const egEsportsStyles = css`
  @font-face {
    font-family: "Alegreya SC Black";
    src: local("Alegreya SC Black"), url(${ALEGREYA_SC_BLACK}) format("truetype");
  }

  @font-face {
    font-family: "Poppins Black";
    src: local("Poppins Black"), url(${POPPINS_BLACK}) format("truetype");
  }

  &.egsports {
    h1 {
      font-family: "Alegreya SC Black", sans-serif;
    }

    h2 {
      font-family: "Poppins Black", sans-serif;
    }

    .has-back-btn {
      svg {
        fill: #f4ded3;

        line {
          stroke: #f4ded3;
        }
      }
    }

    .top-bar-container {
      .auth-btn {
        button {
          background: #f4ded3;
        }
      }
    }

    .icon-play {
      fill: #614938;
    }

    .home-container {
      .header {
        font-family: "Alegreya SC Black", sans-serif;
        color: #f4ded3;
      }
    }

    .my-games-page,
    .game-page-inner {
      .title-md {
        font-family: "Alegreya SC Black", sans-serif;
        color: #f4ded3;
      }
    }

    .tournament-games-page {
      padding-top: 5.625rem;
    }

    .top-tournament-full-width {
      .title-md {
        margin: 0 !important;
      }
    }

    .top-tournament-card {
      .top-bar-container,
      .bottom-bar-container {
        background-color: #e06710;
      }

      .img-style {
        top: 14%;
        height: 13.25rem;
      }

      .bottom-bar-container {
        .bottom-bar-inside {
          height: 4.5rem;

          span {
            color: #ffffff;
          }
        }
      }
    }

    .signup-page,
    .login-page,
    .forget-password-page,
    .verify-otp-page {
      .login-style {
        font-family: "Alegreya SC Black", sans-serif;
        color: #ffffff;
      }

      .icon-container {
        background-color: #ffffff;

        form {
          input {
            background-color: #f4ded3;
            color: #614938;
          }

          .form-check-input {
            background-color: #614938;
          }

          .sub-button {
            background-color: #614938;
            color: #f4ded3;
          }
        }
      }
    }

    .profile-history-wrapper {
      background-color: #ffffff;

      .back-ground {
        background-color: #614938;
        color: #f4ded3;
      }
    }

    .desktop-modal-content-body {
      .btn-sign-up,
      .btn-login {
        color: #f4ded3;
      }
    }

    .browse-game-card.triangle {
      .triangle-bg {
        fill: #44bdd3;
      }
    }

    .modal_header,
    .leaderboard-wrapper {
      background-color: #614938;

      .close {
        fill: #f4ded3;
      }
    }

    .leaderboard-popup-item {
      .gamename-style,
      .reward-sty {
        color: #ffffff;
      }

      .title-header {
        color: #f4ded3;
      }

      .lower-div {
        .icon {
          background: #44bdd3;

          svg {
            fill: #f4ded3;
          }
        }
      }

      .list-heading {
        div {
          color: #ffffff;
        }
      }

      .list-group-item {
        .text-style-transaction {
          color: #ffffff;
        }
      }
    }

    .user-profile-customization {
      .support-style {
        background: #f4ded3;

        .editor-icon {
          fill: #614938;
        }
      }
    }

    .fontHeadStyle,
    .section-title,
    .show-all-text {
      font-family: "Alegreya SC Black", sans-serif;
      color: #f4ded3;
    }

    .modal-body {
      .update-button {
        background: #614938;
        color: #f4ded3;
      }
    }

    .login-active {
      background: #f4ded3;
      color: #614938;
    }

    .browse-game-item {
      .item-container {
        .browse-game-item {
          background-color: #e06710;

          .rank-btn,
          .play-btn {
            background: #f4ded3;
            fill: #614938;
          }
        }
      }
    }

    .trending-game-carousel {
      h1 {
        color: #f4ded3;
      }
    }

    .arcade {
      .swiper-button-prev,
      .swiper-button-next {
        color: #f4ded3;
      }
    }

    .tns-item {
      .header-first,
      .played-count {
        color: #f4ded3;
      }

      svg {
        fill: #f4ded3;
      }
    }

    .arcade-headerSty,
    .arcade-headerSty2,
    .see-style {
      color: #f4ded3;
    }

    .arcade-game-page {
      h1 {
        font-weight: 400;
      }
    }

    .search-bar {
      input {
        background-color: #614938;
        color: #f4ded3;
      }
    }

    .category-link-card {
      background-color: #e06710;
    }

    .category-link-card.active,
    .btn-load-more {
      background-color: #f4ded3 !important;
      color: #614938 !important;
    }

    .react-select__control {
      background-color: #614938;
    }

    .modal-content {
      color: #614938;

      .registration-text {
        color: #614938;
      }

      button {
        background-color: #f4ded3;
        color: #614938;
        fill: #614938;
      }
    }

    .tournament-history-page {
      .header-style {
        font-family: "Alegreya SC Black", sans-serif;
        color: #f4ded3;
      }

      .white-card {
        .street-text {
          font-family: "Poppins Black", sans-serif;
          color: #f4ded3;
        }
      }

      .second-row {
        .bold-text {
          color: #f4ded3;
        }
      }
    }

    .progress-share-overlay {
      .modal-content {
        background-color: #614938;
      }

      .half-style {
        background: #a16233;

        .latest-score-label,
        .latest-score {
          color: #f4ded3;
        }

        .second-half {
          button {
            background: transparent;
          }

          .btn-circle-with-icon {
            svg {
              fill: #614938;
            }
          }
        }
      }

      .recommended-games-container {
        .header-style {
          color: #f4ded3;
          font-family: "Poppins Black", sans-serif;
          font-size: 1.25rem;
        }
      }
    }

    .custom-popup-container {
      .title-text,
      .coming-soon-text {
        color: #f4ded3;
      }
    }

    .browse-popup {
      .title-md {
        color: #f4ded3;
        font-family: "Poppins Black", sans-serif;
      }

      .tournament-list-item {
        button {
          background: #f4ded3;
          color: #614938;

          svg {
            fill: #614938;
          }
        }
      }
    }

    .top-bar-container {
      left: 0;

      @media (width <= 768px) {
        background-color: #614938;
      }
    }

    .close_wrapper {
      .close {
        fill: #f4ded3;
      }
    }

    .prize-list-container {
      .card-prize {
        .label-rank,
        .label-prize {
          color: #f4ded3;
        }
      }
    }
  }
`;

export default egEsportsStyles;
