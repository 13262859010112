import { AppDispatch } from "redux/Store";
import { apiAds } from "./api";
import {
  fetchAdvertisementsError,
  fetchAdvertisementsLoading,
  fetchAdvertisementsSuccess,
  fetchTournamentAdvertisementsSuccess,
} from "redux/reducers/advertisementsReducer";

export const fetchAdvertisements = (appSlug: string, country: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchAdvertisementsLoading());
  return apiAds
    .get(`adverts/applications/${appSlug}/${country}/`)
    .then(response => {
      dispatch(fetchAdvertisementsSuccess(response.data));
      return response.data;
    })
    .catch(() => {
      dispatch(fetchAdvertisementsError());
    });
};

export const fetchTournamentAdvertisements = (tournamentId: number) => async (dispatch: AppDispatch) => {
  return apiAds
    .get(`adverts/tournaments/${tournamentId}/`)
    .then(response => {
      dispatch(
        fetchTournamentAdvertisementsSuccess({
          tournamentId,
          advertisements: response.data,
        }),
      );
      return response.data;
    })
    .catch(() => {});
};
