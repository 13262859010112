import React from "react";
import { css } from "@emotion/react";
import Confetti from "react-confetti";

import confettiMP3 from "assets/sounds/confetti_gun_01.mp3";
import confettiOGG from "assets/sounds/confetti_gun_01.ogg";
import confettiWAV from "assets/sounds/confetti_gun_01.wav";

const animationCSS = () => css`
  .animation {
    position: relative;
    z-index: 9999999 !important;
  }
`;

const ConfettiAnimation = () => {
  setTimeout(() => {
    const confittiSound: any = document.getElementById("confittiSound");
    confittiSound?.play();
  }, 500);
  return (
    <div css={animationCSS}>
      <audio id="confittiSound">
        <source src={confettiMP3} type="audio/mpeg" />
        <source src={confettiOGG} type="audio/ogg" />
        <source src={confettiWAV} type="audio/wav" />
      </audio>
      <div className="animation">
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          tweenDuration={10000}
        />
      </div>
    </div>
  );
};

export default ConfettiAnimation;
