import { css } from "@emotion/react";
import { APP_SLUGS } from "constants/constants";

export const Orange = css`
  &.${APP_SLUGS.ORANGE} {
    .rank-btn,
    .text-play {
      background-color: #0c60a7 !important;
      color: #ffffff !important;
    }

    .button-orange {
      button {
        color: #ffffff !important;
      }
    }
    .title-md {
      color: #ffffff !important;
    }
    .play-btn-browse,
    .play-btn {
      background: #0c60a7 !important;
      color: #ffffff !important;
    }
    .heroes {
      .first-part {
        background: #0c60a7 !important;
      }
    }

    .rank-list-container {
      h5 {
        color: #ffffff !important;
      }
    }

    .header-container {
      .title {
        color: #ffffff !important;
      }
    }
  }
`;

export default Orange;
