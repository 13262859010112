import { css } from "@emotion/react";

const PolemicGamesStyles = css`
  &.test-register-team {
    .image-down-shadow {
      .button-alignment {
        button {
          background: linear-gradient(90deg, #37def3 0%, #d7ff38 100%);
        }
      }
      .game-details {
        bottom: -1.25rem;
        padding: 0 0.625rem;

        span {
          font-size: 0.75rem;
          line-height: normal;
        }
      }
    }

    .browse-games-rectangular-card {
      background: #242156 !important;

      .count {
        color: #f5f5f5 !important;
      }
    }

    .leaderboard-container {
      .title-md,
      .small-card {
        color: #ffffff;
      }

      .small-card {
        button {
          background: linear-gradient(90deg, #37def3 0%, #d7ff38 100%);

          svg {
            fill: #0d5f80;
          }
        }
      }
    }

    .modal_header,
    .leaderboard-popup-item {
      background: #19173d;
    }

    .modal_header {
      .gamename-style {
        color: #ffffff;
      }
    }

    .leaderboard-popup-item {
      .lower-div-items {
        .lower-div-item {
          color: #ffffff;

          svg {
            fill: #0d5f80;
          }
        }
      }
    }

    .latest-score-container {
      span {
        color: #ffffff;
      }
    }

    .subtitle-text {
      color: #ffffff;
    }

    .rank-player {
      .rank-btn {
        background: transparent;
        border: 0.125rem solid;

        svg {
          fill: #ffffff;
        }
      }
    }

    .rank-timer {
      .play-btn {
        svg {
          fill: #0d5f80;
        }
      }
    }

    .desktop-modal-content-body {
      .title {
        color: #000000 !important;

        @media (width < 63.875rem) {
          color: #ffffff !important;
        }
      }
    }
  }
`;

export default PolemicGamesStyles;
