import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "actions/api/tournaments";

export interface IUserContacts {
  results: any;
  loading: boolean;
  error: boolean;
}
const initialState: IUserContacts = {
  results: {},
  loading: false,
  error: false
};

const userFriendListSlice = createSlice({
  name: "userFriendList",
  initialState,
  reducers: {
    fetchUserFriendListLoading(state) {
      state.loading = true;
    },
    fetchUserFriendListSuccess(state, action) {
      state.results = {
        ...state.results,
        [action.payload.tournamentId]: action.payload.data
      };
      state.loading = false;
      state.error = false;
    },
    fetchUserFriendListError(state) {
      state.results = {};
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchUserFriendListLoading,
  fetchUserFriendListSuccess,
  fetchUserFriendListError
} = userFriendListSlice.actions;

export default userFriendListSlice.reducer;

export const fetchUserContacts = (
  tournamentId: number,
  application: string,
  friends: any[]
) => async (dispatch: AppDispatch) => {
  const data = {
    application,
    friends
  };
  dispatch(fetchUserFriendListLoading());
  return api
    .post(`tournaments/${tournamentId}/friends-ranking/`, data)
    .then(response => {
      dispatch(
        fetchUserFriendListSuccess({ tournamentId, data: response.data })
      );
      return response.data;
    })
    .catch(e => {
      dispatch(fetchUserFriendListError());
      throw e;
    });
};
